export enum ReferenceType {

        // Undefined.
        Undefined = "Undefined",

        // Types de contact.
        ContactTypes = "ContactTypes",

        // Types de contentieux.
        ContentieuxTypes = "ContentieuxTypes",

        // Types de délais.
        DelaiTypes = "DelaiTypes",

        // Classes Locarno.
        LocarnoClasses = "LocarnoClasses",

        // Classes Nice.
        NiceClasses = "NiceClasses",

        // Pays.
        Pays = "Pays",

        // Pays de dossiers.
        DossierPays = "DossierPays",

        // Direction régionale de dossiers.
        RegionalDirections = "RegionalDirections",

        // Tribunaux.
        Tribunaux = "Tribunaux"
}
