import { ExportFormats } from '@models/enums/domain/ExportFormats';
import { ExportType } from '@models/enums/domain/ExportType';
import { IExportRequestFormPoco } from '@models/pocos/forms/IExportRequestFormPoco';
import { ConditionsHelper } from './conditions-helper';
import { ITSearchRequestPoco } from '@models/pocos/ITSearchRequestPoco';
import { UtilsHelper } from './utils-helper';
import { IDataPresenterSpecDto } from '@models/dtos/data/export/IDataPresenterSpecDto';
import { IFieldSpecDto } from '@models/dtos/data/export/IFieldSpecDto';

export class ExportHelper {
  
  static toDto<T extends IExportRequestFormPoco>(poco: ITSearchRequestPoco<T>, columns: IFieldSpecDto[] = null) : T {

    let formPoco: T = <T>{};
    if (poco)
    {
      formPoco = UtilsHelper.clone<T>(poco.formPoco);
      formPoco.q = ConditionsHelper.clauseToScript(poco.queryStatementPoco, columns);
      formPoco.orderBy = ConditionsHelper.orderToScript(poco.orderByStatements);  
    }
    
    return formPoco;
  }

  static toExportRequestForm<T extends IExportRequestFormPoco>(
    exportType: ExportType,
    format: ExportFormats,
    from_requestForm: ITSearchRequestPoco<T>,
    data_dialog: any = null) : ITSearchRequestPoco<T>
  {
    let to_requestForm: ITSearchRequestPoco<T> = <ITSearchRequestPoco<T>> {};
    if (to_requestForm)
    {
      to_requestForm = UtilsHelper.clone<ITSearchRequestPoco<T>>(from_requestForm);

      to_requestForm.formPoco ??= <T> {};
      to_requestForm.formPoco.exportType = exportType;
      to_requestForm.formPoco.format = format;

      to_requestForm.formPoco.maxCount = undefined;
      to_requestForm.formPoco.pageIndex = 0;
      to_requestForm.formPoco.pageSize = undefined;

      ExportHelper.updateFromDialogData(to_requestForm.formPoco, data_dialog);
    }

    return to_requestForm;
  }

  private static updateFromDialogData(
    requestForm: IExportRequestFormPoco,
    data: any)
  {
    if (requestForm) {
      requestForm.columns = data.columns;
      requestForm.documentDisplayName = data.documentDisplayName;
      requestForm.fileName = data.fileName;
      requestForm.culture = data.culture;
    }
  }
  
  static getExtensionFile(format: ExportFormats): string
  {
    switch(format) {
      case ExportFormats.Tsv:
        return "csv";
      case ExportFormats.Excel:
        return "xlsx";
      default:
        return format.toLowerCase()
    }
  }

  static formatText(st: string, obj: any): string
  {
    st = st?.replace('{{timestamp}}', Date.now().toString());
    Object.keys(obj).forEach(q=> st = st?.replace('{{' + q + '}}', obj[q].toString()));

    return st;
  }
  
  static mergePresenterSpec(specs: IDataPresenterSpecDto[]): IDataPresenterSpecDto
  {

    let pSpec: IDataPresenterSpecDto = <IDataPresenterSpecDto> { columns: [], filterColumns: [] };
    specs?.forEach(p=>
      {        
        p?.columns?.forEach(q=>
          {
            if (!pSpec.columns.find(r=> r?.name?.toLowerCase() === q.name?.toLowerCase()))
            {
              pSpec.columns.push(q);
            }              
          });

        p?.filterColumns?.forEach(q=>
          {
            if (!pSpec.filterColumns.find(r=> r?.columnName.toLowerCase() === q?.columnName.toLowerCase()))
            {
              pSpec.filterColumns.push(q);
            }              
          })
      });

      return pSpec;
  }
}