<h2 i18n mat-dialog-title>Contact</h2>

<form #contactForm="ngForm" (ngSubmit)="$event.preventDefault()">
  <div mat-dialog-content class="dialog-text-small mb-2" *ngIf="contact">

    <div class="row">
      <div class="col-6">    
        <div class="row">
          <mat-form-field class="col-12" appearance="outline">
            <mat-label i18n>Nom</mat-label>
            <input name="nom" #inputNom="matInput" matInput [(ngModel)]="contact.denomination" required [readonly]="readonly" />
          </mat-form-field>
        </div>
        <div class="row">
          <references-selector i18n-label class="col-6" [type]="ReferenceTypes.ContactTypes" [(item)]="contact.type" appearance="outline"
            [required]="true" label="Type de contact"></references-selector>
  
          <flags-selector i18n-label class="col-6" [type]="FlagTypes.PersonneTypes" [(itemId)]="contact.personneType" appearance="outline"
            [required]="true" label="Type de personne"></flags-selector>
        </div>
      </div>
      <div class="col-6">
        <adresse-view [(adresse)]="contact.adresse" [readonly]="readonly"></adresse-view>
    
        <div class="row">
          <mat-form-field class="col-6" appearance="outline">
            <mat-label i18n>Telephone</mat-label>
            <input name="telephone" matInput [(ngModel)]="contact.telephone" [readonly]="readonly" />
          </mat-form-field>
    
          <mat-form-field class="col-6" appearance="outline">
            <mat-label i18n>Mobile</mat-label>
            <input name="mobile" matInput [(ngModel)]="contact.mobile" [readonly]="readonly" />
          </mat-form-field>
        </div>    
    
        <div class="row">
          <mat-form-field class="col-12" appearance="outline">
            <mat-label i18n>Email</mat-label>
            <input name="email" matInput [(ngModel)]="contact.email" [readonly]="readonly" />
          </mat-form-field>
        </div>    
      </div>      
    </div>
  </div>

  <mat-dialog-actions align="end" class="pt-3">
    <button i18n mat-flat-button class="secondary-text" mat-dialog-close>ANNULER</button>
    <button i18n i18n-matTooltip mat-raised-button class="primary" (click)="save()"
      [disabled]="!contact.denomination"
      matTooltip="Enregistrer les modifications">ENREGISTRER</button>
  </mat-dialog-actions>
</form>