import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAdresseDto } from '@models/dtos/runtime/magasin/IAdresseDto';
import { ReferenceType } from '@models/enums/domain/ReferenceType';

@Component({
  selector: 'adresse-view',
  templateUrl: './adresse-view.component.html'
})
export class AdresseViewComponent implements OnInit {

  ReferenceTypes = ReferenceType;

  @Input('class')
  public class: string;

  @Input()
  public adresse: IAdresseDto = null;

  @Output()
  public adresseChange: EventEmitter<IAdresseDto> = new EventEmitter<IAdresseDto>();

  @Input('readonly')
  public readonly: boolean = false;

  @Output()
  public itemChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output("valid")
  public validChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }
  
  ngOnInit() {
  }

  hasChanged() {    
    this.adresseChange.emit(this.adresse);
    this.itemChange.emit(true);
  }

  // Form ----------------
  
  get valid(): boolean | null
  {
    let b = !!this.adresse?.pays?.id;
    return b;
  }
}
