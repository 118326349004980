import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { IContentieuxDelaiDto } from '@models/dtos/runtime/dossiers/IContentieuxDelaiDto';
import { IDelaiTypeDto } from '@models/dtos/management/referentiel/IDelaiTypeDto';
import { UtilsHelper } from '@services/helpers/utils-helper';
import { ContentieuxDelaiViewDialogComponent } from './contentieux-delai-view-dialog.component';

@Component({
  selector: 'contentieux-delais-listview',
  templateUrl: './contentieux-delais-listview.component.html'
})
export class ContentieuxDelaisListComponent implements OnInit, OnChanges {

  displayedColumns: string[] = ['type', 'date', 'action', 'star'];
  dataSource: MatTableDataSource<IContentieuxDelaiDto>;

  delaiTypes: IDelaiTypeDto[];

  @Input('class')
  public class: string;
  
  @Input('clientId')
  public clientId: string;

  @Input()
  public items: IContentieuxDelaiDto[];

  @Output()
  public itemsChange: EventEmitter<IContentieuxDelaiDto[]> = new EventEmitter<IContentieuxDelaiDto[]>();

  @Output()
  public selectionChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  
  @Input('excluding')
  public excludingContentieux: IContentieuxDelaiDto[] = [];

  @Input('maxCount')
  public maxCount: number = -1;

  @Input('readonly')
  public readonly: boolean = false;

  @Input('required')
  public required: boolean = false;

  @Output("valid")
  public validChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  get valid(): boolean | null
  {
    let b = !this.required || (this.items && this.items.length>0);
    return b;
  }

  constructor(
    private dialog: MatDialog
  ) {    
  }
  
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.refreshContentieuxDelais();
  }
  
  // Contentieux

  async refreshContentieuxDelais()
  {
    this.setContentieuxDelais(this.items, false);

    this.dataSource = new MatTableDataSource<IContentieuxDelaiDto>(this.items);
  }

  async addNewContentieuxDelai()
  {
    if (this.maxCount === -1 || this.items.length<this.maxCount)
    {
      let contentieuxDelai = <IContentieuxDelaiDto> { date: new Date() };

      const dialogRef = this.dialog.open(
        ContentieuxDelaiViewDialogComponent,
        {
          panelClass: ['w-50', 'zoomed'],
          data: {
            contentieuxDelai: contentieuxDelai
          },
          autoFocus: false
        });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result)
        {
          this.items.push(contentieuxDelai);
          this.setContentieuxDelais(null, true);
        }
      });  
    }
  }

  async viewContentieuxDelai(contentieuxDelai: IContentieuxDelaiDto)
  {
    let cloneContentieuxDelai = UtilsHelper.clone<IContentieuxDelaiDto>(contentieuxDelai);

    const dialogRef = this.dialog.open(
      ContentieuxDelaiViewDialogComponent,
      {
        panelClass: ['w-50', 'zoomed'],
        data: {
          contentieuxDelai: cloneContentieuxDelai
        },
        autoFocus: false
      });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        var index = this.items.indexOf(contentieuxDelai);

        if (index > -1) {
          this.items[index] = cloneContentieuxDelai;
          this.setContentieuxDelais(null, true);
        }
      }
    });    
  }

  async removeContentieuxDelai(contentieuxDelais: IContentieuxDelaiDto)
  {
    let index = this.items.indexOf(contentieuxDelais);
    if (index > -1) {
      this.items.splice(index, 1);
    }
    this.setContentieuxDelais(null, true);
  }
  
  private setContentieuxDelais(contentieuxDelais: IContentieuxDelaiDto[] = null, emit: boolean = true)
  {
    this.items = (contentieuxDelais ?? this.items)
      ?.filter(q=> !(this.excludingContentieux?.find(p=> p.id === q.id)))
      ?.sort((a, b)=> a.date?.toString().localeCompare(b.date?.toString()))?.reverse();

    if (emit) {
      this.itemsChange.emit(this.items);
      this.validChange.emit(this.valid);  
      this.selectionChange.emit(true);
    }
  }
}
