<h2 i18n mat-dialog-title>Historique de renouvellement</h2>

<form #dossierDateForm="ngForm" (ngSubmit)="$event.preventDefault()">
  <div mat-dialog-content class="mb-2 small" *ngIf="dossierDate">
    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label i18n>Choisir une date</mat-label>
        <input name="date" matInput [matDatepicker]="picker" [(ngModel)]="dossierDate.date" required [disabled]="readonly || !dossierDate.estEditable">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="form-row">
      <mat-form-field class="col-8" appearance="outline">
        <textarea i18n-placeholder #inputLibelle="matInput" name="libelle" matInput placeholder="Libellé" [disabled]="readonly || !dossierDate.estEditable"
          [(ngModel)]="dossierDate.libelle"
          cdkTextareaAutosize
          cdkAutosizeMinRows="10"></textarea>
      </mat-form-field>
    </div>
  </div>

  <mat-dialog-actions align="end" class="pt-3" *ngIf="readonly || !dossierDate.estEditable">
    <button i18n mat-flat-button class="primary" mat-dialog-close>OK</button>
  </mat-dialog-actions>

  <mat-dialog-actions align="end" class="pt-3" *ngIf="!readonly && dossierDate.estEditable">
    <button i18n mat-flat-button class="secondary-text" mat-dialog-close >ANNULER</button>
    <button i18n i18n-matTooltip mat-raised-button class="primary mr-2" [disabled]="!valid" (click)="save()" matTooltip="Enregistrer les modifications">ENREGISTRER</button>
  </mat-dialog-actions>
</form>