import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { IUtilisateurDto } from '@models/dtos/management/securite/IUtilisateurDto';
import { PageHistoryService } from '@services/nav/page-history.service';
import { QueryResultModes } from '@models/enums/QueryResultModes';
import { ContactsService } from '@services/business/contacts.service';

@Component({
  selector: 'utilisateur-view',
  templateUrl: './utilisateur-view.component.html'
})
export class UtilisateurViewComponent implements OnChanges {

  @Input("item")
  public item: IUtilisateurDto;

  @Input("itemId")
  public itemId: string;

  @Output("refreshed")
  public refreshed: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  constructor(
    private contactsService: ContactsService,
    private pageHistoryService: PageHistoryService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty("item")) {

      this.loadItem(this.item);      
    }

    if (changes.hasOwnProperty("itemId")) {

      this.item = <IUtilisateurDto> { id: this.itemId};
      this.loadItem(this.item);
    }
  }

  // CRUD

  loadItem(item: IUtilisateurDto)
  {
    if (!item) return;

    this.contactsService.getGestionnaireUtilisateur(item.id, QueryResultModes.Quick).subscribe({
      next: result => {
        
        item.prenom = result?.prenom;
        item.nom = result?.nom;

        this.refreshed.emit(true);
      },
      error: _ => {
      }
    });
  }

  async viewItem(utilisateur: IUtilisateurDto)
  {
    this.pageHistoryService.navigate(['/parameters/users', utilisateur?.id]);
  }
}
