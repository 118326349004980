export enum AppRole {

    /// Any.
    Any = "Any",

    /// None.
    None = "None",

    /// Utilisateur basique.
    FIDAL_MARKMANAGER_MEMBER = "FIDAL_MARKMANAGER_MEMBER",

    /// Gestionnaire.
    FIDAL_MARKMANAGER_MANAGER = "FIDAL_MARKMANAGER_MANAGER",

    /// Visiteur.
    FIDAL_MARKMANAGER_VISITOR = "FIDAL_MARKMANAGER_VISITOR",

    /// Administrateur.
    FIDAL_MARKMANAGER_ADMIN = "FIDAL_MARKMANAGER_ADMIN",

    /// Superviseur.
    FIDAL_MARKMANAGER_SUPER = "FIDAL_MARKMANAGER_SUPER"
}
