import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IDossierDto } from '@models/dtos/runtime/dossiers/IDossierDto';
import { DossierNatures } from '@models/enums/domain/DossierNatures';
import { AddingMode } from '@models/enums/AddingMode';
import { ConfirmationDialogComponent } from '@components/shared/dialogs/confirmation-dialog.component';
import { DossiersSearchDialogComponent } from './dossiers-search-dialog.component';
import { PageHistoryService } from '@services/nav/page-history.service';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { StringHelper } from '@services/helpers/string-helper';
import { DossierStatuts } from '@models/enums/domain/DossierStatuts';
import { ReferentielService } from '@services/business/referentiel.service';
import { IFlagsRequestFormPoco } from '@models/pocos/forms/IFlagsRequestFormPoco';
import { FlagType } from '@models/enums/domain/FlagType';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageKind } from '@models/enums/MessageKind';

@Component({
  selector: 'dossiers-listview',
  templateUrl: './dossiers-listview.component.html'
})
export class DossiersListViewComponent implements OnInit, AfterViewInit, OnChanges {

  DossierStatuts = DossierStatuts;

  @Input('class')
  public class: string;
  
  @Input('pageSize')
  pageSize: number;

  dossierStatuts: any = {};

  motcle: string;

  displayedColumns: string[];
  dataSource: MatTableDataSource<IDossierDto>;

  showPaginator: boolean = true;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  @Input()
  public viewMode: string = "denomination";

  @Input()
  public items: IDossierDto[];

  @Input()
  public viewOnItemClicked: boolean = true;

  @Input()
  public viewOnItemRemoved: boolean = true;

  @Output()
  public itemsChange: EventEmitter<IDossierDto[]> = new EventEmitter<IDossierDto[]>();

  @Output()
  public selectionChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Output()
  public itemClick: EventEmitter<IDossierDto> = new EventEmitter<IDossierDto>();

  @Output()
  public itemRemove: EventEmitter<IDossierDto> = new EventEmitter<IDossierDto>();

  @Input('retourneAbandonnes')
  public retourneAbandonnes: boolean = true;  

  @Input('searchable')
  public searchable: boolean = true;

  @Input('clientId')
  public clientId: string;

  @Input('excluding')
  public excludingDossiers: IDossierDto[] = [];

  @Input('maxCount')
  public maxCount: number = -1;

  @Input('readonly')
  public readonly: boolean = false;

  @Input('required')
  public required: boolean = false;

  @Input('addingMode')
  public addingMode: AddingMode = AddingMode.Existing;  

  @Input('parentId')
  public parentId: number | null = null;

  @Output("valid")
  public validChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  get valid(): boolean | null
  {
    let b = !this.required || (this.items?.length>0);
    return b;
  }

  constructor(
    private pageHistoryService: PageHistoryService,
    private referentielService: ReferentielService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.pageSize ??= GlobalConstants.pageSizeOptions_Dialog[0];    
  }

  ngOnInit(){
    this.refreshFlags();
  }

  ngAfterViewInit() {
    this.updatePaginator();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty("items")) {
      this.refreshItems();
    }
  }

  async refreshItems()
  {
    this.setDossiers(this.items, false);

    let items = this.items?.filter(q=> (!this.motcle 
      || StringHelper.unaccent(q?.pays?.libelle)?.toLowerCase().includes(StringHelper.unaccent(this.motcle)?.toLowerCase()) 
      || q?.id?.toLowerCase().includes(this.motcle?.toLowerCase())
      || q?.reference?.toLowerCase().includes(this.motcle?.toLowerCase())
      || StringHelper.unaccent(q?.denomination)?.toLowerCase().includes(StringHelper.unaccent(this.motcle)?.toLowerCase())));

    this.dataSource = new MatTableDataSource<IDossierDto>(items);

    switch(this.viewMode)
    {
      case 'pays':
        this.displayedColumns = ['reference', 'pays', 'statut', 'star'];
        break;
      default:
        this.displayedColumns = ['reference', 'denomination', 'statut', 'star'];
        break;
    }
    this.updatePaginator();
  }

  async refreshFlags() {

    // DossierStatuts

    this.referentielService.listFlags(<IFlagsRequestFormPoco> { flagType: FlagType.DossierStatuts }).subscribe({
      next: result => {
        this.dossierStatuts = {};
        result.forEach(q=> { this.dossierStatuts[q.key]=q.content; });
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
      }
    });
  }

  async updatePaginator()
  {
    this.showPaginator = this.items?.length > this.pageSize;
    if (this.dataSource)
    {
      this.dataSource.paginator = this.paginator;
    }
  }

  addNewDossier()
  {
    if (this.parentId)
    {
      this.pageHistoryService.navigate(['/folders/trademarks', this.parentId, 'trademarks', 'add']);
    }
    else
    {
      this.pageHistoryService.navigate(['/folders/trademarks/add']);
    }    
  }

  async addExistingDossier()
  {
    if (this.maxCount === -1 || this.items.length<this.maxCount)
    {
      let dossier = <IDossierDto> { };

      const dialogRef = this.dialog.open(
        DossiersSearchDialogComponent,
        {
          panelClass: ['w-50', 'zoomed'],
          data: {
            dossier: dossier,
            clientIds: [this.clientId],
            excluding: [...this.items, ...this.excludingDossiers]
          },
          autoFocus: false
        });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result)
        {
          let dossiers: IDossierDto[] = [];

          if (this.maxCount===1)
          {
            dossiers=result;
          }
          else
          {
            dossiers = this.items;
            let newDossiers = result;

            // new dossiers must not be the existing ones
            newDossiers
              .filter(q=> !(dossiers.find(p=>p.id === q.id)))
              .forEach(q=> { dossiers.push(q); });  
          }

          this.setDossiers(dossiers);
        }
      });  
    }
  }

  async viewDossier(dossier: IDossierDto)
  {
    if (!this.viewOnItemClicked)
    {
      this.itemClick?.emit(dossier);
    }
    else
    {
      switch (dossier?.nature)
      {
        case DossierNatures.Marque:
          this.pageHistoryService.navigate(['/folders/trademarks', dossier?.id]);
          break;
        case DossierNatures.Modele:
          this.pageHistoryService.navigate(['/folders/designs', dossier?.id]);
          break;
      }  
    }
  }

  async removeDossier(dossier: IDossierDto)
  {
    if (this.viewOnItemRemoved)
    {
      this.itemRemove?.emit(dossier);
    }
    else
    {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: $localize `Voulez-vous vraiment retirer cet élément ?`
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result) {
  
          let index = this.items.indexOf(dossier);
          if (index > -1) {
            this.items.splice(index, 1);
            this.setDossiers();
          }  
        }
      });  
    }
  }

  private setDossiers(dossiers: IDossierDto[] = null, emit: boolean = true)
  {
    this.items = (dossiers ?? this.items)
      ?.filter(q=> !this.excludingDossiers?.find(p=>p.id === q.id) && (this.retourneAbandonnes || q?.statut!=DossierStatuts.Abandonne))
      .sort((a, b)=> a?.pays?.libelle?.toLowerCase().localeCompare(b?.pays?.libelle?.toLowerCase()));
      
    if (emit)
    {
      this.itemsChange.emit(this.items);
      this.selectionChange.emit(true);
    }
  }
}
