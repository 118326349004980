<h2 mat-dialog-title *ngIf="title">{{title}}</h2>
<h2 i18n mat-dialog-title *ngIf="!title">Fichiers</h2>

<form #documentForm="ngForm" (ngSubmit)="$event.preventDefault()">
  <div mat-dialog-content class="mb-2 small">
    <div class="uploadfilecontainer" (click)="fileDropRef.click()" appDragNDrop (fileDropped)="fileDropped($event)" *ngIf="files.length==0"
      [matTooltip]="toolTip">
      <input hidden type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />

      <mat-icon>cloud_upload</mat-icon><br/>
      <span>{{toolTip}}</span>
    </div>
    <div class="files-list row ml-1" *ngFor="let file of files;let i= index">
      <div class="mt-2">
        <span>{{ file.name }}
          <small>({{ formatBytes(file?.size) }})</small></span>    
      </div>
      <!-- <progress-bar [progress]="file?.progress"></progress-bar> -->
      <button i18n-matTooltip class="normal"
        matTooltip="Retirer cet élément" *ngIf="file?.progress >= 100"
        (click)="$event.stopPropagation(); deleteFile(i);">
        <mat-icon>delete_outline</mat-icon>
      </button>      
    </div>
  </div>

  <mat-dialog-actions align="end" class="pt-2">
    <button i18n mat-flat-button class="secondary-text mr-2" mat-dialog-close>ANNULER</button>
    <button i18n i18n-matTooltip mat-raised-button class="primary" [disabled]="!valid" (click)="save()" matTooltip="Enregistrer les modifications">AJOUTER</button>
  </mat-dialog-actions>
</form>