export enum ResourceStatus {
    /// <summary>
    /// None.
    /// </summary>
    None = "None",

    /// <summary>
    /// Created.
    /// </summary>
    Created = "Created",

    /// <summary>
    /// Updated.
    /// </summary>
    Updated = "Updated",

    /// <summary>
    /// Patched.
    /// </summary>
    Patched = "Patched",

    /// <summary>
    /// Deleted.
    /// </summary>
    Deleted = "Deleted",

    /// <summary>
    /// Any.
    /// </summary>
    Any = "Any"
}
