import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PageHistoryService } from '@services/nav/page-history.service';
import { UtilsHelper } from '@services/helpers/utils-helper';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { MatInput } from '@angular/material/input';
import { IDossierDto } from '@models/dtos/runtime/dossiers/IDossierDto';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { DossierNatures } from '@models/enums/domain/DossierNatures';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageKind } from '@models/enums/MessageKind';
import { DossiersService } from '@services/business/dossiers.service';
import { IDossiersRequestFormPoco } from '@models/pocos/forms/IDossiersRequestFormPoco';

@Component({
  selector: 'dossiers-derniers',
  templateUrl: './dossiers-derniers.component.html',
})
export class DossiersDerniersComponent implements OnInit {

  pageSizeOptions: number[] = GlobalConstants.pageSizeOptions_Dialog;

  @ViewChild('inputMotCle') inputMotCle: MatInput;

  public requestForm: IDossiersRequestFormPoco;
  isLoading: boolean = false;

  dossiers: IDossierDto[];
  dossiersTotalCount: number;

  displayedColumns: string[];
  dataSource: MatTableDataSource<IDossierDto>;
  selection = new SelectionModel<IDossierDto>(true, []);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input('title')
  public title: string;

  @Input('dossierNature')
  public dossierNature: DossierNatures = DossierNatures.Any;

  @Input('maxCount')
  public maxCount: number = null;

  constructor(
    private pageHistoryService: PageHistoryService,
    private dossiersService: DossiersService,
    private snackBar: MatSnackBar
  ) {
    this.displayedColumns = ['reference', 'denomination', 'pays', 'star'];
  } 

  ngOnInit() {     
    this.initRequestForm();
    this.refreshDossiers();
  }
  
  ngAfterViewInit() {
    setTimeout(() => {
      this.inputMotCle?.focus();
    });
  }

  async initRequestForm()
  {
    this.requestForm = <IDossiersRequestFormPoco> { 
      pageIndex: 0,
      pageSize: 8,
      maxCount: this.maxCount,
      orderBy: 'LastUpdateDate desc',
      columns: ['identifiant', "reference", "denomination", "paysLibelle"]
    };
  }

  // Dossiers

  changePage() {
    this.requestForm.pageSize = this.paginator?.pageSize;
    this.requestForm.pageIndex = this.paginator?.pageIndex;

    this.refreshDossiers();
  }

  async refreshDossiers() {

    this.isLoading = true;

    this.dossiersService.listDossiers(this.requestForm).subscribe({
      next: result => {
        this.dossiers = result.items;
        this.dataSource = new MatTableDataSource<IDossierDto>(this.dossiers);
        UtilsHelper.updateDataPageInfo(this.requestForm, result);
        this.dossiersTotalCount = result?.totalCount;
        this.isLoading = false;
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
        this.isLoading = false;
      }
    });
  }

  async viewDossier(dossier: any)
  {
    switch (dossier?.nature)
    {
      case DossierNatures.Marque:
        this.pageHistoryService.navigate(['/folders/trademarks', dossier?.id]);
        break;
      case DossierNatures.Modele:
        this.pageHistoryService.navigate(['/folders/designs', dossier?.id]);
        break;
    }
  }
}
