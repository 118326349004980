<div *ngIf="adresse">
  <div class="row">    
    <mat-form-field class="col-12" appearance="outline">
      <mat-label i18n>Numéro, voie</mat-label>
      <input name="adresseLibelle" matInput [(ngModel)]="adresse.libelle"
        (input)="hasChanged();"
        [readonly]="readonly" />
    </mat-form-field>
  </div>  
  
  <div class="row">
    <mat-form-field class="col-4" appearance="outline">
      <mat-label i18n>Code postal</mat-label>
      <input name="adressePostalCode" matInput [(ngModel)]="adresse.postalCode"
        (input)="hasChanged();"
        [readonly]="readonly" />
    </mat-form-field>
  
    <mat-form-field class="col-8" appearance="outline">
      <mat-label i18n>Ville</mat-label>
      <input name="adresseVille" matInput [(ngModel)]="adresse.ville"
        (input)="hasChanged();"
        [readonly]="readonly" />
    </mat-form-field>
  </div>
  
  <div class="row">    
    <references-selector i18n-label class="col-12" [(item)]="adresse.pays" [type]="ReferenceTypes.Pays" appearance="outline"
      (selectionChange)="hasChanged()"
      [readonly]="readonly" label="Pays"></references-selector>
  </div>
</div>
