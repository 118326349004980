import { IDataKeyValue } from "@models/dtos/common/IDataKeyValue";
import { ExportFormats } from "@models/enums/domain/ExportFormats";
import { ExportType } from "@models/enums/domain/ExportType";
import { Subject } from "rxjs";

export class GlobalConstants {

    public static defaultExportFormat : ExportFormats = ExportFormats.Tsv;

    // UI cultures

    public static readonly supportedLocales: IDataKeyValue[] = [
        {key: "en" , content: "English", icon: undefined, value: 'EN'},
        {key: "fr" , content: "Français", icon: undefined, value: 'FR'}
    ];

    // Page size options

    public static readonly pageSizeOptions_List = [10, 20, 30];

    public static readonly pageSizeOptions_Dialog = [8];

    public static readonly pageSizeOptions_Dialog_Small = [4];

    // Queries

    public static readonly orderedColumnsMaxCount = 3;
    public static readonly queryExportTypes = [ExportType.MarqueDossiers, ExportType.ModeleDossiers, ExportType.Contentieux];

    // Query fields

    public static readonly ColumnNames_Titulaire: string = "TitulairesIdentifiant";
    public static readonly ColumnNames_Deposant: string = "DeposantsIdentifiant";
    public static readonly ColumnNames_Correspondant: string = "CorrespondantsIdentifiant";
    public static readonly ColumnNames_Famille: string = "FamillesIdentifiant";
}