import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { IDossierDateDto } from '@models/dtos/runtime/dossiers/IDossierDateDto';
import { KeyCodes } from '@models/enums/KeyCodes';

@Component({
  selector: 'historique-dates-view-dialog',
  templateUrl: './historique-dates-view-dialog.component.html'
})
export class DossierDateViewDialogComponent implements OnInit, AfterViewInit {

  viewInitialized: boolean = false;

  @ViewChild('dossierDateForm') dossierDateForm: NgForm;
  @ViewChild('inputLibelle') inputLibelle: MatInput;

  @Input('item')
  public dossierDate: IDossierDateDto;

  @Input('readonly')
  public readonly: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<DossierDateViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dossierDate = data.dossierDate;
    this.readonly = data.readonly;
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.inputLibelle.focus();
      this.viewInitialized = true;
    });
  }

  get valid(): boolean | null
  {
    let b = this.viewInitialized && this.dossierDateForm?.valid;
    return b;
  }

  save()
  {
    if (this.valid)
    {
      this.dialogRef.close(this.dossierDate);
    }
  }
}
