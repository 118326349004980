<div class="screen-center on-top" *ngIf="isSearching">
  <mat-spinner></mat-spinner>
</div>

<div class="row mr-1" *ngIf="requestForm">

  <mat-form-field class="col-md-2 col-xs-3 col-3">
    <mat-label i18n>Date début</mat-label>
    <input name="debutDate" matInput [matDatepicker]="pickerDebutDate" [(ngModel)]="requestForm.debutDate" (dateChange)="refreshActionLogs(true)">
    <mat-datepicker-toggle *ngIf="!readonly && requestForm.debutDate" matSuffix (click)="$event.stopPropagation(); requestForm.debutDate=null; refreshActionLogs(true);">
      <mat-icon matDatepickerToggleIcon>clear</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker-toggle matSuffix [for]="pickerDebutDate"></mat-datepicker-toggle>
    <mat-datepicker #pickerDebutDate></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="col-md-2 col-xs-3 col-3">
    <mat-label i18n>Date fin</mat-label>
    <input name="finDate" matInput [matDatepicker]="pickerFinDate" [(ngModel)]="requestForm.finDate" (dateChange)="refreshActionLogs(true)">
    <mat-datepicker-toggle *ngIf="!readonly && requestForm.finDate" matSuffix (click)="$event.stopPropagation(); requestForm.finDate=null; refreshActionLogs(true);">
      <mat-icon matDatepickerToggleIcon>clear</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker-toggle matSuffix [for]="pickerFinDate"></mat-datepicker-toggle>
    <mat-datepicker #pickerFinDate></mat-datepicker>
  </mat-form-field>

  <button i18n-matTooltip class="classic mt-2 mr-2" *ngIf="!moreFiltersShowed"
    matTooltip="Voir tous les critères" (click)="$event.stopPropagation(); moreFiltersShowed = true;">
    <mat-icon>filter_list</mat-icon><span i18n class="ml-1 screen-lg">FILTRES</span>
  </button>

  <button i18n-matTooltip class="normal mt-2" matTooltip="Réinitialiser le formulaire" *ngIf="!isFormEmpty()"
    (click)="$event.stopPropagation(); initRequestForm(true);">
    <mat-icon>highlight_off</mat-icon>
  </button>

  <div class="spacer-item"></div>
</div>

<div class="filter-pane" *ngIf="moreFiltersShowed" click-stop-propagation>

  <div class="float-right">
    <button i18n-matTooltip class="nav-primary"
      matTooltip="Cacher les critères avancés" (click)="moreFiltersShowed = false;">
      <mat-icon>expand_less</mat-icon>
    </button>
  </div>  
  
  <div>
    <div class="col-10">
      <entities-selector i18n-label appearance="standard" *ngIf="!utilisateurId"
        [type]="EntityTypes.Gestionnaire" [(item)]="requestForm.utilisateur" (selectionChange)="refreshActionLogs(true)"    
        label="Utilisateur"></entities-selector>
    </div>  

    <div class="col-10 row">
      <flags-selector i18n-label class="col-4" appearance="standard" *ngIf="!itemEntityType" [action]="ActionKinds.List"
        [type]="FlagTypes.EntityTypes" [(item)]="requestForm.itemEntityType" (selectionChange)="refreshActionLogs(true)"
        label="Type d'objet"></flags-selector>

      <mat-form-field class="col-8 mt-2">
        <mat-label i18n>Mot clé</mat-label>
        <input #inputMotcle="matInput" matInput [(ngModel)]="requestForm.motcle" [readonly]="isSearching" (keyup.enter)="refreshActionLogs(true)" />
        <button mat-button matSuffix mat-icon-button *ngIf="requestForm.motcle" [disabled]="isSearching"
          (click)="$event.stopPropagation(); requestForm.motcle=null; refreshActionLogs(true);">
          <mat-icon>clear</mat-icon>
        </button>
        <button mat-button matSuffix mat-icon-button (click)="refreshActionLogs(true)" [disabled]="isSearching">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="d-flex" *ngIf="requestForm">
  <mat-paginator *ngIf="showPaginator" [pageIndex]="requestForm.pageIndex" [length]="actionLogsTotalCount" [(pageSize)]="requestForm.pageSize" [pageSizeOptions]="pageSizeOptions" (page)="changePage()"></mat-paginator>
</div>

<div class="data-table h-100 zoomed" [ngClass]="showPaginator ? '' : 'pt-3'">
  <table mat-table [dataSource]="dataSource" matSort>

    <!--date Column -->
    <ng-container matColumnDef="date">
      <th style="width: 10em;" i18n mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let row">{{row.date | date: 'short'}}</td>
    </ng-container>

    <!--action Column -->
    <ng-container matColumnDef="action">
      <th i18n mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
      <td mat-cell *matCellDef="let row">
        {{actionKinds[row.actionKind]}}
      </td>
    </ng-container>

    <!--entity Column -->
    <ng-container matColumnDef="entity">
      <th i18n mat-header-cell *matHeaderCellDef mat-sort-header>Entité</th>
      <td mat-cell *matCellDef="let row">
        {{entityTypes[row.itemEntityType]}}
      </td>
    </ng-container>

    <!--item Column -->
    <ng-container matColumnDef="item">
      <th i18n mat-header-cell *matHeaderCellDef mat-sort-header>Elément</th>
      <td mat-cell *matCellDef="let row">
        {{row.itemId}}
      </td>
    </ng-container>
    
    <!--entityId Column -->
    <ng-container matColumnDef="utilisateur">
      <th i18n mat-header-cell *matHeaderCellDef mat-sort-header>Utilisateur</th>
      <td mat-cell *matCellDef="let row">
        <utilisateur-view [itemId]="row.by"></utilisateur-view>
      </td>
    </ng-container>    

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="viewItem(row)"></tr>
  </table>
  
  <div class="mt-4" *ngIf="actionLogsTotalCount=== 0">
    <span i18n class="pl-2">Aucun élément trouvé</span>
  </div>
</div>
