<div class="col-12 mp-0">
  <div *ngIf="readonly && items?.length === 0">
    <span i18n>Aucun</span>
  </div>

  <div *ngIf="items?.length > 0">
    <div class="mb-3 data-table border-top">
      <table mat-table [dataSource]="dataSource" matSort>

        <!--type Column -->
        <ng-container matColumnDef="type">
          <td mat-cell *matCellDef="let row">{{row.type?.libelle}}</td>
        </ng-container>

        <!--date Column -->
        <ng-container matColumnDef="date">
          <td mat-cell *matCellDef="let row">{{row.date | date: 'dd/MM/yyyy'}}</td>
        </ng-container>

        <!--action Column -->
        <ng-container matColumnDef="action">
          <td mat-cell *matCellDef="let row">{{row.action}}</td>
        </ng-container>

        <!-- Star Column -->
        <ng-container matColumnDef="star" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <div class="btn-group">

              <button i18n-matTooltip mat-flat-button class="secondary-text" *ngIf="!readonly"
                matTooltip="Retirer cet élément"
                (click)="$event.stopPropagation(); removeContentieuxDelai(row)">
                <mat-icon>remove_circle_outline</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>      

        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)='viewContentieuxDelai(row)' ></tr>
      </table>
    </div>
  </div>

  <button i18n-matTooltip mat-flat-button class="secondary text-small" *ngIf="!readonly && (maxCount === -1 || items?.length<maxCount)"
    matTooltip="Ajouter un élement" (click)="addNewContentieuxDelai()">
    <mat-icon>add</mat-icon> <span i18n>Ajouter</span>
  </button>
</div>
