import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IDataPresenterSpecDto } from '@models/dtos/data/export/IDataPresenterSpecDto';
import { DataInstructions } from '@models/enums/DataInstructions';
import { ConditionsHelper } from '@services/helpers/conditions-helper';
import { RequeteViewModes } from '@models/enums/RequeteViewModes';
import { IRequeteClausePoco } from '@models/pocos/queries/IRequeteClausePoco';
import { IRequeteFiltrePoco } from '@models/pocos/queries/IRequeteFiltrePoco';
import { IRequeteConditionPoco } from '@models/pocos/queries/IRequeteConditionPoco';
import { FlagType } from '@models/enums/domain/FlagType';

@Component({
  selector: 'filtre-clause',
  templateUrl: './filtre-clause.component.html'
})
export class FiltreClauseComponent implements OnInit {

  RequeteViewModes = RequeteViewModes;
  FlagTypes = FlagType;

  instructions: any = {};

  @Input("parent")
  parent: IRequeteClausePoco;

  @Input("item")
  clause: IRequeteClausePoco;

  @Input("presenterSpec")
  public presenterSpec: IDataPresenterSpecDto;

  @Input("queryData")
  public queryData: any;

  @Input('readonly')
  public readonly: boolean = false;

  @Output('removeItem')
  public removeItemChange: EventEmitter<IRequeteClausePoco> = new EventEmitter<IRequeteClausePoco>();

  @Output("submited")
  public submitedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input('mode')
  public mode: RequeteViewModes = RequeteViewModes.Standard;

  constructor() {
  }

  ngOnInit() {
    if (!this.clause.instruction)
    {
      this.clause.instruction = DataInstructions.And;
    }
    this.updateClause();
  }

  // Filtres

  hasTabbed(filtre: IRequeteFiltrePoco): boolean {

    return ConditionsHelper.hasTabbed(filtre, this.clause);
  }

  convertFiltre(filtre: IRequeteFiltrePoco)
  {
    ConditionsHelper.convertFiltre(filtre, this.clause, this.parent);
  }

  updateClause() {

    ConditionsHelper.updateClause(this.clause);
  }

  // Conditions

  addCondition(filtre: IRequeteFiltrePoco = null)
  {
    ConditionsHelper.addCondition(this.clause, filtre);
  }

  removeCondition(condition: IRequeteConditionPoco)
  {
    ConditionsHelper.removeCondition(condition, this.clause, this.parent);
  }

  lastFilter(): IRequeteFiltrePoco
  {
    return ConditionsHelper.lastFilter(this.clause);
  }

  isLastFilterInSequence(filtre: IRequeteFiltrePoco): boolean
  {
    return ConditionsHelper.isLastFilterInSequence(filtre, this.clause);
  }

  // Clauses

  isClause(filtre: IRequeteFiltrePoco): boolean {
    return ConditionsHelper.isClause(filtre);
  }

  addSubClause()
  {
    ConditionsHelper.addClause(this.clause);

    this.updateClause();
  }
  
  removeClause()
  {    
    this.removeItemChange?.emit(this.clause);
  }

  removeSubClause(clause: IRequeteClausePoco)
  {
    ConditionsHelper.removeClause(clause, this.clause);

    if (this.clause.filtres.length===0) {
      this.removeClause();
    }
    this.updateClause();
  }

  // submit

  submit() {
    this.submitedChange?.emit(true);
  }
}
