import {Component} from '@angular/core';

@Component({
  selector: 'app-intro',
  templateUrl: './app-intro.component.html'
})
export class AppIntroComponent {

  constructor()
  {
  }
}
