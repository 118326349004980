export enum PersonneTypes {
    
    Undefined = "Undefined",

    Morale = "Morale",

    Physique = "Physique",

    Any = "Any",
}
