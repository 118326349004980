import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { KeyCodes } from '@models/enums/KeyCodes';

@Component({
  selector: 'files-add-dialog',
  templateUrl: './files-add-dialog.component.html'
})
export class FilesAddDialogComponent implements OnInit {

  @Input('maxCount')
  public maxCount: number = -1;

  @ViewChild('documentForm') documentForm: NgForm;

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  files: any[] = [];

  @Input("title")
  title: string;

  @Input("toolTip")
  toolTip: string;

  constructor(
    private dialogRef: MatDialogRef<FilesAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef.disableClose = true;

    if (this.data) {
      this.maxCount = data.maxCount;
      this.title = data.title;
      this.toolTip = data.toolTip;
    }
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });
  }

  // Dialog

  get valid(): boolean | null
  {
    let b = this.documentForm?.valid && this.files?.length > 0 && !this.files.find(q=> q.length > 100);

    return b;
  }

  save()
  {
    if (this.valid)
    {
      this.dialogRef.close(this.files);
    }
  }
  
  // Drag and drop

  fileDropped($event) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  deleteFile(index: number) {
    if (this.files[index]?.progress < 100) {
      return;
    }
    this.files.splice(index, 1);
  }

  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (!this.files[index] || this.files[index]?.progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      if (this.maxCount === -1 || this.files.length < this.maxCount) {
        item.progress = 0;
        this.files.push(item);
        this.uploadFilesSimulator(this.files.length-1);  
      }
    }

    this.fileDropEl.nativeElement.value = "";
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
