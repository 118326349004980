export enum LoiMoteurDebutDateTypes {
    // Undefined.
    Undefined = "Undefined",

    // Date de dépôt.
    DepotDate = "DepotDate",

    // Date d'enregistrement.
    EnregistrementDate = "EnregistrementDate",

    // Any.
    Any = "Any"
}
