import { Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import { AppPolicy } from '@models/enums/iam/AppPolicy';
import { AuthService } from '@services/app/auth.service';

/// role * : Any logged user
/// role <empty> : Unlogged user

@Directive({
  selector: '[requiresUserPolicies]'
})
export class UserHasPoliciesDirective implements OnInit {

  @Input("requiresUserPolicies") requiresUserPolicies: string[] = [];

  _isVisible = false;

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private _templateRef: TemplateRef<any>,
    private authService: AuthService
  ) 
  {
  }

  ngOnInit() {
    if (this.requiresUserPolicies === null) return;

    this.hide();

    if (!this.requiresUserPolicies)
    {
      this.show();
    }

    this.authService.isAuthenticatedSubjectChanged().subscribe(_ => {

      if (this.authService.isLoggedIn())
      {
        if (!(this.requiresUserPolicies?.length>0))
        {
          this.hide();
        }
        else if (this.requiresUserPolicies && (this.authService.hasPolicies(...this.requiresUserPolicies)))
        {
          this.show();
        }
      }
    });  
  }
  
  show()
  {
    this._isVisible = true;
    this._viewContainerRef.clear();
    this._viewContainerRef.createEmbeddedView(this._templateRef);  
  }

  hide()
  {
    this._viewContainerRef.clear();
  }
}