<div class="col-12 mp-0">
  <mat-paginator *ngIf="showPaginator" [length]="items.length" [pageSize]="pageSize"></mat-paginator>  
  <div *ngIf="readonly && items?.length === 0">
    <span i18n>Aucun</span>
  </div>
  
  <div class="row mb-3 ml-2" *ngIf="items">
  
    <div class="pointable border row mr-4 mb-2 bg-white text-normal" *ngFor="let item of items" (click)="viewItem(item)">
      <span class="pt-2 pl-2 pr-2">{{item.libelle}}</span>
      <button i18n-matTooltip mat-flat-button class="secondary-text" *ngIf="!readonly"
        matTooltip="Retirer cet élément"
        (click)="$event.stopPropagation(); removeItem(item)">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
    </div>

    <div class="border row mr-4 mb-2 bg-white" *ngIf="!readonly && (maxCount === -1 || items.length<maxCount)">
      <button i18n-matTooltip mat-flat-button class="secondary-text button-small"
        matTooltip="Ajouter un élement"
        (click)="$event.stopPropagation(); addItem()">
        <mat-icon>add</mat-icon>
      </button>
    </div>    
  </div>
</div>