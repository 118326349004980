<div class="col-12 mp-0">
  <evenements-liveview #delaisSemaineLiveView class="col-12 mp-0" itemMaxCount="100"
    [requestForm]="delaisSemaineRequestForm"
    readonly="true" [editionReadonly]="readonly"></evenements-liveview>

  <div class="text-center">
    <button i18n mat-flat-button class="secondary ml-1 mt-4 text-center"
      (click)="voirTous()">VOIR PLUS</button> 
  </div>
</div>
