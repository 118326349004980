import { Component } from '@angular/core';

@Component({
  selector: 'pane-parameters-component',
  templateUrl: './parameters.component.html'
})
export class ParametersComponent {

  constructor() {
  }
}
