import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDataPresenterSpecDto } from '@models/dtos/data/export/IDataPresenterSpecDto';
import { IFieldSpecDto } from '@models/dtos/data/export/IFieldSpecDto';
import { KeyCodes } from '@models/enums/KeyCodes';
import { ConditionsHelper } from '@services/helpers/conditions-helper';
import { ColumnsListViewComponent } from './columns-list-view.component';

@Component({
  selector: 'columns-select-dialog',
  templateUrl: './columns-select-dialog.component.html'
})
export class ColumnsSelectDialogComponent implements OnInit {

  public presenterSpec: IDataPresenterSpecDto;
  defaultSelectedColumnNames: string[];

  @Input('excluding')
  public excludingColumnNames: string[] = [];

  @ViewChild('columnsListView') columnsListView: ColumnsListViewComponent;

  selectedColumns:  IFieldSpecDto[] =[];

  constructor(
    private dialogRef: MatDialogRef<ColumnsSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef.disableClose = true;

    if (data)
    {
      this.presenterSpec = data.spec;
      this.excludingColumnNames = data.excluding;
      this.defaultSelectedColumnNames = data.selectedColumnNames;
    }
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });
  }

  select()
  {
    if (this.selectedColumns?.length>0)
    {
      let columnNames = [...this.selectedColumns.map(q=>q.name)];
      columnNames = ConditionsHelper.getFilteredColumns(columnNames, this.presenterSpec, true);
  
      this.dialogRef.close({ 
        columns: columnNames
      });
    }
  }

  reset()
  {
    this.columnsListView?.setColumns();
  }
}
