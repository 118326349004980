import { IDataKeyValue } from "@models/dtos/common/IDataKeyValue";
import { ExportFormats } from "@models/enums/domain/ExportFormats";
import { ExportType } from "@models/enums/domain/ExportType";
import { StorageHelper } from "@services/helpers/storage-helper";
import { Subject } from "rxjs";
import { GlobalConstants } from "./GlobalConstants";
import { StorageKeys } from "./StorageKeys";

export class GlobalVariables {

    public static getExportFormat() : ExportFormats {
        let exportFormat : ExportFormats = StorageHelper.getSessionItem(StorageKeys.__exportFormat);
        if (!Object.values(ExportFormats).find(q=> q?.toLowerCase() === exportFormat?.toString().toLowerCase()))
        {
            exportFormat = GlobalConstants.defaultExportFormat;
        }
        return exportFormat;
    }
}