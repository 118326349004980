<h2 i18n mat-dialog-title>Conditions d'utilisation</h2>

<div class="form-row mb-0 small">

  <div class="pb-3">
    <span i18n>Toute utilisation du site effectuée à quelque titre que ce soit implique obligatoirement l’acceptation sans réserve, par l’utilisateur, des présentes Conditions Générales d’Utilisation (incluant les</span>
    <a i18n href="javascript:void(0);"
      onclick="javascipt:window.open('assets/docs/notice-legale.pdf', '_blank')">Mentions légales</a>
    <span i18n>et la</span>
    <a i18n href="javascript:void(0);"
      onclick="javascipt:window.open('assets/docs/politique-protection-donnees-personnelles.pdf', '_blank')">Politique de protection des Données personnelles</a>).
  </div>
  <div class="pb-3">
      <span i18n>Les présentes Conditions Générales d’Utilisation s’appliquent à tous les utilisateurs qui naviguent sur le site, dès la création d’un compte personnel et connexion sur celui-ci.</span>
  </div>

  <mat-checkbox class="pb-1 checkbox-inline custom-checkbox nowrap" [(ngModel)]="form.cgu">
    <span i18n style="display: inline-block; white-space: normal; vertical-align: top; position: relative; top: 2px;">Je reconnais avoir pris connaissance des Conditions Générales d’Utilisation et les accepte sans réserve.</span>
  </mat-checkbox>

  <mat-checkbox class="pb-1 checkbox-inline custom-checkbox nowrap" [(ngModel)]="form.codes_perso">
    <span i18n style="display: inline-block; white-space: normal; vertical-align: top; position: relative; top: 2px;">Je reconnais avoir pris particulièrement connaissance de la clause relative à la « Communication des identifiants à un tiers » et l’accepte sans réserve.</span>
  </mat-checkbox>

  <mat-checkbox class="pb-1 checkbox-inline custom-checkbox nowrap" [(ngModel)]="form.donnees_perso">
    <span i18n style="display: inline-block; white-space: normal; vertical-align: top; position: relative; top: 2px;">Je consens à l’utilisation de mes données personnelles dans le cadre du fonctionnement du Site dans les conditions décrites par la Politique de protection des Données personnelles.</span>
  </mat-checkbox>
</div>

<mat-dialog-actions align="end" class="pt-3">
  <button i18n mat-raised-button class="primary"
    (click)="validate()"
    [disabled]="!form.cgu || !form.codes_perso || !form.donnees_perso">VALIDER</button>
</mat-dialog-actions>
