import { Component, OnInit } from '@angular/core';
import { AuthService } from '@services/app/auth.service'
import { StorageKeys } from '@models/app/StorageKeys';
import { PageHistoryService } from '@services/nav/page-history.service';
import { StorageHelper } from '@services/helpers/storage-helper';

@Component({
  selector: 'pane-auth-callback',
  template: ''
})
export class AuthCallbackComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private pageHistoryService: PageHistoryService
    ) 
  { 
  }

  ngOnInit() {    
    this.authService.completeAuthentication().then(_ => 
      {
        let url = StorageHelper.getSessionItem<string>(StorageKeys.CurrentUri, '');
        this.pageHistoryService.navigateByUrl(url); 
      },
      error => {
        this.pageHistoryService.navigate(['/']); 
      });
  }

}