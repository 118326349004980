import {Inject, Injectable, LOCALE_ID } from '@angular/core';
import {HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { IResultItemDto } from '@models/dtos/common/IResultItemDto';
import { IAppInfoDto } from '@models/app/IAppInfoDto';
import { environment } from 'src/environments/environment';
import { IAppConfiguration } from '@models/app/config/IAppConfiguration';

@Injectable()
export class AppService {

    public static __Settings: IAppConfiguration;
    public static __Info: IAppInfoDto;

    private baseUri_Assets: string;
    private baseUri_Docs: string;
    private baseApiUri_App: string;

    constructor(
        private http: HttpClient,
        @Inject(LOCALE_ID) private locale: string) {

        this.baseUri_Assets = `${environment.production ? '/' + this.locale: ''}/assets/`;
    }

    // Configuration
    
    load(): Promise<void> {

        const jsonFile = `${this.baseUri_Assets}config/appsettings.json`;

        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile).toPromise().then((response : IAppConfiguration) => {
                AppService.__Settings = <IAppConfiguration>response;
                console.log("Configuration loaded");

                this.baseApiUri_App = AppService.__Settings.platform.services[0].apiBaseUri + '/v1/app/';

                resolve();
            }).catch(_ => {
                reject('Could not load configuration file');
            });
        });        
    }

    // Information
    
    GetApiInformation(): Observable<IAppInfoDto> {

        let uri: string = `${this.baseApiUri_App}info`;
        return this.http.get<IAppInfoDto>(uri);
    }    

    GetLocalInformation(): Observable<IAppInfoDto> {
        const jsonFile = `${this.baseUri_Assets}app-info.json`;
        return this.http.get<IAppInfoDto>(`${jsonFile}`);
    }

    // Release notes
    
    GetReleaseNote(): Observable<string> {

        let uri: string = `${this.baseUri_Assets }docs/notes/release-note.txt`;
        return this.http.get(uri, {responseType: 'text'});
    }    

    // System
        
    purgeCache() : Observable<IResultItemDto> {
        let uri: string = `${this.baseApiUri_App}system/maintenance`;
        return this.http.delete<IResultItemDto>(`${uri}/cache`);
    }    

    migrateDb() : Observable<IResultItemDto> {
        let uri: string = `${this.baseApiUri_App}system/db/migrate`;
        return this.http.post<IResultItemDto>(`${uri}/db/migrate`, null);
    }
}