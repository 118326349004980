<div class="col-12 mp-0">
  <div class="screen-center on-top" *ngIf="isLoading">
      <mat-spinner></mat-spinner>
  </div>

  <div class="pt-2 pb-2" *ngIf="readonly && !marqueDossierArbre.racineDossier && !(marqueDossierArbre.eiDossiers?.length>0) && !(marqueDossierArbre.internationalDossiers?.length>0)">
    <span i18n>Aucun élément trouvé</span>
  </div>

  <div *ngIf="!readonly || marqueDossierArbre.racineDossier || marqueDossierArbre.eiDossiers?.length>0 || marqueDossierArbre.internationalDossiers?.length>0">

    <div class="pt-2 pb-2" *ngIf="marqueDossier.registreType!=MarqueRegistreTypes.National && marqueDossierArbre.racineDossier">
      <div class="row">
        <mat-icon matTooltip="Marque nationale">flag</mat-icon>
        <h3 class="ml-2 mt-2">
          <span i18n>Origine</span>
          <span i18n *ngIf="marqueDossierArbre.racineDossier?.statut===DossierStatuts.Abandonne || marqueDossierArbre.racineDossier?.statut===DossierStatuts.Rejete" class="font-weight-bold">(Non en vigueur)</span>
        </h3>
      </div>    
      <div class="pt-2 row ml-2">
        <entities-selector [type]="EntityTypes.Dossier" i18n-label class="col-8 mp-0" [(item)]="marqueDossierArbre.racineDossier" [readonly]="true"
          [viewOnItemClicked]="viewOnItemClicked" (viewItem)="viewMarqueDossier(marqueDossierArbre.racineDossier)"></entities-selector>  
      </div>
    </div>

    <div class="pt-2 pb-2" *ngIf="marqueDossier.registreType===MarqueRegistreTypes.International && marqueDossierArbre.eiDossiers?.length>0">
      <div class="row">
        <mat-icon matTooltip="Enregistrement international">language</mat-icon>
        <h3 i18n class="ml-2 mt-2">Enregistrement international</h3>
      </div>
      <div class="pt-2 row ml-2">
        <entities-selector [type]="EntityTypes.Dossier" i18n-label class="col-8 mp-0" [(item)]="marqueDossierArbre.eiDossiers[0]"
          [viewOnItemClicked]="viewOnItemClicked" (viewItem)="viewMarqueDossier(marqueDossierArbre.eiDossiers[0])"
          [readonly]="true"></entities-selector>
      </div>
    </div>
  
    <div class="pt-2 pb-2" *ngIf="marqueDossier.registreType===MarqueRegistreTypes.National || (marqueDossier.registreType===MarqueRegistreTypes.InternationalEnregistrement && marqueDossierArbre.eiDossiers?.length>0)">
      <div class="row" *ngIf="marqueDossier.registreType!=MarqueRegistreTypes.InternationalEnregistrement">
        <mat-icon matTooltip="Enregistrement international">language</mat-icon>
        <h3 i18n class="ml-2 mt-2">Enregistrements internationaux</h3>
        <h3 class="ml-2 mt-2">{{marqueDossierArbre.eiDossiers?.length ? '(' + marqueDossierArbre.eiDossiers.length + ')' : ''}}</h3>
      </div>
      <div class="row" *ngIf="marqueDossier.registreType===MarqueRegistreTypes.InternationalEnregistrement">
        <mat-icon matTooltip="Enregistrement international">language</mat-icon>
        <h3 i18n class="ml-2 mt-2">Autres enregistrements internationaux</h3>
        <h3 class="ml-2 mt-2">{{marqueDossierArbre.eiDossiers?.length ? '(' + marqueDossierArbre.eiDossiers.length + ')' : ''}}</h3>
      </div>
              
      <div class="pt-2">
        <dossiers-listview [(items)]="marqueDossierArbre.eiDossiers"
          addingMode="New" [parentId]="marqueDossier.id" [retourneAbandonnes]="marqueDossier.statut===DossierStatuts.Abandonne || marqueDossier.statut===DossierStatuts.Rejete"
          (itemClick)="viewMarqueDossier($event)" [viewOnItemClicked]="viewOnItemClicked"
          [viewOnItemRemoved]="false"
          [readonly]="readonly || marqueDossier.registreType!=MarqueRegistreTypes.National"></dossiers-listview>
      </div>
    </div>

    <div class="pt-2 pb-2" *ngIf="marqueDossier.registreType===MarqueRegistreTypes.InternationalEnregistrement || marqueDossierArbre.internationalDossiers?.length>0">
      <div class="row" *ngIf="marqueDossier.registreType!=MarqueRegistreTypes.International">
        <mat-icon matTooltip="Marque inernationale">outlined_flag</mat-icon>
        <h3 i18n class="ml-2 mt-2">Pays déposés</h3>
        <h3 class="ml-2 mt-2">{{marqueDossierArbre.internationalDossiers?.length ? '(' + marqueDossierArbre.internationalDossiers.length + ')' : ''}}</h3>
      </div>
      <div class="row" *ngIf="marqueDossier.registreType===MarqueRegistreTypes.International">
        <mat-icon matTooltip="Marque inernationale">outlined_flag</mat-icon>
        <h3 i18n class="ml-2 mt-2">Autres pays déposés</h3>
        <h3 class="ml-2 mt-2">{{marqueDossierArbre.internationalDossiers?.length ? '(' + marqueDossierArbre.internationalDossiers.length + ')' : ''}}</h3>
      </div>

      <div class="pt-2">
        <dossiers-listview [(items)]="marqueDossierArbre.internationalDossiers"
          viewMode="pays" addingMode="New" [parentId]="marqueDossier.id" [retourneAbandonnes]="marqueDossier.statut===DossierStatuts.Abandonne || marqueDossier.statut===DossierStatuts.Rejete"
          (itemClick)="viewMarqueDossier($event)" [viewOnItemClicked]="viewOnItemClicked"
          [viewOnItemRemoved]="false"
          [readonly]="readonly || marqueDossier.registreType!=MarqueRegistreTypes.InternationalEnregistrement"></dossiers-listview>
      </div>
    </div>
  </div>  
</div>
