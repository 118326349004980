export enum ExportFormats {

    /// <summary>
    /// Undefined.
    /// </summary>
    Undefined = "Undefined",

    /// <summary>
    /// Any.
    /// </summary>
    Any = "Any",

    /// <summary>
    /// Excel.
    /// </summary>
    Excel = "Excel",

    /// <summary>
    /// Json.
    /// </summary>
    Json = "Json",

    /// <summary>
    /// Tsv.
    /// </summary>
    Tsv = "Tsv",

    /// <summary>
    /// Pdf.
    /// </summary>
    Pdf = "Pdf"
}
