
export class StringHelper {

  private static accentsMap = new Map([
    ["A", "Á|À|Ã|Â|Ä"],
    ["a", "á|à|ã|â|ä"],
    ["E", "É|È|Ê|Ë"],
    ["e", "é|è|ê|ë"],
    ["I", "Í|Ì|Î|Ï"],
    ["i", "í|ì|î|ï"],
    ["O", "Ó|Ò|Ô|Õ|Ö"],
    ["o", "ó|ò|ô|õ|ö"],
    ["U", "Ú|Ù|Û|Ü"],
    ["u", "ú|ù|û|ü"],
    ["C", "Ç"],
    ["c", "ç"],
    ["N", "Ñ"],
    ["n", "ñ"]
  ]);

  static unaccent = text => [...StringHelper.accentsMap].reduce((acc, [key]) => acc.replace(new RegExp(StringHelper.accentsMap.get(key), "g"), key), text);


  static getTitle(text: string, lengthMax: number | null = 50): string
  {
    if (!text)
    {
      text = " ";
    }
    else if (lengthMax && text.length> lengthMax)
    {
      text = text.substring(0, lengthMax - 3) + '...';
    }

    return text;
  }

  static jsonParse(st: string, defaultObject: object = null): any
  {
    let obj = defaultObject;
    try
    {
      obj = JSON.parse(st);
    }
    catch
    {
    }

    return obj;
  }
}