import { Component, OnInit } from '@angular/core';
import { AuthService } from '@services/app/auth.service'

@Component({
  selector: 'pane-logout',
  template: ''
})
export class LogoutComponent implements OnInit {

  constructor(
    private authService: AuthService
    ) 
  { 
  }

  ngOnInit() {    
    this.authService.logout();
  }
}