<div class="col-12 mp-0">
  <mat-paginator *ngIf="showPaginator" [length]="items.length" [pageSize]="pageSize" [pageSizeOptions]="[pageSize]"></mat-paginator>  
  <div *ngIf="readonly && items?.length === 0">
    Aucun
  </div>
  
  <div class="row mb-3 ml-1">
  
    <div class="pointable border row mr-4 mb-2 bg-white text-normal" *ngFor="let item of items" (click)="viewItem(item)">
      <span class="pt-2 pl-2 pr-2">{{item.prenom}} {{item.nom}}</span>
  
      <button i18n-matTooltip mat-flat-button class="secondary-text mr-1 button-small"
        matTooltip="Ne pas rendre cet interlocuteur comme principal"
        *ngIf="!readonly && item.interlocuteurType==='Principal'"
        (click)="$event.stopPropagation(); switchItemPrincipal(item)">
        <mat-icon>bookmark</mat-icon>
      </button>   
  
      <button i18n-matTooltip mat-flat-button class="secondary-text mr-1 button-small"
        matTooltip="Rendre cet interlocuteur comme principal"
        *ngIf="!readonly && item.interlocuteurType!='Principal'"
        (click)="$event.stopPropagation(); switchItemPrincipal(item)">
        <mat-icon>bookmark_border</mat-icon>
      </button>
  
      <button i18n-matTooltip mat-flat-button class="secondary-text button-small" *ngIf="!readonly"
        matTooltip="Retirer cet interlocuteur"
        (click)="$event.stopPropagation(); removeItem(item)">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
    </div>

    <div class="row mr-4 mb-2" *ngIf="!readonly && (maxCount === -1 || items.length<maxCount)">
      <button i18n-matTooltip mat-flat-button class="primary-secondary button-small mp-0"
        matTooltip="Ajouter un élement"
        (click)="$event.stopPropagation(); addNewItem()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>
