import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IEvenementsRequestFormPoco } from '@models/pocos/forms/IEvenementsRequestFormPoco';
import { EvenementTypes } from '@models/enums/domain/EvenementTypes';
import * as moment from 'moment';
import { EvenementsLiveViewComponent } from '@components/shared/evenements/evenements-liveview.component';
import { PageHistoryService } from '@services/nav/page-history.service';
import { UtilsHelper } from '@services/helpers/utils-helper';

@Component({
  selector: 'delais-semaine',
  templateUrl: './delais-semaine.component.html',
})
export class DelaisSemaineComponent implements OnInit {

  @Input('readonly')
  public readonly: boolean = false;

  @ViewChild('delaisSemaineLiveView') delaisSemaineLiveView: EvenementsLiveViewComponent;

  public delaisSemaineRequestForm = <IEvenementsRequestFormPoco> { maxCount: 100, excludingDelaiTypeCodes: ['EnregistrementFin', 'PublicationEcheance'] };

  constructor(
    private pageHistoryService: PageHistoryService
  ) {
  } 

  ngOnInit() {
    this.refreshDelaisSemaine();
  }

  // Echéances de la semaine

  async refreshDelaisSemaine() {

    let date = new Date();
    let prevMonday = new Date();
    if(date.getDay() == 0){
        prevMonday.setDate(date.getDate() - 7);
    }
    else{
        prevMonday.setDate(date.getDate() - (date.getDay()-1));
    }

    let requestForm: IEvenementsRequestFormPoco = UtilsHelper.clone<IEvenementsRequestFormPoco>(this.delaisSemaineRequestForm);

    requestForm.excludingDelaiTypeCodes = [ 'PublicationEcheance', 'EnregistrementFin', 'Abandon'];
    requestForm.debutDate = moment(prevMonday).toDate();
    requestForm.finDate = moment(prevMonday).add(7 , 'day').toDate();
    requestForm.estTraite = false;
    requestForm.evenementType = EvenementTypes.Any;
    requestForm.orderBy = "date asc";

    this.delaisSemaineRequestForm = requestForm;
  }

  async voirTous()
  {
    let uri = `/agenda/list?reset=true&debutDate=${this.delaisSemaineRequestForm.debutDate?.toISOString().slice(0,10) || ""}&finDate=${this.delaisSemaineRequestForm.finDate?.toISOString().slice(0,10) || ""}`;
    this.pageHistoryService.navigateByUrl(uri);
  }
}
