<div class="screen-center on-top" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<h2 i18n mat-dialog-title>Pays</h2>

<div class="form-row mb-3">
  <mat-form-field class="col-7">
    <mat-label i18n>Mot clé</mat-label>
    <input name="motcle" #inputMotCle="matInput" matInput [(ngModel)]="requestForm.motcle" (keyup)="search();" (keyup.enter)="select();">
    <button mat-button matSuffix mat-icon-button *ngIf="requestForm.motcle" (click)="$event.stopPropagation(); requestForm.motcle=null; refreshItems();">
      <mat-icon>clear</mat-icon>
    </button>
    <button mat-button matSuffix mat-icon-button (click)="refreshItems()">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>
</div>

<div class="data-table">
  <div class="d-flex">
    <mat-paginator [length]="payssTotalCount" [pageSize]="8" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </div>  
  <table mat-table [dataSource]="dataSource" matSort *ngIf="payss">

    <ng-container matColumnDef="select" sticky *ngIf="maxCount!=1">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [disabled]="isExcluded(row)"
            [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    
    <!--libelle Column -->
    <ng-container matColumnDef="libelle">
      <th i18n mat-header-cell *matHeaderCellDef mat-sort-header>Libellé</th>
      <td mat-cell *matCellDef="let row">{{row.libelle}}</td>
    </ng-container>

    <!-- Star Column -->
    <ng-container matColumnDef="star" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <div class="btn-group">
          <button i18n i18n-matTooltip mat-flat-button (click)="selectItem(row)" class="primary"
            [disabled]="isExcluded(row)"
            matTooltip="Sélectionner cet élément" *ngIf="!selection || selection?.selected.length<=1">CHOISIR</button>
        </div>
      </td>
    </ng-container>

    <ng-container *ngIf="maxCount!=1">
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    </ng-container>
    
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
  </table>
</div>

<mat-dialog-actions align="end" class="pt-3">
  <button i18n mat-flat-button class="secondary-text mr-2" mat-dialog-close>ANNULER</button>

  <button i18n i18n-matTooltip mat-raised-button class="primary"
    matTooltip="Choisir les éléments sélectionnés"
    (click)="selectItems()"
    [disabled]="!selection || (maxCount>-1 && selection?.selected.length >= maxCount)"
    *ngIf="selection && selection?.selected.length>1">CHOISIR</button>
</mat-dialog-actions>