import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExportFormats } from "@models/enums/domain/ExportFormats";
import {Observable} from 'rxjs';
import { UtilsHelper } from '@services/helpers/utils-helper'
import { AppService } from '@services/app/app.service'
import { AuthService } from '@services/app/auth.service';
import { IDataPresenterSpecDto } from '@models/dtos/data/export/IDataPresenterSpecDto';
import { ExportType } from '@models/enums/domain/ExportType';
import { IRequeteRequestFormPoco } from '@models/pocos/forms/IRequeteRequestFormPoco';
import { map } from 'rxjs/operators';
import { IGlobalRequeteRequestFormPoco } from '@models/pocos/forms/IGlobalRequeteRequestFormPoco';
import { ConditionsHelper } from '@services/helpers/conditions-helper';
import { IRequeteConditionPoco } from '@models/pocos/queries/IRequeteConditionPoco';

@Injectable()
export class ExportService {

    private baseApiUri_Spec: string;
    private baseApiUri_Export: string;

    constructor(
        private authService : AuthService,
        private httpClient: HttpClient,
        @Inject(LOCALE_ID) private locale: string
    ) {
        let baseApiUri: string = AppService.__Settings.platform.services[0].apiBaseUri + '/v1/data/export';

        this.baseApiUri_Spec = baseApiUri + '/specs';
        this.baseApiUri_Export = baseApiUri;
    }

    isGlobalRequeteRequestFormEmpty(requestForm: IGlobalRequeteRequestFormPoco): boolean {

        if (!requestForm) return true;

        let isEmpty = !(requestForm.queryStatementPoco?.filtres?.length>0
                && (ConditionsHelper.isClause(requestForm.queryStatementPoco?.filtres[0]) || (requestForm.queryStatementPoco?.filtres[0] as IRequeteConditionPoco).columnName))
            && !(requestForm.formPoco?.clients?.length>0)
            && !requestForm.formPoco?.principalGestionnaire?.id;
        return isEmpty;
    }

    getDataPresenterSpecs(exportTypes: ExportType[], lang: string = null): Observable<IDataPresenterSpecDto[]> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        let requestForm = { types: exportTypes, lang: lang ?? "" };
        return this.httpClient.get<IDataPresenterSpecDto[]>(`${this.baseApiUri_Spec}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    exportItems(requestForm: IRequeteRequestFormPoco): any {
        if (requestForm?.format==ExportFormats.Json)
        {
            let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
            return this.httpClient.get<any>(`${this.baseApiUri_Export}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });                
        }
        else
        {
            let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
            return this.httpClient.get(`${this.baseApiUri_Export}?${UtilsHelper.getQueryString(requestForm)}`, {headers: headers, observe: 'response', responseType: 'blob'})
                .pipe(map(item=> {
    
                    let contentDispositionHeader = item.headers.get('Content-Disposition')
                    let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
                    let fileName = result.replace(/"/g, '');
            
                    return {
                        blob: new Blob([item.body], {type: item.headers.get('Content-Type')}),
                        blobName:  fileName
                    };
                }));    
        }
    }
}
