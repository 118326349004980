export enum DossiersAccesTypes {
    
    // None.
    None = "None",

    // Accès total.
    TotalAcces = "TotalAcces",

    // Accès restreint.
    RestreintAcces = "RestreintAcces",

    // Any.
    Any = "Any"
}


