import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { map } from 'rxjs/operators';
import {IResultItemDto} from '@models/dtos/common/IResultItemDto'
import {IMarqueDossierDto} from '@models/dtos/runtime/dossiers/IMarqueDossierDto'
import {IModeleDossierDto} from '@models/dtos/runtime/dossiers/IModeleDossierDto'
import {IContentieuxDto} from '@models/dtos/runtime/dossiers/IContentieuxDto'
import { UtilsHelper } from '@services/helpers/utils-helper'
import { AppService } from '@services/app/app.service'
import { AuthService } from '@services/app/auth.service';
import { IDossierFamilleDto } from '@models/dtos/runtime/dossiers/IDossierFamilleDto';
import { DomainService } from './domain.service';
import { IDossierDto } from '@models/dtos/runtime/dossiers/IDossierDto';
import { IMarqueDossiersRequeteRequestFormPoco } from '@models/pocos/forms/IMarqueDossiersRequeteRequestFormPoco';
import { IContentieuxRequeteRequestFormPoco } from '@models/pocos/forms/IContentieuxRequeteRequestFormPoco';
import { IModeleDossiersRequeteRequestFormPoco } from '@models/pocos/forms/IModeleDossiersRequeteRequestFormPoco';
import { IDossiersRequestFormPoco } from '@models/pocos/forms/IDossiersRequestFormPoco';
import { TDataPage } from '@models/dtos/common/TDataPage';
import { IContentieuxTypeDto } from '@models/dtos/management/referentiel/IContentieuxTypeDto';
import { ILoiMoteurResultatDto } from '@models/dtos/runtime/dossiers/ILoiMoteurResultatDto';
import { IImageDto } from '@models/dtos/runtime/magasin/IImageDto';
import { IMarqueDossierArbreDto } from '@models/dtos/runtime/dossiers/IMarqueDossierArbreDto';
import { IDossierFamillesRequestFormPoco } from '@models/pocos/forms/IDossierFamillesRequestFormPoco';
import { QueryResultModes } from '@models/enums/QueryResultModes';
import { ICheckFormResult } from '@models/pocos/ICheckFormResult';
import { MarqueRegistreTypes } from '@models/enums/domain/MarqueRegistreTypes';
import { IDossierPaysDto } from '@models/dtos/management/referentiel/IDossierPaysDto';
import { IContentieuxDelaiDto } from '@models/dtos/runtime/dossiers/IContentieuxDelaiDto';

@Injectable()
export class DossiersService {

    private baseApiUri_MarqueDossiers: string;
    private baseApiUri_ModeleDossiers: string;
    private baseApiUri_Contentieux: string;
    private baseApiUri_DossierFamilles: string;
    private baseApiUri_Dossiers: string;

    constructor(
        private authService : AuthService,
        private httpClient: HttpClient,
        private domainService: DomainService,
        @Inject(LOCALE_ID) private locale: string
    ) {
        let baseApiUri: string = AppService.__Settings.platform.services[0].apiBaseUri + '/v1/dossiers';

        this.baseApiUri_Dossiers = baseApiUri;
        this.baseApiUri_MarqueDossiers = baseApiUri + '/marques';
        this.baseApiUri_ModeleDossiers = baseApiUri + '/modeles';
        this.baseApiUri_Contentieux = baseApiUri + '/contentieux';
        this.baseApiUri_DossierFamilles = baseApiUri + '/familles';
    }

    // Dossiers

    repairDossier(marqueDossier: IDossierDto) {
        if (!marqueDossier) return;
        
        if (!marqueDossier.correspondants)
        {
            marqueDossier.correspondants = [];
        }

        if (!marqueDossier.deposants)
        {
            marqueDossier.deposants = [];
        }

        if (!marqueDossier.documents)
        {
            marqueDossier.documents = [];
        }
        
        if (!marqueDossier.familles)
        {
            marqueDossier.familles = [];
        }

        if (!marqueDossier.voirAussiDossiers)
        {
            marqueDossier.voirAussiDossiers = [];
        }

        if (!marqueDossier.dossierDates)
        {
            marqueDossier.dossierDates = [];
        }

        if (!marqueDossier.titulaires)
        {
            marqueDossier.titulaires = [];
        }
    }

    listDossiers(requestForm: IDossiersRequestFormPoco): Observable<TDataPage<IDossierDto>> {

        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<TDataPage<IDossierDto>>(`${this.baseApiUri_Dossiers}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getDossier(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IMarqueDossierDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IMarqueDossierDto>(`${this.baseApiUri_Dossiers}/${id || ""}?mode=${mode || ""}`, { headers: headers });
    }

    addDossierDocuments(id: string, files: any[]): Observable<IResultItemDto[]> {
        if (!id || !(files?.length>0)) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        let formData:FormData = new FormData();
        files.forEach(file=> formData.append(file.name, file));

        return this.httpClient.post<IResultItemDto[]>(`${this.baseApiUri_Dossiers}/${id || ""}/documents`, formData, { headers });
    }

    downloadDossierDocument(id: string, documentId: string): any {
        if (!id || !documentId) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get(`${this.baseApiUri_Dossiers}/${id || ""}/documents/${documentId || ""}/download`, {headers: headers, observe: 'response', responseType: 'blob'})
            .pipe(map(item=> {

                let contentDispositionHeader = item.headers.get('Content-Disposition')
                let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
                let fileName = result.replace(/"/g, '');
        
                return {
                    blob: new Blob([item.body], {type: item.headers.get('Content-Type')}),
                    blobName:  fileName
                };
            }));
    }

    // MarqueDossiers

    repairMarqueDossier(marqueDossier: IMarqueDossierDto) {
        if (!marqueDossier) return;

        this.repairDossier(marqueDossier);

        if (!marqueDossier.classes)
        {
            marqueDossier.classes = [];
        }
    }

    checkMarqueDossier(marqueDossier: IMarqueDossierDto): ICheckFormResult {

        let result: ICheckFormResult = <ICheckFormResult> {};

        if (marqueDossier) {

            if (!marqueDossier.denomination) {
                result.message = $localize `Information requise` + ' : ' + $localize `Dénomination`;
                result.location = "general";
            }
            else if (!marqueDossier.client?.id)
            {
                result.message = $localize `Information requise` + ' : ' + $localize `Client`;
                result.location = "general";    
            }
            else if (!UtilsHelper.hasFlagValue(marqueDossier.statut)) {
                result.message = $localize `Information requise` + ' : ' + $localize `Statut`;
                result.location = "general";    
            }
            else if (!UtilsHelper.hasFlagValue(marqueDossier.registreType)) {
                result.message = $localize `Information requise` + ' : ' + $localize `Type de registre`;
                result.location = "general";
            }
            else if (marqueDossier.registreType!=MarqueRegistreTypes.InternationalEnregistrement && !marqueDossier.pays?.id)
            {
                result.message = $localize `Information requise` + ' : ' + $localize `Pays de dépôt`;
                result.location = "general";    
            }
        }

        return result;
    }
    
    checkMarqueDossierParente(marqueDossier: IMarqueDossierDto, arbre: IMarqueDossierArbreDto): boolean {

        let check = true;
        check &&= !(marqueDossier.registreType===MarqueRegistreTypes.InternationalEnregistrement && !arbre?.racineDossier);
        check &&= !(marqueDossier.registreType===MarqueRegistreTypes.International && arbre?.eiDossiers?.length===0);
        check &&= !(marqueDossier.registreType===MarqueRegistreTypes.National && !!marqueDossier.parent);
        check &&= !(marqueDossier.registreType===MarqueRegistreTypes.InternationalEnregistrement && marqueDossier.parent?.id != arbre.racineDossier?.id);
        check &&= !(marqueDossier.registreType===MarqueRegistreTypes.International && arbre?.eiDossiers?.length>0 && marqueDossier.parent?.id != arbre?.eiDossiers[0]?.id);

        return check;
    }

    getMarqueDossierUpdateResult(marqueDossier: IMarqueDossierDto, error: any): ICheckFormResult {

        let result: ICheckFormResult = <ICheckFormResult> {};

        if (marqueDossier) {

            if(error.find(q => q.title.indexOf('field missing')>-1 && q.title.indexOf("'DepotDate'")>-1) != null)
            {
                result.message = $localize `Le moteur de loi requière une date de dépôt`;
                result.location = "general";
            }
            else if(error.find(q => q.title.indexOf('field missing')>-1 && q.title.indexOf("'EnregistrementDate'")>-1) != null)
            {
                result.message = $localize `Le moteur de loi requière une date d'enregistrement`;
                result.location = "general";
            }
            else if(error.find(q => q.title.indexOf('less than')>-1 && q.title.indexOf("'EnregistrementDate'")>-1 && q.title.indexOf("'DepotDate'")>-1) != null)
            {
                result.message = $localize `Date d’enregistrement antérieure à la date de dépôt`;
                result.location = "general";
            }
            else if(error.find(q => q.title.indexOf('greater than')>-1 && q.title.indexOf("'DepotDate'")>-1 && q.title.indexOf("today")>-1) != null)
            {
                result.message = $localize `Date de dépôt postérieure à la date du jour`;
                result.location = "general";
            }
            else if(error.find(q => q.title.indexOf('not within')>-1 && q.title.indexOf("'PrioriteDepotDate'")>-1 && q.title.indexOf("DepotDate")>-1) != null)
            {
                result.message = $localize `Date de dépôt de priorité en dehors des six mois précédant la date de dépôt`;
                result.location = "general";
            }
        }

        return result;
    }

    checkNewMarqueDossier(marqueDossier: IMarqueDossierDto, deposeDossierPays: IDossierPaysDto [] = null): ICheckFormResult {

        let result: ICheckFormResult = <ICheckFormResult> {};

        if (marqueDossier) {
            if (!UtilsHelper.hasFlagValue(marqueDossier.registreType)) {
                result.message = $localize `Information requise` + ' : ' + $localize `Type de registre`;
                result.location = "general";    
            }
            else if (!marqueDossier.client?.id)
            {
                result.message = $localize `Information requise` + ' : ' + $localize `Client`;
                result.location = "general";    
            }
            else if (!UtilsHelper.hasFlagValue(marqueDossier.statut)) {
                result.message = $localize `Information requise` + ' : ' + $localize `Statut`;
                result.location = "general";    
            }            
            else 
                switch (marqueDossier.registreType) {
                    case MarqueRegistreTypes.National:
                        if (!marqueDossier.denomination) {
                            result.message = $localize `Information requise` + ' : ' + $localize `Dénomination`;
                            result.location = "general";    
                        }
                        else if (!marqueDossier.pays?.id)
                        {
                            result.message = $localize `Information requise` + ' : ' + $localize `Pays de dépôt`;
                            result.location = "general";    
                        }                                   
                        break;
                    case MarqueRegistreTypes.InternationalEnregistrement:
                        if (!marqueDossier.parent?.id) {
                            result.message = $localize `Information requise` + ' : ' + $localize `Dossier parent`;
                            result.location = "general";    
                        }                      
                        break;        
                    case MarqueRegistreTypes.International:
                        if (!marqueDossier.parent?.id) {
                            result.message = $localize `Information requise` + ' : ' + $localize `Dossier parent`;
                            result.location = "general";    
                        }
                        else if (!(deposeDossierPays.length>0)) {
                            result.message = $localize `Information requise` + ' : ' + $localize `Pays déposés`;
                            result.location = "general";    
                        }
                        break;
                }
        }

        return result;
    }

    isMarqueDossiersRequestFormEmpty(requestForm: IMarqueDossiersRequeteRequestFormPoco): boolean {
        let isEmpty = !requestForm.motcle
            && !(requestForm.clients?.length>0)
            && !requestForm.pays?.id
            && !requestForm.principalGestionnaire?.id;
        return isEmpty;
    }

    listMarqueDossiers(requestForm: IMarqueDossiersRequeteRequestFormPoco): Observable<TDataPage<IMarqueDossierDto>> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<TDataPage<IMarqueDossierDto>>(`${this.baseApiUri_MarqueDossiers}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    searchMarqueDossiers(requestForm: IMarqueDossiersRequeteRequestFormPoco): Observable<TDataPage<IMarqueDossierDto>> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<TDataPage<IMarqueDossierDto>>(`${this.baseApiUri_MarqueDossiers}/search?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getMarqueDossier(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IMarqueDossierDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IMarqueDossierDto>(`${this.baseApiUri_MarqueDossiers}/${id || ""}?mode=${mode || ""}`, { headers: headers });
    }

    addMarqueDossier(marqueDossier: IMarqueDossierDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_MarqueDossiers}`, marqueDossier, { headers: headers })
    }

    addInternationalMarqueDossiers(parentId: string, deposePaysIds: string[]): Observable<IResultItemDto[]> {
        if (!parentId) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto[]>(`${this.baseApiUri_MarqueDossiers}/${parentId || ""}/international`, deposePaysIds, { headers: headers })
    }

    updateMarqueDossier(marqueDossier: IMarqueDossierDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.put<IResultItemDto>(`${this.baseApiUri_MarqueDossiers}`, marqueDossier, { headers: headers })
    }

    deleteMarqueDossier(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto>(`${this.baseApiUri_MarqueDossiers}/${id || ""}`, { headers: headers })
    }

    deleteMarqueDossiers(ids: string[]): Observable<IResultItemDto[]> {
        if (!ids) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto[]>(`${this.baseApiUri_MarqueDossiers}?${UtilsHelper.getQueryString({ids: ids})}`, { headers: headers })
    }

    cloneMarqueDossier(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_MarqueDossiers}/${id || ""}/clone`, null, { headers: headers })
    }

    exportMarqueDossiers_Json(requestForm: IMarqueDossiersRequeteRequestFormPoco): any {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IMarqueDossierDto>(`${this.baseApiUri_MarqueDossiers}/export?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    exportMarqueDossiers(requestForm: IMarqueDossiersRequeteRequestFormPoco): any {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get(`${this.baseApiUri_MarqueDossiers}/export?${UtilsHelper.getQueryString(requestForm)}`, {headers: headers, observe: 'response', responseType: 'blob'})
            .pipe(map(item=> {

                let contentDispositionHeader = item.headers.get('Content-Disposition')
                let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
                let fileName = result.replace(/"/g, '');
        
                return {
                    blob: new Blob([item.body], {type: item.headers.get('Content-Type')}),
                    blobName:  fileName
                };
            }));
    }

    getLoiMoteurResultat(marqueDossier: IMarqueDossierDto): Observable<ILoiMoteurResultatDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<ILoiMoteurResultatDto>(`${this.baseApiUri_MarqueDossiers}/loi-moteur`, marqueDossier, { headers: headers });
    }

    getMarqueDossierImage(id: string, imageId: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IImageDto> {
        if (!id || !imageId) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IImageDto>(`${this.baseApiUri_MarqueDossiers}/${id || ""}/images/${imageId || ""}?mode=${mode || ""}`, { headers: headers });
    } 

    addMarqueDossierImages(id: string, imageType: string, files: any[]): Observable<IResultItemDto[]> {
        if (!id || !(files?.length>0)) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        let formData:FormData = new FormData();
        files.forEach(file=> formData.append(file.name, file));

        return this.httpClient.post<IResultItemDto[]>(`${this.baseApiUri_MarqueDossiers}/${id || ""}/images/${imageType || ""}`, formData, { headers });
    }

    // MarqueDossierArbre

    repairMarqueDossierArbre(marqueDossierArbre: IMarqueDossierArbreDto) {
        if (!marqueDossierArbre) return;
        
        if (!marqueDossierArbre.eiDossiers)
        {
            marqueDossierArbre.eiDossiers = [];
        }

        if (!marqueDossierArbre.internationalDossiers)
        {
            marqueDossierArbre.internationalDossiers = [];
        }
    }

    getMarqueDossierArbre(id: string, retourneAbandonnes: boolean): Observable<IMarqueDossierArbreDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IMarqueDossierArbreDto>(`${this.baseApiUri_MarqueDossiers}/${id || ""}/arbre?retourneAbandonnes=${retourneAbandonnes}`, { headers: headers });
    }

    // ModeleDossiers

    repairModeleDossier(modeleDossier: IModeleDossierDto) {
        if (!modeleDossier) return;

        this.repairDossier(modeleDossier);

        if (!modeleDossier.classes)
        {
            modeleDossier.classes = [];
        }
    }

    checkModeleDossier(modeleDossier: IModeleDossierDto): ICheckFormResult {

        let result: ICheckFormResult = <ICheckFormResult> {};

        if (modeleDossier) {

            if (!modeleDossier.denomination) {
                result.message = $localize `Information requise` + ' : ' + $localize `Dénomination`;
                result.location = "general";
            }
            else if (!modeleDossier.client?.id)
            {
                result.message = $localize `Information requise` + ' : ' + $localize `Client`;
                result.location = "general";    
            }
            else if (!UtilsHelper.hasFlagValue(modeleDossier.statut)) {
                result.message = $localize `Information requise` + ' : ' + $localize `Statut`;
                result.location = "general";    
            }
            else if (!UtilsHelper.hasFlagValue(modeleDossier.registreType)) {
                result.message = $localize `Information requise` + ' : ' + $localize `Type de registre`;
                result.location = "general";
            }
            else if (!modeleDossier.pays?.id)
            {
                result.message = $localize `Information requise` + ' : ' + $localize `Pays du dossier`;
                result.location = "general";    
            }
        }

        return result;
    }

    isModeleDossiersRequestFormEmpty(requestForm: IModeleDossiersRequeteRequestFormPoco): boolean {
        let isEmpty = !requestForm.motcle
            && !(requestForm.clients?.length>0)
            && !requestForm.pays?.id
            && !requestForm.principalGestionnaire?.id;
        return isEmpty;
    }

    listModeleDossiers(requestForm: IModeleDossiersRequeteRequestFormPoco): Observable<TDataPage<IModeleDossierDto>> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<TDataPage<IModeleDossierDto>>(`${this.baseApiUri_ModeleDossiers}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getModeleDossier(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IModeleDossierDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IModeleDossierDto>(`${this.baseApiUri_ModeleDossiers}/${id || ""}?mode=${mode || ""}`, { headers: headers });
    }

    addModeleDossier(ModeleDossier: IModeleDossierDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_ModeleDossiers}`, ModeleDossier, { headers: headers })
    }

    updateModeleDossier(ModeleDossier: IModeleDossierDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.put<IResultItemDto>(`${this.baseApiUri_ModeleDossiers}`, ModeleDossier, { headers: headers })
    }

    deleteModeleDossier(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto>(`${this.baseApiUri_ModeleDossiers}/${id || ""}`, { headers: headers })
    }

    deleteModeleDossiers(ids: string[]): Observable<IResultItemDto[]> {
        if (!ids) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto[]>(`${this.baseApiUri_ModeleDossiers}?${UtilsHelper.getQueryString({ids: ids})}`, { headers: headers })
    }

    cloneModeleDossier(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(this.baseApiUri_ModeleDossiers + `/${id || ""}/clone`, null, { headers: headers })
    }

    exportModeleDossiers_Json(requestForm: IModeleDossiersRequeteRequestFormPoco): any {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IModeleDossierDto>(`${this.baseApiUri_ModeleDossiers}/export?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });

    }

    exportModeleDossiers(requestForm: IModeleDossiersRequeteRequestFormPoco): any {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get(`${this.baseApiUri_ModeleDossiers}/export?${UtilsHelper.getQueryString(requestForm)}`, {headers: headers, observe: 'response', responseType: 'blob'})
            .pipe(map(item=> {

                let contentDispositionHeader = item.headers.get('Content-Disposition')
                let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
                let fileName = result.replace(/"/g, '');
        
                return {
                    blob: new Blob([item.body], {type: item.headers.get('Content-Type')}),
                    blobName:  fileName
                };
            }));
    }

    getModeleDossierImage(id: string, imageId: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IImageDto> {
        if (!id || !imageId) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IImageDto>(`${this.baseApiUri_ModeleDossiers}/${id || ""}/images/${imageId || ""}?mode=${mode || ""}`, { headers: headers });
    }

    addModeleDossierImages(id: string, imageType: string, files: any[]): Observable<IResultItemDto[]> {
        if (!id || !(files?.length>0)) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        let formData:FormData = new FormData();
        files.forEach(file=> formData.append(file.name, file));

        return this.httpClient.post<IResultItemDto[]>(`${this.baseApiUri_ModeleDossiers}/${id || ""}/images/${imageType || ""}`, formData, { headers });
    }

    // Contentieux

    repairContentieux(contentieux: IContentieuxDto) {
        if (!contentieux) return;

        if (!contentieux.avocats)
        {
            contentieux.avocats = [];
        }

        if (!contentieux.client)
        {
            contentieux.client = null;
        }
        
        if (!contentieux.type)
        {
            contentieux.type = <IContentieuxTypeDto> {};
        }
    }

    checkContentieux(contentieux: IContentieuxDto): ICheckFormResult {

        let result: ICheckFormResult = <ICheckFormResult> {};

        if (contentieux) {

            if (!contentieux.denomination) {
                result.message = $localize `Information requise` + ' : ' + $localize `Dénomination`;
                result.location = "general";
            }
            else if (!contentieux.client?.id) {
                result.message = $localize `Information requise` + ' : ' + $localize `Client`;
                result.location = "general";    
            }
            else if (!(contentieux?.contesteDossiers?.length>0)) {
                result.message = $localize `Information requise` + ' : ' + $localize `Dossier contesté`;
                result.location = "general";    
            }
            else if (!(contentieux?.adverseParties?.length>0)) {
                result.message = $localize `Information requise` + ' : ' + $localize `Partie adverse manquante`;
                result.location = "general";
            }
        }
        
        return result;
    }

    isContentieuxRequestFormEmpty(requestForm: IContentieuxRequeteRequestFormPoco): boolean {
        let isEmpty = !requestForm.motcle
            && !(requestForm.clients?.length>0)
            && !requestForm.referenceDossier?.id
            && !requestForm.pays?.id
            && !requestForm.principalGestionnaire?.id;
        return isEmpty;
    }

    listContentieux(requestForm: IContentieuxRequeteRequestFormPoco): Observable<TDataPage<IContentieuxDto>> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<TDataPage<IContentieuxDto>>(`${this.baseApiUri_Contentieux}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getContentieux(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IContentieuxDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IContentieuxDto>(`${this.baseApiUri_Contentieux}/${id || ""}?mode=${mode || ""}`, { headers: headers });
    }

    addContentieux(contentieux: IContentieuxDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_Contentieux}`, contentieux, { headers: headers })
    }

    updateContentieux(contentieux: IContentieuxDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.put<IResultItemDto>(`${this.baseApiUri_Contentieux}`, contentieux, { headers: headers })
    }

    deleteContentieux(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto>(`${this.baseApiUri_Contentieux}/${id || ""}`, { headers: headers });
    }

    deleteDesContentieux(ids: string[]): Observable<IResultItemDto[]> {
        if (!ids) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto[]>(`${this.baseApiUri_Contentieux}?${UtilsHelper.getQueryString({ids: ids})}`, { headers: headers })
    }

    cloneContentieux(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_Contentieux}/${id || ""}/clone`, null, { headers: headers })
    }

    addContentieuxDocuments(id: string, files: any[]): Observable<IResultItemDto[]> {
        if (!id || !(files?.length>0)) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        let formData:FormData = new FormData();
        files.forEach(file=> formData.append(file.name, file));

        return this.httpClient.post<IResultItemDto[]>(`${this.baseApiUri_Contentieux}/${id || ""}/documents`, formData, { headers });
    }

    downloadContentieuxDocument(id: string, documentId: string): any {
        if (!id || !documentId) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get(`${this.baseApiUri_Contentieux}/${id || ""}/documents/${documentId || ""}/download`, {headers: headers, observe: 'response', responseType: 'blob'})
            .pipe(map(item=> {

                let contentDispositionHeader = item.headers.get('Content-Disposition')
                let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
                let fileName = result.replace(/"/g, '');
        
                return {
                    blob: new Blob([item.body], {type: item.headers.get('Content-Type')}),
                    blobName:  fileName
                };
            }));
    }

    exportContentieux_Json(requestForm: IContentieuxRequeteRequestFormPoco): any {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IModeleDossierDto>(`${this.baseApiUri_Contentieux}/export?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });

    }
 
    exportContentieux(requestForm: IContentieuxRequeteRequestFormPoco): any {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get(`${this.baseApiUri_Contentieux}/export?${UtilsHelper.getQueryString(requestForm)}`, {headers: headers, observe: 'response', responseType: 'blob'})
            .pipe(map(item=> {

                let contentDispositionHeader = item.headers.get('Content-Disposition')
                let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
                let fileName = result.replace(/"/g, '');
        
                return {
                    blob: new Blob([item.body], {type: item.headers.get('Content-Type')}),
                    blobName:  fileName
                };
            }));
    }

    // Délais de contentieux

    
    checkContentieuxDelai(contentieuxDelai: IContentieuxDelaiDto): ICheckFormResult {

        let result: ICheckFormResult = <ICheckFormResult> {};

        if (contentieuxDelai) {

            if (!contentieuxDelai.type?.id) {
                result.message = $localize `Information requise` + ' : ' + $localize `Type de délai`;
                result.location = "general";    
            }
            else if (!(contentieuxDelai?.action)) {
                result.message = $localize `Information requise` + ' : ' + $localize `Action`;
                result.location = "general";    
            }
        }
        
        return result;
    }

    
    // Familles de dossiers

    repairDossierFamille(dossierFamille: IDossierFamilleDto) {

        if (!dossierFamille)
        {
            return;
        }

        this.domainService.repairLibeledDto(dossierFamille);

        if (!dossierFamille.clients)
        {
            dossierFamille.clients = [];
        }
    }

    listDossierFamilles(requestForm: IDossierFamillesRequestFormPoco): Observable<IDossierFamilleDto[]> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IDossierFamilleDto[]>(`${this.baseApiUri_DossierFamilles}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getDossierFamille(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IDossierFamilleDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IDossierFamilleDto>(`${this.baseApiUri_DossierFamilles}/${id || ""}?mode=${mode || ""}`, { headers: headers });
    }

    addDossierFamille(DossierFamille: IDossierFamilleDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_DossierFamilles}`, DossierFamille, { headers: headers })
    }

    updateDossierFamille(DossierFamille: IDossierFamilleDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.put<IResultItemDto>(`${this.baseApiUri_DossierFamilles}`, DossierFamille, { headers: headers })
    }

    deleteDossierFamille(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto>(`${this.baseApiUri_DossierFamilles}/${id || ""}`, { headers: headers })
    }

    deleteDossierFamilles(ids: string[]): Observable<IResultItemDto[]> {
        if (!ids) return of(null);
        const options = UtilsHelper.getUriOptionsWithBody(ids, this.authService);
        return this.httpClient.delete<IResultItemDto[]>(`${this.baseApiUri_DossierFamilles}`, options);
    }
}
