
<div class="col-12 mp-0">
  <mat-form-field class="col-12 mp-0" [appearance]="appearance" *ngIf="readonly && !mustShowMenu">
    <mat-label>{{label}}</mat-label>
    <input name="item" matInput value="{{getItemsText()}}" readonly [required]="required" />
  </mat-form-field>
  
  <div class="col-12 mp-0" *ngIf="(!autoComplete || itemMode===0 || itemMode===1) && (!readonly || mustShowMenu)">
    <mat-form-field class="col-12 mp-0" [appearance]="appearance" *ngIf="mustShowMenu" (click)="$event.stopPropagation(); showMenu();">
      <mat-label>{{label}}</mat-label>
      <input name="item" class="pointable" matInput value="{{getItemsText()}}" readonly [required]="required" />
    </mat-form-field>
        
    <mat-form-field class="col-12 mp-0" [appearance]="appearance" *ngIf="!mustShowMenu">
      <mat-label>{{label}}</mat-label>
  
      <mat-select *ngIf="itemMode===2 || itemMode===3" [ngModel]="item?.id" [disabled]="readonly" (selectionChange)="setItems([{id: $event.value}])">
        <mat-option *ngFor="let item of references" [value]="item.key">
          {{getReferenceText(item)}}
        </mat-option>
      </mat-select>
  
      <mat-select *ngIf="itemMode===0 || itemMode===1" [ngModel]="viewItemIds" [disabled]="readonly" (selectionChange)="setViewItems($event.value)" multiple>
        <mat-option *ngFor="let item of references" [value]="item.key">
          {{getReferenceText(item)}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-12 mp-0" *ngIf="autoComplete && !readonly && (itemMode===2 || itemMode===3)">    
    <items-selector class="col-12 mp-0" [itemId]="item?.id" (itemIdChange)="setItems([{id: $event}])" [label]="label"
      idField="key" labelField="content" [textFormat]="textFormat"
      [required]="required" [readonly]="readonly" [appearance]="appearance"
      [options]="references"></items-selector>
  </div>  
</div>
