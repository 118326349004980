import { IOrderByStatementDto } from '@models/dtos/data/queries/IOrderByStatementDto';
import { OrderByKinds } from '@models/enums/OrderByKinds';
import { IDataPresenterSpecDto } from '@models/dtos/data/export/IDataPresenterSpecDto';
import { DataInstructions } from '@models/enums/DataInstructions';
import { DataOperators } from '@models/enums/DataOperators';
import { DataValueTypes } from '@models/enums/DataValueTypes';
import { IFieldSpecDto } from '@models/dtos/data/export/IFieldSpecDto';
import { FormComponentKind } from '@models/enums/domain/FormComponentKind';
import { IRequeteClausePoco } from '@models/pocos/queries/IRequeteClausePoco';
import { IRequeteFiltrePoco } from '@models/pocos/queries/IRequeteFiltrePoco';
import { IRequeteConditionPoco } from '@models/pocos/queries/IRequeteConditionPoco';
import { IRequeteFiltreDto } from '@models/dtos/data/queries/IRequeteFiltreDto';
import { UtilsHelper } from './utils-helper';
import { RequeteFiltreTypes } from '@models/enums/RequeteFiltreTypes';

export class ConditionsHelper {

  static repairClause(clause: IRequeteClausePoco): void {
    if (!clause) return;

    if (!clause.filtres) clause.filtres = [];
  }

  static toDto(poco: IRequeteFiltrePoco): IRequeteFiltreDto {

    let dto = <IRequeteFiltreDto> {};
    if (poco) {
      if (ConditionsHelper.isClause(poco)) {
        let clause = poco as IRequeteClausePoco;
        dto.instruction = poco?.instruction;
        dto.type = RequeteFiltreTypes.Clause;
        dto.filtres = clause.filtres.map(q=> ConditionsHelper.toDto(q));
      }
      else {
        let condition = poco as IRequeteConditionPoco;
        dto = UtilsHelper.clone<IRequeteFiltreDto>(condition);
        dto.type = RequeteFiltreTypes.Condition;
      }
    }

    return dto;
  }
  
  static toPoco(dto: IRequeteFiltreDto): IRequeteFiltrePoco {

    if (dto) {
      switch (dto.type) {
        case RequeteFiltreTypes.Clause:
          let clause = <IRequeteClausePoco> { };
          clause.instruction = dto?.instruction;
          clause.filtres = dto.filtres.map(q=> ConditionsHelper.toPoco(q));
          return clause;
        case RequeteFiltreTypes.Condition:
          let condition = <IRequeteConditionPoco> { };
          condition.instruction = dto?.instruction;
          condition.columnName = dto?.columnName;
          condition.operator = dto?.operator;
          condition.value = dto?.value;
          return condition;
      }
    }

    return null;
  }

  // Filtres --------------------------

  static convertFiltre(filtre: IRequeteFiltrePoco, parent: IRequeteClausePoco, grandParent: IRequeteClausePoco) {

    if (!parent) return;

    if (ConditionsHelper.isClause(filtre)) {
      // clause -> condition
      let clause : IRequeteClausePoco= filtre as IRequeteClausePoco;
      let condition = clause.filtres?.length===1 ? clause.filtres[0] as IRequeteConditionPoco : ConditionsHelper.newCondition();
      let i = grandParent?.filtres.indexOf(filtre);
      if (i>-1) {
        condition.instruction = clause.instruction;
        grandParent.filtres[i] = condition;
      }  
    }
    else {
      // condition -> clause
      let condition : IRequeteConditionPoco= filtre as IRequeteConditionPoco;
      let clause = ConditionsHelper.newClause();
      clause.instruction = condition.instruction;
      clause.filtres.push(condition);

      let i = parent.filtres.indexOf(filtre);
      if (i>-1) {
        parent.filtres[i] = clause;
      }  
    }
  }

  // Clause ---------------------------

  static isClause(filtre: IRequeteFiltrePoco): boolean {
    return filtre && "filtres" in filtre;
  }

  static updateClause(clause: IRequeteClausePoco) {

    if (!clause?.filtres) return;

    // if the remaining clause is a clause then we convert it to condition
    if (clause.filtres.length===1 && ConditionsHelper.isClause(clause.filtres[0])) {

      clause.filtres = (clause.filtres[0] as IRequeteClausePoco)?.filtres;
    }

    if (clause.filtres.length>0) {
      clause.filtres[0].instruction = DataInstructions.None;
    }
  }

  static newClause(instruction: DataInstructions = DataInstructions.And) : IRequeteClausePoco {
    let clause: IRequeteClausePoco = <IRequeteClausePoco> { instruction: instruction, filtres: []};
  
    return clause;
  }

  static addClause(parent: IRequeteClausePoco) {

    if (!parent) return;
    parent.filtres ??= [];
    parent.filtres.push(ConditionsHelper.newClause());
    }

  static removeClause(clause: IRequeteClausePoco, parent: IRequeteClausePoco)
  {
    if (!parent) return;

    parent.filtres ??= [];
    parent.filtres = parent.filtres.filter(p=> p != clause);
  }

  // Condition ---------------------------

  static newCondition(instruction: DataInstructions = DataInstructions.And) : IRequeteConditionPoco {

    if (instruction === DataInstructions.None) {
      instruction = DataInstructions.And;
    }

    let condition = <IRequeteConditionPoco> { instruction: instruction, columnName: undefined, operator: DataOperators.None, value: null};
    return condition;
  }

  static addCondition(parent: IRequeteClausePoco, filtre: IRequeteFiltrePoco = null) {

    if (!parent) return;
    parent.filtres ??= [];

    let index = parent.filtres.indexOf(filtre);
    if (index>-1) {
      parent.filtres.splice(index + 1, 0, ConditionsHelper.newCondition(filtre?.instruction));
    }
    else {
      parent.filtres.push(ConditionsHelper.newCondition(filtre?.instruction));
    }

    ConditionsHelper.updateClause(parent);
  }

  static removeCondition(condition: IRequeteConditionPoco, parent: IRequeteClausePoco, grandParent: IRequeteClausePoco) {

    if (!parent) return;
    parent.filtres = parent.filtres.filter(p=> p != condition);
    
    if (parent.filtres.length===0) {
      ConditionsHelper.removeClause(parent, grandParent);
    }
    else {
      ConditionsHelper.updateClause(parent);
    }    
  }

  static lastFilter(parent: IRequeteClausePoco): IRequeteFiltrePoco
  {
    if (!parent) return null;
    return parent.filtres.length>0 && parent.filtres[parent.filtres.length-1];
  }

  static isLastFilterInSequence(filtre: IRequeteFiltrePoco, parent: IRequeteClausePoco): boolean
  {
    if (!(parent?.filtres?.length>0)) return null;
    let index = parent.filtres.indexOf(filtre);
    return index>-1 && ((index==parent.filtres.length-1)
      || (index<parent.filtres.length-1 
        && parent.filtres[index].instruction!=parent.filtres[index+1].instruction
        && !(parent.filtres[index].instruction == DataInstructions.None && parent.filtres[index+1].instruction === DataInstructions.And)));
  }

  // Script ---------------------------

  static orderToScript(statements: IOrderByStatementDto[]): string
  {
    let st ="";

    if (!(statements?.length>0)) return "";

    st = statements?.map(q=> q.columnName + " " + (q.kind === OrderByKinds.Ascending ? "asc" : q.kind === OrderByKinds.Descending ? "desc" : "")).join(", ");

    return st;
  }

  static clauseToScript(clause: IRequeteClausePoco, columns: IFieldSpecDto[]): string
  {
    if (!clause) return "";

    clause = ConditionsHelper.rebuildTree(clause);

    let st = ConditionsHelper._clauseToScript(clause, columns);
    return st?.trim();
  }

  static rebuildTree(clause: IRequeteClausePoco): IRequeteClausePoco {
    
    let filtres = [];

    // we rebuild tree
    let parentNode: IRequeteFiltrePoco;
    
    for(let i=0; i < clause.filtres.length; i++)
    {
      let current = clause.filtres[i];

      let isTabbed = ConditionsHelper.hasTabbed(current, clause);

      if (ConditionsHelper.isClause(current)) {
        let clause = ConditionsHelper.rebuildTree(current as IRequeteClausePoco);
        clause.filtres[i] = clause;
      }

      if (parentNode && isTabbed) {
        let parentClause = parentNode as IRequeteClausePoco;

        if (!ConditionsHelper.isClause(parentNode)) {
          // condition -> clause
          parentClause = ConditionsHelper.newClause();
          parentClause.instruction = parentNode.instruction;
          parentClause.filtres.push(parentNode);

          let j = filtres.indexOf(parentNode);
          if (j>-1) {
            filtres[j] = parentClause;
          }  
          parentNode = parentClause;
        }

        parentClause.filtres.push(current);
      }
      else {
        filtres.push(current);
        parentNode=current;
      }
    }

    clause = <IRequeteClausePoco> { instruction: DataInstructions.None, filtres: filtres };
    return clause;
  }

  static _clauseToScript(clause: IRequeteClausePoco, columns: IFieldSpecDto[]): string
  {
    if (!clause) return "";

    let script = "";
    for(let i=0; i<clause.filtres.length; i++)
    {
      let filtre = clause.filtres[i];
      let st = "";
  
      if (ConditionsHelper.isClause(filtre)) {
        st = "(" + ConditionsHelper._clauseToScript(filtre as IRequeteClausePoco, columns) + ")";
      }
      else {
        st = ConditionsHelper.conditionToScript(filtre as IRequeteConditionPoco, columns);
        if (!st) {
          switch (filtre.instruction) {
            case DataInstructions.None:
              st = "1=1";
              break;
            case DataInstructions.And:
              st = "1=1";
              break;
            case DataInstructions.Or:
              st = "1=0";
              break;
          }
        }
      }

      script += (i>0 ? " " + filtre.instruction.toString().toLowerCase() : "") + " (" + st + ")";
    }

    return script;
  }

  private static conditionToScript(condition: IRequeteConditionPoco, columns: IFieldSpecDto[]): string
  {
    if (!condition?.operator) return "";

    let column = columns?.find(q=>q.name?.toLowerCase()===condition.columnName?.toLowerCase());
    if (!column) return "";

    let st = condition.columnName + " ";

    switch(condition.operator)
    {
      case DataOperators.Containing:
        st += "constains";
        break;
      case DataOperators.Different:
        st += "!=";
        break;
      case DataOperators.Equal:
        st += "=";
        break;
      case DataOperators.Greater:
        st += ">";
        break;
      case DataOperators.GreaterOrEqual:
        st += ">=";
        break;
      case DataOperators.Lesser:
        st += "<";
        break;
      case DataOperators.LesserOrEqual:
        st += "<=";
        break;
      case DataOperators.NotContaining:
        st += "_constains";
        break;
    }

    let value = condition.value?.toString() ?? null;

    let valueType = column?.valueType;
    if (value === null) {
      st += "null";
    }
    else if (valueType=== DataValueTypes.Date
        || valueType=== DataValueTypes.Time
        || valueType=== DataValueTypes.Text) {
        value = value?.trim();
        st += " '" + value?.replace("'", "''") + "'";
    }
    else {
      st += value;
    }

    return st;
  }

  static hasTabbed(filtre: IRequeteFiltrePoco, clause: IRequeteClausePoco): boolean {
    
    if (!filtre || !clause) return false;

    let isTabbed: boolean = false;
    let instruction = DataInstructions.None;
    
    for(let i=0;i<clause.filtres.length;i++)
    {
      let current = clause.filtres[i];

      if (current.instruction != instruction) {

        if (instruction === DataInstructions.None) {

          for(let j=i;j<clause.filtres.length;j++) {
            let next = clause.filtres[j];
            if (current.instruction != next.instruction) {
              isTabbed = !isTabbed;
              break;
            }  
          }
        }
        else {
          isTabbed = !isTabbed;
        }

        instruction = current.instruction;
      }
      
      let prev = i === 0 ? null : clause.filtres[i-1];
      let next = i >= clause.filtres.length - 1 ? null : clause.filtres[i+1];
      
      if (current && prev && next && isTabbed && !ConditionsHelper.isClause(current) && !ConditionsHelper.isClause(prev) && !ConditionsHelper.isClause(next)) {
        let currentCondition : IRequeteConditionPoco = current as IRequeteConditionPoco;
        let prevCondition : IRequeteConditionPoco = prev as IRequeteConditionPoco;
        let nextCondition : IRequeteConditionPoco = next as IRequeteConditionPoco;
          
        if((currentCondition.columnName != prevCondition.columnName) && (currentCondition.columnName === nextCondition.columnName)) {
          isTabbed = !isTabbed;
        }
      }
      
      if (current==filtre) break;
    }
    
    return isTabbed;
  }

  // Columns ------------------------
  
  static getFormComponentKind(fieldSpec: IFieldSpecDto): FormComponentKind
  {
    switch(fieldSpec?.valueType)
    {
      case DataValueTypes.Boolean:
        return FormComponentKind.BooleanInput;
      case DataValueTypes.Date:
        return FormComponentKind.DateInput;
      case DataValueTypes.Integer:
      case DataValueTypes.Long:
      case DataValueTypes.Number:
        return FormComponentKind.NumberInput;
      case DataValueTypes.Text:
        //return FormComponentKind.FromApiList;
        return FormComponentKind.TextInput;
    }

    return FormComponentKind.None;
  }
    
  // Flags ------------------------

  static getFilteredColumns(
    columnNames: string[],
    presenterSpec: IDataPresenterSpecDto,
    addKeys: boolean = false,
    showObjects = true): string[] {

    if (!columnNames) { return; }
    
    // first we keep the specification columns

    columnNames = columnNames.filter(q=> presenterSpec?.columns.find(p=> p.name?.toLowerCase() === q?.toLowerCase()));
    
    // we remove the hidden fields and objects if needed

    presenterSpec?.columns?.filter(q=>q.isHidden
        || (!showObjects && (q.valueType === DataValueTypes.ByteArray || q.valueType === DataValueTypes.Object)))
        .forEach(
            p=> columnNames = columnNames.filter(q=> p.name?.toLowerCase() != q?.toLowerCase())
        );

    if (addKeys)
    {
        presenterSpec?.columns.filter(q=>q.isKey == true).forEach(q=> {

            if (!columnNames.find(p=>p===q.name))
            {
              columnNames.push(q.name);
            }
          });
    }

    return columnNames;
  }
}