import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IConsentFormDto } from '@models/dtos/management/securite/IConsentFormDto';
import { KeyCodes } from '@models/enums/KeyCodes';

@Component({
  selector: 'consent-dialog',
  templateUrl: './consent-dialog.component.html'
})
export class ConsentDialogComponent implements OnInit {

  public form: IConsentFormDto = <IConsentFormDto> {};

  constructor(
    private dialogRef: MatDialogRef<ConsentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });
  }

  async validate()
  {
    this.dialogRef.close(true);
  }
}
