import { EventEmitter, Component, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '@components/shared/dialogs/confirmation-dialog.component';
import { IMarqueDossierArbreDto } from '@models/dtos/runtime/dossiers/IMarqueDossierArbreDto';
import { IMarqueDossierDto } from '@models/dtos/runtime/dossiers/IMarqueDossierDto';
import { MessageKind } from '@models/enums/MessageKind';
import { DossiersService } from '@services/business/dossiers.service';
import { PageHistoryService } from '@services/nav/page-history.service';
import { AppPolicy } from '@models/enums/iam/AppPolicy';
import { AuthService } from '@services/app/auth.service';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { EntityType } from '@models/enums/domain/EntityType';
import { MarqueRegistreTypes } from '@models/enums/domain/MarqueRegistreTypes';
import { DossierStatuts } from '@models/enums/domain/DossierStatuts';

@Component({
  selector: 'marques-arbre',
  templateUrl: './marques-arbre.component.html'
})
export class MarquesArbreComponent implements OnInit, OnChanges {

  EntityTypes = EntityType;
  MarqueRegistreTypes = MarqueRegistreTypes;
  DossierStatuts = DossierStatuts;

  isLoading: boolean = false;

  @Input('class')
  public class: string;
  
  @Input('marqueDossier')
  marqueDossier: IMarqueDossierDto;

  @Input('retourneAbandonnes')
  public retourneAbandonnes: boolean = false;  

  @Input('readonly')
  public readonly: boolean = false;

  @Input()
  public viewOnItemClicked: boolean = true;

  @Output('viewItem')
  public viewItemChange: EventEmitter<IMarqueDossierDto> = new EventEmitter<IMarqueDossierDto>();

  public marqueDossierArbre: IMarqueDossierArbreDto;

  @Output('arbre')
  public marqueDossierArbreChange: EventEmitter<IMarqueDossierArbreDto> = new EventEmitter<IMarqueDossierArbreDto>();

  constructor(
    private authService: AuthService,
    private pageHistoryService: PageHistoryService,
    private dialog: MatDialog,
    private dossiersService: DossiersService,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.marqueDossierArbre = <IMarqueDossierArbreDto> {};    
  }
  
  ngOnChanges(changes: SimpleChanges) {

    if (changes.hasOwnProperty("marqueDossier")) {
      this.refreshMarqueDossierArbre();
    }
  }

  async refreshMarqueDossierArbre() {

    this.isLoading = true;
    this.dossiersService.getMarqueDossierArbre(this.marqueDossier?.id, this.retourneAbandonnes).subscribe({
      next: result => {
        this.marqueDossierArbre = result;
        this.marqueDossierArbreChange.emit(this.marqueDossierArbre);
        this.isLoading = false;
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
        this.isLoading = false;
      }
    });  
  }

  isViewOnItemRemoved() : boolean {
    return this.authService.hasPolicies(AppPolicy.DossiersFullWriters)
  }
  
  async viewMarqueDossier(marqueDossier: IMarqueDossierDto)
  {
    if (!this.viewOnItemClicked)
    {
      this.viewItemChange?.emit(marqueDossier);
    }
    else
    {
      this.pageHistoryService.navigate(['/folders/trademarks', marqueDossier?.id]);
    }
  }
}
