<h2 i18n mat-dialog-title>Délai</h2>

<form #evenementForm="ngForm" (ngSubmit)="$event.preventDefault()">
  <div mat-dialog-content class="dialog-text-small mb-2" *ngIf="evenement">

    <div>
      <entities-selector i18n-label class="col-11 mp-0"
        [type]="EntityTypes.Client" [item]='evenement.client'
        [viewOnItemClicked]="false" (viewItem)="viewClient($event);"
        (selectionChange)="hasChanged=true;"
        (itemChange)="setClient($event);" [required]="true" [readonly]="readonly || (clientId || evenement.type===EvenementTypes.Officiel)"
        label="Client"></entities-selector> 
    </div>

    <div class="row" *ngIf="evenement.client?.id">
      <mat-form-field class="col-4" appearance="outline">
        <mat-label i18n>Objet</mat-label>
        <mat-select name="evenementObjetType"
          [(ngModel)]="evenement.objetType"
          (selectionChange)="refreshDelaiTypes(true); hasChanged=true;" [disabled]="readonly || dossierId || contentieuxId || evenement.type===EvenementTypes.Officiel">
          <mat-option *ngFor="let item of objetTypes | keyvalue" [value]="item.key">
          {{item.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="col-8 mt-1" *ngIf="evenement.objetType===EvenementObjetTypes.Marque">
        <entities-selector i18n-label class="col-12 mp-0"
          [type]="EntityTypes.Dossier" [(item)]="evenement.dossier" [data]="{dossierNature: DossierNatures.Marque, clientIds: [evenement.client?.id]}"
          [viewOnItemClicked]="false" (viewItem)="viewMarqueDossier($event);"
          (selectionChange)="hasChanged=true;"
          [required]="true" [readonly]="readonly || dossierId || evenement.type===EvenementTypes.Officiel"
          label="Marque"></entities-selector>   
      </div>

      <div class="col-8 mt-1" *ngIf="evenement.objetType===EvenementObjetTypes.Modele">
        <entities-selector i18n-label class="col-12 mp-0"
          [type]="EntityTypes.Dossier" [(item)]="evenement.dossier" [data]="{dossierNature: DossierNatures.Modele, clientIds: [evenement.client?.id]}"
          [viewOnItemClicked]="false" (viewItem)="viewModeleDossier($event);"
          (selectionChange)="hasChanged=true;"
          [required]="true" [readonly]="readonly || dossierId || evenement.type===EvenementTypes.Officiel"
          label="Modèle"></entities-selector>   
      </div>

      <div class="col-8 mt-1" *ngIf="evenement.objetType===EvenementObjetTypes.Contentieux">
        <entities-selector i18n-label class="col-12 mp-0"
          [type]="EntityTypes.Contentieux" [(item)]="evenement.contentieux" [data]="{clientIds: [evenement.client?.id]}"
          [viewOnItemClicked]="false" (viewItem)="viewContentieux($event);"
          (selectionChange)="hasChanged=true;"
          [required]="true" [readonly]="readonly || contentieuxId || evenement.type===EvenementTypes.Officiel"
          label="Contentieux"></entities-selector>
      </div>    
    </div>

    <div class="row">
      <mat-form-field class="col-4" appearance="outline">
        <mat-label i18n>Choisir une date</mat-label>
        <input name="date" matInput [matDatepicker]="picker"
          [(ngModel)]="evenement.date"
          (dateChange)="hasChanged=true;"
          [disabled]="readonly || evenement.type===EvenementTypes.Officiel" required>

        <mat-datepicker-toggle matSuffix  *ngIf="!readonly && evenement.date && evenement.type!=EvenementTypes.Officiel"
          (click)="$event.stopPropagation(); hasChanged=true; this.evenement.date=null;">
          <mat-icon matDatepickerToggleIcon>clear</mat-icon>
        </mat-datepicker-toggle>

        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="col-8" appearance="outline">
        <mat-label i18n>Type d'évènement</mat-label>
        <mat-select name="evenementType"
          [(ngModel)]="evenement.type"
          (selectionChange)="refreshDelaiTypes(true); hasChanged=true;" required [disabled]="readonly || evenement.type===EvenementTypes.Officiel">
          <mat-option *ngFor="let item of evenementTypes | keyvalue" [value]="item.key">
            {{item.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field class="col-12" appearance="outline" *ngIf="((evenement.objetType===EvenementObjetTypes.Marque || evenement.objetType===EvenementObjetTypes.Modele) && evenement.dossier?.id) || (evenement.objetType===EvenementObjetTypes.Contentieux && evenement.contentieux?.id)">
        <mat-label i18n>Type de délai</mat-label>
        <input name="delaiType" type="text" placeholder="Type de délai" [formControl]="Control_DelaiTypes" matInput [matAutocomplete]="auto"
          (change)="hasChanged=true;"
          required [readonly]="readonly || evenement.type===EvenementTypes.Officiel">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getDelaiTypeText" (optionSelected)="setDelaiType($event.option.value);">
          <mat-option *ngFor="let delaiType of filteredDelaiTypes | async;" [value]="delaiType">
            {{delaiType.libelle}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="row mb-3">
      <mat-form-field class="col-12" appearance="outline">
        <mat-label i18n>Description</mat-label>
        <textarea name="description" #inputDescription="matInput" matInput cdkTextareaAutosize cdkAutosizeMinRows="3"
          (input)="hasChanged=true;"
          [(ngModel)]="evenement.description" [readonly]="readonly || evenement.type===EvenementTypes.Officiel"></textarea>
      </mat-form-field>
    </div>

    <div class="row mb-3 ml-2">
      <mat-checkbox i18n name="estTraite" class="mr-4"
        [(ngModel)]="evenement.estTraite"
        (change)="hasChanged=true;"
        [disabled]="readonly">Traité</mat-checkbox>
    </div>
  </div>

  <mat-dialog-actions align="end" class="pt-3" *ngIf="viewMode!=ActionKinds.Add && (readonly || !hasChanged)">
    <button i18n mat-raised-button class="primary" mat-dialog-close>OK</button>
  </mat-dialog-actions>

  <mat-dialog-actions align="end" class="pt-3" *ngIf="viewMode===ActionKinds.Edit && !readonly && hasChanged">
    <button i18n mat-flat-button class="secondary-text" (click)="resetEvenement();">ANNULER</button>
    <button i18n i18n-matTooltip mat-raised-button class="primary" (click)="save()"
      matTooltip="Enregistrer les modifications">ENREGISTRER</button>
  </mat-dialog-actions>
  
  <mat-dialog-actions align="end" class="pt-3" *ngIf="viewMode===ActionKinds.Add && !readonly">
    <button i18n mat-flat-button class="secondary-text" mat-dialog-close>ANNULER</button>
    <button i18n i18n-matTooltip mat-raised-button class="primary" (click)="save()"
      matTooltip="Ajouter un nouvel élément">AJOUTER</button>
  </mat-dialog-actions>
</form>