import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pane-dossiers-component',
  templateUrl: './dossiers.component.html'
})
export class DossiersComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
