import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { EntityType } from '@models/enums/domain/EntityType';
import { StatsService } from '@services/business/stats.service';
import { IStatsRequestFormPoco_Marques } from '@models/pocos/forms/IStatsRequestFormPoco_Marques';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { MessageKind } from '@models/enums/MessageKind';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'marques-depots',
  templateUrl: './marques-depots.component.html',
})
export class MarquesDepotsComponent implements OnInit {

  EntityTypes = EntityType;

  public requestForm: IStatsRequestFormPoco_Marques = <IStatsRequestFormPoco_Marques> {};
  isSearching: boolean = false;

  @Input('title')
  public title: string;
  
  public chartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
      x: {},
      y: {
        min: 0
      }
    },
    plugins: {
      legend: {
        display: false        
      },
      datalabels: {
        formatter: (value, ctx) => null,
      }
    }
  };
  public chartType: ChartType = 'bar';
  public chartPlugins = [ DatalabelsPlugin ];

  public chartData: ChartData<'bar'>;
  
  constructor(
    private statsService: StatsService,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.refresh();   
  }

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  async refresh() {

    if (this.isSearching) return;

    this.isSearching = true;
    this.statsService.getStatisque_Marques_Depots(this.requestForm).subscribe({
      next: result => {

        this.isSearching = false;

        this.chartOptions.scales.y.max = result.labels?.length > 0 ? undefined : 0;

        this.chartData = {
          labels: result.labels,
          datasets: [
            { data: result.sets[0]?.data, label: result.sets[0]?.label }
          ]
        };

      },
      error: _ => {
        this.isSearching = false;
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
      }
    });
  }
}
