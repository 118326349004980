export enum MarqueDossierImageTypes {

    // Undefined.
    Undefined = "Undefined",

    /// Depot.
    Depot = "Depot",

    /// Exploitation.
    Exploitation = "Exploitation",

    // Any.
    Any = "Any",
}
