import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IDossierDateDto } from '@models/dtos/runtime/dossiers/IDossierDateDto';
import { ConfirmationDialogComponent } from '@components/shared/dialogs/confirmation-dialog.component';
import { DossierDateViewDialogComponent } from './historique-dates-view-dialog.component';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { UtilsHelper } from '@services/helpers/utils-helper';
import { AppRole } from '@models/enums/iam/AppRole';
import { AuthService } from '@services/app/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'historique-dates-listview',
  templateUrl: './historique-dates-listview.component.html'
})
export class DossierDatesListViewComponent implements AfterViewInit, OnChanges {

  pageSize: number;
  pageSizeOptions: number[] = GlobalConstants.pageSizeOptions_Dialog;

  displayedColumns: string[] = ['date', 'libelle', 'creationDate', 'star'];
  dataSource: MatTableDataSource<IDossierDateDto>;

  showPaginator: boolean = true;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  @Input('class')
  public class: string;
  
  @Input()
  public items: IDossierDateDto[];

  @Output()
  public itemsChange: EventEmitter<IDossierDateDto[]> = new EventEmitter<IDossierDateDto[]>();

  @Output()
  public selectionChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Input('excluding')
  public excludingDossierDates: IDossierDateDto[] = [];

  @Input('maxCount')
  public maxCount: number = -1;

  @Input('readonly')
  public readonly: boolean = false;

  constructor(
    private authService: AuthService,
    private dialog: MatDialog
  ) {
    this.pageSize = this.pageSizeOptions[0];
  }

  ngAfterViewInit() {
    this.updatePaginator();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty("items")) {
      this.refreshItems();
    }
  }

  async refreshItems()
  {
    this.setItems(this.items, false);
    this.dataSource = new MatTableDataSource<IDossierDateDto>(this.items);
    this.updatePaginator();
  }

  async updatePaginator()
  {
    this.showPaginator = this.items?.length > this.pageSize;
    if (this.dataSource)
    {
      this.dataSource.paginator = this.paginator;
    }
  }

  canRemoveAll() : boolean {
    return this.authService.hasRoles(AppRole.Super, AppRole.Admin, AppRole.Manager);
  }

  async addNewDossierDate()
  {
    if (this.maxCount === -1 || this.items.length<this.maxCount)
    {
      let dossierDate = <IDossierDateDto> { date: new Date(), estEditable: true };

      const dialogRef = this.dialog.open(
        DossierDateViewDialogComponent,
        {
          panelClass: ['w-50', 'zoomed'],
          data: {
            dossierDate: dossierDate
          },
          autoFocus: false
        });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result)
        {
          this.items.push(dossierDate);
          this.setItems();
          this.refreshItems();
        }
      });
    }
  }

  async viewDossierDate(dossierDate: IDossierDateDto)
  {
    let cloneDossierDate = UtilsHelper.clone<IDossierDateDto>(dossierDate);

    const dialogRef = this.dialog.open(
      DossierDateViewDialogComponent,
      {
        panelClass: ['w-50', 'zoomed'],
        data: {
          dossierDate: cloneDossierDate,
          readonly: this.readonly
        },
        autoFocus: false
      });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        var index = this.items.indexOf(dossierDate);

        if (index > -1) {
          this.items[index] = cloneDossierDate;
          this.setItems();
          this.refreshItems();
        }
      }
    });
  }

  async removeDossierDate(dossierDate: IDossierDateDto)
  {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: $localize `Voulez-vous vraiment retirer cet élément ?`
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {    
        let index = this.items.indexOf(dossierDate);
        if (index > -1) {
          this.items.splice(index, 1);
        }
        this.setItems();
        this.refreshItems();
      }
    });
  }

  private setItems(dossierDates: IDossierDateDto[] = null, emit: boolean = true)
  {
    this.items = (dossierDates ?? this.items).filter(q=> !(this.excludingDossierDates?.find(p=>p.id === q.id)))
      ?.sort((a, b)=> {
        let stA= moment(a.creationDate)?.toISOString();
        let stB= moment(b.creationDate)?.toISOString();
        return stA.localeCompare(stB.toString())
      });

    if (emit)
    {
      this.itemsChange.emit(this.items);
      this.selectionChange.emit(true);
    }
  }
}
