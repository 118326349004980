import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IDataKeyValue } from '@models/dtos/common/IDataKeyValue';
import { IDictionaryDataItem } from '@models/dtos/common/IDictionaryDataItem';

@Component({
  selector: 'dictionary-items-listview',
  templateUrl: './dictionarydataitems-listview.component.html'
})
export class DictionaryDataItemsListViewComponent {

  @Input('item')
  public item: IDictionaryDataItem;

  @Input('keys')
  public keys: string[] | null = null;

  @Input('readonly')
  public readonly: boolean = false;

  @Input('required')
  public required: boolean = false;

  get valid(): boolean | null
  {
    let b = !this.item?.['add.value'].find((q: IDataKeyValue)=>!q.content);

    return b;
  }

  constructor(
    private dialog_dictionarydataitem: MatDialog
  ) {
  }
}
