import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IDataPresenterSpecDto } from '@models/dtos/data/export/IDataPresenterSpecDto';
import { IFieldSpecDto } from '@models/dtos/data/export/IFieldSpecDto';

@Component({
  selector: 'columns-list-view',
  templateUrl: './columns-list-view.component.html'
})
export class ColumnsListViewComponent implements OnChanges {

  @Input('presenterSpec')
  public presenterSpec: IDataPresenterSpecDto;

  @Input('default')
  defaultSelectedColumnNames: string[];

  @Input('excluding')
  public excludingColumnNames: string[] = [];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
  displayedColumns: string[];

  columns_selected: IFieldSpecDto[];
  dataSource_selected: MatTableDataSource<IFieldSpecDto>;

  @Output('selected')
  selectedChange: EventEmitter<IFieldSpecDto[]> = new EventEmitter<IFieldSpecDto[]>();

  columns_unselected: IFieldSpecDto[];
  dataSource_unselected: MatTableDataSource<IFieldSpecDto>;

  @Output('unselected')
  columns_unselectedChange: EventEmitter<IFieldSpecDto[]> = new EventEmitter<IFieldSpecDto[]>();;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.displayedColumns = ['label'];
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.hasOwnProperty("presenterSpec")) {
      this.setColumns(this.defaultSelectedColumnNames, true);
    }
  }

  async refreshColumns()
  {    
    this.dataSource_selected = new MatTableDataSource<IFieldSpecDto>(this.columns_selected);
    this.dataSource_unselected = new MatTableDataSource<IFieldSpecDto>(this.columns_unselected);
  }

  public setColumns(selectedColumnNames: string[] = null, emit: boolean = true)
  {
    let columns = this.presenterSpec?.columns?.filter(q=>!q.isHidden);

    this.columns_selected = selectedColumnNames?.length>0
      ? selectedColumnNames.map(q=> columns?.find(p=> p.name?.toLowerCase() === q?.toLowerCase())).filter(q=> !!q)
      : columns?.filter(q=>q.isDefault);

    this.columns_unselected = columns?.filter(q=> !this.columns_selected.find(p=> p?.name?.toLowerCase() === q?.name?.toLowerCase()))
      .sort((a, b)=> a.label?.toLowerCase().localeCompare(b.label?.toLowerCase()));

    if (emit) {
      this.selectedChange.emit(this.columns_selected);
      this.columns_unselectedChange.emit(this.columns_unselected);
    }

    this.refreshColumns();
  }

  // Drag & drop

  drop(event: CdkDragDrop<string[]>) {

    let column = event.item.data;
    if (column)
    {
      if (this.columns_selected.find(q=>q===column)) {
        // from selected
        if (event.previousContainer === event.container) {
          this.swap(event.previousIndex, event.currentIndex);
        } else {
          this.unselectColumn(column);
        }  
      }
      else if (this.columns_unselected.find(q=>q===column)) {
        // from unselected
        this.selectColumn(column, event.currentIndex);
      }
    }
  }

  swap(i1: number, i2: number)
  {
    if (i1>-1 && i2>-1)
    {
      moveItemInArray(this.columns_selected, i1, i2);
      let columnNames = this.columns_selected.map(q=>q.name);  
      this.setColumns(columnNames);
    }
  }

  // Selection ---------------

  isExcluded(field: IFieldSpecDto)
  {
    return this.excludingColumnNames?.find(q=> field?.name === q);
  }

  unselectColumn(column: IFieldSpecDto) {

    let columnNames = this.columns_selected.filter(q=>q.name!=column?.name).map(q=>q.name);
    this.setColumns(columnNames);
  }

  selectColumn(column: IFieldSpecDto, index: number = -1) {

    let columnNames = this.columns_selected.filter(q=>q.name!=column?.name).map(q=>q.name);
    if (index===-1 || index >= columnNames.length) {
      columnNames.push(column?.name);
    }
    else {
      columnNames.splice(index, 0, column?.name);
    }
    this.setColumns(columnNames);
  }
}
