<div class="col-12 mp-0">
  <div *ngIf="readonly && items?.length === 0">
    <span i18n>Aucun</span>
  </div>

  <div class="row mb-3 ml-2">

    <div class="pointable border row mr-4 mb-2 bg-white text-normal" *ngFor="let item of items">
      <div class="pt-2">
        <utilisateur-view [routerLink]="['/parameters', 'users', item?.id]" [item]="item" (refreshed)="refreshItems();"></utilisateur-view>
      </div>
      <button i18n-matTooltip mat-flat-button class="secondary-text button-small" *ngIf="!readonly"
        matTooltip="Retirer cet élément"
        (click)="$event.stopPropagation(); removeItem(item)">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
    </div>

    <div class="border row mr-4 mb-2 bg-white" *ngIf="!readonly && (maxCount === -1 || items.length<maxCount)">
      <button i18n-matTooltip mat-flat-button class="primary-text button-small"
        matTooltip="Ajouter un élement"
        (click)="$event.stopPropagation(); addItem()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>