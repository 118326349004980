import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationDialogComponent } from '@components/shared/dialogs/confirmation-dialog.component';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { IDossierPaysDto } from '@models/dtos/management/referentiel/IDossierPaysDto';
import { MarqueRegistreTypes } from '@models/enums/domain/MarqueRegistreTypes';
import { PageHistoryService } from '@services/nav/page-history.service';
import { DossierPaysSearchDialogComponent } from './dossierpays-search-dialog.component';

@Component({
  selector: 'dossierpays-listview',
  templateUrl: './dossierpays-listview.component.html'
})
export class DossierPaysListViewComponent implements AfterViewInit, OnChanges {

  @Input('class')
  public class: string;
  
  @Input('pageSize')
  pageSize: number;

  displayedColumns: string[] = ['nom', 'star'];
  dataSource: MatTableDataSource<IDossierPaysDto>;

  showPaginator: boolean = true;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  @Input()
  public items: IDossierPaysDto[];

  @Output()
  public itemsChange: EventEmitter<IDossierPaysDto[]> = new EventEmitter<IDossierPaysDto[]>();

  @Output()
  public selectionChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Input('excluding')
  public excludingDossierPays: IDossierPaysDto[] = [];
 
  @Input('maxCount')
  public maxCount: number = -1;

  @Input('readonly')
  public readonly: boolean = false;

  @Input('marqueRegistreType')
  public marqueRegistreType: MarqueRegistreTypes = MarqueRegistreTypes.Any;

  constructor(
    private pageHistoryService: PageHistoryService,
    private dialog: MatDialog
  ) {
    this.pageSize ??= GlobalConstants.pageSizeOptions_Dialog[0];
  }

  ngAfterViewInit() {
    this.updatePaginator();
  }

  ngOnChanges() {
    this.refreshItems();
  }
  
  // DossierPays

  async refreshItems()
  {
    this.setItems(this.items, false);

    this.dataSource = new MatTableDataSource<IDossierPaysDto>(this.items);
    this.updatePaginator();
  }

  async updatePaginator()
  {
    this.showPaginator = this.items?.length > this.pageSize;
    if (this.dataSource)
    {
      this.dataSource.paginator = this.paginator;
    }
  }

  async addItem()
  {
    if (!this.readonly)
    {
      if (this.maxCount === -1 || this.items?.length<this.maxCount)
      {
        const dialogRef = this.dialog.open(
          DossierPaysSearchDialogComponent,
          {
            panelClass: ['w-50', 'zoomed'],
            data: {
              excluding: [...this.items, ...this.excludingDossierPays],
              marqueRegistreType: this.marqueRegistreType,
              maxCount: this.maxCount
            },
            autoFocus: false,
          });          

        dialogRef.afterClosed().subscribe(result => {
          if(result)
          {
            let dossierPays: IDossierPaysDto[] = [];

            if (this.maxCount===1)
            {
              dossierPays=result;
            }
            else
            {
              dossierPays = this.items;
              let newDossierPays = result;
              newDossierPays
                .filter(q=> !(dossierPays.find(p=>p.id === q.id)))
                .forEach(q=> { dossierPays.push(q); });  
            }

            this.setItems(dossierPays);
          }
        });
      }
    }
  }

  async viewItem(id: string)
  {
    this.pageHistoryService.navigate(['/parameters/masterdata/recordcountries', id]);
  }
  
  async removeItem(dossierPays: IDossierPaysDto)
  {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: $localize `Voulez-vous vraiment retirer cet élément ?`
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {    
        let dossierPayss = this.items;
        let index = dossierPayss.indexOf(dossierPays);
        if (index > -1) {
          dossierPayss.splice(index, 1);
          this.setItems(dossierPayss);
        }
      }
    });
  }

  private setItems(dossierPays: IDossierPaysDto[], emit: boolean = true)
  {
    this.items = (dossierPays ?? this.items).filter(q=> !(this.excludingDossierPays?.find(p=>p.id === q.id)))
      .sort((a, b)=> a.libelle?.toLowerCase().localeCompare(b.libelle?.toLowerCase())); 

    if (emit)
    {
      this.itemsChange.emit(this.items);
      this.selectionChange.emit(true);  
    }
  }
}
