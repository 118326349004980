export enum AddingMode {

    // None
    None = "None",

    // New
    New = "New",

    // Existing
    Existing = "Existing",

    // Both
    Both = "Both"
}
