import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute, Params, NavigationExtras, UrlTree, NavigationBehaviorOptions } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Injectable()
export class PageHistoryService {

  private historyStack: string[] = [];

  constructor(private router: Router) {
    this.push(this.router.url);

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {

        this.push(event.url);
      }
    });
  }

  public get history() {
    return this.historyStack;
  }

  public push(uri: string): string
  {
    if (!uri || uri.indexOf('/auth-callback') > -1)
    {
      this.flushPages();
      return null;
    }
    else if (uri.indexOf('/silent-callback') > -1)
    {
      return null;
    }

    this.historyStack.push(uri);
    return uri;
  }

  public popCurrent(defaultUri: string = null, params: Params = null): string {

    let uri = this.historyStack.pop();

    if (!uri)
    {
      uri = defaultUri;
    }

    let urlTree = this.router.parseUrl(uri);
    urlTree.queryParams['backPage'] = true;
    if (params)
    {
      Object.keys(params)?.forEach(el => {
        urlTree.queryParams[el] = params[el];
      });  
    }
    uri = urlTree.root + urlTree.toString();

    return uri;
  }

  public popLast(defaultUri: string = null, params: Params = null): string {

    let uri = this.popCurrent(null, params);
    uri = this.popCurrent(defaultUri, params);
    return uri;
  }

  public flushPages() {
    this.historyStack = this.historyStack?.length>0 ? [this.historyStack[this.historyStack.length-1]] : [];
  }

  public async navigateByUrl(url: string, extras: NavigationExtras = undefined): Promise<boolean> {

    return this.router.navigateByUrl(url, extras);
  }

  public navigate(commands: any[], extras: NavigationExtras = undefined): Promise<boolean> {

    let tree = this.router.createUrlTree(commands);
    let url = this.router.serializeUrl(tree).split('?')[0];

    return this.navigateByUrl(url, extras);
  }

  static routeParams(route: ActivatedRoute): Observable<Params> {
    return combineLatest(route.pathFromRoot.map(t => t.params))
        .pipe(
            map(q => Object.assign({}, ...q))
        );
  }
}