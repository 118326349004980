import { AfterViewInit, Component, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { DateAdapter } from '@angular/material/core';
import { IContactDto } from '@models/dtos/runtime/contacts/IContactDto';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactsService } from '@services/business/contacts.service';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { MessageKind } from '@models/enums/MessageKind';
import { FlagType } from '@models/enums/domain/FlagType';
import { ReferenceType } from '@models/enums/domain/ReferenceType';
import { EntityType } from '@models/enums/domain/EntityType';
import { KeyCodes } from '@models/enums/KeyCodes';

@Component({
  selector: 'contact-view-dialog',
  templateUrl: './contact-view-dialog.component.html'
})
export class ContactViewDialogComponent implements OnInit, AfterViewInit {

  FlagTypes = FlagType;
  ReferenceTypes = ReferenceType;
  EntityTypes = EntityType;
  
  viewInitialized: boolean = false;

  @ViewChild('contactForm') contactForm: NgForm;
  @ViewChild('inputNom') inputNom: MatInput;

  @Input('readonly')
  public readonly: boolean = false;

  @Input('item')
  public contact: IContactDto;

  constructor(
    private dialogRef: MatDialogRef<ContactViewDialogComponent>,
    private snackBar: MatSnackBar,
    private contactsService: ContactsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dateAdapter: DateAdapter<Date>,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.dialogRef.disableClose = true;

    if (data)
    {
      this.contact = data.contact;
      this.readonly = data.readonly;
    }

    this.dateAdapter.setLocale(this.locale);
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });
  }
  
  ngAfterViewInit() {

    setTimeout(() => {
      this.inputNom?.focus();
      this.viewInitialized = true;
    });
  }

  get checkForm(): string | null
  {
    let result = this.contactsService.checkContact(this.contact);

    return result?.message;
  }

  save()
  {
    let message = "";
    if (message = this.checkForm)
    {
      SnackBarExtension.open(this.snackBar, message, MessageKind.Warning);
    }
    else
    {
      this.dialogRef.close(this.contact);
    }
  }
}
