export enum MessageKind {

    // None.
    None = "None",

    // Message.
    Message = "Message",

    // Information.
    Information = "Information",

    // Warning.
    Warning = "Warning",

    // Error.
    Error = "Error",

    // Exception.
    Exception = "Exception",

    // Success.
    Success = "Success"
}
