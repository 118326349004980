export enum FormComponentKind {

        // Undefined.
        Undefined = "Undefined",
        // None.
        None = "None",
        //  Number.
        NumberInput = "NumberInput",
        // Date.
        DateInput = "DateInput",
        // Text.
        TextInput = "TextInput",        
        // Boolean.
        BooleanInput = "BooleanInput",

        // Sélecteur de client.
        ClientSelector = "ClientSelector",

        // Sélecteur de type de contentieux.
        ContentieuxTypeSelector = "ContentieuxTypeSelector",

        // Sélecteur de dossier.
        DossierSelector = "DossierSelector",

        // Sélecteur d'entités.
        EntitySelector = "EntitySelector",

        // Sélecteur de famille de dossiers.
        FamilleDossierSelector = "FamilleDossierSelector",

        // Sélecteur de données de flag
        FlagSelector = "FlagSelector",

        // Sélecteur de gestionnaire.
        GestionnaireSelector = "GestionnaireSelector",

        // Sélecteur de classe Locarno.
        LocarnoClasseSelector = "LocarnoClasseSelector",

        // Sélecteur de classe Nice.
        NiceClasseSelector = "NiceClasseSelector",

        // Sélecteur de pays.
        PaysSelector = "PaysSelector",

        // Sélecteur de référence.
        ReferenceSelector = "ReferenceSelector",

        // Any data operator.
        Any = "Any"
}
