import { Injectable } from '@angular/core';
import { AuthService } from '@services/app/auth.service';
import { AppPolicy } from '@models/enums/iam/AppPolicy';
import { AppRole } from '@models/enums/iam/AppRole';

@Injectable()
export class PolicyExtension {

  static isValid(authService: AuthService, policy: string): boolean {

    if (!authService)
    {
      return false;
    }
    
    if (policy?.indexOf(",") > -1)
    {
      let b = true;
      policy.split(',').forEach(q=> { 
        b &&= PolicyExtension.isValid(authService, q?.trim());
      });
      return b;
    }

    switch(policy?.toLowerCase())
    {
      // Agenda

      case AppPolicy.AgendaReaders.toString().toLowerCase():
        return authService.hasRoles(AppRole.Visitor, AppRole.Manager, AppRole.Admin, AppRole.Super);
      case AppPolicy.AgendaWriters.toString().toLowerCase():
        return authService.hasRoles(AppRole.Manager, AppRole.Admin, AppRole.Super);
      case AppPolicy.AgendaFullWriters.toString().toLowerCase():
        return authService.hasRoles(AppRole.Admin, AppRole.Super);

      // Contacts

      case AppPolicy.ContactsReaders.toString().toLowerCase():
        return authService.hasRoles(AppRole.Visitor, AppRole.Manager, AppRole.Admin, AppRole.Super);
      case AppPolicy.ContactsWriters.toString().toLowerCase():
        return authService.hasRoles(AppRole.Manager, AppRole.Admin, AppRole.Super);
      case AppPolicy.ContactsFullWriters.toString().toLowerCase():
        return authService.hasRoles(AppRole.Admin, AppRole.Super);

      // Dossiers

      case AppPolicy.DossiersReaders.toString().toLowerCase():
        return authService.isLoggedIn();
      case AppPolicy.DossiersFullReaders.toString().toLowerCase():
        return authService.hasRoles(AppRole.Manager, AppRole.Admin, AppRole.Super)
          || (authService.isClient() && authService.hasRole(AppRole.Member));
      case AppPolicy.DossiersWriters.toString().toLowerCase():
        return authService.hasRoles(AppRole.Manager, AppRole.Admin, AppRole.Super);
      case AppPolicy.DossiersFullWriters.toString().toLowerCase():
        return authService.hasRoles(AppRole.Super);

      // Contentieux

      case AppPolicy.ContentieuxReaders.toString().toLowerCase():
        return authService.hasRoles(AppRole.Visitor, AppRole.Manager, AppRole.Admin, AppRole.Super);
      case AppPolicy.ContentieuxWriters.toString().toLowerCase():
        return authService.hasRoles(AppRole.Manager, AppRole.Admin, AppRole.Super);
      case AppPolicy.ContentieuxFullWriters.toString().toLowerCase():
        return authService.hasRoles(AppRole.Super);

      // Requetes

      case AppPolicy.RequetesReaders.toString().toLowerCase():
        return authService.isLoggedIn();

      // Referentiel

      case AppPolicy.ReferentielReaders.toString().toLowerCase():
        return authService.hasRoles(AppRole.Manager, AppRole.Admin, AppRole.Super);
      case AppPolicy.ReferentielWriters.toString().toLowerCase():
        return authService.hasRoles(AppRole.Admin, AppRole.Super);
      case AppPolicy.ReferentielFullWriters.toString().toLowerCase():
        return authService.hasRoles(AppRole.Super);
 
      // Securite

      case AppPolicy.SecuriteReaders.toString().toLowerCase():
        return authService.hasRoles(AppRole.Super);
      case AppPolicy.SecuriteWriters.toString().toLowerCase():
        return authService.hasRoles(AppRole.Super);
      case AppPolicy.SecuriteFullWriters.toString().toLowerCase():
        return authService.hasRoles(AppRole.Super);
 
      // Logs

      case AppPolicy.LogsReaders.toString().toLowerCase():
        return authService.hasRoles(AppRole.Admin, AppRole.Super);
      case AppPolicy.LogsWriters.toString().toLowerCase():
        return authService.hasRoles(AppRole.Admin, AppRole.Super);
      case AppPolicy.LogsFullWriters.toString().toLowerCase():
        return authService.hasRoles(AppRole.Super);
    }

    return false;
  }
}