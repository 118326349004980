export enum EvenementObjetTypes {

    // Undefined.
    Undefined = "Undefined",

    // Marque.
    Marque = "Marque",

    // Modele.
    Modele = "Modele",

    // Dossier.
    Dossier = "Dossier",

    // Contentieux.
    Contentieux = "Contentieux",

    // Any.
    Any = "Any"
}
