import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IResultItemDto } from '@models/dtos/common/IResultItemDto';
import { IImageDto } from '@models/dtos/runtime/magasin/IImageDto';
import { EntityType } from '@models/enums/domain/EntityType';
import { MarqueDossierImageTypes } from '@models/enums/domain/MarqueDossierImageTypes';
import { ModeleDossierImageTypes } from '@models/enums/domain/ModeleDossierImageTypes';
import { MessageKind } from '@models/enums/MessageKind';
import { ContactsService } from '@services/business/contacts.service';
import { DossiersService } from '@services/business/dossiers.service';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { FilesAddDialogComponent } from '../files/files-add-dialog.component';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog.component';

@Component({
  selector: 'images-selector',
  templateUrl: './images-selector.component.html'
})
export class ImagesListViewComponent implements OnChanges {

  isLoading: boolean = false;

  @Input('class')
  public class: string;
  
  @Output()
  public selectionChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Input()
  public items: IImageDto[];

  @Output()
  public itemsChange: EventEmitter<IImageDto[]> = new EventEmitter<IImageDto[]>();

  @Input()
  public item: IImageDto;

  @Output()
  public itemChange: EventEmitter<IImageDto> = new EventEmitter<IImageDto>();

  private isItemMode: boolean = false;

  @Input('excluding')
  public excludingImages: IImageDto[] = [];

  @Input('maxCount')
  public maxCount: number = -1;

  @Input('readonly')
  public readonly: boolean = false;

  @Input('required')
  public required: boolean = false;

  @Output("valid")
  public validChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input('entityType')
  public entityType: EntityType = EntityType.None;

  @Input('entityId')
  public entityId: string;

  @Input('imageType')
  public imageType: string;

  get valid(): boolean | null
  {
    let b = !this.required || this.item != null || this.items?.length > 0;
    return b;
  }

  constructor(
    public contactsService: ContactsService,
    public dossiersService: DossiersService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {
  }
  
  ngOnChanges(changes: SimpleChanges) {

    if (changes.hasOwnProperty("item")) {
      if (!this.item?.id)
      {
        this.item = null;
      }
  
      this.items = [ this.item ];

      this.maxCount = 1;
      this.isItemMode = true;
    }

    this.refreshImages();
  }

  async refreshImages()
  {
    this.setImages(this.items, false);
  }

  async addImage()
  {
    if (this.maxCount === -1 || this.items.length<this.maxCount)
    {
      let title = null;

      switch(this.imageType)
      {
        case MarqueDossierImageTypes.Depot.toString(): {
          title = $localize `Ajouter une image déposée`;          
          break;
        }
        case MarqueDossierImageTypes.Exploitation.toString(): {
          title = $localize `Ajouter une image exploitée`;          
          break;
        }
        case ModeleDossierImageTypes.Principale.toString(): {
          title = $localize `Ajouter une image principale`;          
          break;
        }
        case ModeleDossierImageTypes.Additionnelle.toString(): {
          title = $localize `Ajouter une image additionnelle`;          
          break;
        }
      }

      const dialogRef = this.dialog.open(
        FilesAddDialogComponent,
        {
          panelClass: ['w-50', 'zoomed'],
          autoFocus: false,
          data: {
            entityType: this.entityType,
            entityId: this.entityId,
            maxCount: this.maxCount === -1 ? -1 : (this.maxCount - this.items.length),
            imageType : this.imageType,
            title: title ?? $localize `Ajouter une image`,
            toolTip: $localize `Déposez votre fichier ici (GIF, Jpeg ou Png)`
          }
        });
      
        dialogRef.afterClosed().subscribe(files => {
          if(files?.length>0)
          {
            this.isLoading = true;
            switch(this.entityType)
            {
              case EntityType.Contact: {
                this.contactsService.addContactImage(this.entityId, files[0]).subscribe({
                  next: result => {
                    this.addImages(result);
                    this.isLoading = false;
                  }, 
                  error: _ => {
                    SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception, $localize `Voir`, 5000);
                    this.isLoading = false;
                  }
                });
                
                break;
              }
              case EntityType.MarqueDossier: {
                this.dossiersService.addMarqueDossierImages(this.entityId, this.imageType, files).subscribe({
                  next: result => {
                    this.addImages(result);
                    this.isLoading = false;
                  },
                  error: _ => {
                    SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception, $localize `Voir`, 5000);
                    this.isLoading = false;
                  }
                });
          
                break;
              }
              case EntityType.ModeleDossier: {
                this.dossiersService.addModeleDossierImages(this.entityId, this.imageType, files).subscribe({
                  next: result => {
                    this.addImages(result);
                    this.isLoading = false;
                  },
                  error: _ => {
                    SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception, $localize `Voir`, 5000);
                    this.isLoading = false;
                  }
                });
      
                break;
              }
            }
          }
        });
    }
  }

  addImages(result: IResultItemDto[]) {

    if (result) {
      let items = this.items;
      result?.filter(q=> !(items.find(p=>p.id?.toString() === q.key?.toString())))
        .forEach(q=> { items.push(<IImageDto> { id: q.key}); });
      this.setImages(items);
    }
  }
    
  async removeImage(image: IImageDto)
  {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: $localize `Voulez-vous vraiment retirer cet élément ?`
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let items = this.items;
        let index = items.indexOf(image);
        if (index > -1) {
          items.splice(index, 1);
          this.setImages(items);
        }  
      }
    });
  }

  private setImages(items: IImageDto[], emit: boolean = true)
  {
    items = items?.sort((a, b)=> a.rang < b.rang ? 1 : -1);

    if(!items)
    {
      items = [];
    }

    items = items?.filter(q=> q?.id && !(this.excludingImages?.find(p=> p.id === q.id)));
    this.items = items;
    
    if (this.isItemMode)
    {
      this.item = this.items.find(q=>q) ?? null;

      if (emit)
      {
        this.itemChange.emit(this.item);
      }
    }
    else if (emit)
    {
      this.itemsChange.emit(this.items);
    }

    if (emit)
    {
      this.selectionChange.emit(true);
      this.validChange.emit(this.valid);  
    }
  }
  
  // Drag & drop

  drop(event: CdkDragDrop<string[]>) {

    let image = event.item.data;
    if (image)
    {
      if (this.items.find(q=>q===image)) {
        this.swap(event.previousIndex, event.currentIndex);
      }
    }
  }

  private swap(i1: number, i2: number)
  {
    if (i1>-1 && i2>-1)
    {
      moveItemInArray(this.items, i1, i2);
    }
  }

  moveLeft(item: IImageDto) {
    if (!item) {
      let index = this.items?.indexOf(item);
      this.swap(index, index-1);
    }
  }

  moveRight(item: IImageDto) {
    if (!item) {
      let index = this.items?.indexOf(item);
      this.swap(index, index+1);
    }
  }
}
