import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { IClientDto } from '@models/dtos/runtime/contacts/IClientDto';
import { IContentieuxDto } from '@models/dtos/runtime/dossiers/IContentieuxDto';
import { MessageKind } from '@models/enums/MessageKind';
import { QueryResultModes } from '@models/enums/QueryResultModes';
import { IContentieuxRequeteRequestFormPoco } from '@models/pocos/forms/IContentieuxRequeteRequestFormPoco';
import { DossiersService } from '@services/business/dossiers.service';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { UtilsHelper } from '@services/helpers/utils-helper';
import { KeyCodes } from '@models/enums/KeyCodes';

@Component({
  selector: 'contentieux-search-dialog',
  templateUrl: './contentieux-search-dialog.component.html'
})
export class ContentieuxSearchDialogComponent implements OnInit, AfterViewInit {

  pageSizeOptions: number[] = GlobalConstants.pageSizeOptions_Dialog;

  @ViewChild('inputMotCle') inputMotCle: MatInput;

  public requestForm: IContentieuxRequeteRequestFormPoco;

  @Input('clientIds')
  public clientIds: string[];

  @Input('excluding')
  public excludingContentieux: IContentieuxDto[] = [];

  @Input('maxCount')
  public maxCount: number = -1;

  isLoading: boolean = false;

  motcle: string;

  contentieux_: IContentieuxDto[];
  contentieuxTotalCount: number;

  displayedColumns: string[];
  dataSource: MatTableDataSource<IContentieuxDto>;
  selection = new SelectionModel<IContentieuxDto>(true, []);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    private dossiersService: DossiersService,
    private dialogRef: MatDialogRef<ContentieuxSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ) {
    this.dialogRef.disableClose = true;

    if (data)
    {
      this.clientIds = data.clientIds;
      this.excludingContentieux = data.excluding;    
      this.maxCount = data.maxCount ?? -1;
    }
    if (!this.clientIds)
    {
      this.clientIds = [];
    }

    this.requestForm = <IContentieuxRequeteRequestFormPoco> { };

    if (this.maxCount===1)
    {
      this.displayedColumns = ['reference', 'denomination', 'star'];
    }
    else
    {
      this.displayedColumns = ['select', 'reference', 'denomination', 'star'];
    }
  }
  
  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });

    this.initRequestForm();
    this.refreshContentieux();
  }
  
  ngAfterViewInit() {
    setTimeout(() => {
      this.inputMotCle.focus();
    });
  }

  async initRequestForm(excludeStorage: boolean = false)
  {
    this.requestForm = <IContentieuxRequeteRequestFormPoco> { 
      resultMode: QueryResultModes.Quick,
      clients: this.clientIds.map(q=> <IClientDto>{ id: q}), 
      pageIndex: 0,
      pageSize: 8,
      orderBy: "reference asc"
    };
  }

  changePage() {
    this.requestForm.pageSize = this.paginator?.pageSize;
    this.requestForm.pageIndex = this.paginator?.pageIndex;

    this.refreshContentieux();
  }

  // refresh ---------------

  async refreshContentieux(initPageIndex: boolean = false) {

    if (initPageIndex)
    {
      this.requestForm.pageIndex = 0;
    }
    this.paginator.pageIndex = this.requestForm.pageIndex;
    this.requestForm.resultMode = QueryResultModes.Quick;

    this.isLoading = true;

    this.dossiersService.listContentieux(this.requestForm).subscribe({
      next: result => {
        this.contentieux_ = result.items;
        this.dataSource = new MatTableDataSource<IContentieuxDto>(this.contentieux_);
        UtilsHelper.updateDataPageInfo(this.requestForm, result);
        this.contentieuxTotalCount = result?.totalCount;

        this.isLoading = false;
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
        this.isLoading = false;
      }
    });
  }

  // Dialog

  selectContentieux_()
  {
    this.dialogRef.close(this.selection.selected);
  }
   
  selectContentieux(contentieux: IContentieuxDto)
  {
    if (contentieux)
    {
      this.dialogRef.close([contentieux]);
    }
  }

  isExcluded(contentieux: IContentieuxDto)
  {
    return this.excludingContentieux.find(q=> contentieux?.id === q.id);
  }

  // Multiselection ---------------

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected())
    {
      this.selection.clear();
    }
    else
    {
      this.selection.clear();
      this.dataSource.data.forEach(row => !this.isExcluded(row) && this.selection.select(row));
    }
  }

  checkboxLabel(row?: IContentieuxDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
    
  search() {
    if (this.requestForm.motcle?.length>3) { 
      this.refreshContentieux(true); 
    }
  }

  select() {
    if (this.contentieux_?.length===1)
    {
      this.selectContentieux(this.contentieux_[0]);
    }
    else
    {
      this.refreshContentieux(true);
    }
  }
}
