import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IClientDto } from '@models/dtos/runtime/contacts/IClientDto';
import { ClientTypes } from '@models/enums/domain/ClientTypes';
import { PersonneTypes } from '@models/enums/domain/PersonneTypes';
import { ConfirmationDialogComponent } from '@components/shared/dialogs/confirmation-dialog.component';
import { ClientsSearchDialogComponent } from './clients-search-dialog.component';
import { PageHistoryService } from '@services/nav/page-history.service';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { StringHelper } from '@services/helpers/string-helper';

@Component({
  selector: 'clients-listview',
  templateUrl: './clients-listview.component.html'
})
export class ClientsListViewComponent implements AfterViewInit, OnChanges {

  @Input('class')
  public class: string;
  
  @Input('pageSize')
  pageSize: number;

  motcle: string;

  displayedColumns: string[] = ['nom', 'star'];
  dataSource: MatTableDataSource<IClientDto>;

  showPaginator: boolean = true;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  @Input()
  public items: IClientDto[];

  @Output()
  public itemsChange: EventEmitter<IClientDto[]> = new EventEmitter<IClientDto[]>();

  @Input('searchable')
  public searchable: boolean = true;

  @Input('excluding')
  public excludingItems: IClientDto[] = [];
  
  @Input('personneType')
  public personneType: PersonneTypes = PersonneTypes.Any;

  @Input('clientType')
  public clientType: ClientTypes.Any;

  @Input('maxCount')
  public maxCount: number = -1;

  @Input('parentId')
  public parentId: number | null = null;

  @Input('additionRestricted')
  public additionRestricted: boolean;  

  @Input('readonly')
  public readonly: boolean = false;

  @Input('required')
  public required: boolean = false;

  @Output('viewItem')
  public viewItemChange: EventEmitter<IClientDto> = new EventEmitter<IClientDto>();

  @Output("valid")
  public validChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public selectionChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  get valid(): boolean | null
  {
    let b = !this.required || (this.items?.length>0);
    return b;
  }

  constructor(
    private pageHistoryService: PageHistoryService,
    private dialog: MatDialog
  ) {
    this.pageSize ??= GlobalConstants.pageSizeOptions_Dialog[0];
  }

  ngAfterViewInit() {
    this.updatePaginator();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty("items")) {
      this.refreshItems();
    }
  }

  async refreshItems()
  {
    this.setItems(this.items, false);

    let items = this.items?.filter(q=> (!this.motcle 
      || q?.id?.toLowerCase().includes(this.motcle?.toLowerCase())
      || StringHelper.unaccent(q?.denomination)?.toLowerCase().includes(StringHelper.unaccent(this.motcle)?.toLowerCase())));

    this.dataSource = new MatTableDataSource<IClientDto>(items);

    this.updatePaginator();
  }

  async updatePaginator()
  {
    this.showPaginator = this.items?.length > this.pageSize;
    if (this.dataSource)
    {
      this.dataSource.paginator = this.paginator;
    }
  }

  async addItem()
  {
    if (!this.readonly)
    {
      if (this.maxCount === -1 || this.items?.length<this.maxCount)
      {
        const dialogRef = this.dialog.open(
          ClientsSearchDialogComponent,
          {
            panelClass: ['w-50', 'zoomed'],
            data: {
              clientType: this.clientType,
              excluding: [...this.items, ...this.excludingItems],
              maxCount: this.maxCount,
              personneType: this.personneType,
              parentId: this.additionRestricted ? this.parentId : null,
              readonly: false
            },
            autoFocus: false,
          });
    
        dialogRef.afterClosed().subscribe(result => {
          if(result)
          {
            let clients: IClientDto[] = [];

            if (this.maxCount===1)
            {
              clients=result;
            }
            else
            {
              clients = this.items;
              let newClients = result;
              newClients
                .filter(q=> !(clients.find(p=>p.id === q.id)))
                .forEach(q=> { clients.push(q); });  
            }

            this.setItems(clients);
          }
        });  
      }
    }
  }

  async addNewItem()
  {
    this.pageHistoryService.navigate(['/clients', this.parentId , 'clients', 'add']);
  }

  async viewItem(client: IClientDto)
  {
    if (this.viewItemChange)
    {
      this.viewItemChange.emit(client);
    }
    else
    {
      this.pageHistoryService.navigate(['/clients', client?.id]);
    }
}
  
  async removeItem(client: IClientDto)
  {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: $localize `Voulez-vous vraiment retirer cet élément ?`
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        let index = this.items.indexOf(client);
        if (index > -1) {
          this.items.splice(index, 1);
          this.setItems();
        }
      }
    });
  }

  private setItems(clients: IClientDto[] = null, emit: boolean = true)
  {
    this.items = (clients ?? this.items)?.filter(q=> !(this.excludingItems?.find(p=>p.id === q.id)))
      .sort((a, b)=> a.denomination?.toLowerCase().localeCompare(b.denomination?.toLowerCase()));

    if (emit)
    {
      this.itemsChange.emit(this.items);
      this.selectionChange.emit(true);
    }
  }
}
