import { Injectable } from '@angular/core';

import { IDictionaryDataItem } from '@models/dtos/common/IDictionaryDataItem';
import { ILabeledDto } from '@models/dtos/common/ILabeledDto';
import { IDataKeyValue } from '@models/dtos/common/IDataKeyValue';
import { GlobalConstants } from '@models/app/GlobalConstants';

@Injectable()
export class DomainService {

    constructor(
    ) {
    } 

    repairLibeledDto(dto: ILabeledDto, columnName: string = "global.libelle") {

        if (!dto)
        {
            return;
        }

        if (!dto[columnName])
        {
            dto[columnName] = <IDictionaryDataItem>{ "add.value": []};
        }

        // we remove all the values that have unknown keys (ui cultures)

        dto[columnName]["add.value"] = dto[columnName]["add.value"]
            .filter(q=> GlobalConstants.supportedLocales.map(q=> q.key).find(p=> q?.key?.toLowerCase() === p?.toLowerCase()));

        // we add required keys (ui cultures) if not existing

        GlobalConstants.supportedLocales
            .map(q=> q.key)
            .filter(q=> !dto[columnName]["add.value"].find(p=> p?.key?.toLowerCase() === q?.toLowerCase()))
            .forEach(q=> dto[columnName]["add.value"].push(<IDataKeyValue> { key: q }));
    }
}