export enum DataInstructions {

    /// <summary>
    /// None.
    /// </summary>
    None = "None",

    /// <summary>
    /// And.
    /// </summary>
    And = "And",

    /// <summary>
    /// Or.
    /// </summary>
    Or = "Or",

    /// <summary>
    /// Not.
    /// </summary>
    Not = "Not"
}
