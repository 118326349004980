import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {Observable} from 'rxjs';

import { UtilsHelper } from '@services/helpers/utils-helper'
import { AppService } from '@services/app/app.service'
import { AuthService } from '@services/app/auth.service';
import { IDataKeyValue } from '@models/dtos/common/IDataKeyValue';
import { IMasseRequestFormDto_DossiersMiseAJour } from '@models/dtos/data/masse/IMasseRequestFormDto_DossiersMiseAJour';
import { IMasseRequestFormDto_DossiersTransfert } from '@models/dtos/data/masse/IMasseRequestFormDto_DossiersTransfert';
import { IMasseRequestFormDto_PortefeuileCopie } from '@models/dtos/data/masse/IMasseRequestFormDto_PortefeuileCopie';

@Injectable()
export class MasseService {

    private baseApiUri_Transfert_PorteFeuille: string;
    private baseApiUri_Transfert_Dossiers: string;
    private baseApiUri_MetAJour_Dossiers: string;

    constructor(
        private authService : AuthService,
        private httpClient: HttpClient,
        @Inject(LOCALE_ID) private locale: string
        
    ) {
        let baseApiUri: string = AppService.__Settings.platform.services[0].apiBaseUri + '/v1/data/mass';

        this.baseApiUri_Transfert_PorteFeuille =  baseApiUri + '/copie-portefeuille';
        this.baseApiUri_Transfert_Dossiers = baseApiUri + '/transfert-dossiers';
        this.baseApiUri_MetAJour_Dossiers = baseApiUri + '/metajour-dossiers';
    }

    // Copie de portefeuilles

    portefeuilleCopie(requestForm: IMasseRequestFormDto_PortefeuileCopie): Observable<IDataKeyValue[]> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IDataKeyValue[]>(`${this.baseApiUri_Transfert_PorteFeuille}`, requestForm, { headers: headers });
    }

    // Transfert de dossiers

    dossiersTransfert(requestForm: IMasseRequestFormDto_DossiersTransfert): Observable<IDataKeyValue[]> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IDataKeyValue[]>(`${this.baseApiUri_Transfert_Dossiers}`, requestForm, { headers: headers });
    }

    // Met à jour de dossiers

    metajourDossiers(requestForm: IMasseRequestFormDto_DossiersMiseAJour): Observable<IDataKeyValue[]> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IDataKeyValue[]>(`${this.baseApiUri_MetAJour_Dossiers}`, requestForm, { headers: headers });
    }
}