import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {IResultItemDto} from '@models/dtos/common/IResultItemDto'
import { AppService } from '@services/app/app.service';
import { UtilsHelper } from '@services/helpers/utils-helper';
import { IBordTableauDto } from '@models/dtos/management/referentiel/IBordTableauDto';
import { AuthService } from '@services/app/auth.service';
import { DomainService } from './domain.service';
import { QueryResultModes } from '@models/enums/QueryResultModes';
import { IRequeteDto } from '@models/dtos/settings/IRequeteDto';
import { ICheckFormResult } from '@models/pocos/ICheckFormResult';

@Injectable()
export class SettingsService {

    private baseApiUri_MesBordTableaux: string;
    private baseApiUri_Requetes: string;

    constructor(
        private authService : AuthService,
        private httpClient: HttpClient,
        private domainService: DomainService,
        @Inject(LOCALE_ID) private locale: string
        
    ) {
        let baseApiUri: string = AppService.__Settings.platform.services[0].apiBaseUri + '/v1/settings';

        this.baseApiUri_MesBordTableaux = baseApiUri + '/bordtableaux';
        this.baseApiUri_Requetes = baseApiUri + '/requetes';
    }

    // Tableaux de bord

    listMesBordTableaux(): Observable<IBordTableauDto[]> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IBordTableauDto[]>(`${this.baseApiUri_MesBordTableaux}`, { headers: headers });
    }

    associeMesBordTableaux(ids: string[]): Observable<IResultItemDto[]> {
        if (!ids) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto[]>(`${this.baseApiUri_MesBordTableaux}`, ids, { headers: headers })
    }

    removeMesBordTableaux(): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_MesBordTableaux}`, null, { headers: headers });
    }

    // Requêtes

    checkRequete(requete: IRequeteDto): ICheckFormResult {

        let result: ICheckFormResult = <ICheckFormResult> {};

        if (requete) {
            if (!UtilsHelper.hasFlagValue(requete.libelle)) {
                result.message = $localize `Information requise` + ' : ' + $localize `Libellé`;
                result.location = "general";
            }
       }
        
        return result;
    }

    repairRequete(requete: IRequeteDto) {
        this.domainService.repairLibeledDto(requete);
        this.domainService.repairLibeledDto(requete, "global.description");
    }

    listRequetes(motcle: string = null): Observable<IRequeteDto[]> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IRequeteDto[]>(`${this.baseApiUri_Requetes}?motcle=${motcle|| ""}`, { headers: headers });
    }

    getRequete(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IRequeteDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IRequeteDto>(`${this.baseApiUri_Requetes}/${id || ""}?mode=${mode || ""}`, { headers: headers });
    }

    addRequete(requete: IRequeteDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_Requetes}`, requete, { headers: headers })
    }

    updateRequete(requete: IRequeteDto, mode: QueryResultModes = QueryResultModes.Full): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.put<IResultItemDto>(`${this.baseApiUri_Requetes}?mode=${mode || ""}`, requete, { headers: headers });
    }

    deleteRequete(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto>(`${this.baseApiUri_Requetes}/${id || ""}`, { headers: headers })
    }

    deleteRequetes(ids: string[]): Observable<IResultItemDto[]> {
        if (!ids) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto[]>(`${this.baseApiUri_Requetes}?${UtilsHelper.getQueryString({ids: ids})}`, { headers: headers })
    }
}