import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDividerModule} from '@angular/material/divider';
import {MatCardModule} from '@angular/material/card';
import {MatToolbarModule} from '@angular/material/toolbar';
import { DragNDropDirective } from '@directives/drag-n-drop.directive';
import { UserHasRolesDirective } from '@directives/user-has-roles.directive';
import {MatExpansionModule} from '@angular/material/expansion';
import { AdresseViewComponent } from '@components/shared/adresses/adresse-view.component';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { ClientsListViewComponent } from '@components/shared/clients/clients-listview.component';
import { ClientsSearchDialogComponent } from '@components/shared/clients/clients-search-dialog.component';
import { InterlocuteursListViewComponent } from '@components/shared/interlocuteurs/interlocuteurs-listview.component';
import { InterlocuteurViewDialogComponent } from '@components/shared/interlocuteurs/interlocuteur-view-dialog.component';
import { GestionnairesSearchDialogComponent } from '@components/shared/gestionnaires/gestionnaires-search-dialog.component';
import { EvenementsLiveViewComponent } from '@components/shared/evenements/evenements-liveview.component';
import { EvenementViewDialogComponent } from '@components/shared/evenements/evenement-view-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatedTrackedDetailsComponent } from '@components/shared/common/datedtracked/datedtracked-details.component';
import { DictionaryDataItemsListViewComponent } from '@components/shared/common/global/dictionarydataitems-listview.component';
import { EmployeesSearchDialogComponent } from '@components/shared/employees/employees-search-dialog.component';
import { GestionnairesListViewComponent } from '@components/shared/gestionnaires/gestionnaires-listview.component';
import { ContactsSearchDialogComponent } from '@components/shared/contacts/contacts-search-dialog.component';
import { DossierDateViewDialogComponent } from '@components/shared/dossiers/historique-dates/historique-dates-view-dialog.component';
import { DossierDatesListViewComponent } from '@components/shared/dossiers/historique-dates/historique-dates-listview.component';
import { DocumentsListViewComponent } from '@components/shared/documents/documents-listview.component';
import { DossierFamillesSearchDialogComponent } from '@components/shared/dossiers/familles/familles-search-dialog.component';
import { DossiersSearchDialogComponent } from '@components/shared/dossiers/dossiers-search-dialog.component';
import { EntitiesSelectorComponent } from '@components/shared/selectors/entities-selector.component';
import { DossiersListViewComponent } from '@components/shared/dossiers/dossiers-listview.component';
import { ContentieuxSearchDialogComponent } from '@components/shared/dossiers/contentieux/contentieux-search-dialog.component';
import { ContentieuxDelaisListComponent } from '@components/shared/dossiers/contentieux-delais/contentieux-delais-listview.component';
import { ImageViewDialogComponent } from '@components/shared/images/image-view-dialog.component';
import { ImageViewComponent } from '@components/shared/images/image-view.component';
import { ImagesListViewComponent } from '@components/shared/images/images-selector.component';
import { NiceClassesListDialogComponent } from '@components/shared/referentiel/classes-nice/niceclasses-list-dialog.component';
import { LocarnoClassesListDialogComponent } from '@components/shared/referentiel/classes-locarno/locarnoclasses-list-dialog.component';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { ContentieuxDelaiViewDialogComponent } from '@components/shared/dossiers/contentieux-delais/contentieux-delai-view-dialog.component';
import { PaysSearchDialogComponent } from '@components/shared/referentiel/pays/pays-search-dialog.component';
import { LoiMoteurViewDialogComponent } from '@components/shared/dossiers/loi-moteur/loi-moteur-view-dialog.component';
import { DelaisPassesComponent } from '@components/plugins/bordtableaux/delais-passes/delais-passes.component';
import { DelaisSemaineComponent } from '@components/plugins/bordtableaux/delais-semaine/delais-semaine.component';
import { UserHasPoliciesDirective } from '@directives/user-has-policies.directive';
import { ReplacePipe } from './pipes/replace.pipe';
import { ClientSnapViewComponent } from '@components/clients/snap/client-snap-view.component';
import { ContactSnapViewComponent } from '@components/contacts/snap/contact-snap-view.component';
import { LiveViewComponent } from '@components/shared/requetes/liveview.component';
import { ContactViewDialogComponent } from '@components/shared/contacts/contact-view-dialog.component';
import { ColumnsSelectDialogComponent } from '@components/shared/requetes/columns/columns-select-dialog.component';
import { MarquesArbreComponent } from '@components/shared/dossiers/arbre/marques-arbre.component';
import { FlagsSelectorComponent } from '@components/shared/selectors/flags-selector.component';
import { ReferencesSelectorComponent } from '@components/shared/selectors/references-selector.component';
import { DossierPaysSearchDialogComponent } from '@components/shared/referentiel/dossierpays/dossierpays-search-dialog.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ItemsSelectorComponent } from '@components/shared/selectors/items-selector.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSplitModule } from 'angular-split';
import { NgModule } from '@angular/core';
import { ConsentDialogComponent } from '@components/shared/legacy/consent-dialog.component';
import { RightsSelectorComponent } from '@components/shared/rights/rights-selector.component';
import { DataKeysListDialogComponent } from '@components/shared/common/datakeys/datakeys-list-dialog.component';
import { ColumnsListViewComponent } from '@components/shared/requetes/columns/columns-list-view.component';
import { FiltreClauseComponent } from '@components/shared/requetes/clauses/filtre-clause.component';
import { FiltreConditionComponent } from '@components/shared/requetes/clauses/filtre-condition.component';
import { FilesAddDialogComponent } from '@components/shared/files/files-add-dialog.component';
import { UtilisateurViewComponent } from '@components/shared/utilisateurs/utilisateur-view.component';
import { CustomMatPaginatorIntl } from '@services/nav/CustomMatPaginatorIntl';
import { DossiersDerniersComponent } from '@components/plugins/bordtableaux/dossiers-derniers/dossiers-derniers.component';
import { DossierPaysListViewComponent } from '@components/shared/referentiel/dossierpays/dossierpays-listview.component';
import { CustomMomentDateAdapter } from '@services/adapters/CustomMomentDateAdapter';
import { ClickStopPropagationDirective } from '@directives/click-stop-propagation';
import { ActionLogsLiveViewComponent } from '@components/shared/actionlogs/actionlogs-liveview.component';
import { ClientAccessListViewComponent } from '@components/shared/client-access/client-access-listview.component';
import { RequetesSearchDialogComponent } from '@components/shared/requetes/requetes-search-dialog.component';
import { ContactsListViewComponent } from '@components/shared/contacts/contacts-listview.component';
import { ColumnsExportDialogComponent } from '@components/shared/requetes/columns/columns-export-dialog.component';
import { NgChartsModule } from 'ng2-charts';
import { MarquesRegistresComponent } from '@components/plugins/bordtableaux/marques-registres/marques-registres.component';
import { MarquesDepotsComponent } from '@components/plugins/bordtableaux/marques-depots/marques-depots.component';
import { MarquesStatutsComponent } from '@components/plugins/bordtableaux/marques-statuts/marques-statuts.component';
import { MarquesZonesComponent } from '@components/plugins/bordtableaux/marques-zones/marques-zones.component';

const customDeclarations = [
  ReplacePipe,
  DragNDropDirective,
  UserHasRolesDirective,
  UserHasPoliciesDirective,
  ClickStopPropagationDirective
];

const materialModules = [
  RouterModule,
  CommonModule,
  HttpClientModule,
  FormsModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSnackBarModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  ReactiveFormsModule,
  MatPaginatorModule,
  MatMomentDateModule,
  AngularSplitModule,
  DragDropModule,
  NgChartsModule
];

const customModules = [
  DataKeysListDialogComponent,
  RightsSelectorComponent,
  ItemsSelectorComponent,
  EntitiesSelectorComponent,
  LiveViewComponent,
  ColumnsSelectDialogComponent,
  ColumnsExportDialogComponent,
  ColumnsListViewComponent,
  AdresseViewComponent,
  ClientsListViewComponent,
  ClientsSearchDialogComponent,
  ClientSnapViewComponent,
  ClientAccessListViewComponent,
  
  DatedTrackedDetailsComponent,
  DictionaryDataItemsListViewComponent,
  EmployeesSearchDialogComponent,
  EvenementViewDialogComponent,
  EvenementsLiveViewComponent,

  GestionnairesListViewComponent,
  GestionnairesSearchDialogComponent,
  UtilisateurViewComponent,

  ActionLogsLiveViewComponent,

  InterlocuteursListViewComponent,
  InterlocuteurViewDialogComponent,
  RequetesSearchDialogComponent,
  ContactsListViewComponent,
  ContactsSearchDialogComponent,
  ContactSnapViewComponent,
  ContactViewDialogComponent,

  DossierDatesListViewComponent,
  DossierDateViewDialogComponent,
  FilesAddDialogComponent,
  DocumentsListViewComponent,
  DossierFamillesSearchDialogComponent,
  DossiersSearchDialogComponent,
  DossiersListViewComponent,
  MarquesArbreComponent,
  ImageViewDialogComponent,
  ImageViewComponent,
  ImagesListViewComponent,
  NiceClassesListDialogComponent,
  LocarnoClassesListDialogComponent,
  PaysSearchDialogComponent,
  DossierPaysListViewComponent,
  DossierPaysSearchDialogComponent,

  ContentieuxSearchDialogComponent,
  ContentieuxDelaiViewDialogComponent,
  ContentieuxDelaisListComponent,

  LoiMoteurViewDialogComponent,

  DelaisPassesComponent,
  DelaisSemaineComponent,
  DossiersDerniersComponent,
  MarquesRegistresComponent,
  MarquesDepotsComponent,
  MarquesStatutsComponent,
  MarquesZonesComponent,

  FiltreClauseComponent,
  FiltreConditionComponent,
  FlagsSelectorComponent,
  ReferencesSelectorComponent,
  ConsentDialogComponent
];

@NgModule({
  declarations: [
    ...customDeclarations,
    ...customModules
  ],
  imports: [
    ...materialModules
  ],
  exports: [
    ...customDeclarations,
    ...materialModules,
    ...customModules
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: CustomMomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    { provide: MatPaginatorIntl,  useClass: CustomMatPaginatorIntl }
  ]
})
export class SharedModule { }
