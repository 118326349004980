import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { IDataKeyValue } from '@models/dtos/common/IDataKeyValue';
import { INiceClasseDto } from '@models/dtos/management/referentiel/INiceClasseDto';

@Component({
  selector: 'datakeys-list-dialog',
  templateUrl: './datakeys-list-dialog.component.html'
})
export class DataKeysListDialogComponent implements OnInit {

  pageSizeOptions: number[] = GlobalConstants.pageSizeOptions_Dialog_Small;

  displayedColumns: string[];
  dataSource: MatTableDataSource<IDataKeyValue>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input('items')
  public items: IDataKeyValue[] = [];
  itemsTotalCount: number;

  @Input('excluding')
  public excludingItems: INiceClasseDto[] = [];

  @Input('maxCount')
  public maxCount: number = -1;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data)
    {
      this.items = data.items;
      this.excludingItems = data.excluding;
      this.maxCount = data.maxCount ?? -1;
    }

    this.displayedColumns = ['code', 'libelle'];
  }
  
  ngOnInit() {
    this.refreshDataKeys();
  }

  async refreshDataKeys() {

    this.dataSource = new MatTableDataSource<IDataKeyValue>(this.items);

    this.dataSource.paginator = this.paginator;
  }
}
