import {Component, Inject, LOCALE_ID, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AboutDialogComponent} from '@components/shared/dialogs/about-dialog.component';
import { AuthService } from '@services/app/auth.service';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { UtilsHelper } from '@services/helpers/utils-helper';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { AppService } from '@services/app/app.service';
import { AppPolicy } from '@models/enums/iam/AppPolicy';
import { AppRole } from '@models/enums/iam/AppRole';
import { EnvironmentType } from '@models/enums/EnvironmentType';
import { IDataKeyValue } from '@models/dtos/common/IDataKeyValue';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html'
})
export class AppHeaderComponent implements OnInit {

  AppPolicies = AppPolicy;
  AppRoles = AppRole;
  EnvironmentTypes = EnvironmentType;

  public environment: string;
  public login : string;
  public supportUri: string = AppService.__Settings['support.uri'];

  langs: IDataKeyValue[] = GlobalConstants.supportedLocales;

  get getAvailableLangs(): IDataKeyValue[] {
    return this.langs.filter(q=> this.locale.toLowerCase()!=q.key.toLowerCase());
  }

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    public router: Router,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(DOCUMENT) private document: Document)
  {
    this.environment = AppService.__Settings.environment;
  }

  ngOnInit() {
    this.authService.isAuthenticatedSubjectChanged().subscribe(result => {

      this.login = this.authService.getUserName();
    });
  }

  isInParameters() : boolean {
    let url = this.router.url;
    return url?.toLowerCase().startsWith('/parameters') === true;
  }

  redirectToMyAccountUri() {
    let url = AppService.__Settings.platform.identityBaseUri + '/home';
    this.document.location.href = url;
  }

  redirectToMySupportUri() {
    window.open(this.supportUri, "_blank");
  }

  changeLanguage(locale: string) {

    let uri = this.router.url;
    let newUri = UtilsHelper.getUriOfLocale(uri, locale);
    
    this.document.location.href = newUri;
  }  
  
  Logout() {
    this.authService.logout();
  }

  openAbout() {
    this.dialog.open(AboutDialogComponent);
  }
}
