<div class="col-12 mp-0">
  <div class="col-12 mp-0" *ngIf="readonly">
    <mat-form-field class="col-12 mp-0" [appearance]="appearance">
      <mat-label>{{label}}</mat-label>
      <input name="item" matInput value="{{getItemsText()}}" readonly [required]="required" />
    </mat-form-field>
  </div>
  
  <div class="col-12 mp-0" *ngIf="(!autoComplete || itemMode===0 || itemMode===1) && !readonly">
    <mat-form-field class="col-12 mp-0" [appearance]="appearance">
      <mat-label>{{label}}</mat-label>
  
      <mat-select #flagtype *ngIf="itemMode===0" [ngModel]="items" [required]="required" [disabled]="readonly" (selectionChange)="setItems($event.value)" multiple>
        <mat-option *ngFor="let option of flagtypes" [value]="option.value">
          {{option.content}}
        </mat-option>
      </mat-select>
      <mat-select #flagtype *ngIf="itemMode===1" [ngModel]="itemIds" [required]="required" [disabled]="readonly" (selectionChange)="setItemIds($event.value)" multiple>
        <mat-option *ngFor="let option of flagtypes" [value]="option.key">
          {{option.content}}
        </mat-option>
      </mat-select>
      <mat-select #flagtype *ngIf="itemMode===2" [ngModel]="item?.toString()" [required]="required" [disabled]="readonly" (selectionChange)="setItem($event.value)">
        <mat-select-trigger>{{ !(flagtype?.selected?.viewValue) ? '' :  (!flagtype?.selected?.value?.icon ? flagtype?.selected?.viewValue : flagtype?.selected?.viewValue.substr(flagtype?.selected?.viewValue.indexOf(' '))) }}</mat-select-trigger>
        <mat-option *ngFor="let option of flagtypes" [value]="option.value">
          <mat-icon *ngIf="option.icon">{{option.icon}}</mat-icon> 
          {{option.content}}
        </mat-option>
      </mat-select>
      <mat-select #flagtype *ngIf="itemMode===3" [ngModel]="itemId" [required]="required" [disabled]="readonly" (selectionChange)="setItemId($event.value)">
        <mat-select-trigger>{{ !(flagtype?.selected?.viewValue) ? '' :  (!flagtype?.selected?.value?.icon ? flagtype?.selected?.viewValue : flagtype?.selected?.viewValue.substr(flagtype?.selected?.viewValue.indexOf(' '))) }}</mat-select-trigger>
        <mat-option *ngFor="let option of flagtypes" [value]="option.key">
          <mat-icon *ngIf="option.icon">{{option.icon}}</mat-icon> 
          {{option.content}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-12 mp-0" *ngIf="autoComplete && !readonly && itemMode===2">
    <items-selector class="col-12 mp-0" [item]="flagtype" (itemChange)="setItem($event?.value)" [label]="label" idField="value" labelField="content"
      [required]="required" [readonly]="readonly" [appearance]="appearance"
      [options]="flagtypes"></items-selector>
  </div>
  <div class="col-12 mp-0" *ngIf="autoComplete && !readonly && itemMode===3">
    <items-selector class="col-12 mp-0" [itemId]="itemId" (itemIdChange)="setItemId($event?.value)" [label]="label" idField="key" labelField="content"
      [required]="required" [readonly]="readonly" [appearance]="appearance"
      [options]="flagtypes"></items-selector>
  </div>  
</div>
