import { Component, Input, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageKind } from '@models/enums/MessageKind';
import { IImageDto } from '@models/dtos/runtime/magasin/IImageDto';
import { EntityType } from '@models/enums/domain/EntityType';
import { ContactsService } from '@services/business/contacts.service';
import { DossiersService } from '@services/business/dossiers.service';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { ImageViewDialogComponent } from './image-view-dialog.component';
import { QueryResultModes } from '@models/enums/QueryResultModes';

@Component({
  selector: 'image-view',
  templateUrl: './image-view.component.html'
})
export class ImageViewComponent implements OnInit {

  isLoading: boolean = false;
  public image: IImageDto;

  imageUrl: string;

  @Input('class')
  public class: string;
  
  @Input('item')
  public item: IImageDto;

  @Output()
  public itemRemove: EventEmitter<IImageDto> = new EventEmitter<IImageDto>();

  @Input('canDrag')
  public canDrag: boolean = true;

  @Input('readonly')
  public readonly: boolean = false;

  @Input('entityType')
  public entityType: EntityType = EntityType.None;

  @Input('entityId')
  public entityId: string;

  constructor(
    public contactService: ContactsService,
    public dossierService: DossiersService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
  }
  
  ngOnInit() {
    this.refreshImage();
  }

  async refreshImage()
  {
    this.isLoading = true;
    switch(this.entityType)
    {
      case EntityType.Contact: {
        this.contactService.getContactImage(this.entityId, this.item?.id, QueryResultModes.Full).subscribe({
          next: result => {
            this.image = result;
            this.setImageUrl();
            this.isLoading = false;
          },
          error: _ => {
            SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception, $localize `Voir`, 5000);
            this.isLoading = false;
          }
        });
        break;
      }
      case EntityType.MarqueDossier: {
        this.dossierService.getMarqueDossierImage(this.entityId, this.item?.id, QueryResultModes.Full).subscribe({
          next: result => {
            this.image = result;
            this.setImageUrl();
            this.isLoading = false;
          },
          error: _ => {
            SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception, $localize `Voir`, 5000);
            this.isLoading = false;
          }
        });
        break;
      }
      case EntityType.ModeleDossier: {
        this.dossierService.getModeleDossierImage(this.entityId, this.item?.id, QueryResultModes.Full).subscribe({
          next: result => {
            this.image = result;
            this.setImageUrl();
            this.isLoading = false;
          },
          error: _ => {
            SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception, $localize `Voir`, 5000);
            this.isLoading = false;
          }
        });
        break;
      }
    }
  }

  setImageUrl() {
    this.imageUrl = 'data:' + this.image?.contenuType + ';base64,'+ this.image?.contenu;
  }

  async viewImage()
  {
    const dialogRef = this.dialog.open(
      ImageViewDialogComponent,
      {
        panelClass: ['w-50', 'zoomed'],
        data: {
          image: this.image
        },
        autoFocus: false
      });
  }

  async removeImage()
  {    
    this.itemRemove?.emit(this.item);
  }
}
