import {Injectable} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { ConsentDialogComponent } from '@components/shared/legacy/consent-dialog.component';
import { StorageKeys } from '@models/app/StorageKeys';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { IamService } from '@services/app/iam.service';
import { AppPolicy } from '@models/enums/iam/AppPolicy';
import { AppRole } from '@models/enums/iam/AppRole';
import {AuthService} from './auth.service'
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageHistoryService } from '@services/nav/page-history.service';
import { MessageKind } from '@models/enums/MessageKind';
import { StorageHelper } from '@services/helpers/storage-helper';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(
        private authService: AuthService,
        private iamService: IamService,
        private pageHistoryService: PageHistoryService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        if(this.authService.isLoggedIn()) {

            if (!this.authService.hasRoles(AppRole.Visitor, AppRole.Member, AppRole.Manager, AppRole.Admin, AppRole.Super))
            {
                let url = this.authService.getUrl_accessDenied();
                window.location.href = url;
                return of(false);
            }

            // we check access according to policies

            let url = state.url.split('?')[0].toLowerCase();

            // we check consent

            let userId = this.authService.getUserId();
            let consentTokenId = StorageKeys.__serviceConsentChecked + "_" + userId;
            let consentChecked = StorageHelper.getLocalItem(consentTokenId, false);
            if (consentChecked != "true")
            {
                return this.iamService.getConsentChecked(userId).pipe(
                    map(
                        result => {

                            let checked = result;

                            if (checked)
                            {
                                StorageHelper.setLocalItem(consentTokenId, "true");
                                return true;
                            }
                            else                    
                            {
                                const dialogRef = this.dialog.open(ConsentDialogComponent, {
                                    panelClass: ['w-50'],
                                });
                                dialogRef.afterClosed().subscribe(result => {
                                    if(result) {
                                        return this.iamService.setConsentChecked(userId, true).subscribe({
                                            next: _ => {
                                                StorageHelper.setLocalItem(consentTokenId, "true");

                                                let uri = state.url ?? '';
                                                this.pageHistoryService.navigateByUrl(uri);
                    
                                                return true;
                                            },
                                            error: _ => {
                                                SnackBarExtension.open(this.snackBar, $localize `Une erreur est intervenue lors de la mise à jour`, MessageKind.Exception);
                                            }
                                        });
                                    }
                                });
                            }

                            return false;
                        },
                        error => {
                            SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
                            return false;
                        }
                    )
                );                  
            }
            
            // We manage redirections

            if ((url === "/") || (url === ""))
            {                
                return of(true);
            }

            // Agenda

            if (url.startsWith('/agenda')
                || (url.startsWith('/folders') && url.endsWith('/events')))
            {
                if (url.endsWith('/add'))
                {
                    return of(this.authService.hasPolicies(AppPolicy.AgendaWriters));
                }

                return of(this.authService.hasPolicies(AppPolicy.AgendaReaders));
            }

            // Clients

            else if (url.startsWith('/clients'))
            {
                if (url.endsWith('/add'))
                {
                    return of(this.authService.hasPolicies(AppPolicy.ContactsWriters));
                }

                return of(this.authService.hasPolicies(AppPolicy.ContactsReaders));
            }

            // Contacts

            else if (url.startsWith('/contacts'))
            {
                if (url.endsWith('/add'))
                {
                    return of(this.authService.hasPolicies(AppPolicy.ContactsWriters));
                }

                return of(this.authService.hasPolicies(AppPolicy.ContactsReaders));
            }
            
            // Dossiers

            else if (url.startsWith('/folders'))
            {
                if (url.endsWith('/add'))
                {
                    if (url.startsWith('/folders/litigations'))
                    {
                        return of(this.authService.hasPolicies(AppPolicy.ContentieuxWriters));
                    }
                    else
                    {
                        return of(this.authService.hasPolicies(AppPolicy.DossiersWriters));
                    }
                }

                let b = 
                    ((url === '/folders') &&
                        (this.authService.hasPolicies(AppPolicy.DossiersReaders)
                        || this.authService.hasPolicies(AppPolicy.ContentieuxReaders)))
                        || (url.startsWith('/folders/trademarks') && this.authService.hasPolicies(AppPolicy.DossiersReaders))
                        || (url.startsWith('/folders/designs') && this.authService.hasPolicies(AppPolicy.DossiersReaders))
                        || (url.startsWith('/folders/litigations') && this.authService.hasPolicies(AppPolicy.ContentieuxReaders))
                        || (url.startsWith('/folders/families') && this.authService.hasPolicies(AppPolicy.DossiersReaders));

                return of(b);
            }

            // Requêtes

            else if (url.startsWith('/queries'))
            {
                return of(true);
            }

            // Paramètres

            else if (url.startsWith('/parameters'))
            {
                if (url === '/parameters')
                {
                    return of(true);
                }
                else if (url.startsWith('/parameters/masterdata') && url.endsWith('/add'))
                {
                    return of(this.authService.hasPolicies(AppPolicy.ReferentielWriters));
                }
                else if (url.startsWith('/parameters/users') && url.endsWith('/add'))
                {
                    return of(this.authService.hasPolicies(AppPolicy.SecuriteWriters));
                }

                let b =                     
                    (url.startsWith('/parameters/my-dashboards'))
                    || (url.startsWith('/parameters/my-queries'))
                    || (url.startsWith('/parameters/masterdata') && this.authService.hasPolicies(AppPolicy.ReferentielReaders, AppPolicy.DossiersWriters))
                    || (url.startsWith('/parameters/logs') && this.authService.hasPolicies(AppPolicy.LogsReaders))
                    || (url.startsWith('/parameters/mass') && this.authService.hasPolicies(AppPolicy.DossiersFullWriters))
                    || (url.startsWith('/parameters/users') && this.authService.hasPolicies(AppPolicy.SecuriteReaders))
                    || (url.startsWith('/parameters/employees') && this.authService.hasPolicies(AppPolicy.SecuriteFullWriters));

                return of(b);
            }

            // Other

            return of(false);
        }
       
        let url = state.url ?? '';
        StorageHelper.setSessionItem(StorageKeys.CurrentUri, url);
    
        this.authService.startAuthentication();
        return of(false);
    }
}