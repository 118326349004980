import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app/app.service'
import {MatSnackBar} from '@angular/material/snack-bar';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { MessageKind } from '@models/enums/MessageKind';
import { IAppInfoDto } from '@models/app/IAppInfoDto';
import { TextDialogComponent } from './text-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppRole } from '@models/enums/iam/AppRole';
import { KeyCodes } from '@models/enums/KeyCodes';

@Component({
  selector: 'about-dialog.component',
  templateUrl: './about-dialog.component.html',
})
export class AboutDialogComponent implements OnInit
{
  AppRoles = AppRole;

  public apiInfo: IAppInfoDto;
  public localInfo: IAppInfoDto;

  constructor(
    private dialogRef: MatDialogRef<AboutDialogComponent>,
    private snackBar: MatSnackBar,
    public appService: AppService,
    private dialog: MatDialog
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });

    this.appService.GetApiInformation().subscribe(
    {
      next: result => {
        this.apiInfo = result;
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
      }      
    });

    this.appService.GetLocalInformation().subscribe(
      {
        next: result => {
          this.localInfo = result;
        },
        error: _ => {
          SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
        }      
      });
  }

  viewReleaseNote() {
    
    this.appService.GetReleaseNote().subscribe(
      {
        next: note => {
          this.dialog.open(
            TextDialogComponent,
            {
              panelClass: 'w-50',
              data: {
                title: $localize `Notes de version`,
                text: note
              },
              autoFocus: false
            });          
        },
        error: _ => {
          SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
        }      
      });    
  }
}