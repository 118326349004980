import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { IClientDto } from '@models/dtos/runtime/contacts/IClientDto';
import { ClientTypes } from '@models/enums/domain/ClientTypes';
import { PersonneTypes } from '@models/enums/domain/PersonneTypes';
import { QueryResultModes } from '@models/enums/QueryResultModes';
import { IClientsRequestFormPoco } from '@models/pocos/forms/IClientsRequestFormPoco';
import { ContactsService } from '@services/business/contacts.service';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { MessageKind } from '@models/enums/MessageKind';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { UtilsHelper } from '@services/helpers/utils-helper';
import { PageHistoryService } from '@services/nav/page-history.service';
import { AppPolicy } from '@models/enums/iam/AppPolicy';
import { KeyCodes } from '@models/enums/KeyCodes';
import { IntervenantTypes } from '@models/enums/domain/IntervenantTypes';

@Component({
  selector: 'clients-search-dialog',
  templateUrl: './clients-search-dialog.component.html'
})
export class ClientsSearchDialogComponent implements OnInit, AfterViewInit {

  AppPolicies = AppPolicy;

  pageSizeOptions: number[] = GlobalConstants.pageSizeOptions_Dialog;

  public title: string;

  @ViewChild('inputMotCle') inputMotCle: MatInput;

  @Input('readonly')
  public readonly: boolean = true;

  public requestForm: IClientsRequestFormPoco;
  isLoading: boolean = false;

  items: IClientDto[];
  itemsTotalCount: number;

  displayedColumns: string[];
  dataSource: MatTableDataSource<IClientDto>;
  selection = new SelectionModel<IClientDto>(true, []);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input('excluding')
  public excludingItems: IClientDto[] = [];

  @Input('intervenantType')
  public intervenantType: IntervenantTypes = IntervenantTypes.Any;

  @Input('retourneTitulaires')
  public retourneTitulaires: boolean = false;  

  @Input('personneType')
  public personneType: PersonneTypes = PersonneTypes.Any;

  @Input('clientType')
  public clientType: ClientTypes.Any;

  @Input('parentId')
  public parentId: string;

  @Input('maxCount')
  public maxCount: number = -1;

  constructor(
    private pageHistoryService: PageHistoryService,
    private contactsService: ContactsService,
    private dialogRef: MatDialogRef<ClientsSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar)
  {
    this.dialogRef.disableClose = true;

    if (data)
    {
      this.clientType = data.clientType;
      this.excludingItems = data.excluding;
      this.maxCount = data.maxCount ?? -1;  
      this.personneType = data.personneType;
      this.parentId = data.parentId;
      this.readonly = data.readonly;
      this.intervenantType = data.intervenantType;
      this.retourneTitulaires = data.retourneTitulaires;
      this.title = data.title;
    }
    this.title ??= $localize `Clients`;

    if (this.maxCount===1)
    {
      this.displayedColumns = ['nom', 'star'];
    }
    else
    {
      this.displayedColumns = ['select', 'nom', 'star'];
    }
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });

    this.initRequestForm();
    this.refreshItems();
  }
  
  ngAfterViewInit() {
    setTimeout(() => {
      this.inputMotCle.focus();
    });
  }

  async initRequestForm()
  {
    this.requestForm = <IClientsRequestFormPoco> { 
      resultMode: QueryResultModes.Partial,
      personneType: this.personneType,
      clientType: this.clientType,
      intervenantType: this.intervenantType,
      retourneTitulaires: this.retourneTitulaires,
      pageIndex: 0,
      parent: <IClientDto> { id: this.parentId},
      pageSize: 8,
      orderBy: "denomination asc"
    };
  }

  changePage() {
    UtilsHelper.updateDataPageInfo(this.requestForm, this.paginator);

    this.refreshItems();
  }

  async refreshItems(initPageIndex: boolean = false) {

    if (initPageIndex)
    {
      this.requestForm.pageIndex = 0;
    }
    this.paginator.pageIndex = this.requestForm.pageIndex;
    this.requestForm.resultMode = QueryResultModes.Partial;

    this.isLoading = true;
    this.contactsService.listClients(this.requestForm).subscribe({
      next: result => {
        this.items = result.items;
        this.dataSource = new MatTableDataSource<IClientDto>(this.items);
        UtilsHelper.updateDataPageInfo(this.requestForm, result);
        this.itemsTotalCount = result?.totalCount;

        this.isLoading = false;
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
        this.isLoading = false;
      }
    });
  }

  selectItems()
  {
    this.dialogRef.close(this.selection.selected);
  }

  selectItem(client: IClientDto)
  {
    if (client)
    {
      this.dialogRef.close([client]);
    }
  }

  isExcluded(client: IClientDto)
  {
    return this.excludingItems.find(q=> client?.id === q.id);
  }

  addNewClient() {

    this.pageHistoryService.navigate(['clients', 'add']); 

    this.dialogRef.close();
  }

  // Multiselection ---------------

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected())
    {
      this.selection.clear();
    }
    else
    {
      this.selection.clear();
      this.dataSource.data.forEach(row => !this.isExcluded(row) && this.selection.select(row));
    }
  }

  checkboxLabel(row?: IClientDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
  
  search() {
    if (this.requestForm.motcle?.length>3) { 
      this.refreshItems(true); 
    }
  }

  select() {
    if (this.items?.length===1)
    {
      this.selectItem(this.items[0]);
    }
    else
    {
      this.refreshItems(true);
    }
  }
}
