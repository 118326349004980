import { Injectable } from '@angular/core';
import { StorageKeys } from '@models/app/StorageKeys';
import { StringHelper } from './string-helper';
import { UtilsHelper } from './utils-helper';

export class StorageHelper {

  static setSessionItem(key: string, obj: any) {
    sessionStorage.setItem(key, JSON.stringify(obj));
  }

  static setLocalItem(key: string, obj: any) {
    localStorage.setItem(key, JSON.stringify(obj));
  }

  static getSessionItem<T>(
    key: string,
    defaultObj: any = null,
    storagecondition = true,
    defaultCondition = false,
    keepFun: (q: T, storedObj: T) => void = undefined): T {
      return StorageHelper.getItem<T>(
        false,
        key,
        defaultObj,
        storagecondition,
        defaultCondition,
        keepFun);
  }
  
  static getLocalItem<T>(
    key: string,
    defaultObj: any = null,
    storagecondition = true,
    defaultCondition = false,
    keepFun: (q: T, storedObj: T) => void = undefined): T {
      return StorageHelper.getItem<T>(
        true,
        key,
        defaultObj,
        storagecondition,
        defaultCondition,
        keepFun);
  }

  private static getItem<T>(
    isLocal: boolean,
    key: string,
    defaultObj: any = null,
    storagecondition = true,
    defaultCondition = false,
    keepFun: (q: T, storedObj: T) => void = undefined): T {

    let obj: T = null;
    let storedObj = null;

    let st = isLocal ? localStorage.getItem(key) : sessionStorage.getItem(key);
    storedObj = st ? StringHelper.jsonParse(st) as T : null;

    if (storagecondition && storedObj) {
      obj =  storedObj;
    }    

    if (!obj || defaultCondition) {
      obj = UtilsHelper.clone(defaultObj);
    }
    if (obj && keepFun) {
      keepFun(obj, storedObj);
    }

    return obj;
  }

  static clear()
  {
    sessionStorage.removeItem(StorageKeys.__formToken_Agenda);
    sessionStorage.removeItem(StorageKeys.__formToken_Clients);
    sessionStorage.removeItem(StorageKeys.__formToken_Contacts);
    sessionStorage.removeItem(StorageKeys.__formToken_Dossiers_Marques);
    sessionStorage.removeItem(StorageKeys.__formToken_Dossiers_Modeles);
    sessionStorage.removeItem(StorageKeys.__formToken_Dossiers_Contentieux);
    sessionStorage.removeItem(StorageKeys.__formToken_Requetes);
    sessionStorage.removeItem(StorageKeys.__formToken_Utilisateurs);

    sessionStorage.removeItem(StorageKeys.__exportColumnsToken_Evenements);
    sessionStorage.removeItem(StorageKeys.__exportColumnsToken_Clients);
    sessionStorage.removeItem(StorageKeys.__exportColumnsToken_Contacts);
    sessionStorage.removeItem(StorageKeys.__exportColumnsToken_Marques);
    sessionStorage.removeItem(StorageKeys.__exportColumnsToken_Modeles);
    sessionStorage.removeItem(StorageKeys.__exportColumnsToken_Contentieux);

    sessionStorage.removeItem(StorageKeys.__exportColumnsToken_Masse);
  }
}