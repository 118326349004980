import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { EntityType } from '@models/enums/domain/EntityType';
import { StatsService } from '@services/business/stats.service';
import { IStatsRequestFormPoco_Marques } from '@models/pocos/forms/IStatsRequestFormPoco_Marques';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { MessageKind } from '@models/enums/MessageKind';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'marques-registres',
  templateUrl: './marques-registres.component.html',
})
export class MarquesRegistresComponent implements OnInit {

  EntityTypes = EntityType;

  public requestForm: IStatsRequestFormPoco_Marques = <IStatsRequestFormPoco_Marques> {};
  isSearching: boolean = false;

  @Input('title')
  public title: string;

  public chartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      }
    }
  };
  public chartType: ChartType = 'pie';
  public chartPlugins = [ DatalabelsPlugin ];

  public chartData: ChartData<'pie', number[], string | string[]>;
  
  constructor(
    private statsService: StatsService,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.refresh();   
  }

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  async refresh() {

    if (this.isSearching) return;

    this.isSearching = true;
    this.statsService.getStatisque_Marques_Registres(this.requestForm).subscribe({
      next: result => {

        this.isSearching = false;

        this.chartData = {
          labels: result.labels,
          datasets: [
            { data: result.sets[0]?.data, label: result.sets[0]?.label }
          ]
        };

      },
      error: _ => {
        this.isSearching = false;
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
      }
    });
  }
}
