import {  Component, Input, OnInit, ViewChild } from '@angular/core';
import { IEvenementsRequestFormPoco } from '@models/pocos/forms/IEvenementsRequestFormPoco';
import { EvenementTypes } from '@models/enums/domain/EvenementTypes';
import * as moment from 'moment';
import { EvenementsLiveViewComponent } from '@components/shared/evenements/evenements-liveview.component';
import { PageHistoryService } from '@services/nav/page-history.service';
import { UtilsHelper } from '@services/helpers/utils-helper';

@Component({
  selector: 'delais-passes',
  templateUrl: './delais-passes.component.html',
})
export class DelaisPassesComponent implements OnInit {

  @Input('readonly')
  public readonly: boolean = false;

  @ViewChild('delaisPassesLiveView') delaisPassesLiveView: EvenementsLiveViewComponent;

  public delaisPassesRequestForm = <IEvenementsRequestFormPoco> { maxCount: 100, excludingDelaiTypeCodes: ['EnregistrementFin', 'PublicationEcheance'] };

  constructor(
    private pageHistoryService: PageHistoryService
  ) {
  } 

  ngOnInit() {
    this.refreshDelaisPasses();
  }

    // Echéances passées

  async refreshDelaisPasses() {

    let date = new Date();

    let requestForm: IEvenementsRequestFormPoco = UtilsHelper.clone<IEvenementsRequestFormPoco>(this.delaisPassesRequestForm);

    requestForm.excludingDelaiTypeCodes = [ 'PublicationEcheance', 'EnregistrementFin', 'Abandon'];
    requestForm.debutDate = moment(date).subtract(7 , 'day').toDate();
    requestForm.finDate = moment(date).toDate();
    requestForm.estTraite = false;
    requestForm.evenementType = EvenementTypes.Any;
    requestForm.orderBy = "date asc";

    this.delaisPassesRequestForm = requestForm;
  }

  async voirTous()
  {
    let uri = `/agenda/list?reset=true&debutDate=${this.delaisPassesRequestForm.debutDate?.toISOString().slice(0,10) || ""}&finDate=${this.delaisPassesRequestForm.finDate?.toISOString().slice(0,10) || ""}`;
    this.pageHistoryService.navigateByUrl(uri);
  }
}
