import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppPolicy } from '@models/enums/iam/AppPolicy';
import { KeyCodes } from '@models/enums/KeyCodes';
import { EntityType } from '@models/enums/domain/EntityType';
import { FlagType } from '@models/enums/domain/FlagType';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ContactTypesAddComponent } from '@components/parametres/referentiel/contacttypes/add/contacttypes-add.component';
import { IRequeteDto } from '@models/dtos/settings/IRequeteDto';
import { SettingsService } from '@services/business/settings.service';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageKind } from '@models/enums/MessageKind';
import { ResourceStatus } from '@models/enums/ResourceStatus';
import { PageHistoryService } from '@services/nav/page-history.service';
import { MatInput } from '@angular/material/input';
import { QueryResultModes } from '@models/enums/QueryResultModes';
import { IDictionaryDataItem } from '@models/dtos/common/IDictionaryDataItem';
import { IDataKeyValue } from '@models/dtos/common/IDataKeyValue';

@Component({
  selector: 'requetes-search-dialog',
  templateUrl: './requetes-search-dialog.component.html'
})
export class RequetesSearchDialogComponent implements OnInit {

  ContactTypes = ContactTypesAddComponent;
  AppPolicy = AppPolicy;
  EntityTypes = EntityType;
  FlagTypes = FlagType;

  public title: string;
  items: IRequeteDto[];
  libelle: string;
  requete: IRequeteDto;

  isSaved: boolean = false;
  isSaving: boolean;
  isLoading: boolean;

  displayedColumns: string[];
  dataSource: MatTableDataSource<IRequeteDto>;

  @ViewChild('inputLibelle', {static: true}) inputLibelle: MatInput;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    private snackBar: MatSnackBar,
    private settingsService: SettingsService,
    private pageHistoryService: PageHistoryService,
    private dialogRef: MatDialogRef<RequetesSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef.disableClose = true;

    if (data)
    {
      this.requete = data.requete;
      this.libelle = this.requete?.libelle;
      this.title = data.title;
    }
    this.title ??= $localize `Mes requêtes`;

    this.items ??= [];

    this.displayedColumns = ['libelle', 'star'];
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });

    this.refreshItems();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.inputLibelle?.focus();
    });
  }

  existeRequete(item: IRequeteDto) : boolean {
    return !!this.items?.find(q=> q.id?.toLowerCase()===item?.id?.toLowerCase());
  }

  refreshItems() {


    this.isLoading = true;

    this.settingsService.listRequetes().subscribe(
      {
        next: result => {
          this.items = result.sort((a, b)=> a.libelle?.toLowerCase().localeCompare(b.libelle?.toLowerCase()));;
          this.dataSource = new MatTableDataSource<IRequeteDto>(this.items);
          this.dataSource.paginator = this.paginator;
          this.isLoading = false;
        },
        error: _ => {
          SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
          this.isLoading = false;
        }
      });
  }

  viewMesRequetes() {
    this.dialogRef.close();
    this.pageHistoryService.navigate(['/parameters/my-queries']);
  }

  select(item: IRequeteDto = null, close: boolean = true) {

    item = item ?? this.requete;

    if (item?.id) {
      this.settingsService.getRequete(item?.id, QueryResultModes.Full).subscribe(
        {
          next: requete => {
            if (requete) {
              if (close) {
                this.dialogRef.close(requete);
              }
              else {
                requete.id = this.requete.id;
                requete.requeteClause = this.requete.requeteClause;
                this.requete = requete;
              }
            }
          },
          error: _ => {
            SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
            this.isLoading = false;
          }
        });  
    }
  }
  
  get checkForm(): string | null
  {
    let result = this.settingsService.checkRequete(this.requete);

    return result?.message;
  }

  saveSelecteItem() {
    let message: string;
    if (message = this.checkForm)
    {
      SnackBarExtension.open(this.snackBar, message, MessageKind.Warning);
      return;
    }

    this.isSaving = true;

    this.requete["global.libelle"] = <IDictionaryDataItem> { 
      "add.value": 
      [
        <IDataKeyValue> { key: "fr", content: this.requete.libelle },
        <IDataKeyValue> { key: "en", content: this.requete.libelle }
      ]
    };
    this.settingsService.updateRequete(this.requete).subscribe({
      next: result => {
        if(result.status=== ResourceStatus.Created || result.status=== ResourceStatus.Updated)
        {
          SnackBarExtension.open(this.snackBar, $localize `Mise à jour effectuée`, MessageKind.Success);
        }

        this.isSaving = false;
        this.isSaved = true;
        this.refreshItems();
      },
      error: _ =>
      {
        this.isSaving = false;

        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors de la mise à jour`, MessageKind.Exception);
      }
    });
  }
  
  addSelecteItem() {
    let message: string;
    if (message = this.checkForm)
    {
      SnackBarExtension.open(this.snackBar, message, MessageKind.Warning);
      return;
    }

    this.requete["global.libelle"] = <IDictionaryDataItem> { 
      "add.value": 
      [
        <IDataKeyValue> { key: "fr", content: this.requete.libelle },
        <IDataKeyValue> { key: "en", content: this.requete.libelle }
      ]
    };

    this.isSaving = true;
    this.settingsService.addRequete(this.requete).subscribe({
      next: result => {
        if(result.status=== ResourceStatus.Created || result.status=== ResourceStatus.Updated)
        {
          SnackBarExtension.open(this.snackBar, $localize `Création effectuée`, MessageKind.Success);
        }

        this.isSaving = false;
        this.requete.id = result.key;
        this.refreshItems();
      },
      error: _ =>
      {
        this.isSaving = false;

        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors de la création`, MessageKind.Exception);
      }
    });
  }

  keyEnter() {
    if (this.requete.id && !this.isSaved) {
      this.saveSelecteItem();
     }
     else if (this.requete.libelle) {
      this.addSelecteItem();
     }
  }
}
