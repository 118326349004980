export enum EnvironmentType {

    // Development.
    DEV = "DEV",

    // Staging.
    STAGING = "STAGING",

    // Production.
    PROD = "PROD"
}
