export enum RequeteViewModes {
    /// <summary>
    /// Standard.
    /// </summary>
    Standard = "Standard",

    /// <summary>
    /// Expert.
    /// </summary>
    Expert = "Expert"
}
