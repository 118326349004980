<div class="screen-center on-top" *ngIf="isSearching || isDeleting">
  <mat-spinner></mat-spinner>
</div>

<div class="row pl-2 pr-2" *ngIf="searchable && requestForm">
  <mat-form-field class="col-lg-4 col-md-5 col-sm-4 col-3 screen-md">
    <mat-label i18n>Mot clé</mat-label>
    <input i18n-placeholder matInput [(ngModel)]="requestForm.motcle" (keyup.enter)="refreshEvenements()" />
    <button mat-button matSuffix mat-icon-button *ngIf="requestForm.motcle" (click)="$event.stopPropagation(); requestForm.motcle=null; refreshEvenements();">
      <mat-icon>clear</mat-icon>
    </button>
    <button mat-button matSuffix mat-icon-button (click)="refreshEvenements()">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>

  <div class="spacer-item"></div>

  <mat-paginator *ngIf="showPaginator" [pageIndex]="requestForm.pageIndex" [length]="evenementsTotalCount" [(pageSize)]="requestForm.pageSize" [pageSizeOptions]="pageSizeOptions" (page)="changePage()"></mat-paginator>
</div>

<div class="d-flex" *ngIf="!searchable || !requestForm">
  <mat-paginator *ngIf="showPaginator" [pageIndex]="requestForm.pageIndex" [length]="evenementsTotalCount" [(pageSize)]="requestForm.pageSize" [pageSizeOptions]="pageSizeOptions" (page)="changePage()"></mat-paginator>
</div>

<div class="data-table h-100 zoomed" [ngClass]="showPaginator ? '' : 'pt-3'">
  <table mat-table [dataSource]="dataSource" matSort>

      <!-- margin Column -->
      <ng-container matColumnDef="margin">
        <th mat-header-cell *matHeaderCellDef style="width: 1px;"></th>
        <td mat-cell *matCellDef="let row">
        </td>
      </ng-container>

      <!--delaiType Column -->
      <ng-container matColumnDef="delaiType">
      <th i18n mat-header-cell *matHeaderCellDef mat-sort-header>Echéance</th>
      <td mat-cell *matCellDef="let row">
        {{row.delaiType?.libelle}}
      </td>
    </ng-container>

    <!--client Column -->
    <ng-container matColumnDef="client">
      <th i18n mat-header-cell *matHeaderCellDef mat-sort-header>Client</th>
      <td mat-cell *matCellDef="let row">
        <button i18n-matTooltip mat-icon-button class="secondary-text col-2 mr-1" *ngIf="row.client"
          matTooltip="Voir le client"
          (click)="viewClient(row.client); $event.stopPropagation();">
          <mat-icon>domain</mat-icon>
        </button>
        {{row.client?.denomination ? row.client?.denomination : '-'}}
      </td>
    </ng-container>

    <!--dossier Column -->
    <ng-container matColumnDef="dossier">
      <th i18n mat-header-cell *matHeaderCellDef mat-sort-header>Dossier, Contentieux</th>
      <td mat-cell *matCellDef="let row">
        <button i18n-matTooltip mat-icon-button class="secondary-text col-2 mr-1" *ngIf="row.dossier"
          matTooltip="Voir le dossier"
          (click)="viewDossier(row.dossier); $event.stopPropagation();">
          <mat-icon>layers</mat-icon>
        </button>

        <button i18n-matTooltip mat-icon-button class="secondary-text col-2 mr-1" *ngIf="row.contentieux"
          matTooltip="Voir le contentieux"
          (click)="viewContentieux(row.contentieux); $event.stopPropagation();">
          <mat-icon>library_books</mat-icon>
        </button>
        <span *ngIf="row.dossier">{{row.dossier?.reference ? row.dossier?.reference : '-'}} | {{row.dossier?.denomination ? row.dossier?.denomination : '-'}}</span>
        <span *ngIf="row.contentieux">{{row.contentieux?.reference ? row.contentieux?.reference : '-'}} | {{row.contentieux?.denomination ? row.contentieux?.denomination : '-'}}</span>
      </td>
    </ng-container>

      <!--date Column -->
      <ng-container matColumnDef="date">
      <th i18n mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let row">{{row.date | date: 'shortDate'}}</td>
    </ng-container>
    
    <!--type Column -->
    <ng-container matColumnDef="type">
      <th i18n mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let row">
        
        <span *ngIf="row.estTraite && (row.type === 'Interne')" class="text-dark">{{evenementTypes["Interne"]}}</span>
        <span *ngIf="!row.estTraite && (row.type === 'Interne')" class="text-success">{{evenementTypes["Interne"]}}</span>
        <span *ngIf="row.estTraite && (row.type === EvenementTypes.Officiel || row.type === 'OfficielDeclare')" class="text-dark">{{evenementTypes["Officiel"]}}</span>
        <span *ngIf="!row.estTraite && (row.type === EvenementTypes.Officiel || row.type === 'OfficielDeclare')" class="text-danger">{{evenementTypes["Officiel"]}}</span>
      </td>
    </ng-container>

    <!-- Star Column -->
    <ng-container matColumnDef="star" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <div class="btn-group float-right">

          <button i18n-matTooltip mat-flat-button matTooltip="Supprimer cet évènement'"
            *ngIf="!readonly && row.type!=EvenementTypes.Officiel" class="secondary-text"
            (click)="$event.stopPropagation(); deleteEvenement(row)">
            <mat-icon>delete_outline</mat-icon>
          </button>

          <button i18n-matTooltip mat-flat-button class="primary-text ml-1 screen-md"
              matTooltip="Voir la fiche"
              (click)="$event.stopPropagation(); viewEvenement(row);">
            <mat-icon>pageview</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="viewEvenement(row);" ></tr>
  </table>
  
  <div class="mt-4" *ngIf="evenementsTotalCount=== 0">
    <span i18n class="pl-2">Aucun élément trouvé</span>
  </div>
</div>
