import { AfterViewInit, Component, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IEvenementDto } from '@models/dtos/runtime/agenda/IEvenementDto';
import { EvenementObjetTypes } from '@models/enums/domain/EvenementObjetTypes';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { ReferentielService } from '@services/business/referentiel.service';
import { IDelaiTypeDto } from '@models/dtos/management/referentiel/IDelaiTypeDto';
import { DateAdapter } from '@angular/material/core';
import { map, startWith } from 'rxjs/operators';
import { DossierNatures } from '@models/enums/domain/DossierNatures';
import { IRequeteRequestFormPoco } from '@models/pocos/forms/IRequeteRequestFormPoco';
import { IClientDto } from '@models/dtos/runtime/contacts/IClientDto';
import { FlagType } from '@models/enums/domain/FlagType';
import { EvenementTypes } from '@models/enums/domain/EvenementTypes';
import { IFlagsRequestFormPoco } from '@models/pocos/forms/IFlagsRequestFormPoco';
import { MessageKind } from '@models/enums/MessageKind';
import { ActionKind } from '@models/enums/ActionKind';
import { AgendaService } from '@services/business/agenda.service';
import { StringHelper } from '@services/helpers/string-helper';
import { EntityType } from '@models/enums/domain/EntityType';
import { KeyCodes } from '@models/enums/KeyCodes';
import { PageHistoryService } from '@services/nav/page-history.service';
import { IContentieuxDto } from '@models/dtos/runtime/dossiers/IContentieuxDto';
import { IMarqueDossierDto } from '@models/dtos/runtime/dossiers/IMarqueDossierDto';
import { IModeleDossierDto } from '@models/dtos/runtime/dossiers/IModeleDossierDto';
import { UtilsHelper } from '@services/helpers/utils-helper';
import { IReferenceRequestFormPoco } from '@models/pocos/forms/IReferenceRequestFormPoco';

@Component({
  selector: 'evenement-view-dialog',
  templateUrl: './evenement-view-dialog.component.html'
})
export class EvenementViewDialogComponent implements OnInit, AfterViewInit {

  EntityTypes = EntityType;
  EvenementTypes = EvenementTypes;
  EvenementObjetTypes = EvenementObjetTypes;
  DossierNatures = DossierNatures;
  ActionKinds = ActionKind;
  
  evenementTypes: any = {};
  objetTypes: any = {};

  viewInitialized: boolean = false;
  
  clientId: string;
  dossierId: string;
  contentieuxId: string;

  hasChanged: boolean = false;

  delaiTypes: IDelaiTypeDto[];
  Control_DelaiTypes = new UntypedFormControl();
  filteredDelaiTypes: any;

  @ViewChild('evenementForm') evenementForm: NgForm;
  @ViewChild('inputDescription') inputDescription: MatInput;

  @Input('item')
  public evenement: IEvenementDto;

  public originalEvenement: IEvenementDto;

  @Input('viewMode')
  public viewMode: ActionKind = ActionKind.Edit;

  public readonly: boolean = true;

  constructor(
    private pageHistoryService: PageHistoryService,
    private snackBar: MatSnackBar,
    private agendaService: AgendaService,
    private referentielService: ReferentielService,
    private dialogRef: MatDialogRef<EvenementViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dateAdapter: DateAdapter<Date>,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.dialogRef.disableClose = true;

    if (data)
    {
      this.viewMode = data.viewMode;
      this.readonly = this.viewMode === ActionKind.View;
      this.originalEvenement = data.evenement;      
      this.clientId = data.clientId;
      this.dossierId = data.dossierId;
      this.contentieuxId = data.contentieuxId;
    }

    this.resetEvenement();

    this.dateAdapter.setLocale(this.locale);
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });

    this.refreshFlags();
    this.refreshDelaiTypes(true);
  }
  
  ngAfterViewInit() {

    setTimeout(() => {
      this.inputDescription?.focus();
      this.viewInitialized = true;
    });
  }

  async refreshFlags() {

    // EvenementTypes

    this.referentielService.listFlags(<IFlagsRequestFormPoco> { flagType: FlagType.EvenementTypes }).subscribe(result => {

      this.evenementTypes = {};
      result.filter(q=> 
          (q.key===EvenementTypes.Officiel && this.evenement.type===EvenementTypes.Officiel)
          ||(q.key!=EvenementTypes.Undefined && q.key!=EvenementTypes.Officiel_OfficielDeclare && q.key!=EvenementTypes.Officiel) 
      ).forEach(q=>
        {
        this.evenementTypes[q.key]=q.content;        
      });
    }, error => {
      SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
    });

    // ObjetTypes

    this.referentielService.listFlags(<IFlagsRequestFormPoco> { flagType: FlagType.EvenementObjetTypes }).subscribe(result => {

      this.objetTypes = {};
      result.filter(q=> q.key!=EvenementObjetTypes.Dossier && q.key!=EvenementTypes.Undefined).forEach(q=>
        {
        this.objetTypes[q.key]=q.content;        
      });
    }, error => {
      SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
    });
  }

  resetEvenement() {
    this.evenement = UtilsHelper.clone<IEvenementDto>(this.originalEvenement);
    this.hasChanged=false;
  }

  async refreshDelaiTypes(isUpdateAutoComplete: boolean = false) {

    let requestForm = <IReferenceRequestFormPoco> { 
      evenementType: this.evenement?.type,
      objetType: this.evenement?.objetType
    };

    this.referentielService.listDelaiTypes(requestForm).subscribe(
    {
      next: result => {

        this.delaiTypes = result;
        this.updateDelaiTypeFilter();

        if (isUpdateAutoComplete)
        {
          this.updateAutoCompleteComponent_DelaiType();
        }
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
      }
    });  
  }

  updateDelaiTypeFilter() {
    this.filteredDelaiTypes = this.Control_DelaiTypes?.valueChanges.pipe(
      startWith(null),
      map(item => item && typeof item === 'object' ? null : item),
      map(item => {
        const filterValue = StringHelper.unaccent(item ?? '')?.toLowerCase();
        return this.delaiTypes?.filter(option => 
          {
            return (option ? StringHelper.unaccent(option?.libelle) : '')?.toLowerCase()?.indexOf(filterValue) > -1
          }) ?? [];
      }
    ));
  }

  getDelaiTypeText(delaitype: IDelaiTypeDto) {
    if (!delaitype)
    {
      return '';
    }

    return delaitype?.libelle;
  }

  setDelaiType = async (delaitype: IDelaiTypeDto) => {
    if (!this.evenement.delaiType)
    {
      this.evenement.delaiType = <IDelaiTypeDto>{};
    }
    this.evenement.delaiType.id=delaitype?.id;
  }

  updateAutoCompleteComponent_DelaiType() {
    let delaiType = this.delaiTypes?.find(q=> q?.id?.toLowerCase() == this.evenement?.delaiType?.id?.toLowerCase());
    this.Control_DelaiTypes.setValue(delaiType);
  }

  viewClient(client: IClientDto)
  {
    this.pageHistoryService.navigateByUrl("/clients/" + client.id);
    this.dialogRef.close();
  }

  viewMarqueDossier(marqueDossier: IMarqueDossierDto)
  {
    this.pageHistoryService.navigateByUrl("/folders/trademarks/" + marqueDossier.id);
    this.dialogRef.close();
  }

  viewModeleDossier(modeleDossier: IModeleDossierDto)
  {
    this.pageHistoryService.navigateByUrl("/folders/designs/" + modeleDossier.id);
    this.dialogRef.close();
  }

  viewContentieux(contentieux: IContentieuxDto)
  {
    this.pageHistoryService.navigateByUrl("/folders/litigations/" + contentieux.id);
    this.dialogRef.close();
  }

  setClient(client: IClientDto)
  {
    this.evenement.client=client;
    this.evenement.objetType = EvenementObjetTypes.Marque;
    this.evenement.dossier=null;
    this.evenement.contentieux=null;
  }
 
  get checkForm(): string | null
  {
    let result = this.agendaService.checkEvenement(this.evenement);

    return result?.message;
  }

  save()
  {
    let message: string;
    if (message = this.checkForm)
    {
      SnackBarExtension.open(this.snackBar, message, MessageKind.Warning);
      return;
    }

    this.dialogRef.close(this.evenement);
  }
 }
