<!-- Logo -->
<div class="logo" [routerLink]='["/"]' alt="Fidal Markmanager">
</div>

<!-- Top bar -->
<div [class]="environment!=EnvironmentTypes.PROD ? 'top-bar staging' : 'top-bar'">
    <div class="app-title">
        Mark Manager
        <i *ngIf="environment!=EnvironmentTypes.PROD"><small>&nbsp;&nbsp;&nbsp;/&nbsp;{{environment?.toUpperCase()}}</small></i>
    </div>

    <div class="menu screen-md screen-lg">
        <button mat-button [matMenuTriggerFor]="accountMenu"><mat-icon>account_circle</mat-icon> {{login}}</button>
        <mat-menu #accountMenu="matMenu" yPosition="below">
            <button i18n mat-menu-item (click)="redirectToMyAccountUri()">Mon compte</button>
            <mat-divider aria-orientation="horizontal"></mat-divider>
            <button i18n mat-menu-item (click)="Logout()">Déconnecter</button>
        </mat-menu>

        <button mat-button [matMenuTriggerFor]="langMenu">{{locale?.toUpperCase()}}</button>
        <mat-menu #langMenu="matMenu" yPosition="below">
            <button mat-menu-item (click)="changeLanguage(lang.key)" *ngFor="let lang of getAvailableLangs">{{lang.content}}</button>
        </mat-menu>
    </div>
    <div class="menu screen-sm">
        <button mat-button [matMenuTriggerFor]="appMenu"><mat-icon>menu</mat-icon></button>
        <mat-menu #appMenu="matMenu" yPosition="below">
            <button i18n mat-menu-item (click)="redirectToMyAccountUri()">Mon compte</button>
            <mat-divider aria-orientation="horizontal"></mat-divider>
            <button mat-menu-item (click)="changeLanguage(lang.key)" *ngFor="let lang of getAvailableLangs">{{lang.content}}</button>
            <mat-divider aria-orientation="horizontal"></mat-divider>
            <button i18n mat-menu-item (click)="Logout()">Déconnecter</button>
        </mat-menu>
    </div>
</div>

<!-- Menu bar -->
<div class="menu-bar">
    <mat-toolbar class="toolbar">  
        <!-- Standard menu -->
        <div class="screen-md">
            <button i18n class="mr-2" mat-button [routerLink]='["/agenda"]' [routerLinkActive]='["highlighted"]' *requiresUserPolicies="[AppPolicies.AgendaReaders]">AGENDA</button>

            <button i18n class="mr-2" mat-button [routerLink]='["/contacts"]' [routerLinkActive]='["highlighted"]' *requiresUserPolicies="[AppPolicies.ContactsReaders]">CONTACTS</button>

            <button i18n class="mr-2" mat-button [routerLink]='["/clients"]' [routerLinkActive]='["highlighted"]' *requiresUserPolicies="[AppPolicies.ContactsReaders]">CLIENTS</button>

            <button i18n class="mr-2" mat-button [routerLink]='["/folders"]' [routerLinkActive]='["highlighted"]' *requiresUserPolicies="[AppPolicies.DossiersReaders]">DOSSIERS</button>

            <button i18n class="mr-2" mat-button [routerLink]='["/queries"]' [routerLinkActive]='["highlighted"]' *requiresUserPolicies="[AppPolicies.RequetesReaders]">REQUETES</button>
        </div>
        <div class="screen-sm">
            <button mat-button [routerLink]='["/agenda"]' [routerLinkActive]='["highlighted"]' matTooltip="Agenda" *requiresUserPolicies="[AppPolicies.AgendaReaders]"><mat-icon>calendar_today</mat-icon></button>

            <button mat-button [routerLink]='["/contacts"]' [routerLinkActive]='["highlighted"]' matTooltip="Contacts" *requiresUserPolicies="[AppPolicies.ContactsReaders]"><mat-icon>portrait</mat-icon></button>

            <button mat-button [routerLink]='["/clients"]' [routerLinkActive]='["highlighted"]' matTooltip="Clients" *requiresUserPolicies="[AppPolicies.ContactsReaders]"><mat-icon>domain</mat-icon></button>

            <button mat-button [routerLink]='["/folders"]' [routerLinkActive]='["highlighted"]' matTooltip="Dossiers" *requiresUserPolicies="[AppPolicies.DossiersReaders]"><mat-icon>layers</mat-icon></button>

            <button mat-button [routerLink]='["/queries"]' [routerLinkActive]='["highlighted"]' matTooltip="Requêtes" *requiresUserPolicies="[AppPolicies.RequetesReaders]"><mat-icon>view_list</mat-icon></button>
        </div>

        <div class="spacer-item"></div>

        <!-- Adminsitration menu -->
        <button mat-button [matMenuTriggerFor]="adminMenu" [class]="isInParameters() ? 'highlighted' : ''" aria-label="Administration">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #adminMenu="matMenu" yPosition="below">
            <button i18n mat-menu-item [routerLink]='["/parameters"]'>Paramètres</button>
            <mat-divider aria-orientation="horizontal" *requiresUserRoles="[AppRoles.Manager, AppRoles.Admin, AppRoles.Super]"></mat-divider>
            <button *ngIf="supportUri" mat-menu-item (click)="redirectToMySupportUri()">Assistance</button>
            <mat-divider *ngIf="supportUri" aria-orientation="horizontal"></mat-divider>
            <button i18n mat-menu-item (click)="openAbout()">A propos...</button>
        </mat-menu>
    
    </mat-toolbar>    
</div>

