import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { KeyCodes } from '@models/enums/KeyCodes';

@Component({
  selector: 'text-dialog.component',
  templateUrl: './text-dialog.component.html'
})
export class TextDialogComponent implements OnInit {

  public title: string;
  public text: string;

  result: boolean = true;

  constructor(
    private dialogRef: MatDialogRef<TextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) 
    { 
      this.dialogRef.disableClose = true;

      this.title = this.data?.title;
      this.text = this.data?.text;
    }
  
  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });
  }    
}