<h2 mat-dialog-title>{{title}}</h2>

<div class="dialog-text-small">
  <div class="row" style="height: 40px;" (keyup.enter)="keyEnter();">
    <mat-form-field class="col-9" appearance="outline">
      <mat-label i18n>Libellé</mat-label>
      <input name="libelle" #inputLibelle="matInput" matInput [(ngModel)]="requete.libelle" (change)="isSaved=false;" />
      <button mat-button matSuffix mat-icon-button *ngIf="requete.libelle" [disabled]="isLoading"
        (click)="$event.stopPropagation(); requete.libelle=null;">
        <mat-icon>clear</mat-icon>
      </button>
    </mat-form-field>  
    <button i18n class="primary mt-2 mr-2" (click)="saveSelecteItem();" [disabled]="!requete.id || isSaved" *ngIf="existeRequete(requete);"
      i18n-matTooltip matTooltip="Enregistrer ma requête"><mat-icon>save</mat-icon></button>
    <button i18n [class]="!requete.id || isSaved ? 'primary mt-2 mr-2' : 'secondary mt-2 mr-2'" (click)="addSelecteItem();" [disabled]="!requete.libelle"
      i18n-matTooltip matTooltip="Ajouter une requête"><mat-icon>add</mat-icon></button>
  </div>
  
  <div class="data-table">
    <div class="screen-center on-top" *ngIf="isLoading || isSaving">
      <mat-spinner></mat-spinner>
    </div>
    
    <div class="d-flex">
      <mat-paginator [length]="items?.length" [pageSize]="5" [pageSizeOptions]="[5]"></mat-paginator>
    </div>
    <table mat-table [dataSource]="dataSource" matSort *ngIf="items">
  
      <!--libelle Column -->
      <ng-container matColumnDef="libelle">
        <td mat-cell *matCellDef="let row">
          <span *ngIf="requete?.id!=row?.id">{{row.libelle}}</span>
          <strong *ngIf="requete?.id===row?.id">{{row.libelle}}</strong>
        </td>
      </ng-container>
      
      <!-- Star Column -->
      <ng-container matColumnDef="star" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <div class="btn-group float-right">
            <button i18n-matTooltip class="primary ml-2" matTooltip="Voir le résultat de cette requête" (click)="select(row);">
              <mat-icon>pageview</mat-icon>
            </button>  
          </div>
        </td>
      </ng-container>
  
      <tr mat-row class="pointable" *matRowDef="let row; columns: displayedColumns;"
        (click)="$event.stopPropagation(); select(row, false);"></tr>
    </table>
  
  </div>
</div>
  
<mat-dialog-actions align="end" class="pt-3">
  <button i18n mat-raised-button class="secondary" (click)="viewMesRequetes();"
    i18n-matTooltip matTooltip="Gérer mes requêtes">GERER</button>

  <div class="spacer-item"></div>

  <button i18n mat-flat-button class="secondary-text ml-5 mr-2" mat-dialog-close>FERMER</button>
</mat-dialog-actions>
  
