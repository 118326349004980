import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MessageKind } from '@models/enums/MessageKind';
import { ResourceStatus } from '@models/enums/ResourceStatus';
import { IEvenementDto } from '@models/dtos/runtime/agenda/IEvenementDto';
import { AgendaService } from '@services/business/agenda.service';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { ConfirmationDialogComponent } from '@components/shared/dialogs/confirmation-dialog.component';
import { EvenementViewDialogComponent } from './evenement-view-dialog.component';
import { IEvenementsRequestFormPoco } from '@models/pocos/forms/IEvenementsRequestFormPoco';
import { MatPaginator } from '@angular/material/paginator';
import { IDossierDto } from '@models/dtos/runtime/dossiers/IDossierDto';
import { IContentieuxDto } from '@models/dtos/runtime/dossiers/IContentieuxDto';
import { DossierNatures } from '@models/enums/domain/DossierNatures';
import { FlagType } from '@models/enums/domain/FlagType';
import { ReferentielService } from '@services/business/referentiel.service';
import { IFlagsRequestFormPoco } from '@models/pocos/forms/IFlagsRequestFormPoco';
import { PageHistoryService } from '@services/nav/page-history.service';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { ActionKind } from '@models/enums/ActionKind';
import { IClientDto } from '@models/dtos/runtime/contacts/IClientDto';
import { UtilsHelper } from '@services/helpers/utils-helper';
import { QueryResultModes } from '@models/enums/QueryResultModes';
import { EvenementTypes } from '@models/enums/domain/EvenementTypes';
  
@Component({
  selector: 'evenements-liveview',
  templateUrl: './evenements-liveview.component.html'
})
export class EvenementsLiveViewComponent implements OnInit, OnChanges {
  
  EvenementTypes = EvenementTypes;

  pageSizeOptions: number[] = GlobalConstants.pageSizeOptions_List;

  evenementTypes: any = {};

  @Output("isSearching")
  public isSearchingChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  isSearching: boolean = false;
  isDeleting: boolean = false;

  displayedColumns: string[] = ['margin', 'delaiType', 'client', 'dossier', 'date', 'type', 'star'];
  dataSource: MatTableDataSource<IEvenementDto>;

  public evenements: IEvenementDto[];
  evenementsTotalCount: number;

  selection = new SelectionModel<IEvenementDto>(true, []);

  showPaginator: boolean = true;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  @Input('searchable')
  public searchable: boolean = true;
  
  @Input('requestForm')
  requestForm: IEvenementsRequestFormPoco;    

  @Output()
  public listChange: EventEmitter<IEvenementDto[]> = new EventEmitter<IEvenementDto[]>();

  @Input('excluding')
  public excludingEvenements: IEvenementDto[] = [];

  @Input('readonly')
  public readonly: boolean = false;

  @Input('editionReadonly')
  public editionReadonly: boolean = false;

  @Input('required')
  public required: boolean = false;

  @Output("valid")
  public validChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output("itemsCount")
  public itemsCount: EventEmitter<number> = new EventEmitter<number>();

  @Input('itemMaxCount')
  public itemMaxCount: number = -1;
  
  get valid(): boolean | null
  {
    let b = !this.required || (this.evenements && this.evenements.length>0);
    return b;
  }

  constructor(
    private pageHistoryService: PageHistoryService,
    private agendaService: AgendaService = null,
    private referentielService: ReferentielService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(){
    this.refreshFlags();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.requestForm)
    {
      this.requestForm.pageIndex ??=0;
      this.requestForm.pageSize ??=10;  
    }

    if (changes.hasOwnProperty("requestForm")) {

      this.refreshEvenements();
    }
  }

  changePage() {
    this.requestForm.pageSize = this.paginator?.pageSize;
    this.requestForm.pageIndex = this.paginator?.pageIndex;

    this.refreshEvenements();
  }

  searching(isSearching: boolean) {
    this.isSearching = isSearching;
    this.isSearchingChanged.emit(isSearching);
  }
  
  async refreshEvenements() {

    if (this.isSearching) return;

    this.searching(true);
    this.agendaService.listEvenements(this.requestForm).subscribe({
      next: result => {
        this.evenements = result.items;
        this.dataSource = new MatTableDataSource<IEvenementDto>(this.evenements);
        UtilsHelper.updateDataPageInfo(this.requestForm, result);
        this.evenementsTotalCount = result?.totalCount;
        if (this.itemMaxCount > -1 && this.evenementsTotalCount > this.itemMaxCount)
        {
          this.evenementsTotalCount = this.itemMaxCount;
        }
        this.itemsCount.emit(this.evenementsTotalCount);

        this.showPaginator = this.evenementsTotalCount > this.requestForm.pageSize;
        this.searching(false);
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
        this.searching(false);
      }      
    });
  }

  async refreshFlags() {

    // EvenementTypes

    this.referentielService.listFlags(<IFlagsRequestFormPoco> { flagType: FlagType.EvenementTypes }).subscribe({
      next: result => {
        this.evenementTypes = {};
        result.forEach(q=> { this.evenementTypes[q.key]=q.content; });
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
      }
    });
  }

  async viewClient(client: IClientDto)
  {
    if (client)
    {
      this.pageHistoryService.navigate(['clients', client.id]);
    }
  }

  async viewDossier(dossier: IDossierDto)
  {
    if (dossier)
    {
      switch (dossier.nature)
      {
        case DossierNatures.Marque:
          this.pageHistoryService.navigate(['folders/trademarks', dossier.id]);
          break;
        case DossierNatures.Modele:
          this.pageHistoryService.navigate(['folders/designs', dossier.id]);
          break;
      }
    }
  }

  async viewContentieux(contentieux: IContentieuxDto)
  {
    if (contentieux)
    {
      this.pageHistoryService.navigate(['folders/litigations', contentieux.id]);
    }
  }

  async viewEvenement(evenement: IEvenementDto)
  {
    if (evenement)
    {
      this.searching(true);
      this.agendaService.getEvenement(evenement?.id, QueryResultModes.Full).subscribe({
        next: result => {
          let evenement = result;
          this.searching(false);

          const dialogRef = this.dialog.open(
          EvenementViewDialogComponent,
          {
            panelClass: ['w-50'],
            data: {
              evenement: evenement,
              viewMode: this.editionReadonly ? ActionKind.View : ActionKind.Edit,
            },
            autoFocus: false
          });

          dialogRef.afterClosed().subscribe(result => {
            if(result)
            {
              this.searching(true);

              this.agendaService.updateEvenement(result).subscribe({
                next: result => {
                  if(result.status=== ResourceStatus.Updated)
                  {
                    SnackBarExtension.open(this.snackBar, $localize `Mise à jour effectuée`, MessageKind.Success);
                  }
            
                  this.searching(false);
                  this.refreshEvenements();
                },
                error: err =>
                {
                  if (err.status=="400")
                  {
                    if(err.error.find(q => q.status==="404") != null)
                    {
                      SnackBarExtension.open(this.snackBar, $localize `Elément non trouvé ou droits insuffisants`, MessageKind.Error);
                      return;
                    }
                  }
            
                  SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors de la mise à jour`, MessageKind.Exception);
                  this.searching(false);
                }
              });  
            }
          });
        },
        error: _ => {
          SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
          this.searching(false);
        }
      });
    }
  }

  // delete ---------------

  async deleteEvenement(evenement: IEvenementDto) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: $localize `Voulez-vous vraiment supprimer cet élément ?`
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.isDeleting = true;
        this.agendaService.deleteEvenement(evenement?.id).subscribe({
          next: result => {
            SnackBarExtension.open(this.snackBar, $localize `Suppression effectuée`, MessageKind.Success);
            this.refreshEvenements();
            this.isDeleting = false;
          },
          error: _ => {
            SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors de la suppression`, MessageKind.Exception);
            this.isDeleting = false;
          }
        });
      }
    });
  }

  async deleteEvenements() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: $localize `Voulez-vous vraiment supprimer ces éléments ?`
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        let ids = this.selection.selected.map(q=>q.id);
        
        this.agendaService.deleteEvenements(ids).subscribe(result => {
          SnackBarExtension.open(this.snackBar, $localize `Les éléments ont été supprimés`);
          this.refreshEvenements();
        }, error => {
          SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors de la suppression`, MessageKind.Exception);
        });
      }
    });
  }
}