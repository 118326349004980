import {  Component, Inject, Input, LOCALE_ID, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { DateAdapter } from '@angular/material/core';
import { IContactDto } from '@models/dtos/runtime/contacts/IContactDto';
import { ContactsService } from '@services/business/contacts.service';
import { PageHistoryService } from '@services/nav/page-history.service';
import { BreadcrumbHelper } from '@services/helpers/breadcrumb-helper';
import { IClientDto } from '@models/dtos/runtime/contacts/IClientDto';
import { MessageKind } from '@models/enums/MessageKind';
import { QueryResultModes } from '@models/enums/QueryResultModes';
import { EntityType } from '@models/enums/domain/EntityType';
import { FlagType } from '@models/enums/domain/FlagType';
import { ReferenceType } from '@models/enums/domain/ReferenceType';

@Component({
  selector: 'contact-snap-view',
  templateUrl: './contact-snap-view.component.html'
})
export class ContactSnapViewComponent implements OnInit, OnChanges {

  EntityTypes = EntityType;
  FlagTypes = FlagType;
  ReferenceTypes = ReferenceType;

  isLoading: boolean = false;

  @ViewChild('contactForm') contactForm: NgForm;

  @Input('itemId')
  public contactId: string;

  public contact: IContactDto;

  constructor(
    private contactsService: ContactsService,
    private pageHistoryService: PageHistoryService,
    private snackBar: MatSnackBar,
    private dateAdapter: DateAdapter<Date>,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.dateAdapter.setLocale(this.locale);
  }
  
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.hasOwnProperty("contactId")) {

      this.refreshContact();
    }
  }
  
  async refreshContact() {

    if (!this.contactId)
    {
      this.contact = null;
    }
    else
    {
      this.isLoading = true;
      
      this.contactsService.getContact(this.contactId, QueryResultModes.Full).subscribe(result => {

        // if there is no result then we redirect to list page
        if (!result)
        {
          let uri = this.pageHistoryService.popLast('/contacts');
          this.pageHistoryService.navigateByUrl(uri);
        }

        this.contact = result;
        this.contactsService.repairContact(this.contact);
        BreadcrumbHelper.setBreadcrumbSubjectItem(this.contact?.denomination);
    
        this.isLoading = false;
      }, error => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
        this.isLoading = false;
        this.pageHistoryService.navigate(['/contacts']);
      });
    }
  }

  async viewContact(id: string)
  {
    this.pageHistoryService.navigate(['contacts', id]);
  }
    
  async viewClient(client: IClientDto)
  {
    this.pageHistoryService.navigate(['clients', client?.id]);
  }
}
