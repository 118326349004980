export enum RequeteFiltreTypes {
    // Undefined.
    Undefined = "Undefined",

    Clause = "Clause",

    Condition = "Condition",

    // Any.
    Any = "Any"
}
