export enum AppPolicy {

        // Utilisateurs.
        Utilisateurs = "Utilisateurs",

        // Agenda ----------

        // Readers d'agenda.
        AgendaReaders = "AgendaReaders",

        // Writers d'agenda.
        AgendaWriters = "AgendaWriters",

        // Writers complets d'agenda.
        AgendaFullWriters = "AgendaFullWriters",

        // Contacts ----------

        // Readers des contacts.
        ContactsReaders = "ContactsReaders",

        // Writers des contacts.
        ContactsWriters = "ContactsWriters",

        // Writers complets des contacts.
        ContactsFullWriters = "ContactsFullWriters",

        // Dossiers ----------

        // Readers de dossiers.
        DossiersReaders = "DossiersReaders",

        // Readers complets de dossiers.
        DossiersFullReaders = "DossiersFullReaders",

        // Writers de dossiers.
        DossiersWriters = "DossiersWriters",

        // Writers complets de dossiers.
        DossiersFullWriters = "DossiersFullWriters",

        // Contentieux ----------

        // Readers de contentieux.
        ContentieuxReaders = "ContentieuxReaders",

        // Writers de contentieux.
        ContentieuxWriters = "ContentieuxWriters",

        // Writers complets de contentieux.
        ContentieuxFullWriters = "ContentieuxFullWriters",

        // Referentiel ----------

        // Readers de référentiel.
        ReferentielReaders = "ReferentielReaders",

        // Writers de référentiel.
        ReferentielWriters = "ReferentielWriters",

        // Writers complets de référentiel.
        ReferentielFullWriters = "ReferentielFullWriters",

        // Requetes ----------

        RequetesReaders = "RequetesReaders",

        // Logs ----------

        // Readers de journaux.
        LogsReaders = "LogsReaders",

        // Writers de journaux.
        LogsWriters = "LogsWriters",

        // Writers complets de journaux.
        LogsFullWriters = "LogsFullWriters",

        // Securite ----------

        // Readers de sécurité.
        SecuriteReaders = "SecuriteReaders",

        // Readers complets des utilisateurs.
        SecuriteFullReaders = "SecuriteFullReaders",

        // Writers de sécurité.
        SecuriteWriters = "SecuriteWriters",

        // Writers complets des utilisateurs administrateurs.
        SecuriteFullWriters = "SecuriteFullWriters"
}
