export enum ClientsAccesTypes {
    
    // None.
    None = "None",

    // Gestionnaire principal.
    PrincipalGestionnaire = "PrincipalGestionnaire",

    // Gestionnaire assistant.
    AssistantGestionnaire = "AssistantGestionnaire",

    // Accès total.
    TotalAcces = "TotalAcces",

    // Accès restreint.
    RestreintAcces = "RestreintAcces",

    // Gestionnaire.
    Gestionnaire = "Gestionnaire",

    // Herité.
    Herite = "Herite",

    // Any.
    Any = "Any"
}


