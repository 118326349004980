import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IInterlocuteurDto } from '@models/dtos/runtime/contacts/IInterlocuteurDto';
import { InterlocuteurTypes } from '@models/enums/domain/InterlocuteurTypes';
import { PersonneTitres } from '@models/enums/domain/PersonneTitres';
import { ConfirmationDialogComponent } from '@components/shared/dialogs/confirmation-dialog.component';
import { InterlocuteurViewDialogComponent } from './interlocuteur-view-dialog.component';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { UtilsHelper } from '@services/helpers/utils-helper';
import { StringHelper } from '@services/helpers/string-helper';

@Component({
  selector: 'interlocuteurs-listview',
  templateUrl: './interlocuteurs-listview.component.html'
})
export class InterlocuteursListViewComponent implements AfterViewInit, OnChanges {

  @Input('class')
  public class: string;

  @Input('pageSize')
  pageSize: number;

  displayedColumns: string[] = ['prenom', 'nom', 'star'];
  dataSource: MatTableDataSource<IInterlocuteurDto>;

  showPaginator: boolean = true;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  @Input('items')
  public items: IInterlocuteurDto[];

  @Output()
  public itemsChange: EventEmitter<IInterlocuteurDto[]> = new EventEmitter<IInterlocuteurDto[]>();

  @Output()
  public selectionChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Input('excluding')
  public excludingInterlocuteurs: IInterlocuteurDto[] = [];

  @Input('maxCount')
  public maxCount: number = -1;

  @Input('readonly')
  public readonly: boolean = false;

  constructor(
    private dialog: MatDialog
  ) {
    this.pageSize ??= GlobalConstants.pageSizeOptions_Dialog[0];
  }

  ngAfterViewInit() {
    this.updatePaginator();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty("items")) {
      this.refreshItems();
    }
  }

  async refreshItems()
  {
    this.setItems(this.items, false);
    this.dataSource = new MatTableDataSource<IInterlocuteurDto>(this.items);
    this.updatePaginator();
  }

  async updatePaginator()
  {
    this.showPaginator = this.items?.length > this.pageSize;
    if (this.dataSource)
    {
      this.dataSource.paginator = this.paginator;
    }
  }

  async addNewItem()
  {
    if (this.maxCount === -1 || this.items.length<this.maxCount)
    {
      let interlocuteur = <IInterlocuteurDto> { personneTitre: PersonneTitres.Undefined };

      const dialogRef = this.dialog.open(
        InterlocuteurViewDialogComponent,
        {
          panelClass: ['w-50'],
          data: {
            interlocuteur: interlocuteur,
            readonly: this.readonly
          },
          autoFocus: false
        });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result)
        {
          this.items.push(interlocuteur);
          this.setItems();
        }
      });
    }
  }

  async viewItem(interlocuteur: IInterlocuteurDto)
  {
    let cloneInterlocuteur = UtilsHelper.clone<IInterlocuteurDto>(interlocuteur);

    const dialogRef = this.dialog.open(
      InterlocuteurViewDialogComponent,
      {
        panelClass: ['w-50'],
        data: {
          interlocuteur: cloneInterlocuteur,
          readonly: this.readonly
        },
        autoFocus: false
      });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        var index = this.items.indexOf(interlocuteur);

        if (index > -1) {
          this.items[index] = cloneInterlocuteur;
          this.setItems();
        }
      }
    });
  }

  async removeItem(interlocuteur: IInterlocuteurDto)
  {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: $localize `Voulez-vous vraiment retirer cet élément ?`
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {    
        let index = this.items.indexOf(interlocuteur);
        if (index > -1) {
          this.items.splice(index, 1);
        }
        this.setItems();
      }
    });
  }
   
  async switchItemPrincipal(interlocuteur: IInterlocuteurDto)
  {
    // we set the current interlocutor as "Principal" and the others as "Other"
    let oldInterlocuteurType = interlocuteur.interlocuteurType;
    let newInterlocuteurType = interlocuteur.interlocuteurType == InterlocuteurTypes.Principal ? InterlocuteurTypes.Autre : InterlocuteurTypes.Principal;

    interlocuteur.interlocuteurType = newInterlocuteurType; 
    this.items?.filter(q=> q != interlocuteur).forEach(q=> q.interlocuteurType = oldInterlocuteurType);

    this.refreshItems();
  }

  private setItems(interlocuteurs: IInterlocuteurDto[] = null, emit: boolean = true)
  {
    this.items = (interlocuteurs ?? this.items).filter(q=> !(this.excludingInterlocuteurs?.find(p=>p.id === q.id)))
      .sort((a, b)=> (a.nom ?? "")?.toLowerCase().localeCompare((b.nom ?? "")?.toLowerCase()));;

    if (emit)
    {
      this.itemsChange.emit(this.items);
      this.selectionChange.emit(true);
    }
  }
}
