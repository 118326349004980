import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { IClientDto } from '@models/dtos/runtime/contacts/IClientDto';
import { IDossierFamilleDto } from '@models/dtos/runtime/dossiers/IDossierFamilleDto';
import { AppPolicy } from '@models/enums/iam/AppPolicy';
import { KeyCodes } from '@models/enums/KeyCodes';
import { MessageKind } from '@models/enums/MessageKind';
import { QueryResultModes } from '@models/enums/QueryResultModes';
import { IDossierFamillesRequestFormPoco } from '@models/pocos/forms/IDossierFamillesRequestFormPoco';
import { DossiersService } from '@services/business/dossiers.service';
import { SnackBarExtension } from '@services/nav/snackbar-extension';

@Component({
  selector: 'familles-search-dialog',
  templateUrl: './familles-search-dialog.component.html'
})
export class DossierFamillesSearchDialogComponent implements OnInit, AfterViewInit {

  AppPolicies = AppPolicy;

  pageSizeOptions: number[] = GlobalConstants.pageSizeOptions_Dialog;

  @ViewChild('inputMotCle') inputMotCle: MatInput;

  public requestForm: IDossierFamillesRequestFormPoco;
  isLoading: boolean = false;

  items: IDossierFamilleDto[];
  itemsTotalCount: number;

  displayedColumns: string[];
  dataSource: MatTableDataSource<IDossierFamilleDto>;
  selection = new SelectionModel<IDossierFamilleDto>(true, []);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input('class')
  public class: string;
  
  @Input('excluding')
  public excludingItems: IDossierFamilleDto[] = [];

  @Input('clientIds')
  public clientIds: string[];

  @Input('maxCount')
  public maxCount: number = -1;

  @Input('readonly')
  public readonly: boolean = true;

  constructor(
    private dossiersService: DossiersService,
    private dialogRef: MatDialogRef<DossierFamillesSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ) {
    this.dialogRef.disableClose = true;

    if (data)
    {
      this.excludingItems = data.excluding;
      this.maxCount = data.maxCount ?? -1;
      this.clientIds = data.clientIds;
      this.readonly = data.readonly ?? true;
    }
    if (!this.clientIds)
    {
      this.clientIds = [];
    }

    if (this.maxCount===1)
    {
      this.displayedColumns = ['libelle', 'star'];
    }
    else
    {
      this.displayedColumns = ['select', 'libelle', 'star'];
    }
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });

    this.initRequestForm();
    this.refreshItems();
  }
  
  ngAfterViewInit() {
    setTimeout(() => {
      this.inputMotCle.focus();
    });
  }

  async initRequestForm(excludeStorage: boolean = false)
  {
    this.requestForm = <IDossierFamillesRequestFormPoco> { 
      resultMode: QueryResultModes.Quick,
      clients: this.clientIds.map(q=> <IClientDto>{ id: q}), 
      pageIndex: 0, 
      pageSize: 8 
    };
  }

  async refreshItems(initPageIndex: boolean = false) {

    if (initPageIndex)
    {
      this.requestForm.pageIndex = 0;
    }
    this.paginator.pageIndex = this.requestForm.pageIndex;
    this.requestForm.resultMode = QueryResultModes.Quick;

    this.isLoading = true;
    this.dossiersService.listDossierFamilles(this.requestForm).subscribe({
      next: result => {
        this.items = result.sort((a, b)=> a.libelle?.toLowerCase().localeCompare(b.libelle?.toLowerCase()));
        this.dataSource = new MatTableDataSource<IDossierFamilleDto>(this.items);
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
        this.isLoading = false;
      }
    });
  }

  selectItems()
  {
    this.dialogRef.close(this.selection.selected);
  }
   
  selectItem(dossierfamille: IDossierFamilleDto)
  {
    if (dossierfamille)
    {
      this.dialogRef.close([dossierfamille]);
    }
  }

  isExcluded(dossierfamille: IDossierFamilleDto)
  {
    return this.excludingItems.find(q=> dossierfamille?.id === q.id);
  }

  // Multiselection ---------------

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected())
    {
      this.selection.clear();
    }
    else
    {
      this.selection.clear();
      this.dataSource.data.forEach(row => !this.isExcluded(row) && this.selection.select(row));
    }
  }

  checkboxLabel(row?: IDossierFamilleDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  search() {
    if (this.requestForm.motcle?.length>3) { 
      this.refreshItems(true); 
    }
  }

  select() {
    if (this.items?.length===1)
    {
      this.selectItem(this.items[0]);
    }
    else
    {
      this.refreshItems(true);
    }
  }  
}
