import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { IClientDto } from '@models/dtos/runtime/contacts/IClientDto';
import { IDossierDto } from '@models/dtos/runtime/dossiers/IDossierDto';
import { DossierNatures } from '@models/enums/domain/DossierNatures';
import { MessageKind } from '@models/enums/MessageKind';
import { QueryResultModes } from '@models/enums/QueryResultModes';
import { IDossiersRequestFormPoco } from '@models/pocos/forms/IDossiersRequestFormPoco';
import { DossiersService } from '@services/business/dossiers.service';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { UtilsHelper } from '@services/helpers/utils-helper';
import { IMarqueDossiersRequeteRequestFormPoco } from '@models/pocos/forms/IMarqueDossiersRequeteRequestFormPoco';
import { MarqueRegistreTypes } from '@models/enums/domain/MarqueRegistreTypes';
import { KeyCodes } from '@models/enums/KeyCodes';

@Component({
  selector: 'dossiers-search-dialog',
  templateUrl: './dossiers-search-dialog.component.html'
})
export class DossiersSearchDialogComponent implements OnInit, AfterViewInit {

  pageSizeOptions: number[] = GlobalConstants.pageSizeOptions_Dialog;

  @ViewChild('inputMotCle') inputMotCle: MatInput;

  public requestForm: IDossiersRequestFormPoco;
  isLoading: boolean = false;

  dossiers: IDossierDto[];
  dossiersTotalCount: number;

  displayedColumns: string[];
  dataSource: MatTableDataSource<IDossierDto>;
  selection = new SelectionModel<IDossierDto>(true, []);

  marqueRegistreTypes: MarqueRegistreTypes[];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input('clientIds')
  public clientIds: string[];

  @Input('dossierNature')
  public dossierNature: DossierNatures = DossierNatures.Any;

  @Input('excluding')
  public excludingDossiers: IDossierDto[] = [];

  @Input('maxCount')
  public maxCount: number = -1;

  constructor(
    private dossiersService: DossiersService,
    private dialogRef: MatDialogRef<DossiersSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ) {
    this.dialogRef.disableClose = true;

    if (data)
    {
      this.clientIds = data.clientIds;
      this.excludingDossiers = data.excluding;
      this.maxCount = data.maxCount ?? -1;
      this.dossierNature = data.dossierNature;
      this.marqueRegistreTypes = data.marqueRegistreTypes;
    }
    if (!this.clientIds)
    {
      this.clientIds = [];
    }

    if (this.maxCount===1)
    {
      this.displayedColumns = ['reference', 'denomination', 'pays', 'star'];
    }
    else
    {
      this.displayedColumns = ['select', 'reference', 'denomination', 'pays', 'star'];
    }
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });

    this.initRequestForm();
    this.refreshDossiers();
  }
  
  ngAfterViewInit() {
    setTimeout(() => {
      this.inputMotCle.focus();
    });
  }

  async initRequestForm(excludeStorage: boolean = false)
  {
    this.requestForm = <IDossiersRequestFormPoco> { 
      resultMode: QueryResultModes.Quick,
      dossierNature: this.dossierNature,
      clients: this.clientIds.map(q=> <IClientDto>{ id: q}), 
      pageIndex: 0,
      pageSize: 8,
      orderBy: "reference asc"
    };
  }

  changePage() {
    UtilsHelper.updateDataPageInfo(this.requestForm, this.paginator);

    this.refreshDossiers();
  }

  async refreshDossiers(initPageIndex: boolean = false) {

    if (initPageIndex)
    {
      this.requestForm.pageIndex = 0;
    }
    this.paginator.pageIndex = this.requestForm.pageIndex;
    this.requestForm.resultMode = QueryResultModes.Quick;

    this.isLoading = true;
    switch(this.dossierNature)
    {
      case DossierNatures.Marque:
        let requestForm = UtilsHelper.clone<IMarqueDossiersRequeteRequestFormPoco>(this.requestForm);
        requestForm.registreTypes = this.marqueRegistreTypes ?? [MarqueRegistreTypes.National, MarqueRegistreTypes.InternationalEnregistrement, MarqueRegistreTypes.International];
        this.dossiersService.listMarqueDossiers(requestForm).subscribe({
          next: result => {

            this.dossiers = result.items;
            this.dataSource = new MatTableDataSource<IDossierDto>(this.dossiers);
            UtilsHelper.updateDataPageInfo(this.requestForm, result);
            this.dossiersTotalCount = result?.totalCount;
      
            this.isLoading = false;
          },
          error: _ => {
            SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
            this.isLoading = false;
          }
        });        
        break;
      default:
        this.dossiersService.listDossiers(this.requestForm).subscribe({
          next: result => {
            this.dossiers = result.items;
            this.dataSource = new MatTableDataSource<IDossierDto>(this.dossiers);
            UtilsHelper.updateDataPageInfo(this.requestForm, result);
            this.dossiersTotalCount = result?.totalCount;      
            this.isLoading = false;
          },
          error: _ => {
            SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
            this.isLoading = false;
          }
        });        
        break;
    }
  }

  selectDossiers()
  {
    this.dialogRef.close(this.selection.selected);
  }
   
  selectDossier(dossier: IDossierDto)
  {
    if (dossier)
    {
      this.dialogRef.close([dossier]);
    }
  }

  isExcluded(dossier: IDossierDto)
  {
    return this.excludingDossiers.find(q=> dossier?.id === q.id);
  }

  // Multiselection ---------------

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected())
    {
      this.selection.clear();
    }
    else
    {
      this.selection.clear();
      this.dataSource.data.forEach(row => this.selection.select(row));
    }
  }

  checkboxLabel(row?: IDossierDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
    
  search() {
    if (this.requestForm.motcle?.length>3) { 
      this.refreshDossiers(true); 
    }
  }

  select() {
    if (this.dossiers?.length===1)
    {
      this.selectDossier(this.dossiers[0]);
    }
    else
    {
      this.refreshDossiers(true);
    }
  }
}
