<div class="pane-center on-top" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="client" class="snap-pane">

  <div class="row">
    <div class="row col-6 mp-0">      
      <mat-form-field class="col-12 highlighted" appearance="outline">
        <mat-label i18n>Dénomination</mat-label>
        <input name="denomination" #inputDenomination="matInput" matInput
          [(ngModel)]="client.denomination"
          [readonly]="true" />
      </mat-form-field>
    </div>
  
    <div class="row col-6 mp-0">
      <flags-selector i18n-label class="col-6" appearance="outline"
        [type]="FlagTypes.ClientStatuts" [(itemId)]="client.statut"
        [readonly]="true"
        label="Statut"></flags-selector>

      <mat-form-field class="col-6" appearance="outline">
        <mat-label i18n>Identifiant interne</mat-label>
        <input name="interneIdentifiant" matInput
          [(ngModel)]="client.interneIdentifiant"
          [readonly]="true" />
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="row col-6 mp-0">
      <flags-selector i18n-label class="col-6" appearance="outline"
        [type]="FlagTypes.PersonneTypes" [(itemId)]="client.personneType"
        [readonly]="true"
        label="Type de personne"></flags-selector>      

      <flags-selector i18n-label class="col-6" appearance="outline" *ngIf="client.personneType===PersonneTypes.Morale"
        [type]="FlagTypes.ClientTypes" [(itemId)]="client.clientType"
        [readonly]="true"
        label="Type d'entreprise">  </flags-selector>      
    </div>
    <div class="row col-6 mp-0">
      <mat-form-field class="col-6" appearance="outline" *ngIf="client.personneType===PersonneTypes.Morale">
        <mat-label i18n>RCS</mat-label>
        <input name="rcs" matInput
          [(ngModel)]="client.rcs"
          [readonly]="true" />
      </mat-form-field>  
    </div>
  </div> 

  <div class="row pt-1">
    <div class="row col-6 mp-0">      
      <adresse-view class="col-12" #adresseView
        [(adresse)]="client.adresse"
        [readonly]="true"></adresse-view>      
    </div>
  
    <div class="row col-6 mp-0">
      <images-selector class="pl-3"
        [entityType]="EntityTypes.Contact"
        [entityId]="client.id" [(item)]="client.image"
        [readonly]="true"></images-selector>

      <mat-form-field class="col-12 pt-1" appearance="outline">
        <mat-label i18n>Site Web</mat-label>
        <input name="webSite" matInput
          [(ngModel)]="client.webSite" [readonly]="true" />
      </mat-form-field>        
    </div>
  </div>

  <div *ngIf="client.clientType===ClientTypes.Liee" class="pl-1 pt-2">
    <div class="pt-3">
      <entities-selector i18n-label class="col-6 mp-0" appearance="outline"
        [type]="EntityTypes.Client" [(item)]='client.parent' [excluding]='[client]' [data]="{personneType: PersonneTypes.Morale, clientType: ClientTypes.Principale}"
        [readonly]="true"
        [viewOnItemClicked]="false" (viewItem)="viewClient($event)"
        label="Société mère"></entities-selector> 
    </div>
  </div>

  <div *ngIf="client.clientType===ClientTypes.Principale" class="pl-1 pt-2">
    <div>
      <strong i18n>Clients liés</strong>
      &nbsp;<strong>{{client.enfants.length ? "(" + client.enfants.length + ")" : ""}}</strong>
    </div>
    <div class="pt-3">
      <clients-listview
        [(items)]="client.enfants" [excluding]="[client]" [parentId]="client.id"
        [readonly]="true"
        (viewItem)="viewClient($event);"></clients-listview>
    </div>
  </div>
</div>