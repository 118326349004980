import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable} from 'rxjs';
import {UtilsHelper} from '@services/helpers/utils-helper'
import { AuthService } from '@services/app/auth.service';
import {IUtilisateurDto} from '@models/dtos/management/securite/IUtilisateurDto'
import { IResultItemDto } from '@models/dtos/common/IResultItemDto';
import { IFidalEmployeeDto } from '@models/dtos/management/securite/IFidalEmployeeDto';
import { IFidalRoleDto } from '@models/dtos/management/securite/IFidalRoleDto';
import { IUtilisateursRequestFormPoco } from '@models/pocos/forms/IUtilisateursRequestFormPoco';
import { TDataPage } from '@models/dtos/common/TDataPage';
import { IFidalEmployeeRequestFormPoco } from '@models/pocos/forms/IFidalEmployeeRequestFormPoco';
import { QueryResultModes } from '@models/enums/QueryResultModes';
import { FidalUserKinds } from '@models/enums/iam/FidalUserKinds';
import { ICheckFormResult } from '@models/pocos/ICheckFormResult';
import { AppService } from './app.service';

@Injectable()
export class IamService {

    private baseApiUri_Utilisateurs: string;
    private baseApiUri_FildalEmployees: string;
    private baseApiUri_FildalRoles: string;

    constructor(
        private authService : AuthService,
        private httpClient: HttpClient,
        @Inject(LOCALE_ID) private locale: string
    ) {
        let baseApiUri: string = AppService.__Settings.platform.services[0].apiBaseUri + '/v1/security/';
        this.baseApiUri_FildalEmployees = baseApiUri + 'employees';
        this.baseApiUri_FildalRoles = baseApiUri + 'roles';

        this.baseApiUri_Utilisateurs = AppService.__Settings.platform.services[0].apiBaseUri + '/v1/utilisateurs';
    }

    // Utilisateurs


    repairUtilisateur(utilisateur: IUtilisateurDto) {

        if (utilisateur)
        {
            if (!utilisateur.uiCulture)
            {
                utilisateur.uiCulture = "fr-FR";
            }

            // repairing roles

            utilisateur.roles = utilisateur.roles.filter(q=> 
                q.id.toUpperCase() === 'FIDAL_MARKMANAGER_MEMBER'
                || q.id.toUpperCase() === 'FIDAL_MARKMANAGER_VISITOR'
                || q.id.toUpperCase() === 'FIDAL_MARKMANAGER_MANAGER'
                || q.id.toUpperCase() === 'FIDAL_MARKMANAGER_ADMIN'
                || q.id.toUpperCase() === 'FIDAL_MARKMANAGER_SUPER');

            if (utilisateur.roles?.map(q=>q.id).includes('FIDAL_MARKMANAGER_MEMBER') === true)
            {
                utilisateur.roles = [<IFidalRoleDto>{ id: 'FIDAL_MARKMANAGER_MEMBER'} ];
            }
            utilisateur.roles = utilisateur.roles.filter((v, i, a) => a.indexOf(v) === i);

            // repairing clients as managers

            if (!utilisateur.clientAccess)
            {
                utilisateur.clientAccess = [];
            }
        }
    }

    checkUtilisateur(utilisateur: IUtilisateurDto): ICheckFormResult {

        let result: ICheckFormResult = <ICheckFormResult> {};

        if (utilisateur) {
            if (!utilisateur.email) {
                result.message = $localize `Information requise` + ' : ' + $localize `Email`;
                result.location = "general";
            }
            else if (utilisateur.type== FidalUserKinds.Salarie && !utilisateur.fidalEmploye?.id) {
                result.message = $localize `Information requise` + ' : ' + $localize `Salarié`;
                result.location = "general";
            }
            else if (utilisateur.type== FidalUserKinds.Client && !utilisateur.client?.id)
            {
                result.message = $localize `Information requise` + ' : ' + $localize `Client`;
                result.location = "general";
            }
            else if ((utilisateur.type== FidalUserKinds.Salarie || utilisateur.type== FidalUserKinds.Application)
                && !utilisateur.email?.toLowerCase().endsWith('@fidal.com'))
            {
                result.message = $localize `Email en @fidal.com requis`;
                result.location = "managers";
            }
            else if (!(utilisateur.roles?.length>0) || utilisateur.roles.find(q=> !q.id))
            {
                result.message = $localize `Information requise` + ' : ' + $localize `Rôles`;
                result.location = "general";
            }
        }
          
        return result;
    }

    listUtilisateurs(requestForm: IUtilisateursRequestFormPoco): Observable<TDataPage<IUtilisateurDto>> {

        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<TDataPage<IUtilisateurDto>>(
            `${this.baseApiUri_Utilisateurs}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    addUtilisateur(utilisateur: IUtilisateurDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(this.baseApiUri_Utilisateurs, utilisateur, { headers: headers });
    }

    removeUtilisateur(id: string): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto>(`${this.baseApiUri_Utilisateurs}/${id}`, { headers: headers });
    }

    removeUtilisateurs(ids: string[]): Observable<IResultItemDto> {
        const options = UtilsHelper.getUriOptionsWithBody(ids, this.authService);
        return this.httpClient.delete<IResultItemDto>(this.baseApiUri_Utilisateurs, options);
    }

    getUtilisateur(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IUtilisateurDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IUtilisateurDto>(`${this.baseApiUri_Utilisateurs}/${id}?mode=${mode || ""}`, { headers: headers });
    }

    updateUtilisateur(id: string, utilisateur: IUtilisateurDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.put<IResultItemDto>(`${this.baseApiUri_Utilisateurs}`, utilisateur, { headers: headers });
    }

    exportUtilisateurs(requestForm: IUtilisateursRequestFormPoco): any {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get(`${this.baseApiUri_Utilisateurs}/export?${UtilsHelper.getQueryString(requestForm)}`, {headers: headers, observe: 'response', responseType: 'blob'})
            .pipe(map(item=> {

                let contentDispositionHeader = item.headers.get('Content-Disposition')
                let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
                let fileName = result.replace(/"/g, '');
        
                return {
                    blob: new Blob([item.body], {type: item.headers.get('Content-Type')}),
                    blobName:  fileName
                };
            }));
    }

    resetPasswordUtilisateur(id: string) {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_Utilisateurs}/${id}/reset-password`, null, { headers: headers });
    }

    // Update consent

    getConsentChecked(userId: string): Observable<boolean>
    {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<boolean>(
            `${this.baseApiUri_Utilisateurs}/${userId}/consent`, { headers: headers });
    }

    setConsentChecked(userId: string, value: boolean)
    {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(
            `${this.baseApiUri_Utilisateurs}/${userId}/consent`, value, { headers: headers });
    }
    
    // Roles

    listFidalRoles(search: string): Observable<IFidalRoleDto[]> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IFidalRoleDto[]>(`${this.baseApiUri_FildalRoles}?search=${search ?? ""}`, { headers: headers });
    }

    // Employees

    getFidalEmployee(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IFidalEmployeeDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IFidalEmployeeDto>(`${this.baseApiUri_FildalEmployees}/${id}?mode=${mode || ""}`, { headers: headers });
    }

    listFidalEmployees(requestForm: IFidalEmployeeRequestFormPoco): Observable<TDataPage<IFidalEmployeeDto>> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<TDataPage<IFidalEmployeeDto>>(`${this.baseApiUri_FildalEmployees}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    checkFidalEmployee(fidalEmployee: IFidalEmployeeDto): ICheckFormResult {

        let result: ICheckFormResult = <ICheckFormResult> {};

        if (fidalEmployee) {
            if (!fidalEmployee.lastName) {
                result.message = $localize `Information requise` + ' : ' + $localize `Nom`;
                result.location = "general";
            }
            else if (!fidalEmployee.firstName) {
                result.message = $localize `Information requise` + ' : ' + $localize `Prénom`;
                result.location = "general";
            }
            else if (!fidalEmployee.email) {
                result.message = $localize `Information requise` + ' : ' + $localize `Email`;
                result.location = "general";
            }
            else if (!fidalEmployee.sirhCode) {
                result.message = $localize `Information requise` + ' : ' + $localize `Code SIRH`;
                result.location = "general";
            }
            else if (fidalEmployee.sirhCode.length!=10) {
                result.message = $localize `Longueur incorrect` + ' : ' + $localize `Code SIRH`;
                result.location = "general";
            }                     
        }
        
        return result;
    }
    
    addFidalEmployee(fidalEmployee: IFidalEmployeeDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(this.baseApiUri_FildalEmployees, fidalEmployee, { headers: headers });
    }
}