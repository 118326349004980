export enum ActionKind {
    // Undefined.
    Undefined = "Undefined",

    Add = "Add",

    Delete = "Delete",

    Edit = "Edit",

    Export = "Export",

    List = "List",

    View = "View",

    // Any.
    Any = "Any"
}
