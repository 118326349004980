<div class="col-12 mp-0">
  <evenements-liveview #delaisPassesLiveView class="col-12 mp-0" itemMaxCount="100"
    [requestForm]="delaisPassesRequestForm"
    readonly="true" [editionReadonly]="readonly"></evenements-liveview>

  <div class="text-center">
    <button i18n mat-flat-button class="secondary mt-4 text-center"
      (click)="voirTous()">VOIR PLUS</button>
  </div>
</div>
