

<div class="screen-center on-top" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<div class="row col-12 mp-0" *ngIf="!readonly || items?.length>0">
  <div class="mr-2 mb-2 image-list">
  
    <image-view *ngFor="let item of items"
      [item]="item" [readonly]="readonly"
      [entityType]="entityType" [entityId]="entityId"
      [canDrag]="!readonly && (maxCount === -1 || maxCount>1)"
      (itemRemove)="removeImage($event)"></image-view>

    <button i18n-matTooltip mat-flat-button class="image-box-add mp-0" (click)="addImage()"
      *ngIf="!readonly && (maxCount === -1 || items.length<maxCount)"
      matTooltip="Ajouter un nouvel élement">
      <mat-icon>add</mat-icon> <span i18n>Ajouter</span>
    </button>
  </div>  
</div>

<div class="image-box-none border text-center pt-5" *ngIf="readonly && items?.length===0">
  <span i18n>Aucune</span>
</div>

