<div class="filtre-condition" *ngIf="condition">

    <flags-selector i18n-label class="filtre-instruction" *ngIf="!readonly && parent.filtres?.length>0 && parent.filtres[0]!=condition"
        [(itemId)]="condition.instruction" [type]="FlagTypes.DataInstructions"
        [readonly]="readonly"></flags-selector>

    <items-selector class="filtre-column" i18n-label label="Champ" idField="name" labelField="label"
        [itemId]="condition.columnName" (itemIdChange)="condition.columnName=$event; focus();"
        required [disabled]="readonly" (selectionChange)="refresh(); condition.value=null; focus();"
        [options]="fieldSpecs"></items-selector>

    <mat-form-field class="filtre-operator" *ngIf="condition.columnName && fieldSpec?.valueType!='Boolean'">
        <mat-label i18n>Opérateur</mat-label>
        <mat-select name="instruction"
            [ngModel]="condition.operator" (ngModelChange)="condition.operator=$event; focus();"
            required [disabled]="readonly">
            <mat-option *ngFor="let item of fieldOperators | keyvalue" [value]="item.key">
            {{item.value}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="filtre-value" *ngIf="condition.columnName && condition.operator">
        <mat-checkbox i18n name="value_boolean" class="col pt-2" *ngIf="fieldSpec?.componentKind===FormComponentKinds.BooleanInput"
            [(ngModel)]="condition.value" [disabled]="readonly"></mat-checkbox>
  
        <mat-form-field class="col" *ngIf="fieldSpec?.componentKind==='DateInput'" (keyup.enter)="submit()">
            <input name="value_date" #input_date="matInput" matInput [matDatepicker]="pickerFinDate" [(ngModel)]="condition.value" [readonly]="readonly">
            <mat-datepicker-toggle *ngIf="condition.value" matSuffix (click)="$event.stopPropagation(); condition.value=null; ">
              <mat-icon matDatepickerToggleIcon>clear</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker-toggle matSuffix [for]="pickerFinDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerFinDate></mat-datepicker>
          </mat-form-field>
    
        <mat-form-field class="col-12" *ngIf="fieldSpec?.componentKind===FormComponentKinds.NumberInput" (keyup.enter)="submit()">
            <mat-label i18n>Valeur</mat-label>
            <input name="value_number" #input_number="matInput" type="number" matInput [(ngModel)]="condition.value" required [readonly]="readonly" />
            <button mat-button matSuffix mat-icon-button *ngIf="condition.value" (click)="$event.stopPropagation(); condition.value=null; ">
                <mat-icon>clear</mat-icon>
            </button>
            <button mat-button matSuffix mat-icon-button (click)="submit();">
                <mat-icon>search</mat-icon>
            </button>
        </mat-form-field>
    
        <mat-form-field class="col-12" *ngIf="fieldSpec?.componentKind==='TextInput'" (keyup.enter)="submit()">
            <mat-label i18n>Valeur</mat-label>
            <input name="value_text" #input_text="matInput" matInput [(ngModel)]="condition.value" required [readonly]="readonly" />
            <button mat-button matSuffix mat-icon-button *ngIf="condition.value" (click)="$event.stopPropagation(); condition.value=null; ">
                <mat-icon>clear</mat-icon>
            </button>
            <button mat-button matSuffix mat-icon-button (click)="submit();">
                <mat-icon>search</mat-icon>
            </button>
        </mat-form-field>
    
        <div class="col-12" *ngIf="fieldSpec?.componentKind===FormComponentKinds.EntitySelector">
            <entities-selector i18n-label class="mp-0"
                [type]="fieldSpec?.entityType" [(itemId)]="condition.value" label="Valeur" [data]="getParamsData()"
                [required]="true" [readonly]="readonly"></entities-selector>
        </div>
    
        <div class="col-12" *ngIf="fieldSpec?.componentKind===FormComponentKinds.FlagSelector">
            <flags-selector i18n-label class="mp-0" [autoComplete]="true"
                [type]="fieldSpec?.flagType" [(item)]="condition.value" label="Valeur"
                [required]="true" [readonly]="readonly"></flags-selector>
        </div>
    
        <div class="col-12" *ngIf="fieldSpec?.componentKind===FormComponentKinds.ReferenceSelector">
            <references-selector i18n-label class="mp-0"
                [type]="fieldSpec?.referenceType" [(itemId)]="condition.value" label="Valeur"
                [required]="true" [readonly]="readonly"></references-selector>
        </div>  
    </div>
</div>
