import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { INiceClasseDto } from '@models/dtos/management/referentiel/INiceClasseDto';
import { ReferenceType } from '@models/enums/domain/ReferenceType';
import { KeyCodes } from '@models/enums/KeyCodes';
import { MessageKind } from '@models/enums/MessageKind';
import { QueryResultModes } from '@models/enums/QueryResultModes';
import { ReferentielService } from '@services/business/referentiel.service';
import { SnackBarExtension } from '@services/nav/snackbar-extension';

@Component({
  selector: 'niceclasses-list-dialog',
  templateUrl: './niceclasses-list-dialog.component.html'
})
export class NiceClassesListDialogComponent implements OnInit {

  ReferenceTypes = ReferenceType;

  isLoading: boolean = false;

  displayedColumns: string[];
  dataSource: MatTableDataSource<INiceClasseDto>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input('items')
  public items: INiceClasseDto[] = [];

  @Input('readonly')
  public readonly: boolean = false;

  currentItem: INiceClasseDto;
  itemsTotalCount: number;

  @Input('maxCount')
  public maxCount: number = -1;

  constructor(
    private referentielService: ReferentielService,
    private dialogRef: MatDialogRef<NiceClassesListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ) {
    this.dialogRef.disableClose = true;

    if (data)
    {
      this.items = data.items;
      this.readonly = data.readonly;
      this.maxCount = data.maxCount ?? -1;
    }

    this.displayedColumns = ['id', 'description', 'star'];
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });

    this.refreshItems();
  }

  async refreshItems() {

    let items = this.items?.sort((a, b)=> { 
      let r = a.id?.toLowerCase().localeCompare(b.id?.toLowerCase());
      return r; 
    });

    this.dataSource = new MatTableDataSource<INiceClasseDto>(items);
    this.itemsTotalCount = items?.length;

    this.dataSource.paginator = this.paginator;
}

  addItem() {
    if (!this.items.find(q=>q.id?.toString()===this.currentItem?.id.toString()))
    {
      this.isLoading = true;
      this.referentielService.getNiceClasse(this.currentItem?.id, QueryResultModes.Full).subscribe({
        next: result => {
          this.items = ([...this.items, result]);
          this.refreshItems();
          this.currentItem = null;
          this.isLoading = false;
        },
        error: _ => {
          SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
          this.isLoading = false;
        }
      });  
    }
  }

  deleteItem(item: INiceClasseDto) {

    this.items = this.items?.filter(q=> q.id?.toString() != item?.id?.toString());
    this.refreshItems();
  }

  selectItems()
  {
    this.dialogRef.close(this.items);
  }
}
