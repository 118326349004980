import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {IResultItemDto} from '@models/dtos/common/IResultItemDto'
import {IEvenementDto} from '@models/dtos/runtime/agenda/IEvenementDto'
import { UtilsHelper } from '@services/helpers/utils-helper'
import { AppService } from '@services/app/app.service'
import { AuthService } from '@services/app/auth.service';
import { IEvenementsRequestFormPoco } from '@models/pocos/forms/IEvenementsRequestFormPoco';
import { TDataPage } from '@models/dtos/common/TDataPage';
import { map } from 'rxjs/operators';
import { QueryResultModes } from '@models/enums/QueryResultModes';
import { ICheckFormResult } from '@models/pocos/ICheckFormResult';
import { EvenementObjetTypes } from '@models/enums/domain/EvenementObjetTypes';
import { EvenementTypes } from '@models/enums/domain/EvenementTypes';

@Injectable()
export class AgendaService {

    private baseApiUri_Evenements: string;

    constructor(
        private authService : AuthService,
        private httpClient: HttpClient,
        @Inject(LOCALE_ID) private locale: string
    ) {
        let baseApiUri: string = AppService.__Settings.platform.services[0].apiBaseUri + '/v1';

        this.baseApiUri_Evenements = baseApiUri + '/evenements';
    }
    
    // Evenements

    repairEvenement(evenement: IEvenementDto) {

        if (!evenement) return;

        if (!evenement.client)
        {
            if (evenement.dossier)
            {
                evenement.client = evenement.dossier.client;
            }
    
            if (evenement.contentieux)
            {
                evenement.client = evenement.contentieux.client;
            }    
        }
    }

    checkEvenement(evenement: IEvenementDto): ICheckFormResult {

        let result: ICheckFormResult = <ICheckFormResult> {};

        if (evenement) {
            if (!evenement.client?.id) {
                result.message = $localize `Information requise` + ' : ' + $localize `Client`;
                result.location = "general";
            }
            else if (!UtilsHelper.hasFlagValue(evenement.objetType) || evenement.objetType === EvenementObjetTypes.Dossier) {
                result.message = $localize `Information requise` + ' : ' + $localize `Type d'objet`;
                result.location = "general";
            }
            else if (evenement.objetType===EvenementObjetTypes.Contentieux && !evenement.contentieux?.id) {
                result.message = $localize `Information requise` + ' : ' + $localize `Contentieux`;
                result.location = "general";
            }
            else if (evenement.objetType===EvenementObjetTypes.Marque && !evenement.dossier?.id) {
                result.message = $localize `Information requise` + ' : ' + $localize `Marque`;
                result.location = "general";
            }
            else if (evenement.objetType===EvenementObjetTypes.Modele && !evenement.dossier?.id) {
                result.message = $localize `Information requise` + ' : ' + $localize `Modèle`;
                result.location = "general";
            }
            else if (!evenement.date) {
                result.message = $localize `Information requise` + ' : ' + $localize `Date`;
                result.location = "general";
            }
            else if (!evenement.type) {
                result.message = $localize `Information requise` + ' : ' + $localize `Type d'évènement`;
                result.location = "general";
            }
            else if (!evenement.delaiType) {
                result.message = $localize `Information requise` + ' : ' + $localize `Type de délai`;
                result.location = "general";
            }
        }
        
        return result;
    }

    isEvenementsRequestFormEmpty(requestForm: IEvenementsRequestFormPoco): boolean {
        let isEmpty = !requestForm.motcle
             && !(requestForm.clients?.length>0)
             && !requestForm.delaiType?.id
             && requestForm.estTraite!=true && requestForm.estTraite!=false
             && !requestForm.evenementType && requestForm.evenementType!=EvenementTypes.Any;

        return isEmpty;
    }

    listEvenements(requestForm: IEvenementsRequestFormPoco): Observable<TDataPage<IEvenementDto>> {

        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<TDataPage<IEvenementDto>>(`${this.baseApiUri_Evenements}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getEvenement(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IEvenementDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IEvenementDto>(`${this.baseApiUri_Evenements}/${id || ""}?mode=${mode || ""}`, { headers: headers });
    }

    addEvenement(evenement: IEvenementDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_Evenements}`, evenement, { headers: headers });
    }

    updateEvenement(evenement: IEvenementDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.put<IResultItemDto>(`${this.baseApiUri_Evenements}`, evenement, { headers: headers });
    }

    deleteEvenement(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto>(`${this.baseApiUri_Evenements}/${id || ""}`, { headers: headers });
    }

    deleteEvenements(ids: string[]): Observable<IResultItemDto[]> {
        if (!ids) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto[]>(`${this.baseApiUri_Evenements}?${UtilsHelper.getQueryString({ids: ids})}`, { headers: headers })
    }

    exportEvenements(requestForm: IEvenementsRequestFormPoco): any {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get(`${this.baseApiUri_Evenements}/export?${UtilsHelper.getQueryString(requestForm)}`, {headers: headers, observe: 'response', responseType: 'blob'})
            .pipe(map(item=> {

                let contentDispositionHeader = item.headers.get('Content-Disposition')
                let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
                let fileName = result.replace(/"/g, '');
        
                return {
                    blob: new Blob([item.body], {type: item.headers.get('Content-Type')}),
                    blobName:  fileName
                };
            }));
    }
}