export enum FidalUserKinds {

    // Any.
    Any = "Any",

    // Undefined.
    Undefined = "Undefined",

    // None.
    None = "None",

    // Salarié.
    Salarie = "Salarie",

    // Client.
    Client = "Client",

    // Application.
    Application = "Application",

    // Standard
    Standard = "Standard"
 }
