import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IDataPresenterSpecDto } from '@models/dtos/data/export/IDataPresenterSpecDto';
import { IFieldSpecDto } from '@models/dtos/data/export/IFieldSpecDto';
import { DataOperators } from '@models/enums/DataOperators';
import { FormComponentKind } from '@models/enums/domain/FormComponentKind';
import { ReferentielService } from '@services/business/referentiel.service';
import { DataValueTypes } from "@models/enums/DataValueTypes";
import { FlagType } from '@models/enums/domain/FlagType';
import { IFlagsRequestFormPoco } from '@models/pocos/forms/IFlagsRequestFormPoco';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { MessageKind } from '@models/enums/MessageKind';
import { MatInput } from '@angular/material/input';
import { ConditionsHelper } from '@services/helpers/conditions-helper';
import { StringHelper } from '@services/helpers/string-helper';
import { IRequeteConditionPoco } from '@models/pocos/queries/IRequeteConditionPoco';
import { IRequeteClausePoco } from '@models/pocos/queries/IRequeteClausePoco';
import { DataInstructions } from '@models/enums/DataInstructions';

@Component({
  selector: 'filtre-condition',
  templateUrl: './filtre-condition.component.html'
})
export class FiltreConditionComponent implements OnInit {

  operators: any = {};
  FormComponentKinds = FormComponentKind;

  @ViewChild('input_number', {static: false}) input_number: MatInput;
  @ViewChild('input_text', {static: false}) input_text: MatInput;
  @ViewChild('input_date', {static: false}) input_date: MatInput;

  @Input("parent")
  parent: IRequeteClausePoco;

  @Input("item")
  condition: IRequeteConditionPoco;

  @Input("presenterSpec")
  public presenterSpec: IDataPresenterSpecDto;

  @Input("queryData")
  public queryData: any;

  @Output("removeItem")
  public removeItemChange: EventEmitter<IRequeteConditionPoco> = new EventEmitter<IRequeteConditionPoco>();

  @Input('readonly')
  public readonly: boolean = false;

  @Output("submited")
  public submitedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  fieldSpecs: IFieldSpecDto[];
  fieldOperators: any = {};
  FlagTypes = FlagType;

  fieldSpec: IFieldSpecDto;

  constructor(
    private referentielService: ReferentielService,
    private snackBar: MatSnackBar,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.refreshFlags();
    this.refresh();
  }
  
  ngOnChanges(changes: SimpleChanges) {

    if (changes.hasOwnProperty("presenterSpec")) {

      this.fieldSpecs = this.presenterSpec?.columns?.filter(q=> this.presenterSpec.filterColumns?.find(p=> p?.columnName.toLowerCase() === q.name?.toLowerCase()))
        .sort((a, b)=> a.label?.toLowerCase().localeCompare(b.label?.toLowerCase()));

      this.refresh();
    }
  }

  refresh() {

    let filterSpec = this.presenterSpec?.filterColumns?.find(p=> p?.columnName.toLowerCase() === this.condition?.columnName?.toLowerCase());

    this.fieldOperators = {};
    if (this.operators && filterSpec)
    {
      Object.keys(this.operators).filter(q=> filterSpec.operators.find(p=> p === q))?.forEach(q=> 
        {
          this.fieldOperators[q]= this.operators[q];
        })
    }
    if (Object.keys(this.fieldOperators).length>0 && !this.fieldOperators[this.condition.operator])
    {
      this.condition.operator = DataOperators[Object.keys(this.fieldOperators)[0]];
    }    

    this.fieldSpec = this.presenterSpec?.columns?.find(p=> p?.name.toLowerCase() === this.condition?.columnName?.toLowerCase());

    if(this.fieldSpec)
    {
      if(this.fieldSpec.componentKind === FormComponentKind.None || this.fieldSpec.componentKind === FormComponentKind.Undefined)
      {
        this.fieldSpec.componentKind = ConditionsHelper.getFormComponentKind(this.fieldSpec);
      }
      
      let value = this.condition.value;
      
      let type = Object.prototype.toString.call(value);
      if (this.fieldSpec.valueType === DataValueTypes.Boolean && type != "boolean")
      {
        value = null;
      }
      else if (this.fieldSpec.valueType === DataValueTypes.Date && type != "[object Date]" && type != "[object String]")
      {
        value = null;
      }
      else if ((this.fieldSpec.valueType === DataValueTypes.Integer
        || this.fieldSpec.valueType === DataValueTypes.Long
        || this.fieldSpec.valueType === DataValueTypes.Number
        || this.fieldSpec.valueType === DataValueTypes.ULong)  && type != "[object Number]")
      {
        value = null;
      }
  
      if (!value)
      {
        this.condition.value = this.fieldSpec.defaultValue;
      } 
    }

    this.changeDetectorRef.detectChanges();
  }

  isBasicComponentKind(componentKind: FormComponentKind)
  {
    return componentKind!= FormComponentKind.None &&
      componentKind!= FormComponentKind.Undefined && 
      componentKind?.toLowerCase().indexOf("input")>0;
  }
  
  removeCondition()
  {
    this.removeItemChange?.emit(this.condition);
  }

  async refreshFlags() {

    // operators

    this.referentielService.listFlags(<IFlagsRequestFormPoco>{ flagType: FlagType.DataOperators}).subscribe({
      next: result => {
        this.operators = {};
        result.forEach(q=>
        {
          this.operators[q.key]=q.content;
        });
        this.refresh();
      },
      error: _ => {
          SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
      }
    });
  }

  getParamsData(): any {

    let data: any = {};
    if (this.fieldSpec?.paramString) {
      data = StringHelper.jsonParse(this.fieldSpec?.paramString);
    }
    if (!data) data= {};

    data.clientIds = this.queryData.clients?.map(q=>q.id);
    
    return data;
  }

  // submit

  submit() {
    this.submitedChange?.emit(true);
  }

  // focus

  focus() {
    this.input_number?.focus();    
    this.input_text?.focus();
    this.input_date?.focus();
  }
}
