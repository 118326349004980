import { AuthService } from '@services/app/auth.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { IDataPageRequest } from '@models/dtos/common/IDataPageRequest';
import { ITSearchRequestPoco } from '@models/pocos/ITSearchRequestPoco';
import { IExportRequestFormPoco } from '@models/pocos/forms/IExportRequestFormPoco';
import { StringHelper } from './string-helper';

export class UtilsHelper {

  static clone<T>(obj: any) : T {
    return StringHelper.jsonParse(JSON.stringify(obj)) as T
  }

  stringEnumToKeyValue(stringEnum: any) {
      var names: string[] = [];
      for(var n in stringEnum) {
          if(typeof stringEnum[n] === 'number') names.push(n);
      }

      return names;
  }

  static repairDataPageRequest(request: IDataPageRequest, pageSizes: number[])
  {
    if (!request.pageIndex) {
      request.pageIndex = 0;
    }
    if (!pageSizes.find(q=>request.pageSize === q)) {
      request.pageSize = pageSizes[0];
    }
  }

  static repairSearchRequestPoco<T extends IExportRequestFormPoco>(page: ITSearchRequestPoco<T>, pageSizes: number[])
  {
    if (page) {
      page.formPoco ??= <T>{};

      UtilsHelper.repairDataPageRequest(page.formPoco, pageSizes);  
    }
  }

  static updateDataPageInfo<T>(request: IDataPageRequest, page: any)
  {
    if (request) {
      request.pageSize = page?.pageSize;
      request.pageIndex = page?.pageIndex;        
    }
  }

  static hasFlagValue(st: string)
  {
    return !!st && st != "None" && st != "Undefined";
  }
  
  static getQueryString(obj: object): string
  {
    if(!obj)
    {
      return "";
    }

    return Object.keys(obj)
      .map((key) => {
        let value = obj[key];
        let type = Object.prototype.toString.call(value);

        if (type === "[object Array]")
        {
          return value.map(q=> {
            let v = <any> { };
            v[key] = q.id ? { id: q.id } : q;
            return UtilsHelper.getQueryString(v);
          }).join('&');
        }
        else if (type === "[object Number]")
        {
          return encodeURIComponent(key) + '=' + encodeURIComponent(value);
        }
        else if (type === "[object Date]")
        {
          value = moment(value).format("yyyy-MM-DD");
        }
        else if (type === "[object Boolean]")
        {
          return encodeURIComponent(key) + '=' + encodeURIComponent(value);
        }
        else if (Array.isArray(value))
        {
          return value.map(q=> encodeURIComponent(key) + '=' + encodeURIComponent(q)).join('&');
        }
        else if (typeof value === "object")
        {
          if (value?.id)
          {
            return encodeURIComponent(key + "Id") + '=' + encodeURIComponent(value.id);
          }
          else if(moment.isMoment(value))
          {
            value = moment(value).format("yyyy-MM-DD");
          }
          else
          {
            value = "";            
          }
        }
        
        if (value)
        {
          return encodeURIComponent(key) + '=' + encodeURIComponent(value);
        }
        
        return "";
      })
      .filter(q=>q)
      .join('&');
  }

  static getUriOptionHeaders(authService: AuthService, locale: string) {
    return new HttpHeaders({
      'Accept-Language': locale,
      'Authorization': authService?.getAuthorizationHeaderValue()
    });
  }

  static getUriOptionsWithBody(body: any, authService: AuthService = null, locale: string = null) {

      return {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept-Language': locale,
            'Authorization': authService?.getAuthorizationHeaderValue()
          }),
          body: body
        };
  }
    
  static getUriOfLocale(uri: string, locale: string): string
  {
    let baseUri = window.location.origin;
    let baseUriLocale = `${baseUri}${environment.production ? '/' + locale : ''}` + uri;
    
    return baseUriLocale;
  }

  static downloadFile(file: any)
  {
    if(window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(new Blob([file.blob], { type: 'application/octet-stream' }), file.blobName);       
    }
    else
    {
      var a = document.createElement("a");
      a.href = URL.createObjectURL(new Blob([file.blob], { type: 'application/octet-stream' }));
      a.download = file.blobName;
      a.click();
    }    
  }
}