import { Component } from '@angular/core';

@Component({
  selector: 'pane-contacts-component',
  templateUrl: './contacts.component.html'
})
export class ContactsComponent {

  constructor() {
  }
}
