import { Component } from '@angular/core';

@Component({
  selector: 'pane-clients-component',
  templateUrl: './clients.component.html'
})
export class ClientsComponent {

  constructor() {
  }
}
