import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';import { GlobalConstants } from '@models/app/GlobalConstants';
import { IDossierPaysDto } from '@models/dtos/management/referentiel/IDossierPaysDto';
import { KeyCodes } from '@models/enums/KeyCodes';
import { MessageKind } from '@models/enums/MessageKind';
import { IRequeteRequestFormPoco } from '@models/pocos/forms/IRequeteRequestFormPoco';
import { IReferenceRequestFormPoco } from '@models/pocos/forms/IReferenceRequestFormPoco';
import { ReferentielService } from '@services/business/referentiel.service';
;
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { MarqueRegistreTypes } from '@models/enums/domain/MarqueRegistreTypes';

@Component({
  selector: 'dossierpays-search-dialog',
  templateUrl: './dossierpays-search-dialog.component.html'
})
export class DossierPaysSearchDialogComponent implements OnInit, AfterViewInit {

  pageSizeOptions: number[] = GlobalConstants.pageSizeOptions_Dialog;

  @ViewChild('inputMotCle') inputMotCle: MatInput;

  public requestForm: IReferenceRequestFormPoco;
  isLoading: boolean = false;

  dossierPayss: IDossierPaysDto[];
  dossierPayssTotalCount: number;

  displayedColumns: string[];
  dataSource: MatTableDataSource<IDossierPaysDto>;
  selection = new SelectionModel<IDossierPaysDto>(true, []);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input('clientId')
  public clientId: string;

  @Input('excluding')
  public excludingDossierPayss: IDossierPaysDto[] = [];

  @Input('maxCount')
  public maxCount: number = -1;

  @Input('marqueRegistreType')
  public marqueRegistreType: MarqueRegistreTypes = MarqueRegistreTypes.Any;

  constructor(
    private referentielService: ReferentielService,
    private dialogRef: MatDialogRef<DossierPaysSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ) {
    this.dialogRef.disableClose = true;

    if (data)
    {
      this.excludingDossierPayss = data.excluding;
      this.marqueRegistreType = data.marqueRegistreType;
      this.maxCount = data.maxCount ?? -1;
    }

    if (this.maxCount===1)
    {
      this.displayedColumns = ['libelle', 'star'];
    }
    else
    {
      this.displayedColumns = ['select', 'libelle', 'star'];
    }
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });

    this.initRequestForm();
    this.refreshDossierPayss();
  }
  
  ngAfterViewInit() {
    setTimeout(() => {
      this.inputMotCle.focus();
    });
  }

  async initRequestForm()
  {
    this.requestForm = <IReferenceRequestFormPoco> {  };
  }

  changePage() {
    this.refreshDossierPayss();
  }

  async refreshDossierPayss() {

    this.isLoading = true;
    this.requestForm.marqueRegistreType = this.marqueRegistreType;
    this.referentielService.listDossierPays(this.requestForm).subscribe({
      next: result => {    
        this.dossierPayss = result?.sort((a, b)=> a.libelle?.toLowerCase().localeCompare(b.libelle?.toLowerCase()));      
        this.dataSource = new MatTableDataSource<IDossierPaysDto>(this.dossierPayss);
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
        this.isLoading = false;
      }
    });
  }

  selectDossierPayss()
  {
    this.dialogRef.close(this.selection.selected);
  }
   
  selectDossierPays(dossierPays: IDossierPaysDto)
  {
    if (dossierPays)
    {
      this.dialogRef.close([dossierPays]);
    }
  }

  isExcluded(dossierPays: IDossierPaysDto)
  {
    return this.excludingDossierPayss.find(q=> dossierPays?.id === q.id);
  }

  // Multiselection ---------------

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected())
    {
      this.selection.clear();
    }
    else
    {
      this.selection.clear();
      this.dataSource.data.forEach(row => !this.isExcluded(row) && this.selection.select(row));
    }
  }

  checkboxLabel(row?: IDossierPaysDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
  
  search() {
    if (this.requestForm.motcle?.length>3) { 
      this.refreshDossierPayss(); 
    }
  }

  select() {
    if (this.dossierPayss?.length===1)
    {
      this.selectDossierPays(this.dossierPayss[0]);
    }
    else
    {
      this.refreshDossierPayss();
    }
  }
}
