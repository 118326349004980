export enum InterlocuteurTypes {

    // Undefined.
    Undefined = "Undefined",

    // Principal.
    Principal = "Principal",

    // Interne.
    Autre = "Autre",

    // Any.
    Any = "Any"
}
