<h2 i18n mat-dialog-title>A propos de Mark Manager</h2>
<mat-dialog-content class="mat-typography">
  <table class="table">
    <tbody>
      <tr>
        <th i18n scope="row">Version</th>
        <td>
          {{localInfo?.version}}<br/>
          API : {{apiInfo?.version}}
        </td>
      </tr>
      <tr>
        <th i18n scope="row">Date de sortie</th>
        <td>{{apiInfo?.buildDate | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
      </tr>
      <tr>
        <th i18n scope="row">Copyright</th>
        <td>{{apiInfo?.copyright}}</td>
      </tr>
      <tr *requiresUserRoles="[AppRoles.Manager, AppRoles.Admin, AppRoles.Super]">
        <th i18n scope="row">Notes de version</th>
        <td>
          <button i18n-matTooltip mat-flat-button class="secondary text-small"
            (click)="viewReleaseNote();"
            matTooltip="Voir la dernière note">
            <span i18n>VOIR</span>
          </button>      
        </td>
      </tr>
    </tbody>
  </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button i18n mat-raised-button class="primary" mat-dialog-close>OK</button>
</mat-dialog-actions>