export enum ExportType {

    // Undefined.
    Undefined = "Undefined",

    // Clients.
    Clients = "Clients",

    // Contentieux.
    Contentieux = "Contentieux",

    // Evenements.
    Evenements = "Evenements",

    // Clients.
    Contacts = "Contacts",

    // MarqueDossiers.
    MarqueDossiers = "MarqueDossiers",

    // ModeleDossiers.
    ModeleDossiers = "ModeleDossiers",

    // Utilisateurs
    Utilisateurs = "Utilisateurs",

    // AppLogs
    AppLogs = "AppLogs"
}
