import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IDataKeyValue } from '@models/dtos/common/IDataKeyValue';
import { ActionKind } from '@models/enums/ActionKind';
import { FlagType } from '@models/enums/domain/FlagType';
import { MessageKind } from '@models/enums/MessageKind';
import { IFlagsRequestFormPoco } from '@models/pocos/forms/IFlagsRequestFormPoco';
import { ReferentielService } from '@services/business/referentiel.service';
import { SnackBarExtension } from '@services/nav/snackbar-extension';

@Component({
  selector: 'flags-selector',
  templateUrl: './flags-selector.component.html'
})
export class FlagsSelectorComponent implements OnChanges {

  flagtypes: IDataKeyValue[] = [];
  Control_Options = new UntypedFormControl();
  filteredOptions: any;

  flagtype: IDataKeyValue;

  @Input('appearance')
  public appearance: string;

  @Input('action')
  public actionKind: ActionKind;

  @Input('sorting')
  public sorting: boolean = true;

  @Output()
  public selectionChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Input()
  public item: number;

  @Output()
  public itemChange: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  public itemId: string;

  @Output()
  public itemIdChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public items: string[];

  @Output()
  public itemsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Input()
  public itemIds: string[];

  @Output()
  public itemIdsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  // 0: items; 1: itemIds; 2: item; 3: itemId
  public itemMode: number = 2;
 
  @Input('autoComplete')
  public autoComplete: boolean = false;

  @Input('excluding')
  public excludingItems: any[] = [];

  @Input('type')
  public type: FlagType = FlagType.Undefined;

  @Input('maxCount')
  public maxCount: number = -1;

  @Input('label')
  public label: string = null;

  @Input('readonly')
  public readonly: boolean = false;

  @Input('required')
  public required: boolean = false;

  @Output("valid")
  public validChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  get valid(): boolean | null
  {
    let b = !this.required || !!this.item;
    return b;
  }
  
  constructor(
    private referentielService: ReferentielService,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {

    
    if (changes.hasOwnProperty("items")) {
      this.itemMode = 0;
    }

    if (changes.hasOwnProperty("itemIds")) {
      this.itemMode = 1;
    }

    if (changes.hasOwnProperty("item")) {

      this.itemMode = 2;
      this.flagtype = this.flagtypes?.find(q=> q.value === this.item?.toString());
    }

    if (changes.hasOwnProperty("itemId")) {

      this.itemMode = 3;
    }

    if (changes.hasOwnProperty("type") || changes.hasOwnProperty("excludingItems")) {
      this.refreshItems();
    }
    
    if (changes.hasOwnProperty("actionKind")) {
      if (this.actionKind=== ActionKind.View)
      {
        this.readonly = true;
      }
    }
  }

  async refreshItems() {

     let requestForm = <IFlagsRequestFormPoco> {
      flagType: this.type,
      any: this.actionKind === ActionKind.List,
      undefined: this.actionKind === ActionKind.Edit || this.actionKind === ActionKind.View
     };
     
    this.referentielService.listFlags(requestForm).subscribe({
      next: result => {
        this.flagtypes = result?.filter(q=> !this.excludingItems.find(p=>p?.toLowerCase()==q.key.toLowerCase()));

        if (this.sorting && this.flagtypes)
        {
          this.flagtypes = this.flagtypes.sort((a, b)=> { 
            let r = a.key==="Any" || a.key==="Undefined" ? -1 :  b.key==="Any" || b.key==="Undefined" ? 1 : a.content?.toLowerCase().localeCompare(b.content?.toLowerCase());
            return r; 
          });
        }

        this.flagtype = this.flagtypes?.find(q=> q.value === this.item?.toString());
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
      }
    });
  }

  setItem(valueString: string, emit: boolean = true)
  {
    this.item = +(valueString);
 
    if (emit)
    {
      this.itemChange.emit(this.item);

      this.selectionChange.emit(true);
      this.validChange.emit(this.valid);  
    }
  }

  setItemId(id: string, emit: boolean = true)
  {
    this.itemId = id;

    if (emit)
    {
      this.itemIdChange.emit(id);

      this.selectionChange.emit(true);
      this.validChange.emit(this.valid);  
    }
  }
  
  setItemIds(itemIds: string[], emit: boolean = true)
  {
    this.itemIds = itemIds;    

    if (emit)
    {
      this.itemIdsChange.emit(this.itemIds);

      this.selectionChange.emit(true);
      this.validChange.emit(this.valid);  
    }
  }

  setItems(items: string[], emit: boolean = true)
  {
    this.items = items;    

    if (emit)
    {
      this.itemsChange.emit(this.items);

      this.selectionChange.emit(true);
      this.validChange.emit(this.valid);  
    }  
  }
    
  getItemsText()
  {
    // 0: items; 1: itemIds; 2: item; 3: itemId

    let flag = null;
    switch (this.itemMode)
    {
      case 0:
        return this.items?.map(q=> {

          let flag = this.flagtypes?.find(q=> q.value === this.item?.toString());
          return flag?.content;
          }).join(", ");
      case 1:
        return this.itemIds?.map(q=> {

          let flag = this.flagtypes?.find(q=> q.key === this.itemId);
          return flag?.content;
          }).join(", ");
      case 2:
        flag = this.flagtypes?.find(q=> q.value === this.item?.toString());
        return flag?.content;
      case 3:
        flag = this.flagtypes?.find(q=> q.key === this.itemId);
        return flag?.content;
    }
  }
}
