import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { KeyCodes } from '@models/enums/KeyCodes';

@Component({
  selector: 'image-view-dialog',
  templateUrl: './image-view-dialog.component.html'
})
export class ImageViewDialogComponent implements OnInit {

  imageUrl: string;

  constructor(
    private dialogRef: MatDialogRef<ImageViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef.disableClose = true;

    let image = data.image;
    this.imageUrl = 'data:' + image?.contenuType + ';base64,'+ image?.contenu;
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });
  }
}
