export enum OrderByKinds {

    /// <summary>
    /// None.
    /// </summary>
    None = "None",

    /// <summary>
    /// Ascending.
    /// </summary>
    Ascending = "Ascending",

    /// <summary>
    /// Descending.
    /// </summary>
    Descending = "Descending"
}
