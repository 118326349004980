import { Subject } from "rxjs";

export class BreadcrumbHelper {
    
    public static BreadcrumbSubject: Subject<any> = new Subject<any>();

    public static setBreadcrumbSubject(value: any)
    {
        BreadcrumbHelper.BreadcrumbSubject.next(value);
    }

    public static setBreadcrumbSubjectItem(value: string, key: string = "objectLabel")
    {
        let obj = {};
        obj[key]=value;

        BreadcrumbHelper.BreadcrumbSubject.next(obj);
    }
}
