
export class StorageKeys {
    
    public static readonly CurrentUri: string = "currentUri";

    public static readonly __serviceConsentChecked: string = "$service_consent_checked";

    public static readonly __requetesExpertMode: string = "requetesExpertMode";
    public static readonly __exportFormat: string = "exportFormat";

    public static readonly __formToken_Agenda: string = "$form_agenda";
    public static readonly __formToken_Clients: string = "$form_clients";
    public static readonly __formToken_Contacts: string = "$form_contacts";
    public static readonly __formToken_Dossiers_Marques: string = "$form_dossiers_marques";
    public static readonly __formToken_Dossiers_Modeles: string = "$form_dossiers_modeles";
    public static readonly __formToken_Dossiers_Contentieux: string = "$form_dossiers_contentieux";
    public static readonly __formToken_Requetes: string = "$form_requetes_";
    public static readonly __formToken_Utilisateurs: string = "$form_requetes_utilisateurs";
    public static readonly __formToken_AppLogs: string = "$form_requetes_appLogs";

    public static readonly __exportColumnsToken_Evenements: string = "$export_columns_evenements";
    public static readonly __exportColumnsToken_Clients: string = "$export_columns_clients";
    public static readonly __exportColumnsToken_Contacts: string = "$export_columns_contacts";
    public static readonly __exportColumnsToken_Marques: string = "$export_columns_marques";
    public static readonly __exportColumnsToken_Modeles: string = "$export_columns_modeles";
    public static readonly __exportColumnsToken_Contentieux: string = "$export_columns_contentieux";
    public static readonly __exportColumnsToken_Requetes: string = "$export_columns_requetes_";
    public static readonly __exportColumnsToken_Utilisateurs: string = "$export_columns_utilisateurs";
    public static readonly __exportColumnsToken_AppLogs: string = "$export_columns_appLogs";
    public static readonly __exportColumnsToken_Masse: string = "$export_columns_masse";
}