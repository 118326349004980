import { Component, Input, OnInit, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { StringHelper } from '@services/helpers/string-helper';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'items-selector',
  templateUrl: './items-selector.component.html'
})
export class ItemsSelectorComponent implements OnChanges {

  Control_Options = new UntypedFormControl();
  filteredOptions: any;

  @Input('appearance')
  public appearance: string;

  @Input('optionIdField')
  public optionIdFieldName: string;

  @Input('idField')
  public idFieldName: string;

  @Input('labelField')
  public labelFieldName: string;

  @Input('textFormat')
  public textFormat: string;  

  @Output()
  public selectionChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Input()
  public item: any;

  @Output()
  public itemChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public itemId: any;

  @Output()
  public itemIdChange: EventEmitter<any> = new EventEmitter<any>();

  // 2: item; 3: itemId
  private itemMode: number = 2;

  @Input('options')
  public options: any[] = [];

  @Input('label')
  public label: string = null;

  @Input('readonly')
  public readonly: boolean = false;

  @Input('disabled')
  public disabled: boolean = false;

  @Input('required')
  public required: boolean = false;

  @Output("valid")
  public validChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(MatMenuTrigger,{static:false}) menu: MatMenuTrigger; 

  get valid(): boolean | null
  {
    let b = !this.required || this.item;
    return b;
  }

  constructor(
  ) {
  }
  
  ngOnChanges(changes: SimpleChanges) {

    if (changes.hasOwnProperty("idFieldName")) {

      this.optionIdFieldName ??= this.idFieldName;
    }

    if (changes.hasOwnProperty("item")) {

      this.updateAutoCompleteComponent_Options();
      this.itemMode = 2;
    }

    if (changes.hasOwnProperty("itemId")) {

      let item = <any> {};
      item[this.idFieldName] = this.itemId;
      this.setItem(item, false);
      this.updateAutoCompleteComponent_Options();
      
      this.itemMode = 3;
    }

    if (changes.hasOwnProperty("options")) {
      this.updateAutoCompleteComponent_Options();
      this.updateOptionFilter();
    }
  }

  get getItemsText()
  {
    return (item: any) => {

      if (!this.labelFieldName || !item) return "";
      let text = this.textFormat ? this.textFormat
        .replace("id", item[this.idFieldName])
        .replace("label", item[this.labelFieldName]) : item[this.labelFieldName];
      return text ?? "";
    };
  }

  get getItemText() {
    return (item: any) => {
      let text = this.textFormat ? this.textFormat
        .replace("id", item[this.idFieldName])
        .replace("label", item[this.labelFieldName]) : item[this.labelFieldName];
      return text;
    };
  }

  updateOptionFilter() {
    this.filteredOptions = this.Control_Options?.valueChanges.pipe(
      startWith(null),
      map(item => item && typeof item === 'object' ? null : item),
      map(item => {
        const filterValue = StringHelper.unaccent(item ?? '')?.toLowerCase();
        return this.options?.filter(option => 
          {
            return (option ? StringHelper.unaccent(option[this.labelFieldName]) : '')?.toLowerCase()?.indexOf(filterValue) > -1
              || (option ? StringHelper.unaccent(option[this.idFieldName]) : '')?.toLowerCase()?.indexOf(filterValue) > -1
          }) ?? [];
      }
    ));
  }

  setOptionValue = async (item: any) => {

    this.setItem(item);
  }

  updateAutoCompleteComponent_Options() {    
    let item = this.item && this.idFieldName && this.item[this.idFieldName] ? this.options?.find(q=> q[this.optionIdFieldName] 
        && StringHelper.unaccent(q[this.optionIdFieldName]).toLowerCase() == StringHelper.unaccent(this.item[this.idFieldName]).toLowerCase()) : null;
    this.Control_Options.setValue(item);
  }
  
  private setItem(item: any, emit: boolean = true)
  {
    this.item = item;

    if (emit)
    {
      switch (this.itemMode)
      {
        case 2:
          this.itemChange.emit(this.item);
          break;
        case 3:
          this.itemIdChange.emit(this.item ? this.item[this.optionIdFieldName] : null);
          break;
      }

      this.selectionChange.emit(true);
      this.validChange.emit(this.valid);  
    }
  }
}
