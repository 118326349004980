<div class="screen-center on-top" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<div class="col-12 mp-0">
  <mat-paginator *ngIf="showPaginator" [length]="items.length" [pageSize]="pageSizeOptions" [pageSizeOptions]="[pageSize]"></mat-paginator>  
  <div *ngIf="readonly && items?.length === 0">
    <span i18n>Aucun</span>
  </div>

  <div class="mb-3 data-table border-top" *ngIf="items?.length > 0">
    <table mat-table [dataSource]="dataSource" matSort>

      <!--libelle Column -->
      <ng-container matColumnDef="libelle">
        <td mat-cell *matCellDef="let row">{{row.nom}} {{row.libelle}}</td>
      </ng-container>

      <!--creationDate Column -->
      <ng-container matColumnDef="creationDate">
        <td mat-cell *matCellDef="let row" class="text-center">
          <span i18n *ngIf="row.creationDate">ajouté le </span>
          <span *ngIf="row.creationDate">{{row.creationDate | date: 'shortDate'}}</span>
        </td>
      </ng-container>
      
      <!-- Star Column -->
      <ng-container matColumnDef="star" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <div class="btn-group">

            <button i18n-matTooltip mat-flat-button class="secondary-text" *ngIf="!readonly"
              matTooltip="Retirer cet élément"
              (click)="$event.stopPropagation(); removeItem(row)">
              <mat-icon>remove_circle_outline</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)='downloadItem(row)' ></tr>
    </table>
  </div>

  <button i18n-matTooltip mat-flat-button class="primary button-small"
    *ngIf="!readonly && (maxCount === -1 || items.length<maxCount)"
    (click)="addNewItem();"
    matTooltip="Ajouter un nouvel élement">
    <mat-icon>add</mat-icon> <span i18n>Ajouter</span>
  </button>
</div>
