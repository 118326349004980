export enum QueryResultModes {

    // Full.
    Full = "Full",

    // Partial.
    Partial = "Partial",

    // Quick.
    Quick = "Quick"
}
