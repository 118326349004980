// Modules

import { AppRoutingModule } from './app-routing.module';

// Services

import { AuthGuardService } from '@services/app/auth-guard.service';
import { AuthService } from '@services/app/auth.service';
import { AgendaService } from '@services/business/agenda.service';
import { ContactsService } from '@services/business/contacts.service';
import { IamService } from '@services/app/iam.service';
import { ReferentielService } from '@services/business/referentiel.service';
import { DossiersService } from '@services/business/dossiers.service';
import { SystemService } from '@services/business/system.service';

// Components

import { AppComponent } from '@components/app.component';
import { AuthCallbackComponent } from '@components/home/auth-callback.component';
import { AppHeaderComponent } from '@components/nav/header/app-header.component';
import { NavBreadCrumbComponent } from '@components/nav/breadcrumb/nav-breadcrumb.component';
import { AppFooterComponent } from '@components/nav/footer/app-footer.component';
import { ConfirmationDialogComponent } from '@components/shared/dialogs/confirmation-dialog.component';
import { AboutDialogComponent } from '@components/shared/dialogs/about-dialog.component';
import { SharedModule } from './shared.module';
import { HomeComponent } from '@components/home/home.component';
import { AppIntroComponent } from '@components/nav/intro/app-intro.component';
import { AppService } from '@services/app/app.service';
import { PageHistoryService } from '@services/nav/page-history.service';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DomainService } from '@services/business/domain.service';
import { HttpConfigInterceptor } from '@services/interceptors/HttpConfigInterceptor';
import { LogoutComponent } from '@components/home/logout.component';
import { MasseService } from '@services/business/masse.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { PendingChangesGuardService } from '@services/nav/PendingChangesGuardService';
import { ExportService } from '@services/business/export.service';
import { TextDialogComponent } from '@components/shared/dialogs/text-dialog.component';
import { SettingsService } from '@services/business/settings.service';
import { NgChartsModule } from 'ng2-charts';
import { StatsService } from '@services/business/stats.service';

export function initializeApp(appService: AppService) {
  return () => appService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    AuthCallbackComponent,
    LogoutComponent,

    HomeComponent,

    AppIntroComponent,
    AppHeaderComponent,
    ConfirmationDialogComponent,
    AboutDialogComponent,
    NavBreadCrumbComponent,
    AppFooterComponent,
    TextDialogComponent
  ],
  imports: [
    SharedModule,
    BrowserModule.withServerTransition({ appId: 'markmanager-ui' }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    AppRoutingModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppService, PageHistoryService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    AppService,
    AuthGuardService,
    PendingChangesGuardService,
    AuthService,
    PageHistoryService,
    
    AppService,
    AgendaService,
    ContactsService,
    DomainService,
    DossiersService,
    ExportService,
    IamService,
    MasseService,
    ReferentielService,
    SettingsService,
    SystemService,
    StatsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
