import { Component } from '@angular/core';
import { AppRole } from '@models/enums/iam/AppRole';

@Component({
  selector: 'pane-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  AppRoles = AppRole;

  constructor() {
  }
}
