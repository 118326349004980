import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PolicyExtension } from '../app/policy-extension';
import { User, UserManager, UserManagerSettings } from 'oidc-client';
import { StringHelper } from '@services/helpers/string-helper';
import { AppService } from './app.service';

@Injectable()
export class AuthService {

  private baseUri: string;
  private user: User = null;
  private _manager: UserManager = null;

  public static __AppToken:string = "Fidal_MarkManager_";

  private authenticatedSubjectChanged: BehaviorSubject<boolean>;

  constructor(
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.baseUri = window.location.origin;

    this.authenticatedSubjectChanged= new BehaviorSubject(false);
  }

  private manager(): UserManager {
    get: {
      if (!this._manager)
      {
        this._manager = new UserManager(this.getClientSettings());

        this._manager.events.addUserLoaded(user => {

          this.handleUser(user);

          this._manager.events.addSilentRenewError(e => {
            console.log("Silent renew error", e.message);
          });
      
          this._manager.events.addAccessTokenExpiring(() =>
          {
            console.log("Token expiring...");
          });
      
          this._manager.events.addAccessTokenExpired(() => {
            console.log("Token expired");
          });
        });
      }

      return this._manager;
    }
  }

  public isAuthenticatedSubjectChanged(): BehaviorSubject<boolean> {
    return this.authenticatedSubjectChanged;
  }

  public getUserName()
  {
    let userName = ((this.getClaims()?.FirstName ?? "") + ' ' + (this.getClaims()?.LastName ?? "")).trim();

    if (!userName)
    {
      userName = this.getClaims()?.name;
      let i = userName?.indexOf('@');
      if (i>0)
      {
        userName = userName.substring(0, i);
      }
    }

    return StringHelper.getTitle(userName, 25);
  }

  isLoggedIn(): boolean {
    return this.user != null && !this.user?.expired;
  }

  isClient(): boolean {    
    return this.getClaims()?.UserKind === '4';
  }

  hasPolicies(...policies: string[]): boolean {
    return this.hasPolicy(policies.map(q=>q).join(','));
  }

  hasPolicy(policy: string): boolean {
    return PolicyExtension.isValid(this, policy);
  }

  hasRoles(...roles: string[]): boolean {
    return this.hasRole(roles.map(q=>q).join(','));
  }

  hasRole(role: string): boolean {
    let roleString = this.user?.profile?.role?.toString();
    let b = roleString?.split(',')
      .some(q=> role?.split(',').some(p=> q.trim().toLowerCase() === (AuthService.__AppToken + p.trim()).toLowerCase())) && !this.user?.expired;
    return b;
  }

  getClaims(): any {
    return this.user?.profile;
  }

  getUserId(): string {
    return this.getClaims()?.sub;
  }

  getAuthorizationHeaderValue(): string {
    return `${this.user?.token_type} ${this.user?.access_token}`;
  }

  startAuthentication(): Promise<void> {

    return this.manager().signinRedirect();
  }

  completeAuthentication(): Promise<void> {
    
    return this.manager().signinRedirectCallback().then();      
  }

  private handleUser(user : User) {
    this.user = user;
    this.authenticatedSubjectChanged.next(true);
  }

  getUrl_accessDenied(): string {
    let settings = this.getClientSettings();
    return settings["authority"] + "/account/access-denied";
  }

  logout(): void {

    var args = {      
      id_token_hint: this.user.id_token,
      redirect_uri: this.baseUri
    };

    this.manager().removeUser()
      .then(_ => {
        this.manager().clearStaleState().then(() => {
          this.user = null;
          sessionStorage.clear();
          this.authenticatedSubjectChanged.next(false);

          this.manager().stopSilentRenew();
          this.manager().clearStaleState();
          this.manager().signoutRedirect(args)
          .catch((reason) => {
            console.error(reason)
          });
        });
      })
      .catch((reason) => {
        console.error(reason)
      });
  }

  public getClientSettings(): UserManagerSettings {
    return {
      authority: AppService.__Settings.platform.identityBaseUri,
      client_id: AppService.__Settings.platform.moduleId,
      redirect_uri: `${this.baseUri}${environment.production ? '/' + this.locale: ''}/auth-callback`,
      post_logout_redirect_uri: `${this.baseUri}${environment.production ? '/' + this.locale: ''}`,
      response_type: 'code',
      scope: "openid profile roles fidal " + AppService.__Settings.platform.services[0].name + "_webapi",
      filterProtocolClaims: true,
      loadUserInfo: true,
      
      automaticSilentRenew: true,
      silentRequestTimeout: 10000,
      //checkSessionInterval : 2000,
      accessTokenExpiringNotificationTime: 10,
      silent_redirect_uri: `${this.baseUri}${environment.production ? '/' + this.locale: ''}/assets/silent-callback.html`
    };
  }
}