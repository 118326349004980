export enum EvenementTypes {

    // Undefined.
    Undefined = "Undefined",

    // Officiel.
    Officiel = "Officiel",

    // Interne.
    Interne = "Interne",

    // Déclaré officiel.
    OfficielDeclare = "OfficielDeclare",

    // Officiel ou déclaré officiel
    Officiel_OfficielDeclare = "Officiel_OfficielDeclare",

    // Any.
    Any = "Any"
}
