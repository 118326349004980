<h2 i18n mat-dialog-title>Interlocuteur</h2>

<form #interlocuteurForm="ngForm" (ngSubmit)="$event.preventDefault()">
  <div mat-dialog-content class="small" *ngIf="interlocuteur">
    <div class="row">
      <flags-selector i18n-label class="col-2" [(itemId)]="interlocuteur.personneTitre"
        [type]="FlagTypes.PersonneTitres" appearance="outline"
        [required]="true" [readonly]="readonly" label="Civilité"></flags-selector>

      <mat-form-field class="col-5">
        <mat-label i18n>Nom</mat-label>
        <input name="nom" #inputNom="matInput" matInput [(ngModel)]="interlocuteur.nom" required (keyup.enter)="save()" [readonly]="readonly">
      </mat-form-field>
      <mat-form-field class="col-5">
        <mat-label i18n>Prénom</mat-label>
        <input name="prenom" matInput [(ngModel)]="interlocuteur.prenom" (keyup.enter)="save()" [readonly]="readonly">
      </mat-form-field>
    </div>

    <div class="row mb-1">
      <mat-form-field class="col-6">
        <mat-label i18n>Fonction</mat-label>
        <input name="fonction" matInput [(ngModel)]="interlocuteur.fonction" (keyup.enter)="save()" [readonly]="readonly">
      </mat-form-field>
    </div>

    <div class="row mb-1">
      <mat-form-field class="col-6">
        <mat-label i18n>Email</mat-label>
        <input name="email" matInput [(ngModel)]="interlocuteur.email" (keyup.enter)="save()" [readonly]="readonly">
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field class="col-6">
        <mat-label i18n>Téléphone fixe</mat-label>
        <input name="telephone" matInput [(ngModel)]="interlocuteur.telephone" (keyup.enter)="save()" [readonly]="readonly">
      </mat-form-field>
      <mat-form-field class="col-6">
        <mat-label i18n>Téléphone mobile</mat-label>
        <input name="mobile" matInput [(ngModel)]="interlocuteur.mobile" (keyup.enter)="save()" [readonly]="readonly">
      </mat-form-field>
    </div>
  </div>

  <mat-dialog-actions align="end" class="pt-3">
    <button i18n mat-flat-button class="secondary-text" mat-dialog-close *ngIf="!readonly">ANNULER</button>
    <button i18n i18n-matTooltip mat-raised-button class="primary" (click)="save()" [disabled]="checkForm"
      matTooltip="Enregistrer les modifications" *ngIf="!readonly" [disabled]="!interlocuteur.nom">ENREGISTRER</button>
    <button i18n mat-flat-button class="primary" mat-dialog-close *ngIf="readonly">OK</button>
  </mat-dialog-actions>
</form>