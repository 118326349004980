import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IUtilisateurDto } from '@models/dtos/management/securite/IUtilisateurDto';
import { ConfirmationDialogComponent } from '@components/shared/dialogs/confirmation-dialog.component';
import { GestionnairesSearchDialogComponent } from './gestionnaires-search-dialog.component';
import { ClientsAccesTypes } from '@models/enums/domain/ClientsAccesTypes';

@Component({
  selector: 'gestionnaires-listview',
  templateUrl: './gestionnaires-listview.component.html'
})
export class GestionnairesListViewComponent implements OnChanges {

  @Input('class')
  public class: string;
  
  @Input()
  public items: IUtilisateurDto[];

  @Output()
  public itemsChange: EventEmitter<IUtilisateurDto[]> = new EventEmitter<IUtilisateurDto[]>();

  @Output()
  public selectionChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Input('clientsAccesType')
  public clientsAccesType: ClientsAccesTypes = ClientsAccesTypes.Any;

  @Input('excluding')
  public excludingGestionnaires: IUtilisateurDto[] = [];
 
  @Input('maxCount')
  public maxCount: number = -1;

  @Input('readonly')
  public readonly: boolean = false;

  constructor(
    private dialog: MatDialog
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty("items")) {
      this.refreshItems();
    }
  }

  async refreshItems()
  {
    this.setItems(this.items, false);
  }

  async addItem()
  {
    if (!this.readonly)
    {
      if (this.maxCount === -1 || this.items?.length<this.maxCount)
      {
        const dialogRef = this.dialog.open(
          GestionnairesSearchDialogComponent,
          {
            panelClass: ['w-50'],
            data: {
              clientsAccesType: this.clientsAccesType,
              excluding: [...this.items, ...this.excludingGestionnaires],
              maxCount: this.maxCount
            },
            autoFocus: false,
          });
    
        dialogRef.afterClosed().subscribe(result => {
          if(result)
          {
            let gestionnaires: IUtilisateurDto[] = [];

            if (this.maxCount===1)
            {
              gestionnaires=result;
            }
            else
            {
              gestionnaires = this.items;
              let newGestionnaires = result;
              newGestionnaires
                .filter(q=> !(gestionnaires.find(p=>p.id === q.id)))
                .forEach(q=> { gestionnaires.push(q); });  
            }

            this.setItems(gestionnaires);
          }
        });  
      }
    }  
  }
  
  async removeItem(utilisateur: IUtilisateurDto)
  {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: $localize `Voulez-vous vraiment retirer cet élément ?`
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {    
        let gestionnaires = this.items;
        let index = gestionnaires.indexOf(utilisateur);
        if (index > -1) {
          gestionnaires.splice(index, 1);
          this.setItems(gestionnaires);
        }
      }
    });
  }

  private setItems(gestionnaires: IUtilisateurDto[], emit: boolean = true)
  {
    this.items = gestionnaires?.filter(q=> !(this.excludingGestionnaires?.find(p=>p.id === q.id)))    
      .sort((a, b)=> a.nom?.toLowerCase().localeCompare(b.nom?.toLowerCase()));

    if (emit)
    {
      this.itemsChange.emit(this.items);
      this.selectionChange.emit(true);
    }
  }
}
