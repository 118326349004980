export enum IntervenantTypes {
    
    // Undefined.
    Undefined = "Undefined",

    // Correspondant.
    Correspondant = "Correspondant",

    // Déposant.
    Deposant = "Deposant",

    // Adverse partie.
    AdversePartie = "AdversePartie",
    
    // Titulaire.
    Titulaire = "Titulaire",

    // Any.
    Any = "Any"
}
