import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IInterlocuteurDto } from '@models/dtos/runtime/contacts/IInterlocuteurDto';
import { FlagType } from '@models/enums/domain/FlagType';
import { KeyCodes } from '@models/enums/KeyCodes';
import { MessageKind } from '@models/enums/MessageKind';
import { ContactsService } from '@services/business/contacts.service';
import { SnackBarExtension } from '@services/nav/snackbar-extension';

@Component({
  selector: 'interlocuteur-view-dialog',
  templateUrl: './interlocuteur-view-dialog.component.html'
})
export class InterlocuteurViewDialogComponent implements OnInit, AfterViewInit {

  FlagTypes = FlagType;
  
  viewInitialized: boolean = false;

  @ViewChild('interlocuteurForm') interlocuteurForm: NgForm;
  @ViewChild('inputNom') inputNom: MatInput;

  @Input('item')
  public interlocuteur: IInterlocuteurDto;

  @Input('readonly')
  public readonly: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<InterlocuteurViewDialogComponent>,
    private snackBar: MatSnackBar,
    private contactsService: ContactsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef.disableClose = true;

    this.interlocuteur = data.interlocuteur;
    this.readonly = data.readonly;
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.inputNom.focus();
      this.viewInitialized = true;
    });
  }

  get checkForm(): string | null
  {
    let result = this.contactsService.checkInterlocuteur(this.interlocuteur);

    return result?.message;
  }

  save()
  {
    let message = "";
    if (message = this.checkForm)
    {
      SnackBarExtension.open(this.snackBar, message, MessageKind.Warning);
    }
    else
    {
      this.dialogRef.close(this.interlocuteur);
    }
  }
}
