<div class="screen-center on-top" *ngIf="isSearching">
  <mat-spinner></mat-spinner>
</div>

<div class="col-12 mp-0" class="dossiersTotalCount>0">
  <div class="col-md-12 col-sm-12 col-12">
    <entities-selector [type]="EntityTypes.Client" i18n-label class="col-12 mp-0" [(item)]="requestForm.client"
      [readonly]="isSearching" (selectionChange)="refresh();"
      [maxCount]="-1" appearance="standard" [required]="true" label="Client"></entities-selector> 
  </div>

  <div class="pt-3" style="margin: auto; max-width: 30em; ">
    <canvas baseChart
            [data]="chartData"
            [type]="chartType"
            [options]="chartOptions"
            [plugins]="chartPlugins">
    </canvas>
  </div>
</div>