export enum AppRole {

        /// <summary>
        /// Any.
        /// </summary>
        Any = "*",

        /// <summary>
        /// Member.
        /// </summary>
        Member = "Member",

        /// <summary>
        /// Visitor.
        /// </summary>
        Visitor = "Visitor",

        /// <summary>
        /// Manager.
        /// </summary>
        Manager = "Manager",
        
        /// <summary>
        /// Admin.
        /// </summary>
        Admin = "Admin",

        /// <summary>
        /// Super.
        /// </summary>
        Super = "Super"
}
