import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { StorageKeys } from '@models/app/StorageKeys';
import { IDataPresenterSpecDto } from '@models/dtos/data/export/IDataPresenterSpecDto';
import { IFieldSpecDto } from '@models/dtos/data/export/IFieldSpecDto';
import { IMasseRequestFormDto_DossiersMiseAJour } from '@models/dtos/data/masse/IMasseRequestFormDto_DossiersMiseAJour';
import { DataValueTypes } from '@models/enums/DataValueTypes';
import { ExportFormats } from '@models/enums/domain/ExportFormats';
import { ExportType } from '@models/enums/domain/ExportType';
import { MessageKind } from '@models/enums/MessageKind';
import { OrderByKinds } from '@models/enums/OrderByKinds';
import { IOrderByStatementDto } from '@models/dtos/data/queries/IOrderByStatementDto';
import { IRequeteRequestFormPoco } from '@models/pocos/forms/IRequeteRequestFormPoco';
import { ExportService } from '@services/business/export.service';
import { MasseService } from '@services/business/masse.service';
import { ExportHelper } from '@services/helpers/export-helper';
import { UtilsHelper } from '@services/helpers/utils-helper';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { ColumnsExportDialogComponent } from './columns/columns-export-dialog.component';
import { ConfirmationDialogComponent } from '@components/shared/dialogs/confirmation-dialog.component';
import { ColumnsSelectDialogComponent } from '@components/shared/requetes/columns/columns-select-dialog.component';
import { ITSearchRequestPoco } from '@models/pocos/ITSearchRequestPoco';
import { IGlobalRequeteRequestFormPoco } from '@models/pocos/forms/IGlobalRequeteRequestFormPoco';
import { StorageHelper } from '@services/helpers/storage-helper';
import { ConditionsHelper } from '@services/helpers/conditions-helper';
import { ActivatedRoute } from '@angular/router';
import { AppRole } from '@models/enums/iam/AppRole';
import { AppPolicy } from '@models/enums/iam/AppPolicy';
import { DossierStatuts } from '@models/enums/domain/DossierStatuts';
import { GlobalVariables } from '@models/app/GlobalVariables';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'liveview',
  templateUrl: './liveview.component.html'
})
export class LiveViewComponent implements OnChanges {

  ExportFormats = ExportFormats;
  ExportTypes = ExportType;
  AppRoles = AppRole;
  AppPolicies = AppPolicy;

  isSearching :boolean;
  isExporting :boolean;
  isMassFunctionsRunning: boolean;

  selection = new SelectionModel<any>(true, []);

  @Output("isSearching")
  public isSearchingChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  public readonly __orderedColumnsMaxCount = GlobalConstants.orderedColumnsMaxCount;

  pageSizeOptions: number[] = GlobalConstants.pageSizeOptions_List;

  displayedColumns: string[] = [];
  displayedColumnSpecs: IFieldSpecDto[];
  dataSource: MatTableDataSource<any>;

  backPage: boolean = false;

  itemsCount: number;

  items: any[];
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  @Input('searchAck')
  searchAck: boolean | null = null;

  @Input('exportType')
  exportType: ExportType = ExportType.Undefined;

  @Input('globalRequestForm')
  globalRequestForm: IGlobalRequeteRequestFormPoco = <IGlobalRequeteRequestFormPoco> {};

  requestForm: ITSearchRequestPoco<IRequeteRequestFormPoco>;
  readonly defaultRequestForm: ITSearchRequestPoco<IRequeteRequestFormPoco> = <ITSearchRequestPoco<IRequeteRequestFormPoco>> { formPoco: <IRequeteRequestFormPoco>{}};

  @Input('presenterSpec')
  pSpec: IDataPresenterSpecDto;

  @Input('class')
  public class: string;

  @Output("viewItem")
  public viewItemChange: EventEmitter<any> = new EventEmitter<any>();

  @Output('itemsCountChange')
  itemsCountChange: EventEmitter<number> = new EventEmitter<number>();

  @Output('filterChanged')
  filterChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private masseService: MasseService,
    private exportService: ExportService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    route: ActivatedRoute) {

      route.queryParams.subscribe(params => {

        if (params.backPage != undefined)
        {
          this.backPage = params.backPage;
        }
      });      
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty("pSpec")) {

      this.initRequestForm();
      this.search(!this.backPage);
    }

    if (changes.hasOwnProperty("searchAck")) {

      if (this.searchAck!=null) {
        this.search(!this.backPage);
      }
    }    
  }

  getStorageKey(): string {
    
    return StorageKeys.__formToken_Requetes + this.exportType.toString();
  }

  async initRequestForm(excludeStorage: boolean = false)
  {
    this.requestForm = StorageHelper.getSessionItem(
      this.getStorageKey(),
      this.defaultRequestForm,
      !excludeStorage
    );

    UtilsHelper.repairSearchRequestPoco(this.requestForm, GlobalConstants.pageSizeOptions_List);
  }

  // Refresh ---------------

  changePage() {
    UtilsHelper.updateDataPageInfo(this.requestForm?.formPoco, this.paginator);

    this.search(false);
  }

  searching(isSearching: boolean) {
    this.isSearching = isSearching;
    this.isSearchingChanged.emit(isSearching);
  }

  async refreshItems()
  {
    this.displayedColumnSpecs = !this.pSpec?.columns ? [] : (this.requestForm?.formPoco.columns?.length > 0 ?
      this.requestForm?.formPoco.columns
        .map(q=> this.pSpec?.columns.find(p=> p.name?.toLowerCase() === q?.toLowerCase()))
        .filter(q=> !!q) :
      [...this.pSpec.columns.filter(q=>q.isDefault)]);      
    
    this.displayedColumnSpecs = this.displayedColumnSpecs.filter(q=> !q.isHidden && q.valueType != DataValueTypes.Object && q.valueType != DataValueTypes.ByteArray);
    this.displayedColumns = [...[],
      ...this.displayedColumnSpecs.map(q=>q.name), ...['star']];

    this.dataSource = new MatTableDataSource<any>(this.items);
  }

  // View item

  async viewItem(item: any)
  {
    this.viewItemChange.emit(item);
  }

  async selectItem(item: any)
  {    
    this.selection.clear();

    this.selection.select(item);
  }

  // Sorting ---------------

  private updateColumnIndex(index: number): number {
    let newIndex = index;
    for(let i =0;i<index;i++) {
      let columnName = this.requestForm.formPoco.columns[i];
      let spec = this.pSpec.columns.find(p=> p?.name.toLowerCase() === columnName.toLowerCase());
      if (spec?.isKey != true && (spec?.valueType === DataValueTypes.Object || spec?.valueType === DataValueTypes.ByteArray)) {
        newIndex++;
      }
    }

    return newIndex;
  }

  drop(event: CdkDragDrop<string[]>) {

    if (this.requestForm?.formPoco?.columns)
    {
      let previousIndex = this.updateColumnIndex(event.previousIndex);
      let currentIndex = this.updateColumnIndex(event.currentIndex);
      moveItemInArray(this.requestForm.formPoco.columns, previousIndex, currentIndex);

      this.refreshItems();
    }
  }

  getOrderKind(field: IFieldSpecDto): string
  {
    let orderByStatements: IOrderByStatementDto[] = this.requestForm.orderByStatements;
    let orderKind = orderByStatements?.find(p=> p?.columnName?.toLowerCase() === field?.name.toLowerCase())?.kind ?? OrderByKinds.None;

    switch(orderKind)
    {
      case OrderByKinds.Ascending:
        return "asc";
      case OrderByKinds.Descending:
        return "desc";
      case OrderByKinds.None:
        return this.pSpec.orderByColumnNames.find(p=> p?.toLowerCase() === field?.name.toLowerCase()) ? "" : null;
    }
  }

  getOrderRank(field: IFieldSpecDto): number
  {
    let orderByStatements: IOrderByStatementDto[] = this.requestForm.orderByStatements;
    let orderSort = orderByStatements?.map(p=> p?.columnName?.toLowerCase()).indexOf(field?.name.toLowerCase());
    return orderSort ?? -1;
  }

  toggleOrderKind(field: IFieldSpecDto)
  {
    let orderByStatements: IOrderByStatementDto[] = this.requestForm.orderByStatements;
    let orderByStatement = orderByStatements?.find(p=> p?.columnName?.toLowerCase() === field?.name.toLowerCase());

    if (orderByStatement)
    {
      switch(orderByStatement?.kind)
      {
        case OrderByKinds.Ascending:
          orderByStatement.kind = OrderByKinds.Descending;
          break;
        case OrderByKinds.Descending:
          this.requestForm.orderByStatements = this.requestForm.orderByStatements
            .filter(p=> p?.columnName?.toLowerCase() != field?.name.toLowerCase());
          break;
        default:
          orderByStatement.kind = OrderByKinds.Ascending;
          break;
      }
    }
    else
    {
      this.requestForm.orderByStatements ??= [];
      this.requestForm.orderByStatements = this.requestForm.orderByStatements.slice(0, 2);
      this.requestForm.orderByStatements.push(<IOrderByStatementDto> { columnName: field?.name, kind: OrderByKinds.Ascending});
    }

    this.search(false);
  }

  toggleOrderRank()
  {
    this.requestForm.orderByStatements ??= [];
    let orderByStatements: IOrderByStatementDto[] = this.requestForm.orderByStatements;
    orderByStatements.unshift(orderByStatements.pop());

    this.search(false);
  }

  // Search ---------------

  setRequestForm(requestForm: IGlobalRequeteRequestFormPoco)
  {
    // we keep columns and order statement
    let pageSize = this.requestForm?.formPoco?.pageSize;
    let pageIndex = this.requestForm?.formPoco?.pageIndex;
    let orderByStatements = this.requestForm?.orderByStatements;
    let columns = this.requestForm?.formPoco?.columns;

    this.requestForm = UtilsHelper.clone<ITSearchRequestPoco<IRequeteRequestFormPoco>>(requestForm);
    this.requestForm.formPoco.format = ExportFormats.Json;
    this.requestForm.formPoco.exportType = this.exportType;
    this.requestForm.formPoco.pageSize = pageSize;
    this.requestForm.formPoco.pageIndex = pageIndex;
    this.requestForm.formPoco.columns = columns;
    this.requestForm.orderByStatements = orderByStatements;

    this.requestForm.formPoco.columns = columns;
    if (!this.requestForm.formPoco.columns)
    {
      this.requestForm.formPoco.columns = this.pSpec.columns?.filter(q=>q.isDefault == true).map(q=>q.name);
    }
    this.requestForm.formPoco.columns = ConditionsHelper.getFilteredColumns(this.requestForm.formPoco.columns, this.pSpec, true);
    this.requestForm.orderByStatements = this.requestForm.orderByStatements?.filter(q=>this.requestForm?.formPoco.columns.find(p=> q.columnName?.toLowerCase() === p?.toLowerCase()));

    UtilsHelper.repairSearchRequestPoco(this.requestForm, GlobalConstants.pageSizeOptions_List);    
  }

  search(initPageIndex: boolean = false) {
   
    if (this.isSearching) return;
 
    this.searching(true);
    this.setRequestForm(this.globalRequestForm);
    if (initPageIndex)
    {
      this.requestForm.formPoco.pageIndex = 0;
    }
    let searchRequestForm = ExportHelper.toDto(this.requestForm, this.pSpec?.columns);

    this.exportService.exportItems(searchRequestForm).subscribe(result => {
      this.items = result.items;

      UtilsHelper.updateDataPageInfo(this.requestForm.formPoco, result);

      this.itemsCount = result.totalCount;
      this.itemsCountChange.emit(this.itemsCount);
      this.refreshItems();

      StorageHelper.setSessionItem(this.getStorageKey(), this.requestForm);

      this.searching(false);
    }, error => {
      SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
      this.searching(false);
    });
  }
  
  // Define columns ---------------

  defineColumns() {

    const dialogRef = this.dialog.open(
      ColumnsSelectDialogComponent,
      {
        panelClass: 'w-50',
        data: {
          spec: this.pSpec,
          selectedColumnNames: this.requestForm.formPoco.columns
        },
        autoFocus: false
      });
    
    dialogRef.afterClosed().subscribe(result => {

      if (result)
      {
        this.requestForm.formPoco.columns = result.columns;

        StorageHelper.setSessionItem(this.getStorageKey(), this.requestForm);

        if (this.itemsCount>0)
        {
          this.search();
        }
      }
    });
  }

  // Set ------------------

  setRetourneAbandonnes(retourneAbandonnes: boolean) {
    this.globalRequestForm.formPoco.retourneAbandonnes= retourneAbandonnes;
    this.filterChanged.emit(true);
  }

  setRetourneEIs(retourneEIs: boolean) {
    this.globalRequestForm.formPoco.retourneEIs= retourneEIs;
    this.filterChanged.emit(true);
  }

  // Export ---------------

  async export()
  {
    let selectedColumnNames= this.requestForm.formPoco?.columns;

    const dialogRef = this.dialog.open(
      ColumnsExportDialogComponent,
      {
        panelClass: 'w-50',
        data: {
          exportType: this.exportType,
          format: GlobalVariables.getExportFormat(),
          selectedColumnNames: selectedColumnNames
        },
        autoFocus: false
      });

      
      dialogRef.afterClosed().subscribe(result => {

        if(result)
        {
          let exportForm: IRequeteRequestFormPoco = ExportHelper.toDto(
            ExportHelper.toExportRequestForm<IRequeteRequestFormPoco>(
              this.exportType,
              result.format,
              this.requestForm,
              result), this.pSpec?.columns);

          // this.requestForm.formPoco.columns = result.columns;
          // StorageHelper.setSessionItem(this.getStorageKey(), this.requestForm);
          StorageHelper.setSessionItem(StorageKeys.__exportFormat, result.format);

          this.isExporting = true;
          this.exportService.exportItems(exportForm).subscribe({
            next: (result: any) => {
              UtilsHelper.downloadFile(result);
              this.isExporting = false;
            },
            error: _ => {
              SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du téléchargement`, MessageKind.Exception);
              this.isExporting = false;
            }
          });
        }
    });

  }

  // Mass ---------------

  abandonner()
  {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: $localize `Voulez-vous vraiment lancer cette opération en masse ?`
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.isMassFunctionsRunning= true;
    
        let exportForm: IRequeteRequestFormPoco = ExportHelper.toDto(
          ExportHelper.toExportRequestForm<IRequeteRequestFormPoco>(
            this.exportType,
            ExportFormats.Undefined,
            this.requestForm,
            { columns: ['Identifiant']}), this.pSpec?.columns);

        let masseRequestForm = <IMasseRequestFormDto_DossiersMiseAJour> {
           dossiersRequestForm: exportForm,
           statut: DossierStatuts.Abandonne
        };

        this.masseService.metajourDossiers(masseRequestForm).subscribe({
          next: result => {    
            SnackBarExtension.open(this.snackBar, $localize `Opération réussie`, MessageKind.Information);
            this.isMassFunctionsRunning= false;

            this.search(true);
          },
          error: _ => {
            SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors de l'opération`, MessageKind.Error);
            this.isMassFunctionsRunning= false;
          }
        });
      }
    });
  }
}
