import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IClientDto } from '@models/dtos/runtime/contacts/IClientDto';
import { ClientTypes } from '@models/enums/domain/ClientTypes';
import { PersonneTypes } from '@models/enums/domain/PersonneTypes';
import { ConfirmationDialogComponent } from '@components/shared/dialogs/confirmation-dialog.component';
import { PageHistoryService } from '@services/nav/page-history.service';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { StringHelper } from '@services/helpers/string-helper';
import { IClientAccesDto } from '@models/dtos/runtime/contacts/IClientAccesDto';
import { ClientsSearchDialogComponent } from '../clients/clients-search-dialog.component';
import { ClientsAccesTypes } from '@models/enums/domain/ClientsAccesTypes';
import { FlagType } from '@models/enums/domain/FlagType';
import { ReferentielService } from '@services/business/referentiel.service';
import { IFlagsRequestFormPoco } from '@models/pocos/forms/IFlagsRequestFormPoco';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageKind } from '@models/enums/MessageKind';
import { ActionKind } from '@models/enums/ActionKind';

@Component({
  selector: 'client-access-listview',
  templateUrl: './client-access-listview.component.html'
})
export class ClientAccessListViewComponent implements AfterViewInit, OnChanges {

  FlagTypes = FlagType;
  ClientsAccesTypes = ClientsAccesTypes;
  ActionKinds = ActionKind;

  @Input('class')
  public class: string;
  
  @Input('pageSize')
  pageSize: number;

  motcle: string;

  displayedColumns: string[] = ['nom', 'clientsAccesType', 'star'];
  dataSource: MatTableDataSource<IClientAccesDto>;

  clientsAccesTypes: any = {};

  showPaginator: boolean = true;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  @Input()
  public items: IClientAccesDto[];

  @Output()
  public itemsChange: EventEmitter<IClientAccesDto[]> = new EventEmitter<IClientAccesDto[]>();

  @Input('searchable')
  public searchable: boolean = true;

  @Input('excluding')
  public excludingItems: IClientDto[] = [];

  @Input('clientsAccesType')
  public clientsAccesType: ClientsAccesTypes = ClientsAccesTypes.Herite;  

  @Input('personneType')
  public personneType: PersonneTypes = PersonneTypes.Any;

  @Input('clientType')
  public clientType: ClientTypes.Any;

  @Input('maxCount')
  public maxCount: number = -1;

  @Input('parentId')
  public parentId: number | null = null;

  @Input('additionRestricted')
  public additionRestricted: boolean;  

  @Input('readonly')
  public readonly: boolean = false;

  @Input('required')
  public required: boolean = false;

  @Output("valid")
  public validChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public selectionChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  get valid(): boolean | null
  {
    let b = !this.required || (this.items?.length>0);
    return b;
  }

  constructor(
    private referentielService: ReferentielService,
    private pageHistoryService: PageHistoryService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.pageSize ??= GlobalConstants.pageSizeOptions_Dialog[0];
  }

  ngAfterViewInit() {
    this.updatePaginator();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty("items")) {
      this.refreshItems();
    }

    if (changes.hasOwnProperty("clientsAccesType")) {
      this.refreshFlags();
    }
  }

  hasChanged() {    
    this.selectionChange.emit(true);
  }

  async refreshFlags() {

    // ClientsAccesTypes

    let excludingClientsAccesTypes = this.clientsAccesType === ClientsAccesTypes.AssistantGestionnaire
      || this.clientsAccesType === ClientsAccesTypes.PrincipalGestionnaire ?
      [ClientsAccesTypes.AssistantGestionnaire, ClientsAccesTypes.PrincipalGestionnaire, ClientsAccesTypes.Herite] :
      [ClientsAccesTypes.Herite];

    this.referentielService.listFlags(<IFlagsRequestFormPoco> { flagType: FlagType.ClientsAccesTypes }).subscribe(result => {

      this.clientsAccesTypes = {};
      result.filter(q=> excludingClientsAccesTypes.find(p=>q.key===p)).forEach(q=>
        {
        this.clientsAccesTypes[q.key]=q.content;        
      });
    }, error => {
      SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
    });
  }

  async refreshItems()
  {
    this.setItems(this.items, false);

    let items = this.items?.filter(q=> (!this.motcle 
      || q?.client?.id?.toLowerCase().includes(this.motcle?.toLowerCase())
      || StringHelper.unaccent(q?.client?.denomination)?.toLowerCase().includes(StringHelper.unaccent(this.motcle)?.toLowerCase())));

    this.dataSource = new MatTableDataSource<IClientAccesDto>(items);

    this.updatePaginator();
  }

  async updatePaginator()
  {
    this.showPaginator = this.items?.length > this.pageSize;
    if (this.dataSource)
    {
      this.dataSource.paginator = this.paginator;
    }
  }

  async addItem()
  {
    if (!this.readonly)
    {
      if (this.maxCount === -1 || this.items?.length<this.maxCount)
      {
        const dialogRef = this.dialog.open(
          ClientsSearchDialogComponent,
          {
            panelClass: ['w-50', 'zoomed'],
            data: {
              clientType: this.clientType,
              excluding: [...this.items.map(q=>q.client), ...this.excludingItems],
              maxCount: this.maxCount,
              personneType: this.personneType,
              parentId: this.additionRestricted ? this.parentId : null,
              readonly: false
            },
            autoFocus: false,
          });
    
        dialogRef.afterClosed().subscribe(result => {
          if(result)
          {
            let clientAcces: IClientAccesDto[] = [];

            if (this.maxCount===1)
            {
              clientAcces= [<IClientAccesDto> { client: result, clientsAccesType: ClientsAccesTypes.Herite }];
            }
            else
            {
              clientAcces = this.items;
              let newClients = result;
              newClients
                .filter(q=> !(clientAcces.find(p=>p.client?.id === q.id)))
                .forEach(q=> { clientAcces.push(<IClientAccesDto> { client: q, clientsAccesType: ClientsAccesTypes.Herite }); });
            }

            this.setItems(clientAcces);
          }
        });  
      }
    }
  }

  async addNewItem()
  {
    this.pageHistoryService.navigate(['/clients', this.parentId , 'clients', 'add']);
  }

  async viewItem(clientAcces: IClientAccesDto)
  {
    this.pageHistoryService.navigate(['/clients', clientAcces?.client?.id]);
  }
  
  async removeItem(clientAcces: IClientAccesDto)
  {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: $localize `Voulez-vous vraiment retirer cet élément ?`
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        let index = this.items.indexOf(clientAcces);
        if (index > -1) {
          this.items.splice(index, 1);
          this.setItems();
        }
      }
    });
  }

  private setItems(clientAccess: IClientAccesDto[] = null, emit: boolean = true)
  {
    this.items = (clientAccess ?? this.items)?.filter(q=> !(this.excludingItems?.find(p=>p.id === q.client?.id)))
      .sort((a, b)=> a.client?.denomination?.toLowerCase().localeCompare(b.client?.denomination?.toLowerCase()));

    if (emit)
    {
      this.itemsChange.emit(this.items);
      this.selectionChange.emit(true);
    }
  }
}
