import {Component} from '@angular/core';
import {Router, ActivatedRoute, RouterEvent, NavigationEnd} from '@angular/router';
import { BreadcrumbHelper } from '@services/helpers/breadcrumb-helper';
import { StringHelper } from '@services/helpers/string-helper';
import { UtilsHelper } from '@services/helpers/utils-helper';

@Component({
  selector: 'nav-breadcrumb',
  templateUrl: './nav-breadcrumb.component.html'
})
export class NavBreadCrumbComponent {

  readonly __LabelLengthMax: number = 50;

  public breadcrumbs: IBreadCrumb[];
  public breadcrumbObject: any;
  public pageTitle: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.pageTitle = null;
        this.breadcrumbs = this.buildBreadCrumbs(this.route.root);
      }
    });

    BreadcrumbHelper.BreadcrumbSubject.subscribe(
      p=> {
        this.breadcrumbObject = p;
        this.breadcrumbs = this.buildBreadCrumbs(this.route.root);
        this.breadcrumbObject = {};
      });
}

  buildBreadCrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {

    let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');
    
    if(route.snapshot) {
      if (isDynamicRoute)
      {
        const paramName = lastRoutePart.split(':')[1];
        var paramValue = route.snapshot?.params[paramName];
        path = path.replace(lastRoutePart, paramValue);  
      }

      if(this.breadcrumbObject) {

        Object.keys(this.breadcrumbObject).forEach(p=>
        {
         label = label.replace('{{' + p +'}}', this.breadcrumbObject[p]);
        });
      }
    }

    label = StringHelper.getTitle(label, this.__LabelLengthMax);

    let nextUrl = path ? `${url}/${path}` : url;

    const breadcrumb: IBreadCrumb = {
        label: label,
        url: nextUrl,
    };

    if (route.firstChild) {
      let label = route.firstChild.routeConfig?.data ? route.firstChild.routeConfig.data.breadcrumb : '';
      if (label)
      {
        let newBreadcrumbs = path ? [ ...breadcrumbs, breadcrumb ] : [ ...breadcrumbs];

        return this.buildBreadCrumbs(route.firstChild, nextUrl, newBreadcrumbs);
      }
    }

    let homeText = $localize `Accueil`;
    this.pageTitle= label === homeText ? "" : label;
    
    let newBreadcrumbs = nextUrl ? [<IBreadCrumb>{ label: homeText, url: "/" }, ...breadcrumbs] : [ ...breadcrumbs];
    if(!path)
    {
      newBreadcrumbs.pop();
    }

    return newBreadcrumbs;
  }
}

export interface IBreadCrumb {
  label: string;
  url: string;
}