import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourceStatus } from '@models/enums/ResourceStatus';
import { Location } from '@angular/common';

import {IContactTypeDto} from '@models/dtos/management/referentiel/IContactTypeDto';

import {ReferentielService} from '@services/business/referentiel.service';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { MessageKind } from '@models/enums/MessageKind';
import { PageHistoryService } from '@services/nav/page-history.service';
import { DictionaryDataItemsListViewComponent } from '@components/shared/common/global/dictionarydataitems-listview.component';
import { AppPolicy } from '@models/enums/iam/AppPolicy';

@Component({
  selector: 'pane-contacttypes-add-component',
  templateUrl: './contacttypes-add.component.html'
})
export class ContactTypesAddComponent implements OnInit {
  
  AppPolicies = AppPolicy;

  contactType: IContactTypeDto;

  @ViewChild('contactTypeForm') contactTypeForm: NgForm;
  @ViewChild('inputCode') inputCode: MatInput;
  @ViewChild('globalLibelleListView') globalLibelleListView: DictionaryDataItemsListViewComponent;  

  parentId: string;
    
  constructor(
    private referentielService: ReferentielService,
    private pageHistoryService: PageHistoryService,
    private location: Location,
    private snackBar: MatSnackBar,
    private router: Router,
    route: ActivatedRoute) {

    PageHistoryService.routeParams(route).subscribe(p=> {
      this.parentId = p.parentId;        
    });

    this.contactType = <IContactTypeDto> { };
    this.referentielService.repairContactType(this.contactType);
  }

  ngOnInit(): void { 

    this.pageHistoryService.popCurrent();    
  }

  async back() {
    let uri = this.pageHistoryService.popCurrent('/parameters/masterdata/contactkinds');
    this.pageHistoryService.navigateByUrl(uri);
  }

  get valid(): boolean | null
  {
    let b = this.contactTypeForm.valid && this.globalLibelleListView.valid;

    return b;
  }

  async addContactType() {

    if (!this.valid)
    {
      SnackBarExtension.open(this.snackBar, $localize `Information requise`, MessageKind.Warning);
      return;
    }

    this.referentielService.repairContactType(this.contactType);

    this.referentielService.addContactType(this.contactType) .subscribe(
      result => {
        if(result.status=== ResourceStatus.Created)
        {
          SnackBarExtension.open(this.snackBar, $localize `Création effectuée`, MessageKind.Success);

          this.pageHistoryService.navigate(['/parameters/masterdata/contactkinds', result.key]);
        }
      },
      error =>
      {
        if (error.status=="400")
        {
          if(error.error.find(q=>q.status==="409") != null)
          {
            SnackBarExtension.open(this.snackBar, $localize `Elément déjà existant`, MessageKind.Error);
            return;
          }
        }

        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors de la création`, MessageKind.Exception);
      });
  }
}
