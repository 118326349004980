<div class="mr-2 mb-2 image-box">
    <div class="image-box-image pointable" (click)="viewImage()">
        <img [src]="imageUrl" />
    </div>
    <div class="mp-0 text-center">
        <button i18n-matTooltip mat-icon-button class="secondary-text button-small" (click)="removeImage()" *ngIf="!readonly && item?.id"
            matTooltip="Retirer cet élément">
            <mat-icon>delete_outline</mat-icon>
        </button>
    </div>
</div>

