<div class="screen-center on-top" *ngIf="isSearching || isSaving">
  <mat-spinner></mat-spinner>
</div>

<div class="content-pane">

  <div i18n-matTooltip class="fidal-jumbotron mb-4" matTooltip="Gérez vos marques et vos contentieux">
    <span i18n class="title">Bienvenue sur Mark Manager</span>
  </div>

  <div class="inner">
    <div class="h-100-outer">

      <div class="inner-body area-dashboard mt-2">

        <div class="box-dashboard" style="width: 48%;" *ngFor="let bordTableau of bordTableaux">

          <div class="float-right on-top button-small">

            <button i18n-matTooltip class="classic ml-1" matTooltip="Choisir mes tableaux de bord" [routerLink]='["/parameters/my-dashboards"]'>
                <mat-icon>dashboards</mat-icon>
            </button>
    
            <button i18n-matTooltip class="normal ml-1" *ngIf="bordTableaux?.length > 1"
              matTooltip="Retirer l'élément" (click)="deleteBordTableau(bordTableau)">
              <mat-icon>delete_outline</mat-icon>
            </button>  
          </div>          

          <div class="text-center">
            <span class="title mt-1" [matTooltip]="bordTableau?.description">{{bordTableau?.libelle}}</span>
          </div>

          <div *ngIf="bordTableau?.id==='8edaa926-bd2f-46a2-b333-9695d0239343'">
            <div *requiresUserRoles="[AppRoles.Visitor, AppRoles.Manager, AppRoles.Admin, AppRoles.Super]">
              <delais-passes [readonly]="readonly"></delais-passes>
            </div>  
          </div>
      
          <div *ngIf="bordTableau?.id==='8ca5fc8d-964a-4b04-9f3d-bc5493628656'">
            <div *requiresUserRoles="[AppRoles.Visitor, AppRoles.Manager, AppRoles.Admin, AppRoles.Super]">
              <delais-semaine [readonly]="readonly"></delais-semaine>
            </div>
          </div>
      
          <div *ngIf="bordTableau?.id==='7a37b96a-3afd-4e24-abe7-4c0446950aeb'">
            <div>
              <dossiers-derniers i18n-title title="Mes dernières marques" [dossierNature]="DossierNatures.Marque"></dossiers-derniers>
            </div>
          </div>

          <div *ngIf="bordTableau?.id==='4aca77ef-f4f4-4b7c-bc02-44b0a107596a'">
            <div>
              <dossiers-derniers i18n-title title="Mes derniers modèles" [dossierNature]="DossierNatures.Modele"></dossiers-derniers>
            </div>
          </div>

          <div *ngIf="bordTableau?.id==='af94ccb9-fa4a-47ee-a5bd-9e128aa0f665'">
            <div>
              <marques-depots i18n-title title="Évolution des dépôts de mes marques"></marques-depots>
            </div>
          </div>

          <div *ngIf="bordTableau?.id==='ed5ba594-a2f8-4bec-a2ab-d811aaaa7c9f'">
            <div>
              <marques-registres i18n-title title="Répartition des registres de mes marques"></marques-registres>
            </div>
          </div>

          <div *ngIf="bordTableau?.id==='bae92ace-a007-4bc1-9eb6-d8878627f6c1'">
            <div>
              <marques-statuts i18n-title title="Répartition des statuts de mes marques"></marques-statuts>
            </div>
          </div>

          <div *ngIf="bordTableau?.id==='ca6cfc83-dc19-4e2c-8609-7fc623b5f449'">
            <div>
              <marques-zones i18n-title title="Répartition de mes marques par zone"></marques-zones>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
