<div class="screen-center on-top" *ngIf="isSearching || isMassFunctionsRunning">
  <mat-spinner></mat-spinner>
</div>

<div class="row ml-2 mr-2 inner-header">

  <div *requiresUserRoles="[AppRoles.Manager, AppRoles.Admin, AppRoles.Super]">
    <mat-checkbox i18n i18n-matTooltip name="retourneAbandonnes" class="pt-2"
      [ngModel]="globalRequestForm.formPoco.retourneAbandonnes" (ngModelChange)="setRetourneAbandonnes($event);"
      matTooltip="Afficher les dossiers abandonnés et rejetés"
      *ngIf="exportType!=ExportTypes.Contentieux && globalRequestForm.formPoco">
      Non en vigueur
    </mat-checkbox>
  </div>

  <mat-checkbox i18n i18n-matTooltip name="retourneEIs" class="pt-2 ml-4"
    [ngModel]="globalRequestForm.formPoco.retourneEIs" (ngModelChange)="setRetourneEIs($event);"
    matTooltip="Afficher les enregistrements internationaux"
    *ngIf="exportType===ExportTypes.MarqueDossiers && globalRequestForm.formPoco">
    EI
  </mat-checkbox>

  <div class="spacer-item"></div>

  <button i18n-matTooltip class="secondary mr-2" (click)="defineColumns()"
    matTooltip="Définir les colonnes">
    <mat-icon>toc</mat-icon>
  </button>

  <button i18n-matTooltip class="primary mr-2" [disabled]="isExporting"
    *ngIf="itemsCount" matTooltip="Exporter" (click)="export()">
    <mat-icon>vertical_align_bottom</mat-icon>
  </button>

  <div class="ml-1" *requiresUserPolicies="[AppPolicies.DossiersFullWriters]">
    <button i18n-matTooltip class="secondary" [matMenuTriggerFor]="massMenu" *ngIf="itemsCount" [disabled]="isMassFunctionsRunning"
      matTooltip="Lancer une fonction en masse">
      <mat-icon>donut_large</mat-icon>
    </button>
    <mat-menu #massMenu="matMenu" yPosition="below">
      <button i18n mat-menu-item (click)="abandonner()">Tout abandonner</button>
    </mat-menu>
  </div>
</div>

<div class="d-flex inner-top">
  <mat-paginator [pageIndex]="requestForm?.formPoco?.pageIndex" [length]="itemsCount" [pageSize]="requestForm?.formPoco?.pageSize"
    [pageSizeOptions]="pageSizeOptions" (page)="changePage()"></mat-paginator>  
</div>
<div class="inner-body" *ngIf="items?.length === 0">
  <span i18n class="pl-2">Aucun élément trouvé</span>
</div>

<div class="data-table text-small inner-body" *ngIf="displayedColumnSpecs && items?.length > 0">
  <table mat-table [dataSource]="dataSource" matSort
    cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">

    <!--column -->
    <ng-container *ngFor="let spec of displayedColumnSpecs" [matColumnDef]="spec.name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="toggleOrderKind(spec)" class="pointable"
        matTooltip="Déplacez cette colonne ou cliquez sur cette colonne pour la trier"
        cdkDropList cdkDrag cdkDropListLockAxis="x" cdkDropListOrientation="horizontal">
        <div class="pr-4 pl-4 row flex-nowrap">
          <span class="th-title" *ngIf="getOrderKind(spec)===null">{{spec.label}}</span>
          <span class="th-title" *ngIf="getOrderKind(spec)!=null">{{spec.label}}</span>
          <button mat-icon-button *ngIf="getOrderKind(spec)==='asc'"><mat-icon>expand_more</mat-icon></button>
          <button mat-icon-button *ngIf="getOrderKind(spec)==='desc'"><mat-icon>expand_less</mat-icon></button>
          <button mat-icon-button *ngIf="getOrderKind(spec)===''"><mat-icon>unfold_more</mat-icon></button>  
          <button mat-icon-button class="pt-0" *ngIf="requestForm?.orderByStatements?.length>1 && getOrderRank(spec)>-1" (click)="toggleOrderRank()">
            <mat-icon *ngIf="getOrderRank(spec)===0">looks_one</mat-icon>
            <mat-icon *ngIf="getOrderRank(spec)===1">looks_two</mat-icon>
            <mat-icon *ngIf="getOrderRank(spec)===2">looks_3</mat-icon>
          </button>
        </div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div class="pr-4 pl-4">{{row[spec.name]}}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="star" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <div class="btn-group float-right">

          <button i18n-matTooltip mat-flat-button class="primary-text ml-1 screen-md"
              matTooltip="Voir la fiche"
              (click)="$event.stopPropagation(); viewItem(row);">
            <mat-icon>pageview</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      [ngClass]="{'highlighted': selection?.selected.length === 1 && selection.isSelected(row)}"
      (dblclick)="viewItem(row)" (click)="selectItem(row)" ></tr>
  </table>
</div>
