import {Component, OnInit, SimpleChanges, Input, Output, EventEmitter} from '@angular/core';
import { AppRole } from '@models/enums/domain/AppRole';
import { FidalUserKinds } from '@models/enums/iam/FidalUserKinds';
import { IFidalRoleDto } from '@models/dtos/management/securite/IFidalRoleDto';
import { ClientsAccesTypes } from '@models/enums/domain/ClientsAccesTypes';
import { ExportType } from '@models/enums/domain/ExportType';
import { DataInstructions } from '@models/enums/DataInstructions';
import { MessageKind } from '@models/enums/MessageKind';
import { ExportService } from '@services/business/export.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExportHelper } from '@services/helpers/export-helper';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { IDataPresenterSpecDto } from '@models/dtos/data/export/IDataPresenterSpecDto';
import { ConditionsHelper } from '@services/helpers/conditions-helper';
import { IUtilisateurDto } from '@models/dtos/management/securite/IUtilisateurDto';
import { StringHelper } from '@services/helpers/string-helper';
import { DossiersAccesTypes } from '@models/enums/domain/DossiersAccesTypes';
import { FlagType } from '@models/enums/domain/FlagType';
import { IClientDto } from '@models/dtos/runtime/contacts/IClientDto';
import { IRequeteClausePoco } from '@models/pocos/queries/IRequeteClausePoco';
import { IRequeteConditionPoco } from '@models/pocos/queries/IRequeteConditionPoco';
import { ActionKind } from '@models/enums/ActionKind';

@Component({
  selector: 'rights-selector',
  templateUrl: './rights-selector.component.html'
})
export class RightsSelectorComponent implements OnInit {

  ActionKinds = ActionKind;
  FlagTypes = FlagType;
  FidalUserKinds = FidalUserKinds;
  DossiersAccesTypes = DossiersAccesTypes;
  ClientsAccesTypes = ClientsAccesTypes;

  @Input('type')
  type: FidalUserKinds = FidalUserKinds.None;

  @Input('utilisateur')
  utilisateur: IUtilisateurDto;

  @Output()
  public itemChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  roleId: string;

  globalSpec: IDataPresenterSpecDto;

  excludedRoleIds: string[] = [];
  excludedClientsAccesTypeIds: string[] = [];

  defaultQueryClause : IRequeteClausePoco;
  dossiersFiltreClause: IRequeteClausePoco;

  associeClients : IClientDto[];

  constructor(
    private exportService: ExportService,
    private snackBar: MatSnackBar) {

      this.defaultQueryClause = <IRequeteClausePoco> { instruction: DataInstructions.None, filtres: [<IRequeteConditionPoco> {}], clauses: [], conditions: [] };
  }

  ngOnInit() {

    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.hasOwnProperty("utilisateur")) {
      
      this.init();
      this.refreshPresenterSpecs();
      this.refresh();
    }

    if (changes.hasOwnProperty("type")) {
      this.refresh();
    }
  }

  hasChanged() {    
    this.itemChange.emit(true);
  }

  init()
  {
    if (!this.utilisateur) return;

    this.roleId = this.utilisateur.roles?.length >0 ? this.utilisateur.roles[0] .id: null;

    this.associeClients = this.utilisateur.clientAccess.map(q=>q.client);

    this.dossiersFiltreClause = StringHelper.jsonParse(this.utilisateur.dossiersFiltreClauseJson, this.defaultQueryClause);
  }

  // Specs ---------------

  refreshPresenterSpecs()
  {
    let exportTypes = [ExportType.MarqueDossiers, ExportType.ModeleDossiers];

    this.exportService.getDataPresenterSpecs(exportTypes).subscribe(
    {
      next: result => {
        let specs = result;
        this.globalSpec = ExportHelper.mergePresenterSpec(specs.filter(q=> exportTypes.find(p=>q.type===p)));
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
      }
    });
  }

  // Roles ------------------------------------------------

  async refresh() {
    this.excludedRoleIds =[AppRole.Any];
    
    switch (this.type)
    {
      case FidalUserKinds.Client:
      case FidalUserKinds.Standard: 
        this.excludedRoleIds.push(
            AppRole.FIDAL_MARKMANAGER_MANAGER,
            AppRole.FIDAL_MARKMANAGER_ADMIN,
            AppRole.FIDAL_MARKMANAGER_SUPER
        );
        break;
    }

    this.excludedClientsAccesTypeIds = [ClientsAccesTypes.Any, ClientsAccesTypes.Herite];
    switch(this.roleId) {
     case AppRole.FIDAL_MARKMANAGER_MANAGER:
        this.excludedClientsAccesTypeIds.push(
          ClientsAccesTypes.Gestionnaire,
          ClientsAccesTypes.RestreintAcces,
          ClientsAccesTypes.TotalAcces);
       break;
    case AppRole.FIDAL_MARKMANAGER_MEMBER:
        this.excludedClientsAccesTypeIds.push(
          ClientsAccesTypes.AssistantGestionnaire,
          ClientsAccesTypes.Gestionnaire,
          ClientsAccesTypes.PrincipalGestionnaire);
        break;
     case AppRole.FIDAL_MARKMANAGER_VISITOR:
        this.excludedClientsAccesTypeIds.push(
          ClientsAccesTypes.AssistantGestionnaire,
          ClientsAccesTypes.Gestionnaire,
          ClientsAccesTypes.PrincipalGestionnaire);
        break;
   }

   if (!this.utilisateur.clientsAccesType || this.excludedClientsAccesTypeIds.indexOf(this.utilisateur.clientsAccesType)>-1) {
    this.utilisateur.clientsAccesType = ClientsAccesTypes.None;
   }
   
   if (!this.utilisateur.dossiersAccesType) {
    this.utilisateur.dossiersAccesType = DossiersAccesTypes.None;
   }
 }

 setRole(id: string) {
   this.roleId = id;
   this.utilisateur.roles = [<IFidalRoleDto>{id: this.roleId}];

   switch(this.roleId) {
      case AppRole.FIDAL_MARKMANAGER_MANAGER:
        this.setGestionnaireType(ClientsAccesTypes.AssistantGestionnaire);
        break;
      case AppRole.FIDAL_MARKMANAGER_MEMBER:
        this.setGestionnaireType(ClientsAccesTypes.RestreintAcces);
        break;
      case AppRole.FIDAL_MARKMANAGER_VISITOR:
        this.setGestionnaireType(ClientsAccesTypes.RestreintAcces);
        break;
    } 

    this.refresh();
  }

  setGestionnaireType(clientsAccesType: ClientsAccesTypes) {
    this.utilisateur.clientsAccesType = clientsAccesType;
  }

  setQueryString() {
    this.utilisateur.dossiersFiltreClauseJson = JSON.stringify(this.dossiersFiltreClause);
    this.utilisateur.dossiersFiltreQuery = ConditionsHelper.clauseToScript(this.dossiersFiltreClause, this.globalSpec?.columns);
    this.hasChanged();
  }

  resetQuery() {
    this.dossiersFiltreClause = this.defaultQueryClause;
  }
}
