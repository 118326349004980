import { Component, Inject, OnInit } from '@angular/core';
import { PageHistoryService } from '@services/nav/page-history.service';
import { StorageKeys } from '@models/app/StorageKeys';
import { DataInstructions } from '@models/enums/DataInstructions';
import { IDataPresenterSpecDto } from '@models/dtos/data/export/IDataPresenterSpecDto';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { UtilsHelper } from '@services/helpers/utils-helper';
import { ExportType } from '@models/enums/domain/ExportType';
import { IGlobalRequeteRequestFormPoco } from '@models/pocos/forms/IGlobalRequeteRequestFormPoco';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { ExportService } from '@services/business/export.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageKind } from '@models/enums/MessageKind';
import { IRequeteRequestFormPoco } from '@models/pocos/forms/IRequeteRequestFormPoco';
import { StorageHelper } from '@services/helpers/storage-helper';
import { ExportHelper } from '@services/helpers/export-helper';
import { ConditionsHelper } from '@services/helpers/conditions-helper';
import { RequeteViewModes } from '@models/enums/RequeteViewModes';
import { EntityType } from '@models/enums/domain/EntityType';
import { AppRole } from '@models/enums/iam/AppRole';
import { ActivatedRoute } from '@angular/router';
import { DataOperators } from '@models/enums/DataOperators';
import { MatDialog } from '@angular/material/dialog';
import { RequetesSearchDialogComponent } from '@components/shared/requetes/requetes-search-dialog.component';
import { SettingsService } from '@services/business/settings.service';
import { IRequeteDto } from '@models/dtos/settings/IRequeteDto';
import { IRequeteClausePoco } from '@models/pocos/queries/IRequeteClausePoco';
import { IRequeteConditionPoco } from '@models/pocos/queries/IRequeteConditionPoco';
import { QueryResultModes } from '@models/enums/QueryResultModes';

@Component({
  selector: 'pane-requetes-component',
  templateUrl: './requetes.component.html'
})
export class RequetesComponent implements OnInit {

  RequeteViewModes = RequeteViewModes;
  EntityTypes = EntityType;
  AppRoles = AppRole;

  pageSizeOptions: number[] = GlobalConstants.pageSizeOptions_List;

  isSearching: boolean = false;

  idRequete: string = null;
  idTitulaire: string = null;
  idCorrespondant: string = null;
  idDeposant: string = null;
  idFamille: string = null;

  ExportTypes = ExportType;
  exportTypes: ExportType[] = GlobalConstants.queryExportTypes;

  itemsCount: any = {};

  tabIndex: number;

  initialized: boolean = false;

  requestForm: IGlobalRequeteRequestFormPoco;
  readonly defaultRequestForm: IGlobalRequeteRequestFormPoco = <IGlobalRequeteRequestFormPoco> {
    formPoco: <IRequeteRequestFormPoco> {
      clients: []
    },
    exportTypes: [ExportType.MarqueDossiers],
    mode: RequeteViewModes.Standard
  };

  globalSpec: IDataPresenterSpecDto;
  specs: IDataPresenterSpecDto[];

  moreFiltersShowed: boolean;

  searchAck: boolean | null = null;

  constructor(
    private route: ActivatedRoute,
    private pageHistoryService: PageHistoryService,
    private settingsService: SettingsService,
    private exportService: ExportService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) {

      this.route.queryParams.subscribe(p => {
        this.idRequete = p.idRequete;
        this.idTitulaire = p.idTitulaire;
        this.idCorrespondant = p.idCorrespondant;
        this.idDeposant = p.idDeposant;
        this.idFamille = p.idFamille;
      }
    );
  }

  ngOnInit() {

    this.initRequestForm();
  }

  async back() {
 
    let uri = this.pageHistoryService.popLast();
    this.pageHistoryService.navigateByUrl(uri);
  }

  storeRequestForm() {
    StorageHelper.setSessionItem(StorageKeys.__formToken_Requetes, this.requestForm);    
  }

  async initRequestForm(excludeStorage: boolean = false)
  {
    if (this.idTitulaire || this.idDeposant || this.idCorrespondant || this.idFamille) {

      let queryStatementPoco = <IRequeteClausePoco> { filtres: [] };

      if (this.idTitulaire) {
        queryStatementPoco.filtres.push(
          <IRequeteConditionPoco> { 
            columnName: GlobalConstants.ColumnNames_Titulaire,
            operator: DataOperators.Equal,
            value: this.idTitulaire
          });
      }
      if (this.idDeposant) {
        queryStatementPoco.filtres.push(
          <IRequeteConditionPoco> { 
            columnName: GlobalConstants.ColumnNames_Deposant,
            operator: DataOperators.Equal,
            value: this.idDeposant
          });
      }
      if (this.idCorrespondant) {
        queryStatementPoco.filtres.push(
          <IRequeteConditionPoco> { 
            columnName: GlobalConstants.ColumnNames_Correspondant,
            operator: DataOperators.Equal,
            value: this.idCorrespondant
          });
      }
      if (this.idFamille) {
        queryStatementPoco.filtres.push(
          <IRequeteConditionPoco> { 
            columnName: GlobalConstants.ColumnNames_Famille,
            operator: DataOperators.Equal,
            value: this.idFamille
          });
      }

      this.requestForm = <IGlobalRequeteRequestFormPoco> {
        formPoco: <IRequeteRequestFormPoco> {
          clients: []
        },
        exportTypes: [ExportType.MarqueDossiers],
        mode: RequeteViewModes.Standard,
        queryStatementPoco: queryStatementPoco
      };
    }
    else {
      this.requestForm = StorageHelper.getSessionItem(
        StorageKeys.__formToken_Requetes,
        this.defaultRequestForm,
        !excludeStorage
      );

      if (this.idRequete) {

        this.isSearching = false;
        this.settingsService.getRequete(this.idRequete, QueryResultModes.Full).subscribe({
          next: result => {
            this.setMaRequete(result);

            this.moreFiltersShowed = true;
          },
          error: _ =>
          {
            this.isSearching = false;
            SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
          }
        });        
      }
    }

    UtilsHelper.repairSearchRequestPoco(this.requestForm, GlobalConstants.pageSizeOptions_List);

    if (!this.requestForm?.queryStatementPoco)
    {
      this.resetFilters();
    }

    this.refreshPresenterSpecs();

    this.moreFiltersShowed = this.requestForm.queryStatementPoco?.filtres?.length>0
      && !(this.requestForm.queryStatementPoco?.filtres?.length===1 
        && !ConditionsHelper.isClause(this.requestForm.queryStatementPoco?.filtres[0])
        && !(this.requestForm.queryStatementPoco?.filtres[0] as IRequeteConditionPoco)?.columnName);
  }

  // Tabs ---------------

  checkTab(tabIndex: number)
  {
    let exportType = this.exportTypes[tabIndex];
    if (!this.isExportTypeChecked(exportType)) {
      this.checkExportType(exportType, true);
    }
    else {
      this.tabIndex = tabIndex;
    }
    this.storeRequestForm();
  }

  resetTabIndex(isReversing: boolean = false)
  {
    let i = 0;
    let exportTypes = this.exportTypes;

    if (!exportTypes) return;

    if (isReversing) {
      exportTypes= exportTypes.slice().reverse();
    }
    
    for(i=0;i<exportTypes.length;i++) {
      if (this.isExportTypeChecked(exportTypes[i])) {
        this.tabIndex = !isReversing ? i : (exportTypes.length -1 - i);
        return;
      }
    }
  }

  isExportTypeChecked(exportType: ExportType): boolean {
    return !!this.requestForm.exportTypes.find(q=> q===exportType);
  }

  checkExportType(exportType: ExportType, checked: boolean) {
    if (checked) {
      this.requestForm.exportTypes = this.requestForm.exportTypes.filter(q=>q!=exportType);
      this.requestForm.exportTypes.push(exportType);  
    }
    else {
      this.requestForm.exportTypes = this.requestForm.exportTypes.filter(q=>q!=exportType);
      this.resetTabIndex(true);  
    }
    this.storeRequestForm();

    this.refreshPresenterSpecs();
  }

  getRoles(roleString: string): string[] {
    return roleString?.split(',');
  }

  //

  toggleMode() {
    this.requestForm.mode = this.requestForm.mode === RequeteViewModes.Expert ? RequeteViewModes.Standard : RequeteViewModes.Expert;
  }

  showFavorites() {

    let requete = <IRequeteDto> { 
      id: this.requestForm?.id,
      libelle: this.requestForm?.libelle,
      requeteClause: ConditionsHelper.toDto(this.requestForm?.queryStatementPoco)
    };    

    const dialogRef = this.dialog.open(
      RequetesSearchDialogComponent,
      {
        panelClass: ['w-50', 'zoomed'],
        data: {
          requete: requete
        },
        autoFocus: false
      });
    
    dialogRef.afterClosed().subscribe(result => {

      if (result)
      {
        this.setMaRequete(result);
      }
    });
  }

  //

  setMaRequete(requete: IRequeteDto) {

    this.requestForm.id = requete?.id;
    this.requestForm.libelle = requete?.libelle;
    this.requestForm.queryStatementPoco = ConditionsHelper.toPoco(requete?.requeteClause) as IRequeteClausePoco;

    if (!(this.requestForm.queryStatementPoco?.filtres?.length>0)) {
      this.resetFilters();
    }

    this.search();
  }

  // Search

  isFormEmpty(): boolean {
    return this.exportService.isGlobalRequeteRequestFormEmpty(this.requestForm);
  }

  search() {

    this.storeRequestForm();

    this.searchAck = !(this.searchAck===null ? false : this.searchAck);
  }

  setItemsCount(exportType: ExportType = null, count: number) {
    this.itemsCount[exportType] = count;
  }

  // Filters

  toggleRequeteViewMode() {
    this.requestForm.mode = this.requestForm.mode === RequeteViewModes.Expert ? RequeteViewModes.Standard : RequeteViewModes.Expert;
  }

  resetFilters()
  {
    this.requestForm.queryStatementPoco = <IRequeteClausePoco> { instruction: DataInstructions.None, filtres: [<IRequeteConditionPoco> {}], clauses: [], conditions: [] };
    this.storeRequestForm();
  }

  showMoreFilters()
  {
    this.moreFiltersShowed = true;

    if (!this.requestForm.queryStatementPoco?.filtres) {
      this.resetFilters();
    }
  }

  getSpec(exportType: ExportType) : IDataPresenterSpecDto {
    return this.specs?.find(q=>q.type === exportType);
  }  

  refreshPresenterSpecs()
  {
    this.exportService.getDataPresenterSpecs(this.exportTypes).subscribe(
    {
      next: result => {
        this.specs = result;
        this.globalSpec = ExportHelper.mergePresenterSpec(this.specs.filter(q=> this.requestForm.exportTypes.find(p=>q.type===p)));
        this.resetTabIndex();
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
      }
    });
  }

  // View items

  viewItem(exportType: ExportType, obj: any)
  {
    let keyColumnName: string;

    let spec = this.getSpec(exportType);
    keyColumnName = spec?.columns?.find(q=>q.isKey)?.name;

    if (keyColumnName)
    {
      switch (exportType)
      {
        case ExportType.MarqueDossiers:
          this.pageHistoryService.navigate(['/folders/trademarks', obj[keyColumnName]]);
          break;
        case ExportType.ModeleDossiers:
          this.pageHistoryService.navigate(['/folders/designs', obj[keyColumnName]]);
          break;
        case ExportType.Contentieux:
          this.pageHistoryService.navigate(['/folders/litigations', obj[keyColumnName]]);
          break;
      }  
    }
  }
}
