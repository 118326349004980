export enum ContactNatures {
    
    // Undefined.
    Undefined = "Undefined",

    // Client.
    Client = "Client",

    // Divers.
    Divers = "Divers",
    
    // Any.
    Any = "Any"
}
