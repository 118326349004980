<div class="content-pane">

  <div class="row ml-1 mr-3">

    <button i18n-matTooltip class="nav-primary mr-2" matTooltip="Revenir en arrière" (click)="$event.preventDefault(); back();"
      *ngIf="idRequete || idTitulaire || idCorrespondant || idDeposant || idFamille">
      <mat-icon>arrow_back_ios</mat-icon> 
    </button>

    <div class="col-md-4 col-sm-5 col-4" *requiresUserRoles="[AppRoles.Manager, AppRoles.Admin, AppRoles.Super]">
      <entities-selector [type]="EntityTypes.Client" i18n-label class="col-12 mp-0" [(items)]="requestForm?.formPoco.clients"
        [readonly]="isSearching" (selectionChange)="search();"
        [maxCount]="-1" appearance="standard" [required]="true" label="Client"></entities-selector> 
    </div>

    <div class="col-md-2 col-xs-4 col-3 screen-xl" *requiresUserRoles="[AppRoles.Manager, AppRoles.Admin, AppRoles.Super]">
      <entities-selector [type]="EntityTypes.Gestionnaire" i18n-label
        [(item)]="requestForm?.formPoco.principalGestionnaire" [data]="{clientsAccesType: 'PrincipalGestionnaire'}"
        [readonly]="isSearching" (selectionChange)="search();"
        class="col-12 mp-0" appearance="standard" label="Gestionnaire principal"></entities-selector>
    </div>

    <button i18n-matTooltip class="classic ml-3 mt-3" *ngIf="!moreFiltersShowed"
      matTooltip="Voir tous les critères" (click)="moreFiltersShowed = true;">
      <mat-icon>filter_list</mat-icon><span i18n class="ml-1 screen-xl">FILTRES</span>
    </button>

    <button i18n-matTooltip class="primary ml-3 mr-1 mt-3 pb-3" matTooltip="Lancer la recherche" (click)="search();" [disabled]="isSearching">
      <mat-icon>search</mat-icon><span i18n class="ml-1 screen-lg">LANCER</span>
    </button>
  
    <button i18n-matTooltip class="normal ml-1 mt-3" matTooltip="Réinitialiser le formulaire" *ngIf="!isFormEmpty()"
      (click)="$event.stopPropagation(); initRequestForm(true);">
      <mat-icon>highlight_off</mat-icon>
    </button>
  </div>

  <!-- Tabs -->

  <div class="inner">

    <!-- Filters -->

    <div class="filter-pane-queries row" *ngIf="moreFiltersShowed">

      <div class="col-10">
        
        <filtre-clause class="col-12 mp-0" [item]="requestForm.queryStatementPoco" (submited)="search();" [readonly]="isSearching"
          [presenterSpec]="globalSpec" [queryData]="{ clients: requestForm?.formPoco.clients }" [mode]="requestForm.mode"          
          (removeItem)="resetFilters()"></filtre-clause>
      </div>

      <div class="col-2">
        <button i18n-matTooltip class="secondary float-right" matTooltip="Cacher les filtres avancés"
          (click)="moreFiltersShowed = false;">
          <mat-icon>expand_less</mat-icon>
        </button>
        <br/><br/>
        <div class="float-right">
          <button i18n-matTooltip class="primary button-small mr-2 mt-3" mat-icon-button matTooltip="Afficher mes requêtes favorites" (click)="showFavorites();">
            <mat-icon>star_border</mat-icon>
          </button>
            <button i18n-matTooltip class="secondary button-small light mr-2 mt-2" mat-icon-button matTooltip="Passer en mode Expert" (click)="toggleMode();" *ngIf="requestForm.mode===RequeteViewModes.Standard">
            <mat-icon>format_line_spacing</mat-icon>
          </button>
          <button i18n-matTooltip class="secondary button-small mr-2 mt-2" mat-icon-button matTooltip="Passer en mode Classique" (click)="toggleMode();" *ngIf="requestForm.mode===RequeteViewModes.Expert">
            <mat-icon>format_line_spacing</mat-icon>
          </button>  
        </div>
      </div>
    </div>

    <!-- Tabs -->

    <mat-tab-group class="rounded h-100-outer" [selectedIndex]="tabIndex" (selectedIndexChange)="checkTab($event);">
      
      <ng-container *ngFor="let exportType of exportTypes">
        <ng-container *ngIf="getSpec(exportType); let spec">

          <mat-tab i18n-label *requiresUserRoles="getRoles(spec.requiresUserRoles)" class="inner-header">
            <ng-template mat-tab-label>
              <mat-checkbox [ngModel]="isExportTypeChecked(exportType)" class="ml-2 mr-3 highlighted"
                (change)="checkExportType(exportType, $event?.checked);" (click)="$event.stopPropagation()"></mat-checkbox>
              <span class="pb-1">{{spec.documentDisplayName}}</span>
              <span class="pb-1 mr-3">
                &nbsp;{{isExportTypeChecked(exportType) && itemsCount && itemsCount[exportType] ? "(" + itemsCount[exportType] + ")" : ""}}
              </span>
            </ng-template>
            <div class="tab-pane-queries inner-body-noscroll" *ngIf="isExportTypeChecked(exportType)">
          
              <liveview class="h-100-outer" [exportType]="exportType" [globalRequestForm]="requestForm" [presenterSpec]="spec" *ngIf="getSpec(exportType); let spec"
                (itemsCountChange)="setItemsCount(exportType, $event);" [searchAck]="searchAck"
                (filterChanged)="search();"
                (viewItem)="viewItem(exportType, $event);"></liveview>
            </div>
          </mat-tab>
        </ng-container>
      </ng-container>
      
    </mat-tab-group>
  </div>
</div>
