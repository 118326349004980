import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IDataKeyValue } from '@models/dtos/common/IDataKeyValue';
import { ReferenceType } from '@models/enums/domain/ReferenceType';
import { MessageKind } from '@models/enums/MessageKind';
import { IReferenceRequestFormPoco } from '@models/pocos/forms/IReferenceRequestFormPoco';
import { ReferentielService } from '@services/business/referentiel.service';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { LocarnoClassesListDialogComponent } from '../referentiel/classes-locarno/locarnoclasses-list-dialog.component';
import { NiceClassesListDialogComponent } from '../referentiel/classes-nice/niceclasses-list-dialog.component';

@Component({
  selector: 'references-selector',
  templateUrl: './references-selector.component.html'
})
export class ReferencesSelectorComponent implements OnChanges {

  references: IDataKeyValue[] = [];

  @Input('appearance')
  public appearance: string;

  @Input('mustShowMenu')
  public mustShowMenu: boolean;

  @Input('textFormat')
  public textFormat: string;  

  @Output()
  public selectionChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Input()
  public items: any[];

  @Output()
  public itemsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input()
  public item: any;

  @Output()
  public itemChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public itemIds: string[];

  public viewItemIds: string[];

  @Output()
  public itemIdsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Input()
  public itemId: string;

  @Output()
  public itemIdChange: EventEmitter<string> = new EventEmitter<string>();
 
  @Input('data')
  public data: any;
  
  @Input('autoComplete')
  public autoComplete: boolean = true;

  // 0: items; 1: itemIds; 2: item; 3: itemId
  public itemMode: number = 0;

  @Input('excluding')
  public excludingItems: any[] = [];

  @Input('type')
  public type: ReferenceType = ReferenceType.Undefined;

  @Input('label')
  public label: string = null;

  @Input('readonly')
  public readonly: boolean = false;

  @Input('required')
  public required: boolean = false;

  @Output("valid")
  public validChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  get valid(): boolean | null
  {
    let b = !this.required || this.items?.length > 0;
    return b;
  }

  constructor(
    private referentielService: ReferentielService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.hasOwnProperty("items")) {
      this.itemMode = 0;
      this.setItems(this.items, false);
    }

    if (changes.hasOwnProperty("itemIds")) {
      let items = [];

      this.itemIds?.forEach(q=>
      {
        let item = <any> { id: q};
        items.push(item);
      });

      this.itemMode = 1;
      this.setItems(items, false);
    }

    if (changes.hasOwnProperty("itemId")) {
      let item = <any> { id: this.itemId};
      let items = !this.itemId ? [] : [item];

      this.itemMode = 3;
      this.setItems(items, false);
    }

    if (changes.hasOwnProperty("item")) {
      let items = !this.item ? [] : [this.item];

      this.itemMode = 2;
      this.setItems(items, false);
    }

    if (changes.hasOwnProperty("type") || changes.hasOwnProperty("excludingItems") || changes.hasOwnProperty("data")) {
      this.textFormat ??= (this.type=== ReferenceType.LocarnoClasses || this.type=== ReferenceType.NiceClasses ? "id - label" : "label");
      this.refreshItems();
    }
  }

  async refreshItems() {

    let requestForm = <IReferenceRequestFormPoco> {
      evenementType: this.data?.evenementType,
      marqueRegistreType: this.data?.marqueRegistreType,
      objetType: this.data?.objetType,
      referenceType: this.type
    };
    this.referentielService.listDonnees(requestForm).subscribe(
    {
      next: result => {

        this.references = result?.filter(q=> !this.excludingItems.find(p=>p.id==q.key))
          .sort((a, b)=> { 
            let r = a.key==="Any" || a.key==="Undefined" ? -1 :  b.key==="Any" || b.key==="Undefined" ? 1 : this.getReferenceText(a).localeCompare(this.getReferenceText(b));
            return r; 
          });
  
        this.setItems(this.items, false);
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
      }
    });
  }

  async emptyItems()
  {
    if (!this.readonly)
    {
      this.setItems([]);
    }
  }

  public setViewItems(values: any[], emit: boolean = true)
  {
    this.setItems(values?.map(q=> <any> {id: q}));
  }

  public setItems(items: any[], emit: boolean = true)
  {
    items = items?.sort((a, b)=> this.getItemText(a)?.localeCompare(this.getItemText(b)));
    if(!items)
    {
      items = [];
    }

    this.items = items;
    this.viewItemIds = this.items?.map(q=> q?.id);

    if (this.items?.length>0)
    {
      if (this.references)
      {
        this.items.forEach(q=> {
          let reference = this.references.find(p=>p.key?.toLowerCase()===q?.id?.toLowerCase());
          if (!q.libelle) {
            q.libelle = reference?.content;
          }
        });
      }
      this.item = this.items.find(q=>q) ?? null;
    }
    else
    {
      this.item = null;
    }

    if (emit)
    {
      switch (this.itemMode)
      {
        case 0:
          this.itemsChange.emit(this.items);
          break;
        case 1:
          let itemIds = this.items?.map(q=>q?.toString());
          this.itemIdsChange.emit(itemIds);
          break;
        case 2:
          this.itemChange.emit(this.item);
          break;
        case 3:
          this.itemIdChange.emit(this.item?.id);
          break;
      }

      this.selectionChange.emit(true);
      this.validChange.emit(this.valid);  
    }
  }

  showMenu() {

    if (this.itemMode===0 || this.itemMode===1)
    {
      let items = [...this.items];

      let dialogRef = null;
      switch(this.type)
      {
        case ReferenceType.LocarnoClasses:
          dialogRef = this.dialog.open(
            LocarnoClassesListDialogComponent,
            {
              panelClass: ['w-50', 'small'],
              data: {
                items: items,
                readonly: this.readonly
              },
              autoFocus: false
            });    
          break;
        case ReferenceType.NiceClasses:
          dialogRef = this.dialog.open(
            NiceClassesListDialogComponent,
            {
              panelClass: ['w-50', 'small'],
              data: {
                items: items,
                readonly: this.readonly
              },
              autoFocus: false
            });
          break;
      }
  
      if (dialogRef)
      {
        dialogRef.afterClosed().subscribe(result => {
          if(result)
          {
            let items: any[] = result;
    
            this.setItems(items);
          }
        });  
      }  
    }
  }
  
  getItemsText()
  {
    return this.items?.map(q=> {

      return this.getItemText(q);
    }).join(", ");
  }

  getItemText(item: any)
  {
    let reference = this.references?.find(p=>p?.key?.toLowerCase()===item?.id?.toLowerCase());
    return this.getReferenceText(reference);
  }

  getReferenceText(reference: IDataKeyValue)
  {
    if (!reference) return "";

    let text = this.textFormat ? this.textFormat
      .replace("id", reference.key)
      .replace("label", reference.content) : reference.content;
    return text;
  }
}
