<div class="col-12 mp-0">
  <mat-form-field class="col-12 mp-0" [appearance]="appearance">
    <mat-label>{{label}}</mat-label>
    <input name="itemId" type="text" [formControl]="Control_Options" matInput [matAutocomplete]="auto"  [required]="required" [readonly]="readonly">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getItemsText" (optionSelected)="setOptionValue($event.option.value);">
      <mat-option *ngFor="let option of filteredOptions | async;" [value]="option">
        {{getItemText(option)}}
      </mat-option>
    </mat-autocomplete>
    <button mat-button matSuffix mat-icon-button *ngIf="!readonly && item && item[idFieldName]" (click)="$event.stopPropagation(); setOptionValue(null); updateAutoCompleteComponent_Options();">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-form-field>
</div>
