<div class="filtre-clause" *ngIf="clause">

    <flags-selector i18n-label class="filtre-instruction" *ngIf="!readonly  && parent?.filtres?.length>0 && parent.filtres[0]!=clause"
        [(itemId)]="clause.instruction" [type]="FlagTypes.DataInstructions"
        [readonly]="readonly"></flags-selector>

    <div [class]="parent ? 'border-left filtre-tabbed' : ''">
        <div [class]="hasTabbed(filtre) ? 'filtre-tabbed filtre-clause' : 'filtre-clause'" *ngFor="let filtre of clause.filtres">
        
            <filtre-clause *ngIf="isClause(filtre)" [item]="filtre" [parent]="clause" [presenterSpec]="presenterSpec" [queryData]="queryData" [mode]="mode"
                (removeItem)="removeSubClause($event)"></filtre-clause>
    
            <filtre-condition class="col mp-0" *ngIf="!isClause(filtre)"
                [item]="filtre" [parent]="clause" [presenterSpec]="presenterSpec" [queryData]="queryData" [readonly]="readonly"
                (removeItem)="removeCondition($event)" (submited)="submit();"></filtre-condition>

            <div class="button-small filtre-star mt-2" *ngIf="!isClause(filtre)">
                <button i18n-matTooltip class="normal mr-1 button-small" *ngIf="!readonly && mode === RequeteViewModes.Expert && !isClause(filtre) && clause?.filtres?.length>1"
                    matTooltip="Convertir en clause"
                    (click)="$event.stopPropagation(); convertFiltre(filtre)">
                    <mat-icon>format_line_spacing</mat-icon>
                </button>

                <button i18n-matTooltip class="normal mr-1 button-small" *ngIf="!readonly && mode === RequeteViewModes.Expert && parent && !isClause(filtre) && clause?.filtres?.length===1"
                    matTooltip="Convertir en condition"
                    (click)="$event.stopPropagation(); convertFiltre(clause)">
                    <mat-icon>subdirectory_arrow_left</mat-icon>
                </button>

                <button i18n-matTooltip class="normal mr-1 button-small" *ngIf="!readonly"
                    matTooltip="Retirer la condition"
                    (click)="$event.stopPropagation(); removeCondition(filtre)">
                    <mat-icon>close</mat-icon>
                </button>
    
                <button i18n-matTooltip class="secondary button-small" [style.visibility]="!readonly && isLastFilterInSequence(filtre) ? 'visible': 'hidden'"
                    matTooltip="Ajouter une nouvelle condition"
                    (click)="addCondition(filtre)">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>
            
        <div class="text-small mt-1" *ngIf="!readonly && (!(clause.filtres?.length>0) || isClause(lastFilter()))">
            <button i18n-matTooltip class="secondary button-small"
                matTooltip="Ajouter une nouvelle condition"
                (click)="addCondition()">
                <mat-icon>add</mat-icon>
            </button>

            <button i18n-matTooltip class="normal button-small ml-1" *ngIf="!readonly && parent"
                matTooltip="Retirer la clause"
                (click)="$event.stopPropagation(); removeClause()">
                <mat-icon>delete_outline</mat-icon>
            </button>
        </div>  
    </div>
</div>
