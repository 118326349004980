import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ILoiMoteurResultatDto } from '@models/dtos/runtime/dossiers/ILoiMoteurResultatDto';
import { LoiMoteurDebutDateTypes } from '@models/enums/domain/LoiMoteurDebutDateTypes';
import { ReferenceType } from '@models/enums/domain/ReferenceType';
import { KeyCodes } from '@models/enums/KeyCodes';
import * as moment from 'moment';

@Component({
  selector: 'loi-moteur-view-dialog',
  templateUrl: './loi-moteur-view-dialog.component.html'
})
export class LoiMoteurViewDialogComponent implements OnInit {

  ReferenceTypes = ReferenceType;
  
  @Input('resultat')
  public resultat: ILoiMoteurResultatDto;

  constructor(
    private dialogRef: MatDialogRef<LoiMoteurViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef.disableClose = true;
    this.resultat = data.resultat;
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });
  }

  select()
  {
    this.dialogRef.close(this.resultat.prochainDate);
  }

  getResultatRegle(): string {

    let st_referenceDate = moment(this.resultat.referenceDate).format("DD/MM/YYYY");
    let st_referenceDateType = "";
    switch(this.resultat.dossierPays?.debutDateType)
    {
      case LoiMoteurDebutDateTypes.DepotDate:
        st_referenceDateType = $localize `(date dépôt)`;
        break;
      case LoiMoteurDebutDateTypes.EnregistrementDate:
        st_referenceDateType = $localize `(date d'enregistrement)`;
        break;
      }

    let st: string;
    st = (!this.resultat.dossierPays ? $localize `Inconnue` : ($localize `Tous les ` + (this.resultat.dossierPays?.calculDelai ? this.resultat.dossierPays?.calculDelai : '-') + $localize ` mois`
      + (this.resultat.dossierPays?.calculAjustement ? $localize ` et ` + this.resultat.dossierPays?.calculAjustement + $localize ` jours` : '')))
      + "\n";
    st += "\n";
    st += $localize `à partir du ` + `${st_referenceDate} ${st_referenceDateType}`;
    console.log(st);
    console.log(this.resultat.dossierPays?.suivantCalculDelai);
    if(this.resultat.dossierPays?.suivantCalculDelai != null && this.resultat.dossierPays?.suivantCalculDelai != 0)
    {
      st += "\n\n";
      st += `Attention tous les ` + (this.resultat.dossierPays?.suivantCalculDelai / 12) + ` ans à partir du 2nd renouvellement`; 
    }
    
    return st;
  }
}
