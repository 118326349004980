import { Component, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { IDataKeyValue } from '@models/dtos/common/IDataKeyValue';
import { IDataPresenterSpecDto } from '@models/dtos/data/export/IDataPresenterSpecDto';
import { IFieldSpecDto } from '@models/dtos/data/export/IFieldSpecDto';
import { ActionKind } from '@models/enums/ActionKind';
import { ExportFormats } from '@models/enums/domain/ExportFormats';
import { ExportType } from '@models/enums/domain/ExportType';
import { FlagType } from '@models/enums/domain/FlagType';
import { KeyCodes } from '@models/enums/KeyCodes';
import { MessageKind } from '@models/enums/MessageKind';
import { ExportService } from '@services/business/export.service';
import { ConditionsHelper } from '@services/helpers/conditions-helper';
import { ExportHelper } from '@services/helpers/export-helper';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { ColumnsListViewComponent } from './columns-list-view.component';

@Component({
  selector: 'columns-export-dialog',
  templateUrl: './columns-export-dialog.component.html'
})
export class ColumnsExportDialogComponent implements OnInit {
  
  ExportFormats = ExportFormats;
  ActionKinds = ActionKind;
  FlagTypes = FlagType;

  presenterSpec: IDataPresenterSpecDto;
  defaultSelectedColumnNames: string[];

  @Input('exportType')
  public exportType: ExportType;

  @Input('excluding')
  public excludingColumnNames: string[] = [];

  selectedColumns:  IFieldSpecDto[] =[];

  @ViewChild('inputFileName') inputFileName: MatInput;

  @ViewChild('columnsListView') columnsListView: ColumnsListViewComponent;

  format: ExportFormats;
  fileName: string;
  documentDisplayName: string;
  culture: string;

  constructor(
    private dialogRef: MatDialogRef<ColumnsExportDialogComponent>,
    private exportService: ExportService,
    private snackBar: MatSnackBar,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef.disableClose = true;

    if (data)
    {
      this.exportType = data.exportType;
      this.format = data.format ?? GlobalConstants.defaultExportFormat;      
      this.presenterSpec = data.spec;
      this.culture = data.culture ?? GlobalConstants.supportedLocales.find(q=>q.key?.toLowerCase()== this.locale?.toLowerCase())?.key;
      this.excludingColumnNames = data.excluding;
      this.defaultSelectedColumnNames = data.selectedColumnNames;
    }
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });

    this.refreshColumns();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.inputFileName.focus();
    });
  }

  refreshColumns() {
    
    this.exportService.getDataPresenterSpecs([this.exportType], this.culture).subscribe({
      next: result => {
        this.presenterSpec = result?.length>0 ? result[0] : null;

        this.refreshData();
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
      }
    });
  }

  refreshData() {
    
    this.documentDisplayName = ExportHelper.formatText(
      this.presenterSpec?.documentDisplayName,
      { extension: ExportHelper.getExtensionFile(this.format) });

    this.fileName = ExportHelper.formatText(
      this.presenterSpec?.documentFileName,
      { extension: ExportHelper.getExtensionFile(this.format)});
  }

  select()
  {
    if (this.selectedColumns?.length>0)
    {
      let columnNames = [...this.selectedColumns.map(q=>q.name)];
      columnNames = ConditionsHelper.getFilteredColumns(columnNames, this.presenterSpec, false);
  
      this.dialogRef.close({ 
        format: this.format,
        columns: columnNames,
        fileName: this.fileName,
        documentDisplayName: this.documentDisplayName,
        culture: this.culture
      });
    }
  }

  reset()
  {
    this.columnsListView?.setColumns();
  }
    
  get getAvailableLangs(): IDataKeyValue[] {
    return GlobalConstants.supportedLocales;
  }
}
