import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { IFidalEmployeeDto } from '@models/dtos/management/securite/IFidalEmployeeDto';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { IamService } from '@services/app/iam.service';
import { MessageKind } from '@models/enums/MessageKind';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { UtilsHelper } from '@services/helpers/utils-helper';
import { IFidalEmployeeRequestFormPoco } from '@models/pocos/forms/IFidalEmployeeRequestFormPoco';
import { KeyCodes } from '@models/enums/KeyCodes';

@Component({
  selector: 'employees-search-dialog',
  templateUrl: './employees-search-dialog.component.html'
})
export class EmployeesSearchDialogComponent implements OnInit, AfterViewInit {

  pageSizeOptions: number[] = GlobalConstants.pageSizeOptions_Dialog;

  @ViewChild('employeeForm') employeeForm: NgForm;
  @ViewChild('inputMotCle') inputMotCle: MatInput;

  isSearching: boolean = false;
  itemsTotalCount: number;

  requestForm: IFidalEmployeeRequestFormPoco = <IFidalEmployeeRequestFormPoco> { pageIndex: 0, pageSize: 8 };

  @Input('isAvailable')
  public isAvailable: boolean = true;

  @Input('excluding')
  public excludingEmployees: IFidalEmployeeDto[] = [];

  @Input('maxCount')
  public maxCount: number = -1;

  employees: IFidalEmployeeDto[];

  displayedColumns: string[];
  dataSource: MatTableDataSource<IFidalEmployeeDto>;
  selection = new SelectionModel<IFidalEmployeeDto>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private iamService: IamService = null,
    private dialogRef: MatDialogRef<EmployeesSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ) {
    this.dialogRef.disableClose = true;

    if (data)
    {
      this.excludingEmployees = data.excluding;
      this.maxCount = data.maxCount ?? -1;
      this.isAvailable = data.isAvailable;
    }

    if (this.maxCount===1)
    {
      this.displayedColumns = ['nom', 'prenom', 'email', 'star'];
    }
    else
    {
      this.displayedColumns = ['select', 'nom', 'prenom', 'email', 'star'];
    }
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });

    this.refreshEmployees();
  }
  
  ngAfterViewInit() {
    setTimeout(() => {
      this.inputMotCle.focus();
    });
  }

  // Refresh ---------------

  changePage() {
    UtilsHelper.updateDataPageInfo(this.requestForm, this.paginator);

    this.refreshEmployees();
  }

  async refreshEmployees() {
    
    this.requestForm.isAvailable = this.isAvailable;

    this.isSearching = true;
    this.iamService.listFidalEmployees(this.requestForm).subscribe({
      next: result => {    
        this.employees = result?.items;      
        UtilsHelper.updateDataPageInfo(this.requestForm, result);
        this.itemsTotalCount = result?.totalCount;
        this.dataSource = new MatTableDataSource<IFidalEmployeeDto>(this.employees);
        this.isSearching = false;
      },
      error: _ => {
        this.isSearching = false;
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
      }
    });
  }

  // Dialog

  selectEmployees()
  {
    this.dialogRef.close(this.selection.selected);
  }
   
  selectEmployee(employee: IFidalEmployeeDto)
  {
    if (employee)
    {
      this.dialogRef.close([employee]);
    }
  }

  isExcluded(employee: IFidalEmployeeDto)
  {
    return this.excludingEmployees.find(q=> employee?.id === q.id);
  }

  // Multiselection ---------------

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected())
    {
      this.selection.clear();
    }
    else
    {
      this.selection.clear();
      this.dataSource.data.forEach(row => !this.isExcluded(row) && this.selection.select(row));
    }
  }

  checkboxLabel(row?: IFidalEmployeeDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
}
