export enum DataValueTypes {
    
    // Undefined
    Undefined = "Undefined",

    // Undefined
    None = "None",

    // Boolean
    Boolean = "Boolean",

    // Carrier
    Carrier = "Carrier",

    // Connector
    Connector = "Connector",

    // Datasource
    Datasource = "Datasource",

    // Element
    Element = "Element",

    // Entity
    Entity = "Entity",

    // Date
    Date = "Date",

    // Document
    Document = "Document",

    // Integer
    Integer = "Integer",

    // Long
    Long = "Long",

    // ULong
    ULong = "ULong",

    // ByteArray
    ByteArray = "ByteArray",

    // Number
    Number = "Number",

    // Object
    Object = "Object",

    // Schema
    Schema = "Schema",

    // SchemaZone
    SchemaZone = "SchemaZone",

    // Text
    Text = "Text",

    // Time
    Time = "Time",

    // Any
    Any = "Any"
}
