import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {IResultItemDto} from '@models/dtos/common/IResultItemDto'
import {IClientDto} from '@models/dtos/runtime/contacts/IClientDto'
import {IContactDto} from '@models/dtos/runtime/contacts/IContactDto'
import { UtilsHelper } from '@services/helpers/utils-helper'
import { AppService } from '@services/app/app.service'
import { AuthService } from '@services/app/auth.service';
import { IAdresseDto } from '@models/dtos/runtime/magasin/IAdresseDto';
import { IPaysDto } from '@models/dtos/management/referentiel/IPaysDto';
import { PersonneTypes } from '@models/enums/domain/PersonneTypes';
import { ITribunalDto } from '@models/dtos/management/referentiel/ITribunalDto';
import { IContactTypeDto } from '@models/dtos/management/referentiel/IContactTypeDto';
import { ClientTypes } from '@models/enums/domain/ClientTypes';
import { IUtilisateurDto } from '@models/dtos/management/securite/IUtilisateurDto';
import { IClientsRequestFormPoco } from '@models/pocos/forms/IClientsRequestFormPoco';
import { IContactsRequestFormPoco } from '@models/pocos/forms/IContactsRequestFormPoco';
import { TDataPage } from '@models/dtos/common/TDataPage';
import { IImageDto } from '@models/dtos/runtime/magasin/IImageDto';
import { IUtilisateursRequestFormPoco } from '@models/pocos/forms/IUtilisateursRequestFormPoco';
import { map } from 'rxjs/operators';
import { QueryResultModes } from '@models/enums/QueryResultModes';
import { AppRole } from '@models/enums/iam/AppRole';
import { ICheckFormResult } from '@models/pocos/ICheckFormResult';
import { IInterlocuteurDto } from '@models/dtos/runtime/contacts/IInterlocuteurDto';
import { ClientsAccesTypes } from '@models/enums/domain/ClientsAccesTypes';

@Injectable()
export class ContactsService {

    private baseApiUri_Clients: string;
    private baseApiUri_Contacts: string;
    private baseApiUri_Gestionnaires: string;

    constructor(
        private authService : AuthService,
        private httpClient: HttpClient,
        @Inject(LOCALE_ID) private locale: string
    ) {
        let baseApiUri: string = AppService.__Settings.platform.services[0].apiBaseUri + '/v1';

        this.baseApiUri_Clients = baseApiUri + '/clients';
        this.baseApiUri_Contacts = baseApiUri + '/contacts';
        this.baseApiUri_Gestionnaires = baseApiUri + '/gestionnaires';
    }

    // Clients

    repairClient(client: IClientDto) {
        if (!client) return;

        if (!client.parent)
        {
            client.parent = null;
        }

        if (client.parent?.id)
        {
            client.clientType = ClientTypes.Liee;
        }
        else
        {
            client.clientType = ClientTypes.Principale;
        }
  
        if (!client.adresse)
        {
            client.adresse = <IAdresseDto>{};
        }
    
        if (!client.adresse.pays)
        {
            client.adresse.pays = <IPaysDto>{};
        }

        if (!client.enfants)
        {
          client.enfants = [];
        }
  
        if (!client.parent)
        {
          client.parent = null;
        }

        if (!client.interlocuteurs)
        {
          client.interlocuteurs = [];
        }

        if (!client.clientAccess)
        {
          client.clientAccess = [];
        }
    }

    checkClient(client: IClientDto, contactId: string = null): ICheckFormResult {

        let result: ICheckFormResult = <ICheckFormResult> {};

        var principalGestionnaires = client?.clientAccess?.filter(q=>
            (q.clientsAccesType === ClientsAccesTypes.PrincipalGestionnaire || q.clientsAccesType === ClientsAccesTypes.Herite)
            && q.utilisateur.clientsAccesType === ClientsAccesTypes.PrincipalGestionnaire).map(q=>q.utilisateur);

        if (client) {
            if (!contactId && !client.denomination) {
                result.message = $localize `Information requise` + ' : ' + $localize `Dénomination`;
                result.location = "general";
            }
            else if (!contactId && !UtilsHelper.hasFlagValue(client.personneType)) {
                result.message = $localize `Information requise` + ' : ' + $localize `Type de personne`;
                result.location = "general";
            }
            else if (!contactId && client.personneType === PersonneTypes.Morale && client.clientType === ClientTypes.Liee && !client.parent?.id)
            {
                result.message = $localize `Information requise` + ' : ' + $localize `Société mère`;
                result.location = "general";
            }
            else if (!(principalGestionnaires?.length>0))
            {
                result.message = $localize `Information requise` + ' : ' + $localize `Gestionnaire principal`;
                result.location = "managers";
            }
        
            if (this.authService.hasRoles(AppRole.Manager) && !this.authService.hasRoles(AppRole.Admin, AppRole.Super))
            {
              let userId = this.authService.getUserId();
          
              if (userId
                && !client?.clientAccess.find(q=> q?.utilisateur.id?.toLowerCase() === userId?.toLowerCase()))
              {
                result.message = $localize `Vous devez être défini comme gestionnaire`;
                result.location = "managers";
              }
            }    
        }
          
        return result;
    }

    isClientsRequestFormEmpty(requestForm: IClientsRequestFormPoco): boolean {
        let isEmpty = !requestForm.motcle
             && requestForm.clientType === ClientTypes.Any
             && !requestForm.principalGestionnaire?.id;
        return isEmpty;
    }
    
    listClients(requestForm: IClientsRequestFormPoco): Observable<TDataPage<IClientDto>> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<TDataPage<IClientDto>>(`${this.baseApiUri_Clients}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getClient(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IClientDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IClientDto>(`${this.baseApiUri_Clients}/${id || ""}?mode=${mode || ""}`, { headers: headers });
    }

    addClient(client: IClientDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_Clients}`, client, { headers: headers })
    }

    updateClient(client: IClientDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.put<IResultItemDto>(`${this.baseApiUri_Clients}`, client, { headers: headers })
    }

    deleteClient(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto>(`${this.baseApiUri_Clients}/${id || ""}`, { headers: headers })
    }

    deleteClients(ids: string[]): Observable<IResultItemDto[]> {
        if (!ids) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto[]>(`${this.baseApiUri_Clients}?${UtilsHelper.getQueryString({ids: ids})}`, { headers: headers })
    }

    cloneClient(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_Clients}/${id || ""}/clone`, null, { headers: headers })
    }

    exportClients(requestForm: IClientsRequestFormPoco): any {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get(`${this.baseApiUri_Clients}/export?${UtilsHelper.getQueryString(requestForm)}`, {headers: headers, observe: 'response', responseType: 'blob'})
            .pipe(map(item=> {

                let contentDispositionHeader = item.headers.get('Content-Disposition')
                let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
                let fileName = result.replace(/"/g, '');
        
                return {
                    blob: new Blob([item.body], {type: item.headers.get('Content-Type')}),
                    blobName:  fileName
                };
            }));
    }
    
    getDeleteClientResult(error: any): ICheckFormResult {

        let result: ICheckFormResult = <ICheckFormResult> {};

        if(error.find(q => q.title.indexOf("Cannot delete")>-1 && q.title.indexOf("trademark")>-1) != null)
        {
            result.message = $localize `Impossible de supprimer un client avec une marque en vigueur`;
        }
        else if(error.find(q => q.title.indexOf("Cannot delete")>-1 && q.title.indexOf("design")>-1) != null)
        {
            result.message = $localize `Impossible de supprimer un client avec un modèle en vigueur`;
        }
        else if(error.find(q => q.title.indexOf("Cannot delete")>-1 && q.title.indexOf("litigation")>-1) != null)
        {
            result.message = $localize `Impossible de supprimer un client avec un contentieux en vigueur`;
        }

        return result;
    }

    // Contacts

    repairContact(contact: IContactDto) {
        if (!contact) return;

        if (!contact.adresse)
        {
            contact.adresse = <IAdresseDto>{};
        }
    
        if (!contact.adresse.pays)
        {
            contact.adresse.pays = <IPaysDto>{};
        }
    
        if (!contact.interlocuteurs)
        {
            contact.interlocuteurs = [];
        }

        if (!contact.type)
        {
          contact.type = <IContactTypeDto> {};
        }

        if (!contact.tribunal)
        {
          contact.tribunal = <ITribunalDto> {};
        }

        if (!contact.exercicePayss)
        {
          contact.exercicePayss = [];
        }
    }

    checkContact(contact: IContactDto): ICheckFormResult {

        let result: ICheckFormResult = <ICheckFormResult> {};

        if (contact) {
            if (!contact.type?.id) {
                result.message = $localize `Information requise` + ' : ' + $localize `Type de contact`;
                result.location = "general";
            }

            if (!contact.denomination)
            {
                result.message = $localize `Information requise` + ' : ' + $localize `Dénomination`;
                result.location = "general";    
            }
            else if (!UtilsHelper.hasFlagValue(contact.personneType)) {
                result.message = $localize `Information requise` + ' : ' + $localize `Type de personne`;
                result.location = "general";
            }
        }
        
        return result;
    }

    isContactsRequestFormEmpty(requestForm: IContactsRequestFormPoco): boolean {
        let isEmpty = !requestForm.motcle
             && !requestForm.contactType?.id
             && !requestForm.exercicePays?.id
             && !requestForm.tribunal?.id;
        return isEmpty;
    }

    listContacts(requestForm: IContactsRequestFormPoco): Observable<TDataPage<IContactDto>> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<TDataPage<IContactDto>>(`${this.baseApiUri_Contacts}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getContact(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IContactDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IContactDto>(`${this.baseApiUri_Contacts}/${id || ""}?mode=${mode || ""}`, { headers: headers });
    }

    getContactEstTitulaire(id: string): Observable<boolean> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<boolean>(`${this.baseApiUri_Contacts}/${id || ""}/estIntervenant?type=titulaire`, { headers: headers });
    }

    getContactEstCorrespondant(id: string): Observable<boolean> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<boolean>(`${this.baseApiUri_Contacts}/${id || ""}/estIntervenant?type=correspondant`, { headers: headers });
    }

    getContactEstDeposant(id: string): Observable<boolean> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<boolean>(`${this.baseApiUri_Contacts}/${id || ""}/estIntervenant?type=deposant`, { headers: headers });
    }

    getContactImage(id: string, imageId: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IImageDto> {
        if (!id || !imageId) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IImageDto>(`${this.baseApiUri_Contacts}/${id || ""}/images/${imageId || ""}?mode=${mode || ""}`, { headers: headers });
    }

    addContactImage(id: string, file: any): Observable<IResultItemDto[]> {
        if (!id || !file) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        let formData:FormData = new FormData();
        formData.append(file.name, file);

        return this.httpClient.post<IResultItemDto[]>(`${this.baseApiUri_Contacts}/${id || ""}/images`, formData, { headers });
    }

    addContact(contact: IContactDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_Contacts}`, contact, { headers: headers })
    }

    convertContactToClient(client: IClientDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_Contacts}/convert`, client, { headers: headers })
    }

    updateContact(contact: IContactDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.put<IResultItemDto>(`${this.baseApiUri_Contacts}`, contact, { headers: headers })
    }

    deleteContact(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto>(`${this.baseApiUri_Contacts}/${id || ""}`, { headers: headers })
    }

    deleteContacts(ids: string[]): Observable<IResultItemDto[]> {
        if (!ids) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto[]>(`${this.baseApiUri_Contacts}?${UtilsHelper.getQueryString({ids: ids})}`, { headers: headers })
    }

    cloneContact(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_Contacts}/${id || ""}/clone`, null, { headers: headers })
    }

    exportContacts(requestForm: IContactsRequestFormPoco): any {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get(`${this.baseApiUri_Contacts}/export?${UtilsHelper.getQueryString(requestForm)}`, {headers: headers, observe: 'response', responseType: 'blob'})
            .pipe(map(item=> {

                let contentDispositionHeader = item.headers.get('Content-Disposition')
                let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
                let fileName = result.replace(/"/g, '');
        
                return {
                    blob: new Blob([item.body], {type: item.headers.get('Content-Type')}),
                    blobName:  fileName
                };
            }));
    }

    // Gestionnaires

    listGestionnaireUtilisateurs(requestForm: IUtilisateursRequestFormPoco): Observable<TDataPage<IUtilisateurDto>> {

        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<TDataPage<IUtilisateurDto>>(
            `${this.baseApiUri_Gestionnaires}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getGestionnaireUtilisateur(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IUtilisateurDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IUtilisateurDto>(`${this.baseApiUri_Gestionnaires}/${id}?mode=${mode || ""}`, { headers: headers });
    }

    // Interlocuteurs

    checkInterlocuteur(interlocuteur: IInterlocuteurDto): ICheckFormResult {

        let result: ICheckFormResult = <ICheckFormResult> {};

        if (interlocuteur) {
            if (!UtilsHelper.hasFlagValue(interlocuteur.nom)) {
                result.message = $localize `Information requise` + ' : ' + $localize `Nom`;
                result.location = "general";
            }
       }
        
        return result;
    }
}