import { Component, OnInit } from '@angular/core';
import { AppPolicy } from '@models/enums/iam/AppPolicy';
import { AppRole } from '@models/enums/iam/AppRole';
import { DossierNatures } from '@models/enums/domain/DossierNatures';
import { AuthService } from '@services/app/auth.service';
import { SettingsService } from '@services/business/settings.service';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageKind } from '@models/enums/MessageKind';
import { IBordTableauDto } from '@models/dtos/management/referentiel/IBordTableauDto';
import { PageHistoryService } from '@services/nav/page-history.service';

@Component({
  selector: 'pane-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {

  DossierNatures = DossierNatures;
  AppRoles = AppRole;
  AppPolicy = AppPolicy;

  readonly: boolean = true;
  isSearching: boolean = false;
  isSaving: boolean = false;

  bordTableaux: IBordTableauDto[];

  constructor(
    private pageHistoryService: PageHistoryService,
    private authService: AuthService,
    private settingsService: SettingsService,
    private snackBar: MatSnackBar
  ) {
  } 

  ngOnInit() {

    this.pageHistoryService.flushPages();

    this.readonly = !this.authService.hasPolicies(AppPolicy.AgendaWriters);
    this.refreshbordTableauIds();
  }

  refreshbordTableauIds() {

    this.isSearching = true;
    this.settingsService.listMesBordTableaux().subscribe({
      next: result => {
        this.bordTableaux = result;
        
        this.isSearching = false;
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
        this.isSearching = false;
      }
    });
  }

  deleteBordTableau(bordTableau: IBordTableauDto) {

    this.bordTableaux = this.bordTableaux.filter(q=> q?.id.toLowerCase() != bordTableau?.id?.toLowerCase());
    let bordTableauIds = this.bordTableaux.map(q=>q.id);
    this.settingsService.associeMesBordTableaux(bordTableauIds).subscribe({
      next: _ => {
        this.isSaving = false;
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
        this.isSaving = false;
      }
    });
  }
}
