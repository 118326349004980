<div class="screen-center on-top" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<h2 i18n mat-dialog-title>Classes Nice</h2>

<div class="row" *ngIf="!readonly">
  <references-selector i18n-label class="col-10" appearance="outline"
    [(item)]="currentItem" [type]="ReferenceTypes.NiceClasses" [excluding]="items" [autoComplete]="true"
    (selectionChange)="addItem()"
    label="Nouvelle classe"></references-selector>
</div>

<div class="data-table zoomed">
  <div class="d-flex">
    <mat-paginator [length]="itemsTotalCount" [pageSize]="3" [pageSizeOptions]="[3]"></mat-paginator>
  </div>
  <table mat-table [dataSource]="dataSource" matSort *ngIf="items">

    <!--code Column -->
    <ng-container matColumnDef="id">
      <td mat-cell *matCellDef="let row">
        <button mat-flat-button [matTooltip]="row.libelle">{{row.id}}</button>
      </td>
    </ng-container>

    <!--libelle Column -->
    <ng-container matColumnDef="description">
      <td mat-cell *matCellDef="let row">
        <mat-form-field class="col-12 mt-2" appearance="outline">
          <textarea name="description" matInput [readonly]="readonly"
          [(ngModel)]="row.description"
          cdkAutosizeMinRows="3"></textarea>
        </mat-form-field>
      </td>
    </ng-container>

    <!-- Star Column -->
    <ng-container matColumnDef="star" stickyEnd>
      <td mat-cell *matCellDef="let row">
        <div class="btn-group" *ngIf="!readonly">
          <button mat-button matSuffix mat-icon-button (click)="deleteItem(row)">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
  </table>
</div>

<mat-dialog-actions align="end" class="pt-3">
  <button i18n class="primary" mat-dialog-close *ngIf="readonly">OK</button>

  <button i18n mat-flat-button class="secondary-text mr-2" mat-dialog-close *ngIf="!readonly">ANNULER</button>

  <button i18n i18n-matTooltip class="primary" *ngIf="!readonly"
    matTooltip="Enregistrer les modifications"
    (click)="selectItems()">VALIDER</button>
</mat-dialog-actions>
