export enum MarqueRegistreTypes {

    // Undefined.
    Undefined = "Undefined",

    // Aripo.
    Aripo = "Aripo",

    // Internationale.
    International = "International",
    
    // Enregistrement international.
    InternationalEnregistrement = "InternationalEnregistrement",

    // Nationale.
    National = "National",

    // Any.
    Any = "Any",
}
