<h2 i18n mat-dialog-title>Détails</h2>

<div class="data-table zoomed">
  <div class="d-flex">
    <mat-paginator [length]="itemsTotalCount" [(pageSize)]="pageSizeOptions[0]" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </div>
  <table mat-table [dataSource]="dataSource" matSort *ngIf="items">

    <!--code Column -->
    <ng-container matColumnDef="code" class="ml-2 mr-2">
      <th i18n mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
      <td mat-cell *matCellDef="let row">
        <div class="pr-4 pl-4">{{row.key}}</div>
      </td>
    </ng-container>

    <!--libelle Column -->
    <ng-container matColumnDef="libelle" class="col-5">
      <th i18n mat-header-cell *matHeaderCellDef mat-sort-header>Libellé</th>
      <td mat-cell *matCellDef="let row">
        <div class="pr-4">{{row.content}}</div>
      </td>
    </ng-container>

    <ng-container *ngIf="maxCount!=1">
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    </ng-container>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
  </table>
</div>

<mat-dialog-actions align="end" class="pt-3">
  <button i18n mat-flat-button class="primary" mat-dialog-close>OK</button>
</mat-dialog-actions>