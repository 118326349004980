<div *ngIf="isSearching" class="screen-center on-top">
  <mat-spinner></mat-spinner>
</div>

<h2 i18n mat-dialog-title>Salariés</h2>

<div class="d-flex">
  <mat-form-field class="col-7">
    <mat-label i18n>Mot clé</mat-label>
    <input name="keyword" #inputMotCle="matInput" matInput [(ngModel)]="requestForm.keyword" (keyup)="refreshEmployees();" (keyup.enter)="refreshEmployees()">
    <button i18n-matTooltip mat-button matSuffix mat-icon-button *ngIf="requestForm.keyword" (click)="$event.stopPropagation(); requestForm.keyword=null;">
      <mat-icon>clear</mat-icon>
    </button>
    <button i18n-matTooltip mat-button matSuffix mat-icon-button (click)="refreshEmployees()">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>
</div>

<div class="data-table">
  <div class="d-flex">
    <mat-paginator [length]="itemsTotalCount" [(pageSize)]="requestForm.pageSize" [pageSizeOptions]="pageSizeOptions" (page)="changePage()"></mat-paginator>  
  </div>
  <table mat-table [dataSource]="dataSource" matSort *ngIf="employees">
    <ng-container matColumnDef="select" sticky *ngIf="maxCount!=1">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [disabled]="isExcluded(row)"
            [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!--nom Column -->
    <ng-container matColumnDef="nom">
      <th i18n mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
      <td mat-cell *matCellDef="let row">{{row.lastName}}</td>
    </ng-container>

    <!--prenom Column -->
    <ng-container matColumnDef="prenom">
      <th i18n mat-header-cell *matHeaderCellDef mat-sort-header>Prénom</th>
      <td mat-cell *matCellDef="let row">{{row.firstName}}</td>
    </ng-container>

    <!--email Column -->
    <ng-container matColumnDef="email">
      <th i18n mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let row">{{row.email}}</td>
    </ng-container>

    <!-- Star Column -->
    <ng-container matColumnDef="star" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <div class="btn-group float-right">
          <button i18n i18n-matTooltip class="primary"
            (click)="selectEmployee(row)"
            [disabled]="isExcluded(row)"
            matTooltip="Sélectionner cet élément" *ngIf="!selection || selection?.selected.length<=1">CHOISIR</button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
  </table>
  
  <div class="mt-4" *ngIf="itemsTotalCount=== 0">
    <span i18n class="pl-2">Aucun élément trouvé</span>
  </div>
</div>

<mat-dialog-actions align="end" class="pt-3">
  <button i18n mat-flat-button class="secondary-text mr-2" mat-dialog-close>ANNULER</button>

  <button i18n i18n-matTooltip mat-raised-button class="primary"
    matTooltip="Choisir les éléments sélectionnés"
    (click)="selectEmployees()"
    [disabled]="!selection || (maxCount>-1 && selection?.selected.length >= maxCount)"
    *ngIf="selection && selection?.selected.length>1">CHOISIR</button>
</mat-dialog-actions>
