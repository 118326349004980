<h5 i18n>Exporter</h5>

<div class="row dialog-text-small" style="height: 40px;" (keyup.enter)="select();">

  <flags-selector i18n-label class="col-2 highlighted" appearance="outline" [(itemId)]="format" [type]="FlagTypes.ExportFormats"
    (selectionChange)="refreshData()" [action]="ActionKinds.List" [excluding]="[ExportFormats.Undefined, ExportFormats.Any, ExportFormats.Json]"
    label="Type"></flags-selector>

  <mat-form-field class="col-4" appearance="outline">
    <mat-label i18n>Nom du fichier</mat-label>
    <input name="fileName" #inputFileName="matInput" matInput [(ngModel)]="fileName" />
  </mat-form-field>  
  <mat-form-field class="col-4" appearance="outline" (keyup.enter)="select();">
    <mat-label i18n>Titre du document</mat-label>
    <input name="documentDisplayName" #inputNom="matInput" matInput [(ngModel)]="documentDisplayName" />
  </mat-form-field>
  <mat-form-field class="col-2" appearance="outline">
    <mat-label i18n>Langue</mat-label>
    <mat-select name="culture" [(ngModel)]="culture">
      <mat-option [value]="lang.key" *ngFor="let lang of getAvailableLangs">{{lang.value}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<columns-list-view #columnsListView (selected)="selectedColumns=$event;"
  [presenterSpec]="presenterSpec" [default]="defaultSelectedColumnNames"
  [excluding]="excludingColumnNames"></columns-list-view>

<mat-dialog-actions align="end" class="pt-3 zoomed">

  <button i18n i18n-matTooltip class="secondary" (click)="reset();" matTooltip="Réinitialiser les colonnes">REINITIALISER</button>

  <div class="spacer-item"></div>

  <button i18n mat-flat-button class="secondary-text mr-2" mat-dialog-close>ANNULER</button>

  <button i18n i18n-matTooltip class="primary"
    (click)="select()"
    [disabled]="!(selectedColumns?.length>0)">VALIDER</button>
</mat-dialog-actions>  
