export enum PersonneTitres {
    
    // Undefined.
    Undefined = "Undefined",

    /// Mr.
    Mr = "Mr",

    // Mme.
    Mme = "Mme",

    // Any.
    Any = "Any"
}
