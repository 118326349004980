<div class="mp-0" *ngIf="utilisateur">
  <div class="col-xl-3 col-sm-6 col-12 mp-0">
    <flags-selector i18n-label [type]="FlagTypes.AppRoles" appearance="outline"
      [itemId]="roleId" (itemIdChange)="setRole($event);" [excluding]="excludedRoleIds"
      [action]="ActionKinds.Edit" label="Rôles"
      (selectionChange)="hasChanged();"></flags-selector>
  </div>

  <div *ngIf="roleId==='FIDAL_MARKMANAGER_MANAGER' || roleId==='FIDAL_MARKMANAGER_MEMBER' || roleId==='FIDAL_MARKMANAGER_VISITOR'" >
    <!-- Affectations -->

    <div class="mb-4">
      <div class="row ml-1">
        <strong i18n>Affectations clients</strong>
      </div>
      <div class="pt-3">
        <div class="col-xl-3 col-sm-6 col-12 mp-0">        
          <flags-selector i18n-label [type]="FlagTypes.ClientsAccesTypes" appearance="outline"          
            [itemId]="utilisateur.clientsAccesType" (itemIdChange)="setGestionnaireType($event);"
            [action]="ActionKinds.Edit" [excluding]="excludedClientsAccesTypeIds"
            label="Accès clients"
            (selectionChange)="hasChanged();"></flags-selector>
        </div>
    
        <client-access-listview [(items)]="utilisateur.clientAccess" 
            [clientsAccesType]="utilisateur.clientsAccesType"
            [parentId]="utilisateur?.type===FidalUserKinds.Client ? utilisateur.client?.id : null" [additionRestricted]="true"
            *ngIf="utilisateur.clientsAccesType && utilisateur.clientsAccesType!=ClientsAccesTypes.None && utilisateur.clientsAccesType!=ClientsAccesTypes.TotalAcces"
            (selectionChange)="hasChanged();"></client-access-listview>  
      </div>
    </div>

    <div class="mb-4">
      <div class="row ml-1">
        <strong i18n>Affectations dossiers</strong>
      </div>
      <div class="pt-3">
        <div class="col-xl-3 col-sm-6 col-12 mp-0">
          <flags-selector i18n-label i18n-label [type]="FlagTypes.DossiersAccesTypes" appearance="outline"          
            [(itemId)]="utilisateur.dossiersAccesType"
            [action]="ActionKinds.Edit"
            label="Accès dossiers"
            (selectionChange)="hasChanged();"></flags-selector>
        </div>

        <div class="filter-pane-queries text-normal" *ngIf="globalSpec && utilisateur.dossiersAccesType===DossiersAccesTypes.RestreintAcces">
          <filtre-clause class="col-12 mp-0" [item]="dossiersFiltreClause"
            [presenterSpec]="globalSpec" [queryData]="{ clients: associeClients }" [mode]="FidalUserKinds.Standard"
            (submited)="setQueryString()" (removeItem)="resetQuery()"></filtre-clause>
        </div>
      </div>
    </div>

  </div>
</div>
