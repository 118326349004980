import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivate } from '@angular/router';
import { ConfirmationDialogComponent } from '@components/shared/dialogs/confirmation-dialog.component';
import { Observable, of } from 'rxjs';
import { ComponentCanDeactivate } from './ComponentCanDeactivate';

@Injectable()
export class PendingChangesGuardService implements CanDeactivate<ComponentCanDeactivate> {

  constructor(
    private dialog: MatDialog) {  
  }

  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> {

    let result = component?.canDeactivate();
    if (!result)
    {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: $localize `Vous avez des informations non enregistrées. Voulez-vous réellement quitter cette page ?`
      });
      return dialogRef.afterClosed();
    }
    else
    {
      return of(true);
    }
  }
}