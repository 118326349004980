<div class="d-flex">
  <mat-form-field *ngIf="searchable && items?.length>1" class="col-lg-4 col-md-5 col-sm-4 col-3 screen-md">
    <mat-label i18n>Mot clé</mat-label>
    <input i18n-placeholder matInput [(ngModel)]="motcle" (keyup.enter)="refreshItems()" />
    <button mat-button matSuffix mat-icon-button *ngIf="motcle" (click)="$event.stopPropagation(); motcle=null; refreshItems();">
      <mat-icon>clear</mat-icon>
    </button>
    <button mat-button matSuffix mat-icon-button (click)="refreshItems()">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>

  <div class="spacer-item"></div>
  <mat-paginator [class]="showPaginator ? '' : 'd-none'" [length]="items?.length" [pageSize]="pageSize"></mat-paginator>  
</div>

<div *ngIf="readonly && items?.length === 0">
  <span i18n>Aucun</span>
</div>

<div class="mb-3 data-table border-top" *ngIf="items?.length > 0">
  <table mat-table [dataSource]="dataSource" matSort>

    <!--reference Column -->
    <ng-container matColumnDef="reference">
      <td mat-cell *matCellDef="let row">
        <span class="text-highlighted">{{row.reference}}</span>
      </td>
    </ng-container>

    <!--denomination Column -->
    <ng-container matColumnDef="denomination">
      <td mat-cell *matCellDef="let row">{{row.denomination}}</td>
    </ng-container>

    <!--statut Column -->
    <ng-container matColumnDef="statut">
      <td mat-cell *matCellDef="let row">
        <span *ngIf="row.statut!=DossierStatuts.Abandonne && row.statut!=DossierStatuts.Rejete">{{dossierStatuts[row.statut] ?? '-'}}</span>

        <span *ngIf="row.statut===DossierStatuts.Abandonne || row.statut===DossierStatuts.Rejete" class="font-weight-bold">{{dossierStatuts[row.statut] ?? '-'}}</span>
      </td>
    </ng-container>

    <!--pays Column -->
    <ng-container matColumnDef="pays">
      <td mat-cell *matCellDef="let row">{{row.pays?.libelle ?? '-'}}</td>
    </ng-container>

    <!-- Star Column -->
    <ng-container matColumnDef="star" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <div class="btn-group">

          <button i18n-matTooltip mat-flat-button class="secondary-text" *ngIf="!readonly"
            matTooltip="Retirer cet élément"
            (click)="$event.stopPropagation(); removeDossier(row)">
            <mat-icon>remove_circle_outline</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)='viewDossier(row)' ></tr>
  </table>
</div>

<button i18n-matTooltip mat-flat-button class="secondary button-small"
  *ngIf="addingMode==='New' && !this.readonly && (this.maxCount === -1 || this.items?.length<this.maxCount)"
  matTooltip="Ajouter un nouvel élement"
  (click)="addNewDossier()">
  <mat-icon>add</mat-icon> <span i18n>Ajouter</span>
</button>

<button i18n-matTooltip mat-flat-button class="secondary button-small"
  *ngIf="addingMode==='Existing' && !this.readonly && (this.maxCount === -1 || this.items?.length<this.maxCount)"
  matTooltip="Ajouter un élement"
  (click)="addExistingDossier()">
  <mat-icon>add</mat-icon> <span i18n>Ajouter</span>
</button>