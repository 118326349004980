import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackBarExtension } from "@services/nav/snackbar-extension";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { MessageKind } from '@models/enums/MessageKind';
import { AuthService } from "@services/app/auth.service";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(
      private snackBar: MatSnackBar
    ) { }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {

      // if we get Unauthorization errors
      if (err.status === 401 || err.status === 403) {
        
        // then we logout
        SnackBarExtension.open(this.snackBar, $localize `Droits insuffisants pour accéder à cette ressource. Veuillez rafraîchir la page`, MessageKind.Exception);

          // if you've caught / handled the error, we don't want to rethrow it
          return of(err.message);
      }
      return throwError(err);
  }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      return next.handle(request).pipe(catchError(x=> this.handleAuthError(x)))
    }
}