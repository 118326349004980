<div class="row pt-1 pb-1">
  <mat-form-field class="col-6" appearance="outline">
    <mat-label i18n>Créé le</mat-label>
    <input name="creationDate" matInput [ngModel]="item?.creationDate | date: 'dd/MM/yyyy HH:mm:ss'" readonly />
  </mat-form-field>

  <mat-form-field class="col-6" appearance="outline" *requiresUserRoles="[AppRoles.Manager, AppRoles.Admin, AppRoles.Super]">
    <mat-label i18n>Créé par</mat-label>
    <input name="createdBy" matInput [ngModel]="createdBy" readonly />
  </mat-form-field>
</div>
<div class="row">
  <mat-form-field class="col-6" appearance="outline">
    <mat-label i18n>Modifié le</mat-label>
    <input name="lastModificationDate" matInput [ngModel]="item?.lastModificationDate | date: 'dd/MM/yyyy HH:mm:ss'" readonly />
  </mat-form-field>

  <mat-form-field class="col-6" appearance="outline" *requiresUserRoles="[AppRoles.Manager, AppRoles.Admin, AppRoles.Super]">
    <mat-label i18n>Modifié par</mat-label>
    <input name="lastModifiedBy" matInput [ngModel]="lastModifiedBy" readonly />
  </mat-form-field>
</div>
