<div class="content-pane">
  <h2 i18n>Nouveau type de contact</h2>

  <div class="inner mb-5">
    <form #contactTypeForm="ngForm" (ngSubmit)="$event.preventDefault()" *ngIf="contactType">
      <div class="p-4 form col-8 card-pane">
    
        <div class="pb-4">
          <h3 i18n>Libellés</h3>
    
          <div class="mp-0 col-12">
            <dictionary-items-listview #globalLibelleListView [(item)]="contactType['global.libelle']" [required]="true"></dictionary-items-listview>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="bottom pt-5 row ml-2">
    <button i18n-matTooltip i18n-matTooltip mat-raised-button class="primary" *requiresUserPolicies="[AppPolicies.ReferentielWriters]"
      matTooltip="Ajouter un nouvel élement"
      (click)="addContactType()">AJOUTER</button>
    &nbsp;&nbsp;
    <button i18n-matTooltip i18n-matTooltip mat-flat-button class="secondary-text" (click)="$event.preventDefault(); back();">ANNULER</button>
  </div>
</div>
