import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { UtilsHelper } from '@services/helpers/utils-helper'
import { AuthService } from '@services/app/auth.service';
import { IChartDatasetDto } from '@models/dtos/data/stats/IChartDatasetDto';
import { AppService } from '@services/app/app.service';
import { IStatsRequestFormPoco_Marques } from '@models/pocos/forms/IStatsRequestFormPoco_Marques';

@Injectable()
export class StatsService {

    private baseApiUri_Marques_Registres: string;
    private baseApiUri_Marques_Statuts: string;
    private baseApiUri_Marques_Depots: string;

    constructor(
        private authService : AuthService,
        private httpClient: HttpClient,
        @Inject(LOCALE_ID) private locale: string
    ) {
        let baseApiUri: string = AppService.__Settings.platform.services[0].apiBaseUri + '/v1/data/stats';

        this.baseApiUri_Marques_Registres = baseApiUri + '/marques/registres';
        this.baseApiUri_Marques_Statuts = baseApiUri + '/marques/statuts';
        this.baseApiUri_Marques_Depots = baseApiUri + '/marques/depots';
    }

    // Creation

    getStatisque_Marques_Registres(requestForm: IStatsRequestFormPoco_Marques = null): Observable<IChartDatasetDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IChartDatasetDto>(`${this.baseApiUri_Marques_Registres}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getStatisque_Marques_Statuts(requestForm: IStatsRequestFormPoco_Marques = null): Observable<IChartDatasetDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IChartDatasetDto>(`${this.baseApiUri_Marques_Statuts}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getStatisque_Marques_Depots(requestForm: IStatsRequestFormPoco_Marques = null): Observable<IChartDatasetDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IChartDatasetDto>(`${this.baseApiUri_Marques_Depots}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }
}