<h5 i18n>Choisir les colonnes</h5>

<columns-list-view #columnsListView (selected)="selectedColumns=$event;"
  [presenterSpec]="presenterSpec" [default]="defaultSelectedColumnNames"
  [excluding]="excludingColumnNames"></columns-list-view>

<mat-dialog-actions align="end" class="pt-3 zoomed">

  <button i18n i18n-matTooltip class="secondary" (click)="reset();" matTooltip="Réinitialiser les colonnes">REINITIALISER</button>

  <div class="spacer-item"></div>

  <button i18n mat-flat-button class="secondary-text mr-2" mat-dialog-close>ANNULER</button>

  <button i18n i18n-matTooltip class="primary"
    (click)="select()"
    [disabled]="!(selectedColumns?.length>0)">VALIDER</button>
</mat-dialog-actions>
