import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { MessageKind } from '@models/enums/MessageKind';

export class SnackBarExtension {

  public static open(
    snackBar: MatSnackBar,
    message: string,
    messageKind: MessageKind = MessageKind.Information,
    action: string = null,
    duration: number = 2000): MatSnackBarRef<TextOnlySnackBar>
  {
    let panelClass = null;
    switch (messageKind)
    {
      case MessageKind.Error:
        panelClass = ['mat-toolbar', 'mat-warn']
        break;
      case MessageKind.Exception:
          panelClass = ['mat-toolbar', 'mat-exception']
        break;
      case MessageKind.Information:
        panelClass = ['mat-toolbar', 'mat-information']
        break;        
      case MessageKind.Message:
        panelClass = ['mat-toolbar', 'mat-primary']
        break;
      case MessageKind.Warning:
        panelClass = ['mat-toolbar', 'mat-accent']
        break;
      case MessageKind.Success:
        panelClass = ['mat-toolbar', 'mat-success']
        break;
    }

    return snackBar.open(message, action, {
      duration: duration,
      panelClass: panelClass
    });
  }
}