export enum DossierNatures {

        /// <summary>
        /// Undefined.
        /// </summary>
        Undefined = "Undefined",

        /// <summary>
        /// Marque.
        /// </summary>
        Marque = "Marque",

        /// <summary>
        /// Modèle.
        /// </summary>
        Modele = "Modele",

        /// <summary>
        /// Any.
        /// </summary>
        Any = "Any"
}
