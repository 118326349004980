<h2 i18n mat-dialog-title>Calcul de la date de renouvellement</h2>

<form #resultatForm="ngForm" (ngSubmit)="$event.preventDefault()">
  <div mat-dialog-content class="mb-2 small" *ngIf="resultat">

    <div class="row">
      <references-selector i18n-label class="col-12"
        [item]="resultat.dossierPays" [type]="ReferenceTypes.DossierPays" appearance="outline"
        [readonly]="true"
        label="Pays"></references-selector> 
    </div>

    <div class="row">
      <mat-form-field class="col-8" appearance="outline">
        <mat-label i18n>Règle</mat-label>
        <textarea name="renouvellementRegle" matInput readonly [ngModel]="getResultatRegle()" cdkTextareaAutosize  cdkAutosizeMinRows="4"></textarea>
      </mat-form-field>
    </div>

    <hr />

    <div class="row pt-2">
      <mat-form-field class="col-3" appearance="outline">
        <mat-label i18n>Dernière date</mat-label>
        <input name="dernierRenouvellementDate" matInput readonly [ngModel]="resultat.dernierDate | date: 'shortDate'" />
      </mat-form-field>

      <mat-form-field class="col-3 hightlighted" appearance="outline" class="highlighted">
        <mat-label i18n>Prochaine date</mat-label>
        <input name="prochainDate" matInput readonly [ngModel]="resultat.prochainDate | date: 'shortDate'" />
      </mat-form-field>
    </div>
  </div>

  <mat-dialog-actions align="end" class="pt-3">
    <button i18n mat-flat-button class="secondary-text" mat-dialog-close>ANNULER</button>
    <button i18n mat-raised-button class="primary mr-2" (click)="select()" *ngIf="resultat" [disabled]="!resultat.prochainDate">CHOISIR</button>
  </mat-dialog-actions>
</form>
