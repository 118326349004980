import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {IResultItemDto} from '@models/dtos/common/IResultItemDto'
import {IContactTypeDto} from '@models/dtos/management/referentiel/IContactTypeDto'
import {IDossierPaysDto} from '@models/dtos/management/referentiel/IDossierPaysDto'
import {ITribunalDto} from '@models/dtos/management/referentiel/ITribunalDto'
import {IDelaiTypeDto} from '@models/dtos/management/referentiel/IDelaiTypeDto'
import {ILocarnoClasseDto} from '@models/dtos/management/referentiel/ILocarnoClasseDto'
import {INiceClasseDto} from '@models/dtos/management/referentiel/INiceClasseDto'
import { UtilsHelper } from '@services/helpers/utils-helper'
import { AppService } from '@services/app/app.service'
import { AuthService } from '@services/app/auth.service';
import { DomainService } from './domain.service';
import { IContentieuxTypeDto } from '@models/dtos/management/referentiel/IContentieuxTypeDto';
import { IReferenceRequestFormPoco } from '@models/pocos/forms/IReferenceRequestFormPoco';
import { IDataKeyValue } from '@models/dtos/common/IDataKeyValue';
import { ReferenceType } from '@models/enums/domain/ReferenceType';
import { IPaysDto } from '@models/dtos/management/referentiel/IPaysDto';
import { IRegionalDirectionDto } from '@models/dtos/management/referentiel/IRegionalDirectionDto';
import { IFlagsRequestFormPoco } from '@models/pocos/forms/IFlagsRequestFormPoco';
import { QueryResultModes } from '@models/enums/QueryResultModes';
import { IBordTableauDto } from '@models/dtos/management/referentiel/IBordTableauDto';
import { IRequeteRequestFormPoco } from '@models/pocos/forms/IRequeteRequestFormPoco';

@Injectable()
export class ReferentielService {

    private baseApiUri_Donnees: string;
    private baseApiUri_BordTableaux: string;
    private baseApiUri_Flags: string;
    private baseApiUri_Pays: string;
    private baseApiUri_DossierPays: string;
    private baseApiUri_RegionalDirections: string;
    private baseApiUri_Tribunaux: string;
    private baseApiUri_DelaiTypes: string;
    private baseApiUri_LocarnoClasses: string;
    private baseApiUri_NiceClasses: string;
    private baseApiUri_ContactTypes: string;
    private baseApiUri_ContentieuxTypes: string;

    constructor(
        private authService : AuthService,
        private httpClient: HttpClient,
        private domainService: DomainService,
        @Inject(LOCALE_ID) private locale: string
        
    ) {
        let baseApiUri: string = AppService.__Settings.platform.services[0].apiBaseUri + '/v1/referentiel';

        this.baseApiUri_Donnees= baseApiUri;

        this.baseApiUri_BordTableaux = baseApiUri + '/bordTableaux';
        this.baseApiUri_Flags = baseApiUri + '/flags';
        this.baseApiUri_Pays =  baseApiUri + '/pays';
        this.baseApiUri_DossierPays = baseApiUri + '/dossierpays';
        this.baseApiUri_RegionalDirections = baseApiUri + '/regionaldirections';
        this.baseApiUri_Tribunaux = baseApiUri + '/tribunaux';
        this.baseApiUri_DelaiTypes = baseApiUri + '/delaitypes';
        this.baseApiUri_LocarnoClasses = baseApiUri + '/classifications/locarno';
        this.baseApiUri_NiceClasses = baseApiUri + '/classifications/nice';
        this.baseApiUri_ContactTypes = baseApiUri + '/contacttypes';
        this.baseApiUri_ContentieuxTypes = baseApiUri + '/contentieuxtypes';
    }

    // Flags

    listFlags(requestForm: IFlagsRequestFormPoco): Observable<IDataKeyValue[]> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IDataKeyValue[]>(`${this.baseApiUri_Flags}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    // Donnees

    listDonnees(requestForm: IReferenceRequestFormPoco): Observable<IDataKeyValue[]> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IDataKeyValue[]>(`${this.baseApiUri_Donnees}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    // Pays

    repairPays(pays: IPaysDto) {

        this.domainService.repairLibeledDto(pays);
    }

    listPays(requestForm: IReferenceRequestFormPoco = null): Observable<IPaysDto[]> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IPaysDto[]>(`${this.baseApiUri_Pays}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getPays(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IPaysDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IPaysDto>(`${this.baseApiUri_Pays}/${id || ""}?mode=${mode || ""}`, { headers: headers });
    }

    addPays(pays: IPaysDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_Pays}`, pays, { headers: headers });
    }

    updatePays(pays: IPaysDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.put<IResultItemDto>(`${this.baseApiUri_Pays}`, pays, { headers: headers });
    }

    deletePays(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto>(`${this.baseApiUri_Pays}/${id || ""}`, { headers: headers });
    }

    deleteDesPays(ids: string[]): Observable<IResultItemDto[]> {
        if (!ids) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto[]>(`${this.baseApiUri_Pays}?${UtilsHelper.getQueryString({ids: ids})}`, { headers: headers })
    }

    // DossierPays

    repairDossierPays(dossierPays: IDossierPaysDto) {

        this.domainService.repairLibeledDto(dossierPays);
    }

    listDossierPays(requestForm: IReferenceRequestFormPoco = null): Observable<IDossierPaysDto[]> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IDossierPaysDto[]>(`${this.baseApiUri_DossierPays}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getDossierPays(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IDossierPaysDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IDossierPaysDto>(`${this.baseApiUri_DossierPays}/${id || ""}?mode=${mode || ""}`, { headers: headers });
    }

    addDossierPays(dossierPays: IDossierPaysDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_DossierPays}`, dossierPays, { headers: headers });
    }

    updateDossierPays(dossierPays: IDossierPaysDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.put<IResultItemDto>(`${this.baseApiUri_DossierPays}`, dossierPays, { headers: headers });
    }

    deleteDossierPays(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto>(`${this.baseApiUri_DossierPays}/${id || ""}`, { headers: headers });
    }

    deleteDesDossierPays(ids: string[]): Observable<IResultItemDto[]> {
        if (!ids) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto[]>(`${this.baseApiUri_DossierPays}?${UtilsHelper.getQueryString({ids: ids})}`, { headers: headers })
    }

    // RegionalDirection

    repairRegionalDirection(regionalDirection: IRegionalDirectionDto) {

        this.domainService.repairLibeledDto(regionalDirection);
    }

    listRegionalDirections(requestForm: IReferenceRequestFormPoco = null): Observable<IRegionalDirectionDto[]> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IRegionalDirectionDto[]>(`${this.baseApiUri_RegionalDirections}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getRegionalDirection(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IRegionalDirectionDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IRegionalDirectionDto>(`${this.baseApiUri_RegionalDirections}/${id || ""}?mode=${mode || ""}`, { headers: headers });
    }

    addRegionalDirection(regionalDirection: IRegionalDirectionDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_RegionalDirections}`, regionalDirection, { headers: headers });
    }

    updateRegionalDirection(regionalDirection: IRegionalDirectionDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.put<IResultItemDto>(`${this.baseApiUri_RegionalDirections}`, regionalDirection, { headers: headers });
    }

    deleteRegionalDirection(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto>(`${this.baseApiUri_RegionalDirections}/${id || ""}`, { headers: headers });
    }

    deleteRegionalDirections(ids: string[]): Observable<IResultItemDto[]> {
        if (!ids) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto[]>(`${this.baseApiUri_RegionalDirections}?${UtilsHelper.getQueryString({ids: ids})}`, { headers: headers })
    }

    // Tribunaux

    repairTribunal(tribunal: ITribunalDto) {
        this.domainService.repairLibeledDto(tribunal);
    }

    listTribunaux(requestForm: IReferenceRequestFormPoco = null): Observable<ITribunalDto[]> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<ITribunalDto[]>(`${this.baseApiUri_Tribunaux}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getTribunal(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<ITribunalDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<ITribunalDto>(`${this.baseApiUri_Tribunaux}/${id || ""}?mode=${mode || ""}`, { headers: headers });
    }

    addTribunal(Tribunal: ITribunalDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_Tribunaux}`, Tribunal, { headers: headers });
    }

    updateTribunal(Tribunal: ITribunalDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.put<IResultItemDto>(`${this.baseApiUri_Tribunaux}`, Tribunal, { headers: headers });
    }

    deleteTribunal(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto>(`${this.baseApiUri_Tribunaux}/${id || ""}`, { headers: headers });
    }

    deleteTribunaux(ids: string[]): Observable<IResultItemDto[]> {
        if (!ids) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto[]>(`${this.baseApiUri_Tribunaux}?${UtilsHelper.getQueryString({ids: ids})}`, { headers: headers })
    }

    // Type d'échéances

    repairDelaiType(DelaiType: IDelaiTypeDto) {

        this.domainService.repairLibeledDto(DelaiType);
    }

    listDelaiTypes(requestForm: IReferenceRequestFormPoco = null): Observable<IDelaiTypeDto[]> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IDelaiTypeDto[]>(`${this.baseApiUri_DelaiTypes}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getDelaiType(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IDelaiTypeDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IDelaiTypeDto>(`${this.baseApiUri_DelaiTypes}/${id || ""}?mode=${mode || ""}`, { headers: headers });
    }

    addDelaiType(DelaiType: IDelaiTypeDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_DelaiTypes}`, DelaiType, { headers: headers });
    }

    updateDelaiType(DelaiType: IDelaiTypeDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.put<IResultItemDto>(`${this.baseApiUri_DelaiTypes}`, DelaiType, { headers: headers });
    }

    deleteDelaiType(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto>(`${this.baseApiUri_DelaiTypes}/${id || ""}`, { headers: headers });
    }

    deleteDelaiTypes(ids: string[]): Observable<IResultItemDto[]> {
        if (!ids) return of(null);
        const options = UtilsHelper.getUriOptionsWithBody(ids, this.authService);
        return this.httpClient.delete<IResultItemDto[]>(`${this.baseApiUri_DelaiTypes}`, options);
    }

    // Classes Locarno

    repairLocarnoClasse(locarnoClasse: ILocarnoClasseDto) {
        this.domainService.repairLibeledDto(locarnoClasse);
    }

    listLocarnoClasses(requestForm: IReferenceRequestFormPoco = null): Observable<ILocarnoClasseDto[]> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<ILocarnoClasseDto[]>(`${this.baseApiUri_LocarnoClasses}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getLocarnoClasse(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<ILocarnoClasseDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<ILocarnoClasseDto>(`${this.baseApiUri_LocarnoClasses}/${id || ""}?mode=${mode || ""}`, { headers: headers });
    }

    addLocarnoClasse(classe: ILocarnoClasseDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_LocarnoClasses}`, classe, { headers: headers })
    }

    updateLocarnoClasse(classe: ILocarnoClasseDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.put<IResultItemDto>(`${this.baseApiUri_LocarnoClasses}`, classe, { headers: headers });
    }

    deleteLocarnoClasse(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto>(`${this.baseApiUri_LocarnoClasses}/${id || ""}`, { headers: headers })
    }

    deleteLocarnoClasses(ids: string[]): Observable<IResultItemDto[]> {
        if (!ids) return of(null);
        const options = UtilsHelper.getUriOptionsWithBody(ids, this.authService);
        return this.httpClient.delete<IResultItemDto[]>(`${this.baseApiUri_LocarnoClasses}`, options);
    }

    // Classes Nice

    repairNiceClasse(niceClasse: INiceClasseDto) {
        this.domainService.repairLibeledDto(niceClasse);
    }

    listNiceClasses(requestForm: IReferenceRequestFormPoco = null): Observable<INiceClasseDto[]> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<INiceClasseDto[]>(`${this.baseApiUri_NiceClasses}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getNiceClasse(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<INiceClasseDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<INiceClasseDto>(`${this.baseApiUri_NiceClasses}/${id || ""}?mode=${mode || ""}`, { headers: headers });
    }

    addNiceClasse(classe: INiceClasseDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_NiceClasses}`, classe, { headers: headers })
    }

    updateNiceClasse(classe: INiceClasseDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.put<IResultItemDto>(`${this.baseApiUri_NiceClasses}`, classe, { headers: headers })
    }

    deleteNiceClasse(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto>(`${this.baseApiUri_NiceClasses}/${id || ""}`, { headers: headers })
    }

    deleteNiceClasses(ids: string[]): Observable<IResultItemDto[]> {
        if (!ids) return of(null);
        const options = UtilsHelper.getUriOptionsWithBody(ids, this.authService);
        return this.httpClient.delete<IResultItemDto[]>(`${this.baseApiUri_NiceClasses}`, options);
    }

    // Types de contacts

    repairContactType(contactType: IContactTypeDto) {
        this.domainService.repairLibeledDto(contactType);
    }

    listContactTypes(requestForm: IReferenceRequestFormPoco = null): Observable<IContactTypeDto[]> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IContactTypeDto[]>(`${this.baseApiUri_ContactTypes}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getContactType(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IContactTypeDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IContactTypeDto>(`${this.baseApiUri_ContactTypes}/${id || ""}?mode=${mode || ""}`, { headers: headers });
    }

    addContactType(ContactType: IContactTypeDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_ContactTypes}`, ContactType, { headers: headers })
    }

    updateContactType(ContactType: IContactTypeDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.put<IResultItemDto>(`${this.baseApiUri_ContactTypes}`, ContactType, { headers: headers })
    }

    deleteContactType(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto>(`${this.baseApiUri_ContactTypes}/${id || ""}`, { headers: headers })
    }

    deleteContactTypes(ids: string[]): Observable<IResultItemDto[]> {
        if (!ids) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto[]>(`${this.baseApiUri_ContactTypes}?${UtilsHelper.getQueryString({ids: ids})}`, { headers: headers })
    }
    
    // Types de contentieux

    repairContentieuxType(contentieuxType: IContentieuxTypeDto) {
        this.domainService.repairLibeledDto(contentieuxType);
    }

    listContentieuxTypes(requestForm: IReferenceRequestFormPoco = null): Observable<IContentieuxTypeDto[]> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IContentieuxTypeDto[]>(`${this.baseApiUri_ContentieuxTypes}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getContentieuxType(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IContentieuxTypeDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IContentieuxTypeDto>(`${this.baseApiUri_ContentieuxTypes}/${id || ""}?mode=${mode || ""}`, { headers: headers });
    }

    addContentieuxType(ContentieuxType: IContentieuxTypeDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_ContentieuxTypes}`, ContentieuxType, { headers: headers })
    }

    updateContentieuxType(ContentieuxType: IContentieuxTypeDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.put<IResultItemDto>(`${this.baseApiUri_ContentieuxTypes}`, ContentieuxType, { headers: headers })
    }

    deleteContentieuxType(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto>(`${this.baseApiUri_ContentieuxTypes}/${id || ""}`, { headers: headers })
    }

    deleteContentieuxTypes(ids: string[]): Observable<IResultItemDto[]> {
        if (!ids) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto[]>(`${this.baseApiUri_ContentieuxTypes}?${UtilsHelper.getQueryString({ids: ids})}`, { headers: headers })
    }
    
    // Tableaux de bord

    repairBordTableau(BordTableau: IBordTableauDto) {

        this.domainService.repairLibeledDto(BordTableau);
    }

    listBordTableaux(requestForm: IReferenceRequestFormPoco = null): Observable<IBordTableauDto[]> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IBordTableauDto[]>(`${this.baseApiUri_BordTableaux}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getBordTableau(id: string, mode: QueryResultModes = QueryResultModes.Quick): Observable<IBordTableauDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IBordTableauDto>(`${this.baseApiUri_BordTableaux}/${id || ""}?mode=${mode || ""}`, { headers: headers });
    }

    addBordTableau(BordTableau: IBordTableauDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_BordTableaux}`, BordTableau, { headers: headers });
    }

    updateBordTableau(BordTableau: IBordTableauDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.put<IResultItemDto>(`${this.baseApiUri_BordTableaux}`, BordTableau, { headers: headers });
    }

    deleteBordTableau(id: string): Observable<IResultItemDto> {
        if (!id) return of(null);
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto>(`${this.baseApiUri_BordTableaux}/${id || ""}`, { headers: headers });
    }

    deleteBordTableaux(ids: string[]): Observable<IResultItemDto[]> {
        if (!ids) return of(null);
        const options = UtilsHelper.getUriOptionsWithBody(ids, this.authService);
        return this.httpClient.delete<IResultItemDto[]>(`${this.baseApiUri_BordTableaux}`, options);
    }
}