<div class="d-flex">
  <mat-form-field *ngIf="searchable && items?.length>1" class="col-lg-4 col-md-5 col-sm-4 col-3 screen-md">
    <mat-label i18n>Mot clé</mat-label>
    <input i18n-placeholder matInput [(ngModel)]="motcle" (keyup.enter)="refreshItems()" />
    <button mat-button matSuffix mat-icon-button *ngIf="motcle" (click)="$event.stopPropagation(); motcle=null; refreshItems();">
      <mat-icon>clear</mat-icon>
    </button>    
    <button mat-button matSuffix mat-icon-button (click)="refreshItems()">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>

  <div class="spacer-item"></div>

  <mat-paginator [class]="showPaginator ? '' : 'd-none'" [length]="items?.length" [pageSize]="pageSize"></mat-paginator>  
</div>

<div *ngIf="readonly && items?.length === 0" class="pt-2">
  <span i18n>Aucun</span>
</div>

<div class="mb-3 data-table border-top" *ngIf="items?.length > 0">
  <table mat-table [dataSource]="dataSource" matSort>

    <!--nom Column -->
    <ng-container matColumnDef="nom">
      <td mat-cell *matCellDef="let row" (click)='viewItem(row)'>{{row.client?.denomination}}</td>
    </ng-container>

    <ng-container matColumnDef="clientsAccesType">
      <td mat-cell class="col-3 col-lg-1 pr-2 pt-1" *matCellDef="let row">
        <mat-form-field appearance="outline">
          <mat-label i18n>Type d'accès</mat-label>
          <mat-select name="clientsAccesType"
            [(ngModel)]="row.clientsAccesType"
            (selectionChange)="hasChanged();">
            <mat-option *ngFor="let item of clientsAccesTypes | keyvalue" [value]="item.key">
            {{item.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
  
        <!-- <flags-selector [type]="FlagTypes.ClientsAccesTypes" appearance="outline"          
          [itemId]="row.clientsAccesType" (itemIdChange)="row.clientsAccesType=$event;"
          [action]="'Edit'" [excluding]="[ClientsAccesTypes.None, ClientsAccesTypes.TotalAcces, ClientsAccesTypes.RestreintAcces, ClientsAccesTypes.Gestionnaire]"
          label="Accès clients"
          (selectionChange)="hasChanged();"></flags-selector> -->
      </td>
    </ng-container>

    <!-- Star Column -->
    <ng-container matColumnDef="star" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <div class="btn-group">
          
          <button i18n-matTooltip mat-flat-button class="secondary-text" *ngIf="!readonly"
            matTooltip="Retirer cet élément"
            (click)="$event.stopPropagation(); removeItem(row)">
            <mat-icon>remove_circle_outline</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
  </table>
</div>

<button i18n-matTooltip mat-flat-button class="secondary text-small"
  matTooltip="Ajouter un nouvel élement" (click)="$event.stopPropagation(); addItem()"
  *ngIf="!readonly && (maxCount === -1 || items?.length<maxCount)">
  <mat-icon>add</mat-icon> <span i18n>Ajouter</span>
</button>
