import { Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import { AppRole } from '@models/enums/iam/AppRole';
import { AuthService } from '@services/app/auth.service';

/// role * : Any logged user
/// role <empty> : Unlogged user

@Directive({
  selector: '[requiresUserRoles]'
})
export class UserHasRolesDirective implements OnInit {

  @Input("requiresUserRoles") requiresUserRoles: string[] = [];

  _isVisible = false;

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private _templateRef: TemplateRef<any>,
    private authService: AuthService
  ) 
  {
  }

  ngOnInit() {

    this.hide();

    if (!this.requiresUserRoles)
    {
      this.show();
    }

    this.authService.isAuthenticatedSubjectChanged().subscribe(_ => {

      if (this.authService.isLoggedIn())
      {
        if (!(this.requiresUserRoles?.length>0))
        {
          this.hide();
        }
        else if (this.requiresUserRoles && (this.requiresUserRoles?.find(q=>q===AppRole.Any) || this.authService.hasRoles(...this.requiresUserRoles)))
        {
          this.show();
        }
      }
    });  
  }
  
  show()
  {
    this._isVisible = true;
    this._viewContainerRef.clear();
    this._viewContainerRef.createEmbeddedView(this._templateRef);  
  }

  hide()
  {
    this._viewContainerRef.clear();
  }
}