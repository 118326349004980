import {Component, Input, OnInit} from '@angular/core';
import { IDatedTrackedDto } from '@models/dtos/common/IDatedTrackedDto';
import { AppRole } from '@models/enums/iam/AppRole';
import { IamService } from '@services/app/iam.service';

@Component({
  selector: 'datedtracked-details',
  templateUrl: './datedtracked-details.component.html',
})
export class DatedTrackedDetailsComponent implements OnInit
{

  AppRoles = AppRole;

  @Input('item')
  public item: IDatedTrackedDto;

  public createdBy: string;
  public lastModifiedBy: string;

  constructor(private iamService: IamService) {
  }
  
  ngOnInit() {

    if (this.item?.createdBy)
    {
      this.iamService.getUtilisateur(this.item.createdBy).subscribe(result => {

        this.createdBy = (result?.prenom ? result?.prenom + " " : "") + (result?.nom ?? "");
      }, error => {
      });  
    }

    if (this.item?.lastModifiedBy)
    {
      this.iamService.getUtilisateur(this.item.lastModifiedBy).subscribe(result => {

        this.lastModifiedBy = (result?.prenom ? result?.prenom + " " : "") + (result?.nom ?? "");
      }, error => {
      });
  
    }
  }
}