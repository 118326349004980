import {Inject, Injectable, LOCALE_ID, Optional } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';

@Injectable()
export class CustomMomentDateAdapter extends MomentDateAdapter {
  // constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
  //   super(dateLocale);
  // }

  public format(date: moment.Moment, displayFormat: string): string
  {
    const format = this.locale === "fr" ? "DD/MM/yyyy" : displayFormat;
    
    return super.format(date, format);
  }
}