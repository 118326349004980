import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';import { GlobalConstants } from '@models/app/GlobalConstants';
import { IPaysDto } from '@models/dtos/management/referentiel/IPaysDto';
import { KeyCodes } from '@models/enums/KeyCodes';
import { MessageKind } from '@models/enums/MessageKind';
import { IReferenceRequestFormPoco } from '@models/pocos/forms/IReferenceRequestFormPoco';
import { ReferentielService } from '@services/business/referentiel.service';
import { SnackBarExtension } from '@services/nav/snackbar-extension';

@Component({
  selector: 'pays-search-dialog',
  templateUrl: './pays-search-dialog.component.html'
})
export class PaysSearchDialogComponent implements OnInit, AfterViewInit {

  pageSizeOptions: number[] = GlobalConstants.pageSizeOptions_Dialog;

  @ViewChild('inputMotCle') inputMotCle: MatInput;

  public requestForm: IReferenceRequestFormPoco;
  isLoading: boolean = false;

  payss: IPaysDto[];
  payssTotalCount: number;

  displayedColumns: string[];
  dataSource: MatTableDataSource<IPaysDto>;
  selection = new SelectionModel<IPaysDto>(true, []);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input('clientId')
  public clientId: string;

  @Input('excluding')
  public excludingPayss: IPaysDto[] = [];

  @Input('maxCount')
  public maxCount: number = -1;

  constructor(
    private referentielService: ReferentielService,
    private dialogRef: MatDialogRef<PaysSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ) {
    this.dialogRef.disableClose = true;

    if (data)
    {
      this.excludingPayss = data.excluding;
      this.maxCount = data.maxCount ?? -1;
    }

    if (this.maxCount===1)
    {
      this.displayedColumns = ['libelle', 'star'];
    }
    else
    {
      this.displayedColumns = ['select', 'libelle', 'star'];
    }
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });

    this.initRequestForm();
    this.refreshItems();
  }
  
  ngAfterViewInit() {
    setTimeout(() => {
      this.inputMotCle.focus();
    });
  }

  async initRequestForm(excludeStorage: boolean = false)
  {
    this.requestForm = <IReferenceRequestFormPoco> {  };
  }

  changePage() {

    this.refreshItems();
  }

  async refreshItems() {
    
    this.isLoading = true;

    this.referentielService.listPays(this.requestForm).subscribe(result => {
    
      this.payss = result?.sort((a, b)=> a.libelle?.toLowerCase().localeCompare(b.libelle?.toLowerCase()));
      this.dataSource = new MatTableDataSource<IPaysDto>(this.payss);

      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    }, error => {
      SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
      this.isLoading = false;
    });
  }

  selectItems()
  {
    this.dialogRef.close(this.selection.selected);
  }
   
  selectItem(pays: IPaysDto)
  {
    if (pays)
    {
      this.dialogRef.close([pays]);
    }
  }

  isExcluded(pays: IPaysDto)
  {
    return this.excludingPayss.find(q=> pays?.id === q.id);
  }

  // Multiselection ---------------

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected())
    {
      this.selection.clear();
    }
    else
    {
      this.selection.clear();
      this.dataSource.data.forEach(row => !this.isExcluded(row) && this.selection.select(row));
    }
  }

  checkboxLabel(row?: IPaysDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
  
  search() {
    if (this.requestForm.motcle?.length>3) { 
      this.refreshItems(); 
    }
  }

  select() {
    if (this.payss?.length===1)
    {
      this.selectItem(this.payss[0]);
    }
    else
    {
      this.refreshItems();
    }
  }
}
