export enum FlagType {
    
    // Undefined.
    Undefined = "Undefined",
    
    // Any.
    Any = "Any",

    // ActionKinds
    ActionKinds = "ActionKinds",

    // AppRoles
    AppRoles = "AppRoles",

    // ClientsAccesTypes
    ClientsAccesTypes = "ClientsAccesTypes",

    // ClientQualites.
    ClientQualites = "ClientQualites",

    // ClientStatuts.
    ClientStatuts = "ClientStatuts",

    // ClientTypes.
    ClientTypes = "ClientTypes",

    // ContactNatures.
    ContactNatures = "ContactNatures",

    // ContentieuxNatures.
    ContentieuxNatures = "ContentieuxNatures",

    // ContactTypes
    ContactTypes = "ContactTypes",

    // DataInstructions
    DataInstructions = "DataInstructions",

    // DataOperators.
    DataOperators = "DataOperators",

    // DataSortingModes.
    DataSortingModes = "DataSortingModes",

    // ClientsAccesTypes
    DossiersAccesTypes = "DossiersAccesTypes",

    // DossierStatuts.
    DossierStatuts = "DossierStatuts",

    // DossierNatures.
    DossierNatures = "DossierNatures",

    // EntityTypes
    EntityTypes = "EntityTypes",

    // EntrepriseObjectTypes.
    EntrepriseObjectTypes = "EntrepriseObjectTypes",

    // EvenementObjetTypes.
    EvenementObjetTypes = "EvenementObjetTypes",

    // EvenementTypes.
    EvenementTypes = "EvenementTypes",

    // ExportFormats
    ExportFormats = "ExportFormats",

    // FidalUserKinds
    FidalUserKinds = "FidalUserKinds",

    // IntervenantTypes.
    IntervenantTypes = "IntervenantTypes",

    // JuridictionTypes.
    JuridictionTypes = "JuridictionTypes",

    // MarqueRegistreTypes.
    MarqueRegistreTypes = "MarqueRegistreTypes",

    // MarqueTypes.
    MarqueTypes = "MarqueTypes",

    // ModeleRegistreTypes.
    ModeleRegistreTypes = "ModeleRegistreTypes",

    // PersonneTitres.
    PersonneTitres = "PersonneTitres",

    // PersonneTypes.
    PersonneTypes = "PersonneTypes"
}
