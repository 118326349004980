<div class="data-table">

  <div class="row" cdkDropListGroup>

    <!-- Select columns -->

    <div class="col-6 mt-3" style="overflow-y: auto; height: 20em;">
      <table mat-table [dataSource]="dataSource_selected" matSort *ngIf="this.presenterSpec?.columns"
        cdkDropList
        cdkDropListOrientation="vertical" (cdkDropListDropped)="drop($event)">

        <!--label Column -->
        <ng-container matColumnDef="label">
          <td mat-cell *matCellDef="let row">
            <mat-checkbox class="mr-2" [checked]="true" [disabled]="isExcluded(row)"></mat-checkbox>
            {{row.label}}</td>
        </ng-container>

        <tr mat-row class="pointable" (click)="unselectColumn(row);" *matRowDef="let row; columns: displayedColumns;"
          cdkDrag cdkDropListLockAxis="y" [cdkDragData]="row"></tr>
      </table>
    </div>

    <!-- Unselect columns -->

    <div class="col-6 mt-3" style="overflow-y: auto; height: 20em;">
      <table mat-table [dataSource]="dataSource_unselected" matSort *ngIf="this.presenterSpec?.columns"
        cdkDropList
        cdkDropListOrientation="vertical" (cdkDropListDropped)="drop($event)">

        <ng-container matColumnDef="label">
          <td mat-cell *matCellDef="let row">{{row.label}}</td>
        </ng-container>

        <tr mat-row class="pointable" (click)="selectColumn(row);" *matRowDef="let row; columns: displayedColumns;"
          cdkDrag cdkDropListLockAxis="y" [cdkDragData]="row" ></tr>
      </table>
    </div>
  </div>

</div>
