<div class="screen-center on-top" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<div class="col-12 mp-0" class="dossiersTotalCount>0">
  <div class="text-center">
    <span class="title">{{title}}</span>
  </div>
  <div>
    <div class="d-flex">
      <mat-paginator [length]="dossiersTotalCount" [(pageSize)]="requestForm.pageSize" [pageSizeOptions]="pageSizeOptions" (page)="changePage()"></mat-paginator>
    </div>
    
    <div class="data-table h-100 zoomed">
      <table mat-table [dataSource]="dataSource" matSort>

        <!--reference Column -->
        <ng-container matColumnDef="reference">
          <th i18n mat-header-cell *matHeaderCellDef mat-sort-header>Référence</th>
          <td class="col-2" mat-cell *matCellDef="let row">{{row.Reference}}</td>
        </ng-container>
    
        <!--denomination Column -->
        <ng-container matColumnDef="denomination">
          <th i18n mat-header-cell *matHeaderCellDef mat-sort-header>Dénomination</th>
          <td mat-cell *matCellDef="let row">{{row.Denomination}}</td>
        </ng-container>
    
        <!--pays Column -->
        <ng-container matColumnDef="pays">
          <th i18n mat-header-cell *matHeaderCellDef mat-sort-header>Pays</th>
          <td mat-cell *matCellDef="let row">{{row.PaysLibelle ?? '-'}}</td>
        </ng-container>

        <!-- Star Column -->
        <ng-container matColumnDef="star" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <div class="btn-group float-right">

              <button i18n-matTooltip mat-flat-button class="primary-text ml-1 screen-md"
                  matTooltip="Voir la fiche"
                  (click)="$event.stopPropagation(); viewDossier(row);">
                <mat-icon>pageview</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)='viewDossier(row)' ></tr>
      </table>

      <div class="mt-4" *ngIf="dossiersTotalCount=== 0">
        <span i18n class="pl-2">Aucun élément trouvé</span>
      </div>      
    </div>    
  </div>
</div>
