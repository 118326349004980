export enum DataOperators {

        //  Undefined.
        Undefined = "Undefined",
        // None.
        None = "None",
        // Exists.
        Existing = "Existing",
        // Contains.
        Containing = "Containing",
        // NotContaining.
        NotContaining = "NotContaining",
        // Equal.
        Equal = "Equal",
        // Different.
        Different = "Different",
        // Lesser.
        Lesser = "Lesser",
        // Lesser or equal.
        LesserOrEqual = "LesserOrEqual",
        // Greater.
        Greater = "Greater",
        // Greater or equal.
        GreaterOrEqual = "GreaterOrEqual",
        // Any data operator.
        Any = "Any"
}
