<h2 i18n mat-dialog-title>Délai de contentieux</h2>

<form #contentieuxDelaiForm="ngForm" (ngSubmit)="$event.preventDefault()">
  <div mat-dialog-content class="mb-2 small" *ngIf="contentieuxDelai">
    <div class="form-row">
      <references-selector i18n-label class="col-8" [type]="ReferenceTypes.DelaiTypes"
         [(item)]="contentieuxDelai.type"
        label="Type de délai"></references-selector>
    </div>

    <div class="form-row mb-3">
      <mat-form-field appearance="outline" class="ml-2">
        <mat-label i18n>Choisir une date</mat-label>
        <input name="date" matInput [matDatepicker]="picker" [(ngModel)]="contentieuxDelai.date" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-placeholder i18n>Date</mat-placeholder>
      </mat-form-field>
    </div>

    <div class="form-row">
      <mat-form-field class="col-7" appearance="outline">
        <mat-label i18n>Actions à entreprendre</mat-label>
        <input name="action" matInput [(ngModel)]="contentieuxDelai.action" (keyup.enter)="save()">
        <mat-placeholder i18n>Actions à entreprendre</mat-placeholder>
      </mat-form-field>
    </div>
  </div>

  <mat-dialog-actions align="end" class="pt-3">
    <button i18n mat-flat-button class="secondary-text" mat-dialog-close>ANNULER</button>
    <button i18n i18n-matTooltip mat-raised-button class="primary mr-2" (click)="save()" matTooltip="Enregistrer les modifications">ENREGISTRER</button>
  </mat-dialog-actions>
</form>