export enum ClientTypes {

    Undefined = "Undefined",

    Principale = "Principale",

    Liee = "Liee",

    Any = "Any"
}
