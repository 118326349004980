import { Component, Inject, Input, LOCALE_ID, OnInit, ViewChild, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { ReferentielService } from '@services/business/referentiel.service';
import { DateAdapter } from '@angular/material/core';
import { FlagType } from '@models/enums/domain/FlagType';
import { PersonneTypes } from '@models/enums/domain/PersonneTypes';
import { ClientTypes } from '@models/enums/domain/ClientTypes';
import { IClientDto } from '@models/dtos/runtime/contacts/IClientDto';
import { ContactsService } from '@services/business/contacts.service';
import { PageHistoryService } from '@services/nav/page-history.service';
import { MessageKind } from '@models/enums/MessageKind';
import { QueryResultModes } from '@models/enums/QueryResultModes';
import { EntityType } from '@models/enums/domain/EntityType';
import { StringHelper } from '@services/helpers/string-helper';
import { ReferenceType } from '@models/enums/domain/ReferenceType';

@Component({
  selector: 'client-snap-view',
  templateUrl: './client-snap-view.component.html'
})
export class ClientSnapViewComponent implements OnInit, OnChanges {

  isLoading: boolean = false;

  EntityTypes = EntityType;
  FlagTypes = FlagType;
  ReferenceTypes = ReferenceType;
  PersonneTypes = PersonneTypes;
  ClientTypes = ClientTypes;

  pageTitle: string;

  @ViewChild('clientForm') clientForm: NgForm;

  @Input('itemId')
  public clientId: string;

  public client: IClientDto;
  
  @Output('viewItem')
  public viewItemChange: EventEmitter<IClientDto> = new EventEmitter<IClientDto>();

  constructor(
    private contactsService: ContactsService,
    private pageHistoryService: PageHistoryService,
    private snackBar: MatSnackBar,
    private dateAdapter: DateAdapter<Date>,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.pageTitle = StringHelper.getTitle(this.client?.denomination);

    this.dateAdapter.setLocale(this.locale);
  }
  
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.hasOwnProperty("clientId")) {

      this.refreshClient();
    }
  }

  async refreshClient() {

    if (!this.clientId)
    {
      this.client = null;
    }
    else
    {
      this.isLoading = true;
      this.contactsService.getClient(this.clientId, QueryResultModes.Full).subscribe({
        next: result => {
          this.isLoading = false;

          // if there is no result then we redirect to list page
          if (!result)
          {
            let uri = this.pageHistoryService.popLast('/clients');
            this.pageHistoryService.navigateByUrl(uri);
          }
    
          this.client = result;
          this.contactsService.repairClient(this.client);      
        },
        error: _ => {
          this.isLoading = false;
          SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
          this.pageHistoryService.navigate(['/clients']);
        }
      });
    }
  }
  
  async viewClient(client: IClientDto)
  {
    this.viewItemChange?.emit(client);
  }
}
