<div class="col-12 mp-0">
  <div *ngIf="!readonly">
    <div [matMenuTriggerFor]="itemMenu" *ngIf="maxCount===1">
      <mat-form-field class="pointable col-12 mp-0" [appearance]="appearance"      
        (click)="$event.stopPropagation(); addItem();">
        <mat-label>{{label}}</mat-label>
        <input name="item" class="pointable" matInput value="{{getItemsText()}}" readonly [required]="required" />
        <mat-icon matSuffix (click)="$event.stopPropagation(); showMenu();" >keyboard_arrow_down</mat-icon>    
      </mat-form-field>
    </div>

    <div [matMenuTriggerFor]="itemMenu" *ngIf="maxCount!=1">
      <mat-form-field class="pointable col-12 mp-0" [appearance]="appearance" (click)="$event.stopPropagation(); showMenu();">
        <mat-label>{{label}}</mat-label>
        <input name="item" class="pointable" matInput value="{{getItemsText()}}" readonly [required]="required" />
        <mat-icon class="large" matSuffix
          *ngIf="!readonly && items && (maxCount===-1 || items.length < maxCount)"
          (click)="$event.stopPropagation(); addItem();" >add_box</mat-icon>    
      </mat-form-field>
    </div>

    <mat-menu #itemMenu="matMenu" yPosition="below" class="positioned-menu" [overlapTrigger]="false">
      <button i18n mat-menu-item *ngIf="items?.length>1" (click)="emptyItems()">Aucun</button>
      <button mat-menu-item *ngFor="let item of items" (click)="removeItem(item)">
        <mat-icon>check</mat-icon> 
        <mat-icon color="primary"
          i18n-matTooltip matTooltip="Voir la fiche de l'élément"
          (click)="$event.stopPropagation(); viewItem(item);" *ngIf="viewItemEnabled">{{getItemIcon()}}</mat-icon>
        {{getItemText(item)}}
      </button>
      <button i18n mat-menu-item *ngIf="!readonly && items && (maxCount===-1 || items.length===1 || items.length < maxCount)" (click)="addItem()">Choisir...</button>
    </mat-menu>
  </div>
  <div *ngIf="readonly">
    <mat-form-field class="pointable col-12 mp-0" *ngIf="!(items.length > 1)" [appearance]="appearance" (click)="$event.stopPropagation(); viewItem(items && items.length > 0 ? items[0] : null);">
      <mat-label>{{label}}</mat-label>
      <input name="item" class="pointable" matInput value="{{getItemsText()}}" readonly [required]="required" />
    </mat-form-field>

    <mat-form-field class="pointable col-12 mp-0" *ngIf="items.length > 1" [appearance]="appearance">
      <mat-label>{{label}} ({{items.length}})</mat-label>
      <mat-select [ngModel]="items" [required]="required" (selectionChange)="viewItem($event.value)">
        <mat-option *ngFor="let item of items" [value]="item">
          {{getItemText(item)}}
        </mat-option>
      </mat-select>
    </mat-form-field>    
  </div>  
</div>
