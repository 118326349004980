import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { IUtilisateurDto } from '@models/dtos/management/securite/IUtilisateurDto';
import { IUtilisateursRequestFormPoco } from '@models/pocos/forms/IUtilisateursRequestFormPoco';
import { ContactsService } from '@services/business/contacts.service';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { MessageKind } from '@models/enums/MessageKind';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { ClientsAccesTypes } from '@models/enums/domain/ClientsAccesTypes';
import { QueryResultModes } from '@models/enums/QueryResultModes';
import { UtilsHelper } from '@services/helpers/utils-helper';
import { KeyCodes } from '@models/enums/KeyCodes';

@Component({
  selector: 'gestionnaires-search-dialog',
  templateUrl: './gestionnaires-search-dialog.component.html',
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class GestionnairesSearchDialogComponent implements OnInit, AfterViewInit {

  pageSizeOptions: number[] = GlobalConstants.pageSizeOptions_Dialog;

  @ViewChild('inputMotCle') inputMotCle: MatInput;

  isLoading: boolean = false;

  requestForm: IUtilisateursRequestFormPoco;

  items: IUtilisateurDto[];
  itemsTotalCount: number;

  displayedColumns: string[];
  dataSource: MatTableDataSource<IUtilisateurDto>;
  selection = new SelectionModel<IUtilisateurDto>(true, []);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input('clientsAccesType')
  public clientsAccesType: ClientsAccesTypes = ClientsAccesTypes.Any;

  @Input('excluding')
  public excludingUtilisateurs: IUtilisateurDto[] = [];

  @Input('maxCount')
  public maxCount: number = -1;

 constructor(
    private contactsService: ContactsService,
    private dialogRef: MatDialogRef<GestionnairesSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ) {
    this.dialogRef.disableClose = true;

    if (data)
    {
      this.clientsAccesType = data.clientsAccesType;
      this.excludingUtilisateurs = data.excluding;
      this.maxCount = data.maxCount ?? -1;
    }

    if (this.maxCount===1)
    {
      this.displayedColumns = ['nom', 'prenom', 'star'];
    }
    else
    {
      this.displayedColumns = ['select', 'nom', 'prenom', 'star'];
    }
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });

    this.initRequestForm();
    this.refreshItems();
  }
  
  ngAfterViewInit() {
    setTimeout(() => {
      this.inputMotCle.focus();
    });
  }

  onClick(ev: Event) {
    ev.stopImmediatePropagation();
  }

  async initRequestForm()
  {
    this.requestForm = <IUtilisateursRequestFormPoco> { clientsAccesType: this.clientsAccesType, pageIndex: 0, pageSize: 8 };
  }

  changePage() {
    UtilsHelper.updateDataPageInfo(this.requestForm, this.paginator);

    this.refreshItems();
  }

  async refreshItems(initPageIndex: boolean = false) {

    if (initPageIndex)
    {
      this.requestForm.pageIndex = 0;
    }
    this.paginator.pageIndex = this.requestForm.pageIndex;
    this.requestForm.resultMode = QueryResultModes.Quick;
    this.requestForm.orderBy = "LastName";

    this.isLoading = true;
    this.contactsService.listGestionnaireUtilisateurs(this.requestForm).subscribe({
      next: result => {
        this.items = result.items;
        this.dataSource = new MatTableDataSource<IUtilisateurDto>(this.items);
        UtilsHelper.updateDataPageInfo(this.requestForm, result);
        this.itemsTotalCount = result?.totalCount;
  
        this.isLoading = false;
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
        this.isLoading = false;
      }
    });
  }

  selectItems()
  {
    this.dialogRef.close(this.selection.selected);
  }
     
  selectItem(utilisateur: IUtilisateurDto)
  {
    if (utilisateur)
    {
      this.dialogRef.close([utilisateur]);
    }
  }

  select()
  {
    if (this.items?.length===1)
    {
      this.selectItem(this.items[0]);
    }
    else
    {
      this.refreshItems(true);
    }
  }

  isExcluded(utilisateur: IUtilisateurDto)
  {
    return this.excludingUtilisateurs.find(q=> utilisateur?.id === q.id);
  }

  // Multiselection ---------------

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected())
    {
      this.selection.clear();
    }
    else
    {
      this.selection.clear();
      this.dataSource.data.forEach(row => !this.isExcluded(row) && this.selection.select(row));
    }
  }

  checkboxLabel(row?: IUtilisateurDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
}
