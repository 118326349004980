export enum EntityType {

    // None
    None = "None",

    // Client
    Client = "Client",

    // Fidal Employee
    FidalEmployee = "FidalEmployee",

    // Dossier
    Dossier = "Dossier",

    // DossierFamille
    DossierFamille = "DossierFamille",

    // DossierIntervention
    DossierIntervention = "DossierIntervention",

    // Gestionnaire
    Gestionnaire = "Gestionnaire",

    // Contact
    Contact = "Contact",

    // MarqueDossier
    MarqueDossier = "MarqueDossier",

    // ModeleDossier
    ModeleDossier = "ModeleDossier",

    // Contentieux
    Contentieux = "Contentieux",

    // Utilisateur
    Utilisateur = "Utilisateur",

    // Any
    Any = "Any"
}
