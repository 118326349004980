export enum ModeleDossierImageTypes {

    // Undefined.
    Undefined = "Undefined",

    // Principale.
    Principale = "Principale",

    // Additionnelle.
    Additionnelle = "Additionnelle",

    // Any.
    Any = "Any"
}
