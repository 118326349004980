import { Component, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IContentieuxDelaiDto } from '@models/dtos/runtime/dossiers/IContentieuxDelaiDto';
import { IDelaiTypeDto } from '@models/dtos/management/referentiel/IDelaiTypeDto';
import { ReferenceType } from '@models/enums/domain/ReferenceType';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { MessageKind } from '@models/enums/MessageKind';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DossiersService } from '@services/business/dossiers.service';

@Component({
  selector: 'contentieux-delai-view-dialog',
  templateUrl: './contentieux-delai-view-dialog.component.html'
})
export class ContentieuxDelaiViewDialogComponent implements OnInit {

  ReferenceTypes = ReferenceType;
  viewInitialized: boolean = false;

  @Input('item')
  public contentieuxDelai: IContentieuxDelaiDto;

  constructor(
    private dialogRef: MatDialogRef<ContentieuxDelaiViewDialogComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dateAdapter: DateAdapter<Date>,
    @Inject(LOCALE_ID) private locale: string,
    private dossiersService: DossiersService
  ) {
    this.contentieuxDelai = data.contentieuxDelai;
    
    this.dateAdapter.setLocale(this.locale);

    if (this.contentieuxDelai && !this.contentieuxDelai.type)
    {
      this.contentieuxDelai.type = <IDelaiTypeDto> { };
    }
  }
  
  ngOnInit() {
  }

  get checkForm(): string | null
  {
    let result = this.dossiersService.checkContentieuxDelai(this.contentieuxDelai);

    return result?.message;
  }

  save()
  {

    let message: string;
    if (message = this.checkForm)
    {
      SnackBarExtension.open(this.snackBar, message, MessageKind.Warning);
      return;
    }

    this.dialogRef.close(this.contentieuxDelai);
  }
}
