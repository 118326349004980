import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MessageKind } from '@models/enums/MessageKind';
import { IDocumentDto } from '@models/dtos/runtime/magasin/IDocumentDto';
import { EntityType } from '@models/enums/domain/EntityType';
import { DossiersService } from '@services/business/dossiers.service';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { ConfirmationDialogComponent } from '@components/shared/dialogs/confirmation-dialog.component';
import { UtilsHelper } from '@services/helpers/utils-helper';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { FilesAddDialogComponent } from '../files/files-add-dialog.component';
import { IResultItemDto } from '@models/dtos/common/IResultItemDto';

@Component({
  selector: 'documents-listview',
  templateUrl: './documents-listview.component.html'
})
export class DocumentsListViewComponent implements AfterViewInit, OnChanges {

  isLoading: boolean = false;

  pageSize: number;
  pageSizeOptions: number[] = GlobalConstants.pageSizeOptions_Dialog;

  displayedColumns: string[] = ['libelle', 'creationDate', 'star'];
  dataSource: MatTableDataSource<IDocumentDto>;

  showPaginator: boolean = true;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  @Input('class')
  public class: string;
  
  @Input()
  public items: IDocumentDto[];

  @Output()
  public itemsChange: EventEmitter<IDocumentDto[]> = new EventEmitter<IDocumentDto[]>();

  @Output()
  public selectionChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Input('excluding')
  public excludingDocuments: IDocumentDto[] = [];

  @Input('maxCount')
  public maxCount: number = -1;

  @Input('readonly')
  public readonly: boolean = false;

  @Input('entityType')
  public entityType: EntityType = EntityType.None;

  @Input('entityId')
  public entityId: string;

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public dossiersService: DossiersService
  ) {
    this.pageSize = this.pageSizeOptions[0];
  }

  ngAfterViewInit() {
    this.updatePaginator();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty("items")) {
      this.refreshItems();
    }
  }

  async refreshItems()
  {
    this.setItems(this.items, false);
    this.dataSource = new MatTableDataSource<IDocumentDto>(this.items);
    this.updatePaginator();
  }

  async updatePaginator()
  {
    this.showPaginator = this.items?.length > this.pageSize;
    if (this.dataSource)
    {
      this.dataSource.paginator = this.paginator;
    }
  }

  async addNewItem()
  {
    if (this.maxCount === -1 || this.items.length<this.maxCount)
    {
      let title = $localize `Ajouter un document`;

      const dialogRef = this.dialog.open(
        FilesAddDialogComponent,
        {
          panelClass: ['w-50', 'zoomed'],
          data: {
            entityType: this.entityType,
            entityId: this.entityId,
            maxCount: this.maxCount === -1 ? -1 : (this.maxCount - this.items.length),
            title: title,
            toolTip: $localize `Déposez votre fichier ici`
          },
          autoFocus: false
        });
  
      dialogRef.afterClosed().subscribe(files => {
        if(files?.length>0)
        {
          this.isLoading = true;
          switch(this.entityType)
          {
            case EntityType.Dossier: {
              this.dossiersService.addDossierDocuments(this.entityId, files).subscribe({
                next: result => {
                    this.addDocuments(result, files);
                    this.isLoading = false;
                  },
                  error: _ => {
                    SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception, $localize `Voir`, 5000);
                    this.isLoading = false;
                  }
              });
        
              break;
            }
            case EntityType.Contentieux: {
              this.dossiersService.addContentieuxDocuments(this.entityId, files).subscribe({
                next: result => {
                  this.addDocuments(result, files);
                  this.isLoading = false;
                },
                error: _ => {
                  SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception, $localize `Voir`, 5000);
                  this.isLoading = false;
                }
              });
    
              break;
            }
          }        
        }
      });  
    }
  }

  addDocuments(result: IResultItemDto[], files: any[]) {

    if (result) {
      let items = this.items;
      let i = 0;

      result?.filter(q=> !(items.find(p=>p.id?.toString() === q.key?.toString())))
        .forEach(q=> {
          items.push(<IDocumentDto> { id: q.key, libelle: files[i]?.name, creationDate: new Date(Date.now())}); 
          i++;
        });

      this.setItems(items);
    }
  }

  async downloadItem(doc: IDocumentDto) {

    switch(this.entityType)
    {
      case EntityType.Dossier: {
        this.dossiersService.downloadDossierDocument(this.entityId, doc?.id).subscribe({
          next: (result: any) => {
            UtilsHelper.downloadFile(result);
          }, 
          error: _ => {
            SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du téléchargement`, MessageKind.Exception);
          }
        });
      
        break;
      }
      case EntityType.Contentieux: {
        this.dossiersService.downloadContentieuxDocument(this.entityId, doc?.id).subscribe({
          next: (result: any) => {
            UtilsHelper.downloadFile(result);
          },
          error : _=> {
            SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du téléchargement`, MessageKind.Exception);
          }
        });

        break;
      }
    }    
  }

  async removeItem(document: IDocumentDto)
  {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: $localize `Voulez-vous vraiment retirer cet élément ?`
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {    
        let index = this.items.indexOf(document);
        if (index > -1) {
          this.items.splice(index, 1);
        }
        this.setItems();
      }
    });
  }

  private setItems(documents: IDocumentDto[] = null, emit: boolean = true)
  {
    this.items = (documents ?? this.items).filter(q=> !(this.excludingDocuments?.find(p=>p.id === q.id)))
      .sort((a, b)=> (a.rang === 0 && b.rang > 0) || (a.rang > 0 && b.rang > 0 && a.rang > b.rang) || (a.rang === 0 && b.rang === 0 && a.creationDate < b.creationDate) ? -1 : 1);

    if (emit)
    {
      this.itemsChange.emit(this.items);
      this.selectionChange.emit(true);
    }
  }
}
