<div class="pane-center on-top" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="contact" class="snap-pane">

  <div class="row">
    <mat-form-field class="col-12 highlighted" appearance="outline">
      <mat-label i18n>Dénomination</mat-label>
      <input name="nom" #inputNom="matInput" matInput [(ngModel)]="contact.denomination" [readonly]="true" />
    </mat-form-field>  
  </div>
  <div class="row">
    <div class="row col-6 mp-0">
      <flags-selector i18n-label class="col-12" [(itemId)]="contact.personneType" [type]="FlagTypes.PersonneTypes" appearance="outline"
        [readonly]="true" label="Type de personne"></flags-selector>
    </div>  
    <div class="row col-6 mp-0">
      <references-selector i18n-label class="col-12" [(items)]="contact.exercicePayss" [type]="ReferenceTypes.Pays" appearance="outline"
        [readonly]="true"
        label="Pays d'exercice"></references-selector>
    </div>
  </div>
  
  <div class="row"> 
    <div class="row col-6 mp-0">
      <references-selector i18n-label class="col-12" [(item)]="contact.tribunal" [type]="ReferenceTypes.Tribunaux" appearance="outline"
        [readonly]="true"
        label="Tribunal"></references-selector>
    </div> 

    <div class="row col-6 mp-0">
      <references-selector i18n-label class="col-12" [(item)]="contact.type" [type]="ReferenceTypes.ContactTypes" appearance="outline"
        [readonly]="true" label="Type de contact"></references-selector>
    </div>
  </div> 

  <div class="row"> 
    <div class="col-6 mp-0">
      <div class="col-12">
        <adresse-view class="col-12" [(adresse)]="contact.adresse" [readonly]="true"></adresse-view>
      </div>

      <div class="col-12 mp-0">
        <mat-form-field class="col-6" appearance="outline">
          <mat-label i18n>Téléphone</mat-label>
          <input name="telephone" matInput [(ngModel)]="contact.telephone" [readonly]="true" />
        </mat-form-field>
  
        <mat-form-field class="col-6" appearance="outline">
          <mat-label i18n>Mobile</mat-label>
          <input name="mobile" matInput [(ngModel)]="contact.mobile" [readonly]="true" />
        </mat-form-field>
      </div>

      <mat-form-field class="col-12" appearance="outline">
        <mat-label i18n>Email</mat-label>
        <input name="email" matInput [(ngModel)]="contact.email" [readonly]="true" />
      </mat-form-field>
    </div>
    
    <div class="col-6 mp-0">
      <div class="col-12 pt-3">
        <strong i18n>Interlocuteurs</strong>
        &nbsp;<strong>{{contact.interlocuteurs?.length ? '(' + contact.interlocuteurs?.length + ')' : ''}}</strong>
      </div>
      <div class="col-12 pt-4">
        <interlocuteurs-listview [(items)]="contact.interlocuteurs" [readonly]="true"></interlocuteurs-listview>
      </div>
    </div>
  </div> 
</div>
