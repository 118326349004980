export enum DossierStatuts {

    // Undefined = 0.
    Undefined = "Undefined",

    // A déposer = 1
    ADeposer = "ADeposer",

    // Deposée = 2
    Depose = "Depose",

    // Publiée = 3
    Publie = "Publie",

    // Enregistrée = 4
    Enregistre = "Enregistre",

    // En cours de renouvellement = 5
    EnCoursRenouvellement = "EnCoursRenouvellement",

    // Renouvelée = 6
    Renouvele = "Renouvele",

    // Contestée / Refusée = 7
    Conteste_Refuse = "Conteste_Refuse",

    // Abandonnée = 8
    Abandonne = "Abandonne",

    // Rejetée = 10
    Rejete = "Rejete",

    /// Opposé.
    Oppose = "Oppose",

    /// Cessation de mandat.
    MandatCessation = "MandatCessation",

    // Non en gestion = 20
    NonEnGestion = "NonEnGestion",

    // Enregistrée/action en invalidité en cours = 21
    InvaliditeEnCours = "InvaliditeEnCours"
}
