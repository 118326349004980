import { Component, Input, OnInit, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ClientsSearchDialogComponent } from '@components/shared/clients/clients-search-dialog.component';
import { ContentieuxSearchDialogComponent } from '@components/shared/dossiers/contentieux/contentieux-search-dialog.component';
import { DossiersSearchDialogComponent } from '@components/shared/dossiers/dossiers-search-dialog.component';
import { GestionnairesSearchDialogComponent } from '@components/shared/gestionnaires/gestionnaires-search-dialog.component';
import { ContactsSearchDialogComponent } from '@components/shared/contacts/contacts-search-dialog.component';
import { EmployeesSearchDialogComponent } from '@components/shared/employees/employees-search-dialog.component';
import { EntityType } from '@models/enums/domain/EntityType';
import { ContactsService } from '@services/business/contacts.service';
import { DossiersService } from '@services/business/dossiers.service';
import { IamService } from '@services/app/iam.service';
import { Observable } from 'rxjs';
import { DossierFamillesSearchDialogComponent } from '@components/shared/dossiers/familles/familles-search-dialog.component';
import { DossierNatures } from '@models/enums/domain/DossierNatures';
import { PageHistoryService } from '@services/nav/page-history.service';
import { AuthService } from '@services/app/auth.service';
import { AppRole } from '@models/enums/iam/AppRole';
import { QueryResultModes } from '@models/enums/QueryResultModes';

@Component({
  selector: 'entities-selector',
  templateUrl: './entities-selector.component.html'
})
export class EntitiesSelectorComponent implements OnInit, OnChanges {

  public viewItemEnabled = true;

  @Input('type')
  public entityType: EntityType = EntityType.None;

  @Input('appearance')
  public appearance: string;

  @Output()
  public selectionChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Input()
  public items: any[];

  @Output()
  public itemsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input()
  public item: any;

  @Output()
  public itemChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public itemIds: string[];

  @Output()
  public itemIdsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Input()
  public itemId: string;

  @Output()
  public itemIdChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public viewOnItemClicked: boolean = true;

  @Output('viewItem')
  public viewItemChange: EventEmitter<any> = new EventEmitter<any>();

  // 0: items; 1: itemIds; 2: item; 3: itemId
  private itemMode: number = 0;

  @Input('excluding')
  public excludingItems: any[] = [];
  
  @Input('data')
  public data: any;

  @Input('maxCount')
  public maxCount: number = -1;

  @Input('label')
  public label: string = null;

  @Input('readonly')
  public readonly: boolean = false;

  @Input('required')
  public required: boolean = false;

  @Output("valid")
  public validChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(MatMenuTrigger,{static:false}) menu: MatMenuTrigger; 

  get valid(): boolean | null
  {
    let b = !this.required || this.items?.length > 0;
    return b;
  }

  constructor(
    private pageHistoryService: PageHistoryService,
    public contactsService: ContactsService,
    public dossiersService: DossiersService,
    public iamService: IamService,
    public authService: AuthService,
    private dialog: MatDialog
  ) {
  }
  
  ngOnInit() {

    //this.refreshItems();
  }
  
  ngOnChanges(changes: SimpleChanges) {

    if (changes.hasOwnProperty("entityType")) {
      this.viewItemEnabled = !(this.entityType === EntityType.DossierFamille
        || (this.entityType === EntityType.FidalEmployee && !this.authService.hasRoles(AppRole.Super))
        || (this.entityType === EntityType.Gestionnaire && !this.authService.hasRoles(AppRole.Super))
        || (this.entityType === EntityType.Contact && !this.authService.hasRoles(AppRole.Manager, AppRole.Admin, AppRole.Super)));
    }

    if (changes.hasOwnProperty("items")) {
      this.itemMode = 0;
    }

    if (changes.hasOwnProperty("itemIds")) {
      this.items = [];

      this.itemIds?.forEach(q=>
      {
        let item = <any> { id: q }; 
        this.items.push(item);

        if (!this.isItemLoaded(item))
        {
          this.loadItem(item);
        }
      });

      this.itemMode = 2;

      this.refreshItems();
    }

    if (changes.hasOwnProperty("itemId")) {

      if (!this.itemId) this.itemId=null;
      this.item = <any> { id: this.itemId };
      this.items = [this.item];
      if (this.itemId && !this.isItemLoaded(this.item))
      {
        this.loadItem(this.item);
      }

      this.maxCount = 1;
      this.itemMode = 3;

      this.refreshItems();
    }

    if (changes.hasOwnProperty("item")) {
      this.items = [];

      if (!this.item?.id)
      {
        this.item = null;
      }
      if (this.item)
      {
        this.items = [ this.item ];
      }  

      this.maxCount = 1;
      this.itemMode = 2;

      this.refreshItems();
    }
  }

  async refreshItems()
  {
    this.setItems(this.items, false);
  }

  getItemIcon() {
    switch(this.entityType)
    {
      case EntityType.Client:
        return "domain";
      case EntityType.Contact:
        return "portrait";
      case EntityType.Contentieux:
        return "compare_arrow";
      case EntityType.Dossier:
        return "layers";
      case EntityType.DossierFamille:
        return "storage";
      case EntityType.Gestionnaire:
      case EntityType.FidalEmployee:
          return "how_to_reg";
      case EntityType.MarqueDossier:
        return "tab";
      case EntityType.ModeleDossier:
        return "wallpaper";
    }

    return "article";    
  }

  getItemText(item: any)
  {
    switch(this.entityType)
    {
      case EntityType.Client:
      case EntityType.Contact:
          return item.denomination ?? "-";
      case EntityType.Dossier:
      case EntityType.Contentieux:
      case EntityType.MarqueDossier:
      case EntityType.ModeleDossier:
        return !item.reference || !item.denomination ? "-" : ((item.reference ?? "-") + ' | ' + (item.denomination ?? "-"));
      case EntityType.DossierFamille:
        return item.libelle;
      case EntityType.Gestionnaire:
        return (!item.prenom ? "" : item.prenom + " ") + (item.nom ?? "-");
      case EntityType.FidalEmployee:
        return (!item.firstName ? "" : item.firstName + " ") + (item.lastName ?? "-");
    }

    return "";
  }

  getComparingItemText(item: any)
  {
    switch(this.entityType)
    {
      case EntityType.FidalEmployee:
        return item.lastName;
      default:
        return this.getItemText(item);
    }
  }

  getItemsText(): string
  {
    let text = !this.items || this.items?.length===0 ? $localize `Aucun` : this.items.map(q=> this.getItemText(q)).join(', ');
    return text;
  }

  async emptyItems()
  {
    if (!this.readonly)
    {
      this.setItems([]);
    }
  }

  isItemLoaded(item: any): boolean {
    switch(this.entityType)
    {
      case EntityType.Client:
      case EntityType.Contact:
      case EntityType.Contentieux:
      case EntityType.Dossier:
      case EntityType.MarqueDossier:
      case EntityType.ModeleDossier:
        return item?.denomination;
        break;
      case EntityType.DossierFamille:
        return item?.libelle;
        break;    
      case EntityType.Gestionnaire:
        return item?.prenom || item?.nom;
        break;
    }
    
    return true;
  }

  loadItem(item: any)
  {
    if (item)
    {
      let obs: Observable<any>;
      switch(this.entityType)
      {
        case EntityType.Client:
          obs = this.contactsService.getClient(item?.id, QueryResultModes.Quick);
          break;
        case EntityType.Contact:
          obs = this.contactsService.getContact(item?.id, QueryResultModes.Quick);
          break;
        case EntityType.Contentieux:
          obs = this.dossiersService.getContentieux(item?.id, QueryResultModes.Quick);
          break;
        case EntityType.Dossier:
          obs = this.dossiersService.getDossier(item?.id, QueryResultModes.Quick);
          break;
        case EntityType.DossierFamille:
          obs = this.dossiersService.getDossierFamille(item?.id, QueryResultModes.Quick);
          break;
        case EntityType.FidalEmployee:
          obs = this.iamService.getFidalEmployee(item?.id, QueryResultModes.Quick);
          break;
        case EntityType.Gestionnaire:
          obs = this.iamService.getUtilisateur(item?.id, QueryResultModes.Quick);
          break;
        case EntityType.MarqueDossier:
          obs = this.dossiersService.getMarqueDossier(item?.id, QueryResultModes.Quick);
          break;
        case EntityType.ModeleDossier:
          obs = this.dossiersService.getModeleDossier(item?.id, QueryResultModes.Quick);
          break;
      }

      obs?.subscribe({
        next: result => {
          switch(this.entityType)
          {
            case EntityType.Client:
            case EntityType.Contact:
            case EntityType.Contentieux:
            case EntityType.Dossier:
            case EntityType.MarqueDossier:
            case EntityType.ModeleDossier:
              item.denomination = result?.denomination;
              break;
            case EntityType.DossierFamille:
              item.libelle = result?.libelle;
              break;    
            case EntityType.Gestionnaire:
              item.prenom = result?.prenom;
              item.nom = result?.nom;
              break;
          }
        },
        error: _ => {
        }
      });
    }
  }

  async addItem()
  {
    if (!this.readonly)
    {
      if (this.maxCount === -1 || this.items.length===1 || this.items.length<this.maxCount)
      {
        let dialogRef;
        let config = {
          panelClass: ['w-50', 'zoomed'],
          data: <any> {
            excluding: [...this.items, ...this.excludingItems ?? []],
            maxCount: this.maxCount
          },
          autoFocus: false,
        };
        
        config.data.title = this.data?.title;
        switch(this.entityType)
        {
          case EntityType.Client:
            config.data.intervenantType = this.data?.intervenantType;
            config.data.clientType = this.data?.clientType;
            config.data.parentId = this.data?.parentId;
            config.data.personneType = this.data?.personneType;
            dialogRef = this.dialog.open(ClientsSearchDialogComponent, config);
            break;
          case EntityType.Contact:
            config.data.contactNature = this.data?.contactNature;
            config.data.intervenantType = this.data?.intervenantType;
            dialogRef = this.dialog.open(ContactsSearchDialogComponent, config);
            break;
          case EntityType.Contentieux:
            config.data.clientIds = this.data?.clientIds;
            config.data.dossierNature = this.data?.dossierNature;
            dialogRef = this.dialog.open(ContentieuxSearchDialogComponent, config);
            break;
          case EntityType.Dossier:
            config.data.clientIds = this.data?.clientIds;
            config.data.dossierNature = this.data?.dossierNature;
            dialogRef = this.dialog.open(DossiersSearchDialogComponent, config);
            break;
          case EntityType.DossierFamille:
            config.data.clientIds = this.data?.clientIds;
            config.data.readonly = this.data.readonly;
            dialogRef = this.dialog.open(DossierFamillesSearchDialogComponent, config);
            break;
          case EntityType.FidalEmployee:
            config.data.isAvailable = this.data?.isAvailable;
            dialogRef = this.dialog.open(EmployeesSearchDialogComponent, config);
            break;
          case EntityType.Gestionnaire:
            config.data.clientsAccesType = this.data?.clientsAccesType;
            dialogRef = this.dialog.open(GestionnairesSearchDialogComponent, config);
            break;
          case EntityType.MarqueDossier:
            config.data.clientIds = this.data?.clientIds;
            config.data.dossierNature = DossierNatures.Marque;
            config.data.marqueRegistreTypes = this.data?.marqueRegistreTypes;
            dialogRef = this.dialog.open(DossiersSearchDialogComponent, config);
            break;
          case EntityType.ModeleDossier:
            config.data.clientIds = this.data?.clientIds;
            config.data.dossierNature = DossierNatures.Modele;
            dialogRef = this.dialog.open(DossiersSearchDialogComponent, config);
            break;
        }
    
        dialogRef?.afterClosed().subscribe(
        {
          next: result => {
            if(result)
            {
              let items: any[] = [];
  
              if (this.maxCount===1)
              {
                items=result;
              }
              else
              {
                items = this.items;
                let newItems = result;
  
                // new items must not be existing ones
                
                newItems
                  .filter(q=> !(items.find(p=>p.id === q.id)))
                  .forEach(q=> { items.push(q); });
              }
  
              this.setItems(items);
            }
          }
        });  
      }
    }
  }
    
  async removeItem(entity: any)
  {
    let items = this.items;
    let index = items.indexOf(entity);
    if (index > -1) {
      items.splice(index, 1);
      this.setItems(items);
    }
  }

  private setItems(items: any[], emit: boolean = true)
  {
    items = items?.sort((a, b)=> this.getComparingItemText(a).localeCompare(this.getComparingItemText(b)));

    if(!this.items)
    {
      items = [];
    }

    items = items?.filter(q=> q.id && !(this.excludingItems?.find(p=> p.id === q.id)));

    this.items = items;

    if (this.itemMode === 2 || this.itemMode === 3)
    {
      this.item = this.items.find(q=>q) ?? null;
    }

    if (emit)
    {
      switch (this.itemMode)
      {
        case 0:
          this.itemsChange.emit(this.items);
          break;
        case 1:
          this.itemIdsChange.emit(this.items);
          break;
        case 2:
          this.itemChange.emit(this.item);
          break;
        case 3:
          let itemId = this.item?.id;
          this.itemIdChange.emit(itemId);
          break;
      }

      this.selectionChange.emit(true);
      this.validChange.emit(this.valid);  
    }
  }

  showMenu() {
    if (this.items?.length === 0) {
      this.addItem();
    }
    else
    {
      this.menu.openMenu();
    }
  }

  viewItem(item: any)
  {
    if (!item) return;

    if (!this.viewOnItemClicked)
    {
      this.viewItemChange?.emit(item);
    }
    else
    {
      switch(this.entityType)
      {
        case EntityType.Client:
          this.pageHistoryService.navigateByUrl("/clients/" + item.id);
          break;
        case EntityType.Contentieux:
          this.pageHistoryService.navigateByUrl("/folders/litigations/" + item.id);
          break;
        case EntityType.Dossier:
          switch(item?.nature)
          {
            case DossierNatures.Marque:
              this.pageHistoryService.navigateByUrl("/folders/trademarks/" + item.id);
              break;
            case DossierNatures.Modele:
              this.pageHistoryService.navigateByUrl("/folders/designs/" + item.id);
              break;
            }
          break;
        case EntityType.DossierFamille:
          this.pageHistoryService.navigateByUrl("/folders/families/" + item.id);
          break;
        case EntityType.FidalEmployee:
          break;
        case EntityType.Gestionnaire:
          this.pageHistoryService.navigateByUrl("/parameters/users/" + item.id);
          break;
        case EntityType.Contact:
          this.pageHistoryService.navigateByUrl("/contacts/" + item.id);
          break;  
        case EntityType.MarqueDossier:
          this.pageHistoryService.navigateByUrl("/folders/trademarks/" + item.id);
          break;
        case EntityType.ModeleDossier:
          this.pageHistoryService.navigateByUrl("/folders/designs/" + item.id);
          break;
      }
    }
  }
}
