import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { IContactDto } from '@models/dtos/runtime/contacts/IContactDto';
import { MessageKind } from '@models/enums/MessageKind';
import { QueryResultModes } from '@models/enums/QueryResultModes';
import { ResourceStatus } from '@models/enums/ResourceStatus';
import { IContactsRequestFormPoco } from '@models/pocos/forms/IContactsRequestFormPoco';
import { ContactsService } from '@services/business/contacts.service';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { UtilsHelper } from '@services/helpers/utils-helper';
import { PersonneTypes } from '@models/enums/domain/PersonneTypes';
import { AppPolicy } from '@models/enums/iam/AppPolicy';
import { KeyCodes } from '@models/enums/KeyCodes';
import { ContactViewDialogComponent } from './contact-view-dialog.component';
import { IntervenantTypes } from '@models/enums/domain/IntervenantTypes';
import { ContactNatures } from '@models/enums/domain/ContactNatures';

@Component({
  selector: 'contacts-search-dialog',
  templateUrl: './contacts-search-dialog.component.html'
})
export class ContactsSearchDialogComponent implements OnInit, AfterViewInit {

  AppPolicies = AppPolicy;
  
  pageSizeOptions: number[] = GlobalConstants.pageSizeOptions_Dialog;

  public title: string;

  @Input('contactNature')
  public contactNature: ContactNatures = ContactNatures.Any;

  @Input('intervenantType')
  public intervenantType: IntervenantTypes = IntervenantTypes.Any;

  @ViewChild('inputMotCle') inputMotCle: MatInput;

  isLoading: boolean = false;

  public requestForm: IContactsRequestFormPoco;

  contacts: IContactDto[];
  itemsTotalCount: number;

  displayedColumns: string[];
  dataSource: MatTableDataSource<IContactDto>;
  selection = new SelectionModel<IContactDto>(true, []);

  @Input('excluding')
  public excludingContacts: IContactDto[] = [];

  @Input('maxCount')
  public maxCount: number = -1;

  @Input('readonly')
  public readonly: boolean = true;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    private contactsService: ContactsService,
    private dialogRef: MatDialogRef<ContactsSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,    
    private dialog: MatDialog
  ) {
    this.dialogRef.disableClose = true;

    if (data)
    {
      this.excludingContacts = data.excluding;
      this.maxCount = data.maxCount ?? -1;
      this.readonly = data.readonly;
      this.contactNature = data.contactNature;
      this.intervenantType = data.intervenantType;      
      this.title = data.title;
    }
    this.title ??= $localize `Contacts`;

    if (this.maxCount===1)
    {
      this.displayedColumns = ['denomination', 'star'];
    }
    else
    {
      this.displayedColumns = ['select', 'denomination', 'star'];
    }
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === KeyCodes.ESCAPE) {
        this.dialogRef.close();
      }
    });
    
    this.initRequestForm();
    this.refreshContacts();
  }
  
  ngAfterViewInit() {
    setTimeout(() => {
      this.inputMotCle.focus();
    });
  }

  async initRequestForm()
  {
    this.requestForm = <IContactsRequestFormPoco> { 
      resultMode: QueryResultModes.Partial,
      contactNature: this.contactNature,
      intervenantType: this.intervenantType,
      pageIndex: 0,
      pageSize: 8,
      orderBy: "denomination asc"
    };
  }

  changePage() {
    this.requestForm.pageSize = this.paginator?.pageSize;
    this.requestForm.pageIndex = this.paginator?.pageIndex;

    this.refreshContacts();
  }

  async refreshContacts(initPageIndex: boolean = false) {

    if (initPageIndex)
    {
      this.requestForm.pageIndex = 0;
    }
    this.paginator.pageIndex = this.requestForm.pageIndex;
    this.requestForm.resultMode = QueryResultModes.Partial;

    this.isLoading = true;

    this.contactsService.listContacts(this.requestForm).subscribe(result => {

      this.contacts = result.items;
      this.dataSource = new MatTableDataSource<IContactDto>(this.contacts);
      UtilsHelper.updateDataPageInfo(this.requestForm, result);
      this.itemsTotalCount = result?.totalCount;

      this.isLoading = false;
    }, error => {
      SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
      this.isLoading = false;
    });
  }
  
  selectContacts()
  {
    this.dialogRef.close(this.selection.selected);
  }
   
  selectContact(contact: IContactDto)
  {
    if (contact)
    {
      this.dialogRef.close([contact]);
    }
  }

  isExcluded(contact: IContactDto)
  {
    return this.excludingContacts.find(q=> contact?.id === q.id);
  }

  async addNewContact()
  {
    let contact = <IContactDto> { 
      personneType: PersonneTypes.Morale
    };
    this.contactsService.repairContact(contact);

    const dialogRef = this.dialog.open(
      ContactViewDialogComponent,
      {
        panelClass: 'w-50',
        data: {
          contact: contact,
          readonly: false        
        },
        autoFocus: false
      });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        let contact = result;

        this.contactsService.addContact(contact).subscribe(
        {
          next: result => {

            if(result.status=== ResourceStatus.Created)
            {
              contact.id = result.key;
              SnackBarExtension.open(this.snackBar, $localize `Création effectuée`, MessageKind.Success);
  
              this.dialogRef.close([contact]);
            }
          },
          error: _ =>
          {
            SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors de l'ajout`, MessageKind.Exception);
          }
        });  
      }
    });
  }

  // Multiselection ---------------

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected())
    {
      this.selection.clear();
    }
    else
    {
      this.selection.clear();
      this.dataSource.data.forEach(row => !this.isExcluded(row) && this.selection.select(row));
    }
  }

  checkboxLabel(row?: IContactDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
  
  search() {
    if (this.requestForm.motcle?.length>3) { 
      this.refreshContacts(true); 
    }
  }

  select() {
    if (this.contacts?.length===1)
    {
      this.selectContact(this.contacts[0]);
    }
    else
    {
      this.refreshContacts(true);
    }
  }
}
