import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MessageKind } from '@models/enums/MessageKind';
import { SnackBarExtension } from '@services/nav/snackbar-extension';
import { MatPaginator } from '@angular/material/paginator';
import { FlagType } from '@models/enums/domain/FlagType';
import { ReferentielService } from '@services/business/referentiel.service';
import { IFlagsRequestFormPoco } from '@models/pocos/forms/IFlagsRequestFormPoco';
import { GlobalConstants } from '@models/app/GlobalConstants';
import { ActionKind } from '@models/enums/ActionKind';
import { UtilsHelper } from '@services/helpers/utils-helper';
import { IActionLogDto } from '@models/dtos/system/IActionLogDto';
import { IActionLogsRequestFormPoco } from '@models/pocos/forms/IActionLogsRequestFormPoco';
import { SystemService } from '@services/business/system.service';
import { EntityType } from '@models/enums/domain/EntityType';
import { PageHistoryService } from '@services/nav/page-history.service';
import { ExportFormats } from '@models/enums/domain/ExportFormats';
import { ReferenceType } from '@models/enums/domain/ReferenceType';
import { AppPolicy } from '@models/enums/iam/AppPolicy';
import { AuthService } from '@services/app/auth.service';
import { IUtilisateurDto } from '@models/dtos/management/securite/IUtilisateurDto';
import { StorageHelper } from '@services/helpers/storage-helper';
import { StorageKeys } from '@models/app/StorageKeys';
import * as moment from 'moment';
import { ExportHelper } from '@services/helpers/export-helper';
import { ExportType } from '@models/enums/domain/ExportType';
import { ITSearchRequestPoco } from '@models/pocos/ITSearchRequestPoco';
import { IOrderByStatementDto } from '@models/dtos/data/queries/IOrderByStatementDto';
import { OrderByKinds } from '@models/enums/OrderByKinds';
import { MatDialog } from '@angular/material/dialog';
import { ColumnsExportDialogComponent } from '../requetes/columns/columns-export-dialog.component';
import { GlobalVariables } from '@models/app/GlobalVariables';
  
@Component({
  selector: 'actionlogs-liveview',
  templateUrl: './actionlogs-liveview.component.html'
})
export class ActionLogsLiveViewComponent implements OnInit {
  
  ExportFormats = ExportFormats;
  EntityTypes = EntityType;
  FlagTypes = FlagType;
  ReferenceTypes = ReferenceType;
  ActionKinds = ActionKind;
  AppPolicies = AppPolicy;

  pageSizeOptions: number[] = GlobalConstants.pageSizeOptions_List;

  actionKinds: any = {};
  entityTypes: any = {};

  isSearching: boolean = false;
  isDeleting: boolean = false;
  isExporting: boolean = false;

  moreFiltersShowed: boolean;

  displayedColumns: string[] = ['date', 'action', 'entity', 'item', 'utilisateur'];
  dataSource: MatTableDataSource<IActionLogDto>;

  public actionLogs: IActionLogDto[];
  actionLogsTotalCount: number;

  selection = new SelectionModel<IActionLogDto>(true, []);

  showPaginator: boolean = true;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  
  @Input('itemEntityType')
  itemEntityType: EntityType;

  @Input('itemId')
  itemId: string;

  @Input('debutDate')
  debutDate: Date;

  @Input('finDate')
  finDate: Date;

  @Input('utilisateurId')
  utilisateurId: string;
  
  requestForm: IActionLogsRequestFormPoco;    

  @Output()
  public listChange: EventEmitter<IActionLogDto[]> = new EventEmitter<IActionLogDto[]>();

  @Input('excluding')
  public excludingActionLogs: IActionLogDto[] = [];

  @Input('readonly')
  public readonly: boolean = false;

  @Input('required')
  public required: boolean = false;

  @Input('itemMaxCount')
  public itemMaxCount: number = -1;

  @Output("valid")
  public validChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly defaultRequestForm: IActionLogsRequestFormPoco = <IActionLogsRequestFormPoco> { 
    debutDate: moment(new Date()).subtract(1 , 'month').toDate(),
    finDate: moment(new Date()).add(1, 'day').toDate(),
    itemEntityType: EntityType.Any
  };

  get valid(): boolean | null
  {
    let b = !this.required || (this.actionLogs && this.actionLogs.length>0);
    return b;
  }

  constructor(
    private authService: AuthService,
    private pageHistoryService: PageHistoryService,
    private systemService: SystemService = null,
    private referentielService: ReferentielService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(){
    this.refreshFlags();

    this.readonly = !this.authService.hasPolicies(AppPolicy.SecuriteWriters);

    this.initRequestForm();
  }

  changePage() {
    this.requestForm.pageSize = this.paginator?.pageSize;
    this.requestForm.pageIndex = this.paginator?.pageIndex;

    this.refreshActionLogs();
  }

  async initRequestForm(excludeStorage: boolean = false)
  {
    this.requestForm = StorageHelper.getSessionItem(
      StorageKeys.__formToken_AppLogs,
      this.defaultRequestForm,
      !excludeStorage && !this.utilisateurId,
      true,
      (p, stored)=> { p.pageSize = stored?.pageSize }
    );

    if (this.debutDate)
    {
      this.requestForm.debutDate = this.debutDate;
    }
    if (this.finDate)
    {
      this.requestForm.finDate = this.finDate;
    }

    this.requestForm.utilisateur = this.utilisateurId ? <IUtilisateurDto> { id: this.utilisateurId} : null;

    this.requestForm.orderBy = "date desc";
    this.requestForm.debutDate ??= null;
    this.requestForm.finDate ??= null;

    UtilsHelper.repairDataPageRequest(this.requestForm, this.pageSizeOptions);

    this.refreshActionLogs();
  }
  
  async refreshActionLogs(initPageIndex: boolean = false) {

    if (initPageIndex)
    {
      this.requestForm.pageIndex = 0;
    }
    this.requestForm.itemId = this.itemId;
    UtilsHelper.repairDataPageRequest(this.requestForm, GlobalConstants.pageSizeOptions_List);

    StorageHelper.setSessionItem(StorageKeys.__formToken_AppLogs, this.requestForm);

    this.isSearching = true;
    this.systemService.listActionLogs(this.requestForm).subscribe({
      next: result => {
        this.actionLogs = result.items;
        this.dataSource = new MatTableDataSource<IActionLogDto>(this.actionLogs);
        UtilsHelper.updateDataPageInfo(this.requestForm, result);
        this.actionLogsTotalCount = result?.totalCount;
        if (this.itemMaxCount > -1 && this.actionLogsTotalCount > this.itemMaxCount)
        {
          this.actionLogsTotalCount = this.itemMaxCount;
        }

        this.displayedColumns = this.itemId ? ['date', 'action'] : ['date', 'action', 'entity', 'item'];
        if (!this.utilisateurId) {
          this.displayedColumns.push("utilisateur");
        }

        this.showPaginator = this.actionLogsTotalCount > this.requestForm.pageSize;
        this.isSearching = false;
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
        this.isSearching = false;
      }      
    });
  }

  async refreshFlags() {

    // ActionKinds

    this.referentielService.listFlags(<IFlagsRequestFormPoco> { flagType: FlagType.ActionKinds }).subscribe({
      next: result => {
        this.actionKinds = {};
        if (result) {
          result.forEach(q=> { this.actionKinds[q.key]=q.content; });
        }
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
      }
    });

    // EntityTypes

    this.referentielService.listFlags(<IFlagsRequestFormPoco> { flagType: FlagType.EntityTypes }).subscribe({
      next: result => {
        this.entityTypes = {};
        if (result) {
          result.forEach(q=> { this.entityTypes[q.key]=q.content; });
        }
      },
      error: _ => {
        SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du chargement`, MessageKind.Exception);
      }
    });
  }

  viewItem(item: IActionLogDto) {
    if (!item || item.actionKind === ActionKind.Delete) return;

    switch(item.itemEntityType)
    {
      case EntityType.Client:
        this.pageHistoryService.navigate(['/clients', item.itemId ]);
        break;
      case EntityType.Contentieux:
        this.pageHistoryService.navigate(['/folders/litigations', item.itemId ]);
        break;
      case EntityType.DossierFamille:
        this.pageHistoryService.navigate(['/masterdata/recordfamilies', item.itemId ]);
        break;
      case EntityType.FidalEmployee:
        this.pageHistoryService.navigate(['/masterdata/users', item.itemId ]);
        break;
      case EntityType.Gestionnaire:
        this.pageHistoryService.navigate(['/masterdata/users', item.itemId ]);
        break;  
      case EntityType.Contact:
        this.pageHistoryService.navigate(['/contacts', item.itemId ]);
        break;  
      case EntityType.MarqueDossier:
        this.pageHistoryService.navigate(['/folders/trademarks', item.itemId ]);
        break;
      case EntityType.ModeleDossier:
        this.pageHistoryService.navigate(['/folders/designs', item.itemId ]);
        break;
    }
  }

  // Refresh ---------------

  isFormEmpty(): boolean {
    return !this.requestForm.debutDate && !this.requestForm.finDate;
  }

  // Export ---------------

  async exportAppLogs()
  {
    let selectedColumnNames= StorageHelper.getSessionItem(StorageKeys.__exportColumnsToken_AppLogs);

    const dialogRef = this.dialog.open(
      ColumnsExportDialogComponent,
      {
        panelClass: 'w-50',
        data: {
          exportType: ExportType.AppLogs,
          format: GlobalVariables.getExportFormat(),
          selectedColumnNames: selectedColumnNames
        },
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe(result => {

        if(result)
        {
          let exportForm: IActionLogsRequestFormPoco = ExportHelper.toDto(
            ExportHelper.toExportRequestForm<IActionLogsRequestFormPoco>(
              ExportType.AppLogs,
              result.format,
              <ITSearchRequestPoco<IActionLogsRequestFormPoco>> {
                formPoco: this.requestForm,
                orderByStatements: [<IOrderByStatementDto> { columnName: "date", kind: OrderByKinds.Ascending}]
              },
              result));

          StorageHelper.setSessionItem(StorageKeys.__exportColumnsToken_AppLogs, exportForm.columns);
          StorageHelper.setSessionItem(StorageKeys.__exportFormat, result.format);

          this.isExporting = true;
          this.systemService.exportActionLogs(exportForm).subscribe({
            next: (result: any) => {
              this.isExporting = false;
              UtilsHelper.downloadFile(result);
            },
            error: _ => {
              this.isExporting = false;
              SnackBarExtension.open(this.snackBar, $localize `Une erreur est survenue lors du téléchargement`, MessageKind.Exception);
            }
          });
        }
    });
  }  
}