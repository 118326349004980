import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {map, Observable} from 'rxjs';
import {IResultItemDto} from '@models/dtos/common/IResultItemDto'
import {IActionLogDto} from '@models/dtos/system/IActionLogDto'
import { UtilsHelper } from '@services/helpers/utils-helper'
import { AppService } from '@services/app/app.service'
import { AuthService } from '@services/app/auth.service';
import { IActionLogsRequestFormPoco } from '@models/pocos/forms/IActionLogsRequestFormPoco';
import { TDataPage } from '@models/dtos/common/TDataPage';

@Injectable()
export class SystemService {

    private baseApiUri_ActionLogs: string;

    constructor(
        private authService : AuthService,
        private httpClient: HttpClient,
        @Inject(LOCALE_ID) private locale: string
    ) {
        let baseApiUri: string = AppService.__Settings.platform.services[0].apiBaseUri + '/v1/app';
        this.baseApiUri_ActionLogs = baseApiUri + '/logs';
    }

    // Action logs

    listActionLogs(requestForm: IActionLogsRequestFormPoco): Observable<TDataPage<IActionLogDto>> {

        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<TDataPage<IActionLogDto>>(`${this.baseApiUri_ActionLogs}?${UtilsHelper.getQueryString(requestForm)}`, { headers: headers });
    }

    getActionLog(code: string): Observable<IActionLogDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get<IActionLogDto>(`${this.baseApiUri_ActionLogs}/${code || ""}`, { headers: headers });
    }

    addActionLog(actionLog: IActionLogDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.post<IResultItemDto>(`${this.baseApiUri_ActionLogs}`, actionLog, { headers: headers })
    }

    updateActionLog(actionLog: IActionLogDto): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.put<IResultItemDto>(`${this.baseApiUri_ActionLogs}`, actionLog, { headers: headers })
    }

    deleteActionLog(code: string): Observable<IResultItemDto> {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.delete<IResultItemDto>(`${this.baseApiUri_ActionLogs}/${code || ""}`, { headers: headers })
    }

    deleteActionLogs(codes: string[]): Observable<IResultItemDto[]> {
        const options = UtilsHelper.getUriOptionsWithBody(codes, this.authService);
        return this.httpClient.delete<IResultItemDto[]>(`${this.baseApiUri_ActionLogs}`, options);
    }
    
    exportActionLogs(requestForm: IActionLogsRequestFormPoco): any {
        let headers = UtilsHelper.getUriOptionHeaders(this.authService, this.locale);
        return this.httpClient.get(`${this.baseApiUri_ActionLogs}/export?${UtilsHelper.getQueryString(requestForm)}`, {headers: headers, observe: 'response', responseType: 'blob'})
            .pipe(map(item=> {

                let contentDispositionHeader = item.headers.get('Content-Disposition')
                let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
                let fileName = result.replace(/"/g, '');
        
                return {
                    blob: new Blob([item.body], {type: item.headers.get('Content-Type')}),
                    blobName:  fileName
                };
            }));
    }
}