import { Component } from '@angular/core';

@Component({
  selector: 'pane-agenda-component',
  templateUrl: './agenda.component.html'
})
export class AgendaComponent {

  constructor() {
  }
}
